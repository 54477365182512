<script lang="ts" setup>
import { ElementInputEvent } from '@/types/general'

import MyErrorMessage from '@/components/my-components/form/MyErrorMessage.vue'
import MyHelperMessage from '@/components/my-components/form/MyHelperMessage.vue'
import MyInputLabel from '@/components/my-components/form/MyInputLabel.vue'

export interface Props {
    modelValue: string | number | null
    id?: string
    groupClass?: string | string[] | Record<string, boolean>
    error?: string
    label?: string
    helper?: string
    border?: string
    background?: string
    noShadow?: boolean
    name?: string
}

const props = withDefaults(defineProps<Props>(), {
    id: (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1),
})

const emit = defineEmits<{
    (e: 'update:modelValue', value: string | number | null): void
    (e: 'focus', event: FocusEvent): void
    (e: 'blur', event: FocusEvent): void
}>()
</script>

<script lang="ts">
export default { inheritAttrs: false }
</script>

<template>
    <div class="group block w-full" :class="groupClass">
        <MyInputLabel v-if="props.label" :for="props.id" v-text="props.label" />

        <textarea
            :id="props.id"
            :class="[
                props.background ?? 'dark:bg-dark-400',
                props.border ??
                    'border-gray-300 focus:border-gray-300 dark:border-transparent dark:focus:border-transparent',
                {
                    'pl-8': $slots.icon,
                    'focus:shadow-lg dark:focus:shadow-dark-600': !props.noShadow,
                },
            ]"
            :value="props.modelValue || ''"
            class="flex w-full items-center rounded-xl border px-4 py-2.5 text-sm outline-none ring-0 transition-all placeholder:text-xs placeholder:font-semibold placeholder:uppercase placeholder:text-gray-400 focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:border disabled:bg-primary-50 dark:disabled:bg-dark-600 sm:text-sm"
            v-bind="$attrs"
            @blur="emit('blur', $event)"
            @focus="emit('focus', $event)"
            @input="emit('update:modelValue', ($event as ElementInputEvent).target.value)"
        />

        <MyHelperMessage :helper="props.helper" />
        <MyErrorMessage :input-name="props.name" :error="props.error" :label="props.label" />
    </div>
</template>
