<script lang="ts">
type valueType = string | number | boolean | null

interface Props {
    id?: string
    name?: string
    modelValue: valueType
    label?: string
    disabled?: boolean
    error?: string
    helper?: string
}
</script>

<script lang="ts" setup>
import { v4 as uuid } from 'uuid'
import { computed, provide, ref, watch } from 'vue'

import { radioGroupValueChangedKey, RadioGroupProps, radioGroupPropsKey } from '@/types/inputs'

import MyErrorMessage from '@/components/my-components/form/MyErrorMessage.vue'
import MyHelperMessage from '@/components/my-components/form/MyHelperMessage.vue'
import MyInputLabel from '@/components/my-components/form/MyInputLabel.vue'

const props = withDefaults(defineProps<Props>(), { id: () => uuid(), disabled: false })
const emit = defineEmits<{ (e: 'update:modelValue', value: valueType): void }>()

const wrapper = ref<HTMLDivElement>()

const data = computed<RadioGroupProps>(() => ({
    id: props.id,
    name: props.name,
    modelValue: props.modelValue,
    disabled: props.disabled,
}))

function updateValue(value: valueType) {
    emit('update:modelValue', value)
}

function focus() {
    const element = wrapper.value?.querySelector('input') as HTMLElement
    element?.focus()
}

/**
 * When the value changes outside the dom
 * We'll need to tell the element that it is not checked / no longer checked.
 */
function updateInputElementValues() {
    wrapper.value?.querySelectorAll('input')?.forEach((input) => {
        input.checked = input.value == data.value.modelValue
    })
}

watch(
    data,
    (data, oldData) => {
        if (data.modelValue !== oldData.modelValue) {
            updateInputElementValues()
        }
    },
    { deep: true },
)

provide(radioGroupPropsKey, data)
provide(radioGroupValueChangedKey, updateValue)

defineExpose({ focus })
</script>

<template>
    <div>
        <MyInputLabel v-if="props.label || $slots.label" :for="id" @click="focus">
            <slot name="label">{{ props.label }}</slot>
        </MyInputLabel>

        <div
            :id="props.id"
            ref="wrapper"
            class="inline-flex w-full items-stretch justify-between rounded-lg uppercase text-green-500 transition-colors"
        >
            <slot />
        </div>

        <MyHelperMessage :helper="props.helper" />
        <MyErrorMessage :input-name="props.name" :error="props.error" :label="props.label" />
    </div>
</template>
