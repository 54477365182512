<script lang="ts">
export interface Props {
    modelValue: boolean
    settlementTemplate?: SettlementTemplate
}
</script>

<script setup lang="ts">
import type { Except } from 'type-fest'

import { notify } from '@kyvg/vue3-notification'
import { Method } from 'axios'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { clamp, useEventListener } from '@vueuse/core'
import { v4 as uuid } from 'uuid'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { useConfirm } from '@/hooks/use-confirm'
import { MyButtonScheme } from '@/types/layout/my-button'
import {
    SettlementTemplateRate,
    SettlementTemplate,
    SettlementTemplateType,
    weekday,
    Timeslot,
} from '@/types/driver-report'
import { DropdownOption } from '@/types/inputs'

import MySelect from '@/components/my-components/form/MySelect.vue'
import MyInputLabel from '@/components/my-components/form/MyInputLabel.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import ContentEditable from '@/components/my-components/ContentEditable.vue'
import MyRadioButtonGroup from '@/components/my-components/form/MyRadioButtonGroup.vue'
import MyRadioButton from '@/components/my-components/form/MyRadioButton.vue'

const rateColors = ['#3f51b5', '#1e88e5', '#009688', '#795548', '#ffa000', '#00acc1']
const cellHeight = 30

interface DraggedTimeslot {
    dayOfWeek: weekday
    index: number
    start: boolean | null
    timeslotStart: number
    timeslotEnd: number
    clientY: number
    minStart: number
    maxEnd: number
}

interface Form extends Except<SettlementTemplate, 'id' | 'rates'> {
    rates: Record<string, SettlementTemplateRate>
}

enum HourResolution {
    Hours = 1,
    Quarters = 4,
}

const weekdays: weekday[] = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
]
const defaultRateId = uuid()
const defaultRates: Form['rates'] = {
    [defaultRateId]: { id: defaultRateId, name: 'T1', color: rateColors[0] },
}
const defaultDays: SettlementTemplate['days'] = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void
    (e: 'saved'): void
}>()

const authStore = useAuthStore()
const { t } = useI18n()
const confirm = useConfirm()

const form = useForm<Form>({
    name: '',
    type: SettlementTemplateType.Timebased,
    rates: defaultRates,
    days: defaultDays,
})
const draggedItem = ref<DraggedTimeslot | null>(null)
const rateModalOpen = ref(false)
const selectedTimeslot = ref<{ dayOfWeek: weekday; index: number } | null>(null)

const hourResolutions = ref<DropdownOption[]>([
    { label: t('hours'), value: HourResolution.Hours },
    { label: t('quarters'), value: HourResolution.Quarters },
])
const hourResolution = ref(HourResolution.Hours)

const virtualCellHeight = computed(() => cellHeight * hourResolution.value)

const availableColors = computed<string[]>(() => {
    const usedColors = Object.values(form.data.rates).map((rate) => rate.color)

    return rateColors.filter((color) => !usedColors.includes(color))
})
const visibleDays = computed<weekday[]>(() => {
    if (form.data.type === SettlementTemplateType.Hourbased) {
        return [weekdays[0]]
    }

    return weekdays
})

function startDrag(
    event: MouseEvent,
    dayOfWeek: weekday,
    index: number,
    startHandle: boolean | null,
) {
    event.preventDefault()

    const timeslot = form.data.days[dayOfWeek][index]
    let minStart = 0
    let maxEnd = 24

    for (const currentTimeslot of form.data.days[dayOfWeek]) {
        if (timeslot.start > currentTimeslot.start) {
            minStart = Math.max(minStart, currentTimeslot.end)
        }

        if (timeslot.start < currentTimeslot.start) {
            maxEnd = Math.min(maxEnd, currentTimeslot.start)
        }
    }

    draggedItem.value = {
        dayOfWeek: dayOfWeek,
        index: index,
        start: startHandle,
        timeslotStart: timeslot.start,
        timeslotEnd: timeslot.end,
        clientY: event.clientY,
        minStart,
        maxEnd,
    }
}

async function addTimeslot(event: MouseEvent, dayOfWeek: weekday, endHour: number) {
    const count = form.data.days[dayOfWeek].push({
        start: endHour - 1 / hourResolution.value,
        end: endHour,
        rate: Object.keys(form.data.rates)[0],
    })
    startDrag(event, dayOfWeek, count - 1, false)
}

function addRate() {
    const id = uuid()
    form.data.rates[id] = { id, name: 'New rate', color: availableColors.value[0] || '#333' }
}

function changeRate(dayOfWeek: weekday, index: number) {
    selectedTimeslot.value = { dayOfWeek, index }
    rateModalOpen.value = true
}

function saveRate(rate: SettlementTemplateRate) {
    if (!selectedTimeslot.value) return

    form.data.days[selectedTimeslot.value.dayOfWeek][selectedTimeslot.value.index].rate = rate.id
    rateModalOpen.value = false
}

async function removeRate(rate: SettlementTemplateRate) {
    try {
        await confirm(
            t('deleteEntityTitle', { entity: t('rate') }),
            t('areYouSure', { entity: t('rate') }),
            {
                confirmText: t('yes'),
                cancelText: t('no'),
                confirmButtonScheme: MyButtonScheme.Warning,
            },
        )
    } catch {
        return
    }

    delete form.data.rates[rate.id]
    for (const dayOfWeek of Object.keys(form.data.days)) {
        form.data.days[dayOfWeek as weekday].forEach((timeslot, index) => {
            if (timeslot.rate === rate.id) {
                form.data.days[dayOfWeek as weekday][index].rate = Object.keys(form.data.rates)[0]
            }
        })
    }
}

async function removeTimeslot() {
    if (!selectedTimeslot.value) return

    try {
        await confirm(
            t('deleteEntityTitle', { entity: t('timeslot') }),
            t('areYouSure', { entity: t('timeslot') }),
            {
                confirmText: t('yes'),
                cancelText: t('no'),
                confirmButtonScheme: MyButtonScheme.Warning,
            },
        )
    } catch {
        return
    }

    form.data.days[selectedTimeslot.value.dayOfWeek].splice(selectedTimeslot.value.index, 1)
    rateModalOpen.value = false
}

async function saveTemplate() {
    let route = window.route('dr.company.settlement-templates.store', authStore.companyId)
    let method: Method = 'POST'
    if (props.settlementTemplate) {
        method = 'PUT'
        route = window.route('dr.company.settlement-templates.update', [
            authStore.companyId,
            props.settlementTemplate!.id,
        ])
    }

    if (await form.submit(method, route)) {
        emit('update:modelValue', false)
        emit('saved')
        notify({ type: 'success', text: t('settlementTemplateSaved') })
    }
}

useEventListener('mousemove', (e) => {
    if (!draggedItem.value) return

    const deltaY = e.clientY - draggedItem.value.clientY

    const timeslot = form.data.days[draggedItem.value.dayOfWeek][draggedItem.value.index]!

    const draggedSlots =
        Math.floor((deltaY / virtualCellHeight.value) * hourResolution.value) / hourResolution.value

    if (draggedItem.value.start === null) {
        const start = snapResolution(draggedItem.value.timeslotStart + draggedSlots)
        const end = snapResolution(draggedItem.value.timeslotEnd + draggedSlots)
        if (start < draggedItem.value.minStart || end > draggedItem.value.maxEnd) return

        timeslot.start = start
        timeslot.end = end
    } else if (draggedItem.value.start) {
        const clampedStart = clamp(
            draggedItem.value.timeslotStart + draggedSlots,
            draggedItem.value.minStart,
            draggedItem.value.timeslotEnd - 1 / hourResolution.value,
        )

        timeslot.start = snapResolution(clampedStart)
    } else {
        const clampedEnd = clamp(
            draggedItem.value.timeslotEnd + draggedSlots,
            draggedItem.value.timeslotStart + 1 / hourResolution.value,
            draggedItem.value.maxEnd,
        )

        timeslot.end = snapResolution(clampedEnd)
    }
})

function snapResolution(value: number): number {
    /**
     * Snaps the resolution to the closest value based on the resolution.
     * E.g. 12.4 will become 12 when hourly and 12.4666 will become 12.45 when quarterly.
     */
    return Math.floor(value * hourResolution.value) / hourResolution.value
}

function determineHourResolution(days: Record<weekday, Timeslot[]>): void {
    for (const day of Object.values(days)) {
        for (const time of day) {
            if (time.end % 1 !== 0 || time.start % 1 !== 0) {
                hourResolution.value = HourResolution.Quarters
                return
            }
        }
    }
}

useEventListener('mouseup', () => {
    draggedItem.value = null
})

watch(
    () => props.modelValue,
    async (open) => {
        if (!open) return

        hourResolution.value = HourResolution.Hours

        const days = props.settlementTemplate?.days ?? defaultDays
        let rates = { ...defaultRates }

        determineHourResolution(days)

        if (props.settlementTemplate) {
            rates = {}
            for (const rate of props.settlementTemplate.rates) {
                rates[rate.id] = { ...rate }
            }
        }

        form.reset({
            name: props.settlementTemplate?.name ?? '',
            days: { ...days },
            rates,
            type: props.settlementTemplate?.type ?? SettlementTemplateType.Timebased,
        })
    },
)
</script>

<template>
    <MyModal :max-width="920" :value="props.modelValue" @close="emit('update:modelValue', false)">
        <template #title>{{ t('manageSettlementTemplate') }}</template>

        <MyForm :errors="form.errors.value" @submit.prevent="saveTemplate">
            <LoaderWrapper :visible="form.loading.value" />

            <div class="space-y-2">
                <MyInput v-model="form.data.name" name="name" :label="t('name')" />

                <MyRadioButtonGroup v-model="form.data.type" name="typw" :label="t('template')">
                    <MyRadioButton
                        :label="t('timebasedTemplate')"
                        :value="SettlementTemplateType.Timebased"
                    />
                    <MyRadioButton
                        :label="t('hourbasedTemplate')"
                        :value="SettlementTemplateType.Hourbased"
                    />
                </MyRadioButtonGroup>

                <MySelect
                    v-model="hourResolution"
                    :options="hourResolutions"
                    :placeholder="t('resolution')"
                    :label="t('resolution')"
                />

                <div>
                    <MyInputLabel v-text="t('rates')" />

                    <div class="flex flex-wrap space-x-2">
                        <div
                            v-for="(rate, id) in form.data.rates"
                            :key="id"
                            :style="{ backgroundColor: rate.color }"
                            class="mb-2 flex h-10 rounded-lg px-2 py-2 text-white"
                        >
                            <ContentEditable
                                v-model="rate.name"
                                tag="div"
                                class="rounded px-2 outline-none ring-white hover:ring focus:ring"
                                no-new-lines
                            />

                            <MyButton
                                v-if="Object.keys(form.data.rates).length > 1"
                                v-tooltip="t('delete')"
                                type="button"
                                class="ml-2 flex h-6 w-6 items-center justify-center rounded-lg bg-transparent text-xs text-red-500 drop-shadow-lg hover:bg-primary-200 dark:hover:bg-dark-800"
                                icon
                                size="micro"
                                scheme="danger"
                                @click="removeRate(rate)"
                            >
                                <mdi:trash-can />
                            </MyButton>
                        </div>

                        <MyButton type="button" scheme="primary" @click="addRate">
                            <mdi:plus />
                        </MyButton>
                    </div>
                </div>
            </div>

            <div class="mt-4 flex border-t">
                <div class="flex flex-col border-l">
                    <div class="border-b py-2 text-transparent">-</div>
                    <template v-for="hour in 24" :key="hour">
                        <div class="flex flex-col">
                            <template v-if="hourResolution == HourResolution.Quarters">
                                <div
                                    v-for="quarter in [15, 30, 45]"
                                    :key="`${hour}-${quarter}`"
                                    class="flex flex-shrink-0 items-center justify-end border-b px-2 font-mono"
                                    :style="{ height: cellHeight + 'px' }"
                                >
                                    <span
                                        v-if="form.data.type === SettlementTemplateType.Hourbased"
                                        v-text="`${hour - 1}${t('h')}${quarter}${t('m')}`"
                                    />
                                    <span
                                        v-else
                                        v-text="
                                            `${(hour - 1).toString().padStart(2, '0')}:${quarter}`
                                        "
                                    />
                                </div>
                            </template>

                            <div
                                class="flex flex-shrink-0 items-center justify-end border-b px-2 font-mono"
                                :style="{ height: cellHeight + 'px' }"
                            >
                                <span
                                    v-if="form.data.type === SettlementTemplateType.Timebased"
                                    v-text="hour.toString().padStart(2, '0') + ':00'"
                                />
                                <span v-else v-text="hour + t('h')" />
                            </div>
                        </div>
                    </template>
                </div>

                <div class="flex w-full border-l">
                    <div v-for="dow in visibleDays" :key="dow" class="w-full border-r">
                        <div
                            v-if="form.data.type === SettlementTemplateType.Timebased"
                            class="border-b py-2 text-center"
                            v-text="t(dow)"
                        />
                        <div v-else class="border-b py-2 text-center" v-text="t('times')" />

                        <div class="relative">
                            <div
                                v-for="hour in 24 * hourResolution"
                                :key="hour"
                                class="cursor-pointer border-b text-center text-transparent hover:text-black"
                                :style="{ height: cellHeight + 'px' }"
                                @mousedown="addTimeslot($event, dow, hour / hourResolution)"
                            >
                                +
                            </div>

                            <div
                                v-for="(timeslot, index) in form.data.days[dow]"
                                :key="index"
                                :style="{
                                    transform: `translateY(${
                                        timeslot.start * virtualCellHeight
                                    }px)`,
                                    height:
                                        (timeslot.end - timeslot.start) * virtualCellHeight + 'px',
                                    backgroundColor: form.data.rates[timeslot.rate].color,
                                }"
                                class="absolute left-0 top-0 flex w-full cursor-pointer items-center justify-center overflow-hidden rounded-lg px-2 text-white"
                                @mousedown.prevent="startDrag($event, dow, index, null)"
                            >
                                <span
                                    class="block w-full truncate text-center text-sm"
                                    v-text="form.data.rates[timeslot.rate].name"
                                />
                                <span
                                    class="cursor-pointer pl-2 text-sm hover:text-gray-300"
                                    @click="changeRate(dow, index)"
                                >
                                    <mdi:pencil />
                                </span>

                                <div
                                    class="absolute top-0 left-0 h-2 w-full cursor-row-resize bg-dark-800/10"
                                    @mousedown.stop="startDrag($event, dow, index, true)"
                                />

                                <div
                                    class="absolute bottom-0 left-0 h-2 w-full cursor-row-resize bg-dark-800/10"
                                    @mousedown.stop="startDrag($event, dow, index, false)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-4 flex justify-end">
                <MyButton scheme="primary">{{ t('save') }}</MyButton>
            </div>
        </MyForm>
    </MyModal>

    <MyModal v-model="rateModalOpen">
        <template #title>{{ t('selectRate') }}</template>

        <div class="mt-2 flex flex-wrap justify-center space-x-2">
            <div
                v-for="(rate, id) in form.data.rates"
                :key="id"
                :style="{ backgroundColor: rate.color }"
                class="mb-2 h-10 cursor-pointer rounded-lg px-4 py-2 text-white"
                @click="saveRate(rate)"
                v-text="rate.name"
            />
        </div>

        <hr class="my-4" />

        <div class="flex w-full justify-center">
            <MyButton scheme="danger" @click="removeTimeslot">{{ t('deleteTimeslot') }}</MyButton>
        </div>
    </MyModal>
</template>
