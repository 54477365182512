<script lang="ts" setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@/stores/auth-store'

import ReportEmployeesTable from '@/components/driver-reports/ReportEmployeesTable.vue'
import ReportsTable from '@/components/driver-reports/ReportsTable.vue'
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import MyTabs from '@/components/my-components/MyTabs.vue'

const { t } = useI18n()
const authStore = useAuthStore()
const summary = ref<HTMLDivElement>()
</script>

<template>
    <CrumbsAndActions v-if="authStore.companyId">
        <Breadcrumb :to="{ name: 'dr' }" v-text="t('driverReports')" />
        <Breadcrumb current v-text="t('reports')" />
    </CrumbsAndActions>

    <RouterView />
    <div>
        <MyPanel v-if="authStore.companyId && summary" shadow>
            <MyTabs
                v-slot="{ tabKey }"
                :tabs="{ reports: t('reports'), employees: t('employees') }"
                :default-tab="'reports'"
            >
                <ReportsTable v-if="tabKey === 'reports'" />
                <ReportEmployeesTable v-if="tabKey === 'employees'" />
            </MyTabs>
        </MyPanel>

        <!-- Element is sometimes rendered after reports table so we have to wait for it to be mounted -->
        <!-- We need a proper method for doing this -->
        <div id="summary" ref="summary"></div>
    </div>
</template>
