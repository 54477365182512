<script lang="ts" setup>
import axios from 'axios'
import { onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { usePaginatedTable } from '@/hooks/table/use-paginated-table'
import { useAuthStore } from '@/stores/auth-store'
import { MinimalResource, PaginatedResponse, uuid } from '@/types/general'
import { DropdownStringOption } from '@/types/inputs'
import { MinimalStatement } from '@/types/packaging'
import { localeDate } from '@/utils/dates'

import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import ActionRowItem from '@/components/table/ActionRowItem.vue'
import MyFilterSelect from '@/components/table/MyFilterSelect.vue'
import MyTable from '@/components/table/MyTable.vue'
import MyTableColumn from '@/components/table/MyTableColumn.vue'

interface Params extends Record<string, unknown> {
    filter: {
        'receiver-company-id': uuid | null
    }
}

const { t } = useI18n()
const authStore = useAuthStore()

const customers = ref<DropdownStringOption[]>([])
const { data, paginationData, params, loading, error, refetch } = usePaginatedTable<
    MinimalStatement,
    Params
>(
    async (params, abortController) => {
        const response = await axios.get<PaginatedResponse<MinimalStatement>>(
            window.route('packaging.company.statements.index', {
                company: authStore.companyId,
            }),
            { params: params, signal: abortController.signal },
        )

        return response.data
    },
    { filter: { 'receiver-company-id': null } },
)

async function fetchCustomers() {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.customers', { company: authStore.companyId }),
    )

    customers.value = response.data.map((customer) => ({
        value: customer.id,
        label: customer.name,
    }))
}

onBeforeMount(() => fetchCustomers())
</script>

<template>
    <CrumbsAndActions v-if="authStore.companyId">
        <Breadcrumb :to="{ name: 'packaging' }" v-text="t('packaging')" />
        <Breadcrumb current v-text="t('statements')" />
    </CrumbsAndActions>

    <MyPanel shadow>
        <MyTable
            :error="error"
            :get-data="refetch"
            :loading="loading"
            :pagination-data="paginationData"
            :rows="data"
            table-id="packaging-statements"
            :entity-name="t('statements')"
        >
            <template #filters>
                <MyFilterSelect
                    :label="t('customer')"
                    filter-name="receiver-company-id"
                    :options="customers"
                    @change="(id) => (params.filter['receiver-company-id'] = id || '')"
                />
            </template>
            <template #actionRow="{ row }">
                <RouterLink
                    :to="{
                        name: `packaging.statements.show`,
                        params: { statementId: row.id as uuid },
                    }"
                >
                    <ActionRowItem>
                        <mdi:eye />
                    </ActionRowItem>
                </RouterLink>
            </template>
            <MyTableColumn :name="t('locations')" :sortable="false" property="companyLocations" />
            <MyTableColumn :name="t('customer')" property="receiverCompany.name" />
            <MyTableColumn :name="t('creator')" property="user.name" />
            <MyTableColumn :name="t('receivers')" property="receivers" />
            <MyTableColumn
                :name="t('receiverLocations')"
                :sortable="false"
                property="receiverLocations"
            />
            <MyTableColumn
                :name="t('transactions')"
                :sortable="false"
                property="transactionCount"
            />
            <MyTableColumn :name="t('period')" property="startDate" />

            <template #user="{ row }">
                {{ row.user?.name || t('system') }}
            </template>

            <template #companyLocations="{ row }">
                {{ row.companyLocations || t('all') }}
            </template>

            <template #receiverLocations="{ row }">
                {{ row.receiverLocations || t('all') }}
            </template>

            <template #startDate="{ row }">
                {{ localeDate(row.startDate as string) }} -
                {{ localeDate(row.endDate as string) }}
            </template>
        </MyTable>
    </MyPanel>
</template>
