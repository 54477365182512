<script lang="ts">
export interface Props extends /** @vue-ignore */ InputHTMLAttributes {
    modelValue?: string | number | null
    id?: string
    name?: string
    groupClass?: HTMLClass
    error?: string
    label?: string
    helper?: string
    border?: string
    shadow?: string
    background?: HTMLClass
}

export default { inheritAttrs: false }
</script>

<script lang="ts" setup>
import { v4 as uuid } from 'uuid'
import { InputHTMLAttributes, ref, watch } from 'vue'

import { HTMLClass } from '@/types/inputs'

import MyErrorMessage from '@/components/my-components/form/MyErrorMessage.vue'
import MyHelperMessage from '@/components/my-components/form/MyHelperMessage.vue'
import MyInputLabel from '@/components/my-components/form/MyInputLabel.vue'

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'update:modelValue', value: string | number | null): void
    (e: 'focus', event: FocusEvent): void
    (e: 'blur', event: FocusEvent): void
}>()

const input = ref<HTMLInputElement>()
const id = ref(props.id ?? uuid())

watch(
    () => props.id,
    () => {
        id.value = props.id ?? uuid()
    },
)

defineExpose({ input })
</script>

<template>
    <div class="group w-full" :class="props.groupClass">
        <slot :id="id" name="label" :label="label">
            <MyInputLabel v-if="props.label" :for="id" v-text="props.label" />
        </slot>

        <div class="relative flex items-center">
            <slot />
            <div
                v-if="$slots.icon"
                class="absolute top-0 left-2 z-0 flex h-full cursor-text items-center text-gray-400 transition group-focus-within:text-primary-500 dark:group-focus-within:text-primary-100"
            >
                <slot name="icon" />
            </div>
            <input
                :id="id"
                ref="input"
                :class="[
                    props.background ?? 'dark:bg-dark-400',
                    props.border ?? 'border-gray-300 dark:border-transparent',
                    props.shadow ?? 'focus:shadow-lg dark:focus:shadow-dark-600',
                    { 'pl-8': $slots.icon },
                ]"
                :value="props.modelValue"
                class="flex w-full items-center rounded-xl border px-4 py-2.5 text-sm outline-none ring-0 transition-all placeholder:text-xs placeholder:font-semibold placeholder:uppercase placeholder:text-gray-400 focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:border disabled:bg-primary-50 dark:disabled:bg-dark-600 sm:text-sm"
                v-bind="$attrs"
                @blur="emit('blur', $event)"
                @focus="emit('focus', $event)"
                @input="emit('update:modelValue', ($event.target as HTMLInputElement).value)"
            />
            <div v-if="$slots.button">
                <slot name="button" />
            </div>
        </div>

        <MyHelperMessage :helper="props.helper" />
        <MyErrorMessage :input-name="props.name" :error="props.error" :label="props.label" />
    </div>
</template>
