<script lang="ts" setup>
import { inject, ref } from 'vue'
import ViewerJs from 'viewerjs'
import { component as VViewer } from 'v-viewer'
import axios from 'axios'
import download from 'downloadjs'
import { v4 } from 'uuid'

import { imageViewerOpenKey } from '@/types/global-injection-keys'

interface Viewer extends ViewerJs {
    image: HTMLImageElement | null
}

const options: ViewerJs.Options = {
    url: 'data-src',
    title: (image: Record<string, string>, imageData: Record<string, string>) => {
        let imageName = image.alt
        if (image.alt === 'thumbnail') {
            imageName = ''
        }
        return `${imageName} (${imageData.naturalWidth} × ${imageData.naturalHeight})`
    },
    toolbar: {
        zoomIn: true,
        zoomOut: true,
        reset: true,
        prev: true,
        next: true,
        async download() {
            const image = viewer.value?.image
            if (!image) return

            let imagepath = image.src
            if (imagepath.includes('imagedelivery.net')) {
                // If the image is stored in Cloudflare images we'll download the "full" variant instead
                imagepath = imagepath.replace(/\/\w+$/, '/full')
            }

            const response = await axios.get(imagepath, {
                responseType: 'blob',
                headers: { Accept: 'image/jpeg, image/png' },
                withCredentials: false,
            })

            const filename = image.alt || v4()
            download(response.data, filename)
        },
    },
    movable: true,
    scalable: false,
    rotatable: false,
}

const imageViewerOpen = inject(imageViewerOpenKey)!

const viewer = ref<Viewer>()
</script>

<template>
    <VViewer
        class="flex flex-col"
        :options="options"
        @shown="imageViewerOpen = true"
        @hidden="imageViewerOpen = false"
        @inited="viewer = $event"
    >
        <slot />
    </VViewer>
</template>

<style>
.viewer-download {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23fff'%3E%3Cpath d='M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z' /%3E%3C/svg%3E");
    background-size: 58%;
    background-position: center;
    background-repeat: no-repeat;
}
</style>
