import {
    ShipmentType,
    ColliScanEventType,
    DeviationState,
    ShipmentEventType,
} from '@/types/delivery-management'

export const deviationStates: Record<DeviationState, string> = {
    [DeviationState.Open]: 'open',
    [DeviationState.Closed]: 'closed',
    [DeviationState.Solved]: 'solved',
}

export const shipmentTypes: Record<ShipmentType, string> = {
    [ShipmentType.Delivery]: 'delivery',
    [ShipmentType.Return]: 'return',
    [ShipmentType.Pickup]: 'pickup',
}

export const shipmentEventTypes: Record<ShipmentEventType, string> = {
    [ShipmentEventType.TerminalLoad]: 'loadedOnVehicle',
    [ShipmentEventType.TerminalUnload]: 'unloadedFromVehicle',
    [ShipmentEventType.Delivery]: 'delivered',
    [ShipmentEventType.Pickup]: 'pickedUp',
}

export const colliScanEventTypes: Record<string, string> = {
    [ColliScanEventType.Load]: 'load',
    [ColliScanEventType.Delivery]: 'delivery',
    [ColliScanEventType.Pickup]: 'pickup',
    [ColliScanEventType.Unload]: 'unload',
}
