<script lang="ts" setup>
import type { MyInputRef } from '@/types/inputs'

import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import useForm from '@/hooks/use-form'
import { localAsset } from '@/utils/assets'
import { useAuthStore } from '@/stores/auth-store'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MyErrorMessage from '@/components/my-components/form/MyErrorMessage.vue'

interface ResetResponse {
    appEnabled: boolean
    webportalEnabled: boolean
}

const authStore = useAuthStore()
const route = useRoute()
const { t } = useI18n()

const password = ref<MyInputRef>()
const resetResponse = ref<ResetResponse | null>(null)
const { data, errors, loading, submit } = useForm({
    userId: route.query.user,
    password: '',
    passwordConfirmation: '',
    token: route.params.hash,
})

function resetMessage(): string {
    if (resetResponse.value?.appEnabled && !resetResponse.value?.webportalEnabled) {
        return `${t('passwordHasBeenReset')} ${t('returnToApp')}`
    } else if (resetResponse.value?.webportalEnabled && !resetResponse.value?.appEnabled) {
        return `${t('passwordHasBeenReset')} ${t('returnToWeb')}`
    } else if (resetResponse.value?.appEnabled && resetResponse.value?.webportalEnabled) {
        return `${t('passwordHasBeenReset')} ${t('returnToWebOrApp')}`
    } else {
        return ''
    }
}

async function onSubmit() {
    resetResponse.value = null
    const response = await submit<ResetResponse>('post', window.route('password.update'))

    if (response && authStore.isLoggedIn) {
        window.location.href = '/'
    } else if (response && !authStore.isLoggedIn) {
        resetResponse.value = response
    }
}

onMounted(() => password.value?.input.focus())
</script>

<template>
    <MyPanel
        class="flex w-full flex-col overflow-hidden"
        padded
        :panel-class="
            'max-w-sm shadow-xl' +
            (authStore.isLoggedIn ? '' : ' shadow-primary-500 dark:shadow-secondary-900')
        "
    >
        <img
            v-if="!authStore.isLoggedIn"
            :src="localAsset('assets/mypallet.png')"
            alt="myPallet"
            class="mx-auto mb-5 dark:hidden"
            style="width: 300px; height: 90px"
        />
        <img
            :src="localAsset('assets/mypallet-white.png')"
            alt="myPallet"
            class="mx-auto mb-5 hidden dark:block"
            style="width: 300px; height: 90px"
        />

        <div v-if="resetResponse">
            <div class="mb-2 text-center text-sm text-green-400" v-text="resetMessage()" />

            <RouterLink
                v-if="resetResponse.webportalEnabled"
                class="mt-3 block text-center text-xs font-medium underline hover:text-blue-500"
                to="/login"
                v-text="t('goBackToLogin')"
            />
        </div>

        <MyForm v-else :errors="errors" @submit.prevent="onSubmit">
            <h3 class="font-lg text-center font-semibold" v-text="t('resetPassword')" />

            <p class="mt-2" v-text="t('resetPasswordToContinue')" />

            <MyInput
                ref="password"
                v-model="data.password"
                :label="t('password')"
                :placeholder="t('password')"
                autocomplete="new-password"
                group-class="mt-2"
                name="password"
                required
                type="password"
            />

            <MyInput
                v-model="data.passwordConfirmation"
                :label="t('confirmPassword')"
                :placeholder="t('confirmPassword')"
                autocomplete="new-password"
                group-class="mt-2"
                name="passwordConfirmation"
                required
                type="password"
            />

            <MyErrorMessage class="mt-4" input-name="token" />

            <div class="mt-5 block text-right">
                <MyButton
                    class="w-full"
                    scheme="primary"
                    type="submit"
                    v-text="t('resetPassword')"
                />

                <RouterLink
                    v-if="!authStore.isLoggedIn"
                    class="mt-1 inline-block text-xs font-medium underline hover:text-blue-500"
                    to="/login"
                    v-text="t('goBackToLogin')"
                />
            </div>

            <LoaderWrapper :visible="loading" />
        </MyForm>
    </MyPanel>
</template>
