<script lang="ts" setup>
import { computed, nextTick, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { useProductStore } from '@/stores/product-store'
import { Customer, CustomerCompany } from '@/types/company'
import { uuid } from '@/types/general'
import { DropdownOption, DropdownStringOption } from '@/types/inputs'
import { BalanceLimitType, CustomerBalanceLimit, Product } from '@/types/packaging'
import { image } from '@/utils/assets'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'

export interface Props {
    modelValue: boolean
    customer: Customer<CustomerCompany>
}
interface Form {
    limits: CustomerBalanceLimit[]
}

const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void
    (e: 'updated', value: void): void
}>()

const props = defineProps<Props>()

const { t } = useI18n()
const authStore = useAuthStore()
const productStore = useProductStore()

const productId = ref<string | null>(null)
const form = useForm<Form>({ limits: [] })

const balanceLimitTypes = computed<DropdownOption[]>(() => [
    { value: BalanceLimitType.Amount, label: t('amount') },
    { value: BalanceLimitType.Price, label: t('price') },
])
const currency = computed(() => {
    const moneyFormatter = Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: authStore.company.options.currency || 'USD',
        maximumFractionDigits: 0,
    })

    // We have no way to easily get the symbol from the NumberFormat
    // so we'll have to do it like this
    return moneyFormatter.format(0).replace('0', '')
})
const products = computed<Record<uuid, Product>>(() => {
    const products: Record<uuid, Product> = {}
    // We'll use all products since they could have removed a product that does not yet have an alarm
    // In which case the product would not be found if companyProducts were used
    for (const product of productStore.products) {
        products[product.id] = product
    }

    return products
})
const currentProductIds = computed(() => form.data.limits.map((limit) => limit.productId))
const availableProducts = computed<DropdownStringOption[]>(() => {
    const products: DropdownStringOption[] = productStore.companyProducts
        .filter((product) => !currentProductIds.value.includes(product.id))
        .map((product) => ({
            value: product.id,
            label: product.name,
            extra: { image: product.image },
        }))

    if (!currentProductIds.value.includes(null)) {
        products.unshift({ value: 'all', label: t('allProducts') })
    }

    return products
})

async function save() {
    const response = await form.submit(
        'PUT',
        window.route('packaging.company.customers.balance-limits.update', {
            company: authStore.companyId,
            customer: props.customer.id,
        }),
    )

    if (!response) return

    emit('updated')
    emit('update:modelValue', false)
}

watch(productId, async () => {
    if (!productId.value) return

    const id = productId.value === 'all' ? null : productId.value
    form.data.limits.push({
        productId: id,
        type: BalanceLimitType.Amount,
        limit: 100,
        reachedAt: null,
    })
    await nextTick()
    productId.value = null
})

watch(
    () => props.modelValue,
    async () => {
        if (!props.modelValue) return

        await productStore.fetchProducts()

        form.reset({ limits: props.customer.balanceLimits })
    },
)
</script>

<template>
    <MyModal :value="props.modelValue" @close="emit('update:modelValue', false)">
        <template #title>{{ t('configureBalanceLimts') }}</template>

        <LoaderWrapper :visible="form.loading.value" immediate />

        <MyForm class="space-y-4" :errors="form.errors.value" @submit.prevent="save">
            <p class="mb-5 leading-5" v-text="t('customerBalanceLimitDescription')" />

            <div>
                <b class="font-semibold" v-text="t('limits')" />

                <div
                    v-for="(limit, index) in form.data.limits"
                    :key="limit.productId || 'all'"
                    class="flex justify-between space-x-4"
                >
                    <div class="mt-8 flex w-full">
                        <img
                            v-if="limit.productId"
                            :alt="products[limit.productId].name"
                            :src="image(products[limit.productId].image as string)"
                            class="mr-2 h-[24px] w-[40px] object-contain"
                        />

                        {{ products[limit.productId || '']?.name ?? t('allProducts') }}
                    </div>

                    <MySelect
                        v-model="limit.type"
                        :label="t('type')"
                        :options="balanceLimitTypes"
                        :name="`limits.${index}.type`"
                        group-class="min-w-[100px]"
                    />

                    <MyInput
                        v-model="limit.limit"
                        type="number"
                        :min="0"
                        :label="t('limit')"
                        :name="`limits.${index}.limit`"
                        group-class="max-w-[150px]"
                    >
                        <template v-if="limit.type === BalanceLimitType.Price" #button>
                            <span
                                class="absolute top-0 bottom-0.5 right-2 m-auto h-5"
                                v-text="currency"
                            />
                        </template>
                    </MyInput>

                    <MyButton
                        v-tooltip="t('remove')"
                        type="button"
                        icon
                        class="mt-6 px-2"
                        @click="form.data.limits.splice(index, 1)"
                    >
                        <mdi:close class="text-red-500" />
                    </MyButton>
                </div>

                <div v-if="form.data.limits.length === 0" v-text="t('noDefinedLimits')" />
            </div>

            <MySelect
                v-model="productId"
                :label="t('addProduct')"
                :placeholder="t('selectAProduct')"
                :options="availableProducts"
            >
                <template #option="{ option }">
                    <div class="flex">
                        <img
                            v-if="option.extra"
                            :key="option.value"
                            :alt="option.value"
                            :src="image(option.extra.image as string)"
                            class="mr-2 h-[24px] w-[40px] object-contain"
                        />

                        {{ option.label }}
                    </div>
                </template>
            </MySelect>

            <div class="flex justify-end">
                <MyButton :disabled="form.loading.value" scheme="primary">{{ t('save') }}</MyButton>
            </div>
        </MyForm>
    </MyModal>
</template>
