import { Ref, ref } from 'vue'

/**
 * Hook used to make it easy to have lazy loaded lists
 * Will only fetch the items once through the provided fetch parameter.
 * To force fetch call fetch with the force parameter set to true.
 * @param fetch Fetch parameter called once to fetch items
 */
export function useLazyLoadedList<Item>(fetch: () => Promise<Item[]>) {
    const isLoaded = ref(false)
    const fetching = ref(false)
    // We need to set type as Vue sets it as SimpleRef something.
    const items = ref<Item[]>([]) as Ref<Item[]>

    return {
        fetched: isLoaded,
        fetching,
        items: items,
        async fetch(force = false) {
            if (isLoaded.value && !force) return

            isLoaded.value = true
            fetching.value = true
            try {
                items.value = await fetch()
            } finally {
                fetching.value = false
            }
        },
        reset() {
            isLoaded.value = false
            items.value = []
        },
    }
}
