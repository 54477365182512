export enum EmailTemplateType {
    PackagingStatement,
    OwnerDamageReportEmail,
}

export interface EmailTemplate {
    type: EmailTemplateType
    subject: string
    body: string
}
