<script lang="ts" setup>
import { Component, computed, inject, onMounted, shallowRef, watch } from 'vue'

import { insertFormWizardStepKey, updateFormWizardStepKey, WizardStep } from '@/types/form'

export interface Props {
    icon?: Component
    title: string
    id: string
    active?: boolean
    submit?: () => boolean
    loading?: boolean
}

const props = defineProps<Props>()

watch(props, () => {
    updateFormWizardStep(step.value)
})

const step = computed<WizardStep>(() => ({
    icon: shallowRef(props.icon),
    id: props.id,
    title: props.title,
    submit: props.submit,
    loading: props.loading,
}))

const insertFormWizardStep = inject(insertFormWizardStepKey)!
const updateFormWizardStep = inject(updateFormWizardStepKey)!

onMounted(() => insertFormWizardStep!(step.value))
</script>

<template>
    <KeepAlive>
        <slot v-if="props.active" />
    </KeepAlive>
</template>
