<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { onBeforeMount, ref, watch, nextTick } from 'vue'
import axios from 'axios'
import { notify } from '@kyvg/vue3-notification'

import { FieldPreference } from '@/types/general'
import { DriverReportPreferences, SettlementTemplate } from '@/types/driver-report'
import { useAuthStore } from '@/stores/auth-store'
import { LicenseType } from '@/types/company'
import { useConfirm } from '@/hooks/use-confirm'
import { MyButtonScheme } from '@/types/layout/my-button'
import { useCompanyStore } from '@/stores/company-store'

import ManageSettlementTemplateModal from '@/components/driver-reports/ManageSettlementTemplateModal.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import Loader from '@/components/loaders/Loader.vue'
import MyRadioButtonGroup from '@/components/my-components/form/MyRadioButtonGroup.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import MyRadioButton from '@/components/my-components/form/MyRadioButton.vue'

let mounted = false

const authStore = useAuthStore()
const companyStore = useCompanyStore()
const { t } = useI18n()
const confirm = useConfirm()

const preferences = ref<DriverReportPreferences>({
    tourType: FieldPreference.Required,
    referenceNumber: FieldPreference.Optional,
    kilometerCountImage: FieldPreference.Optional,
    tourPicker: FieldPreference.Optional,
    vehicle: FieldPreference.Optional,
    units: FieldPreference.Optional,
    kilometerCount: FieldPreference.Optional,
    departureNote: FieldPreference.Optional,
})
const saving = ref(false)
const settlementTemplateModalOpen = ref(false)
const selectedSettlementTemplate = ref<SettlementTemplate>()

async function savePreferences() {
    if (!mounted) return

    saving.value = true
    await axios.put(
        window.route('dr.company.preferences.update', { company: authStore.companyId }),
        preferences.value,
    )
    authStore.licenses[LicenseType.DriverReportModule] = {
        ...authStore.licenses[LicenseType.DriverReportModule],
        ...preferences.value,
    }
    saving.value = false
}

function editSettlementTemplate(template: SettlementTemplate) {
    selectedSettlementTemplate.value = template
    settlementTemplateModalOpen.value = true
}

async function deleteSettlementTemplate(template: SettlementTemplate) {
    try {
        await confirm(
            t('deleteEntityTitle', { entity: t('settlementTemplate') }),
            t('deleteEntityDescription', { entity: t('settlementTemplate') }),
            {
                confirmText: t('yes'),
                cancelText: t('no'),
                confirmButtonScheme: MyButtonScheme.Warning,
            },
        )
    } catch {
        return
    }

    companyStore.loadingSettlementTemplates = true
    await axios.delete(
        window.route('dr.company.settlement-templates.destroy', [authStore.companyId, template.id]),
    )
    notify({ title: t('entityDeleted', { entity: t('settlementTemplate') }), type: 'success' })
    companyStore.fetchSettlementTemplates()
}

watch(preferences, savePreferences, { deep: true })
watch(settlementTemplateModalOpen, (open) => {
    if (!open) {
        selectedSettlementTemplate.value = undefined
    }
})

onBeforeMount(async () => {
    companyStore.fetchSettlementTemplates()
    const prefs: Partial<DriverReportPreferences> =
        authStore.licenses[LicenseType.DriverReportModule] ?? {}

    preferences.value = { ...preferences.value, ...prefs }
    await nextTick()
    mounted = true
})
</script>

<template>
    <MyPanel panel-class="w-96" padded shadow>
        <Loader
            class="absolute right-6 top-6 opacity-0 transition-opacity duration-300"
            :class="{ 'opacity-100': saving }"
        />

        <div class="flex flex-col space-y-4">
            <div class="flex justify-between">
                <h3
                    class="relative text-sm font-bold uppercase tracking-wide text-primary-400 transition-opacity dark:text-primary-300"
                    v-text="t('settlementTemplates')"
                />

                <Loader
                    :size="25"
                    class="transition-opacity"
                    :class="
                        companyStore.loadingSettlementTemplates
                            ? 'opacity-1 visible'
                            : 'invisible opacity-0'
                    "
                />
            </div>

            <div
                v-for="template in companyStore.settlementTemplates"
                :key="template.id"
                class="flex items-center justify-between"
            >
                {{ template.name }}

                <div class="flex">
                    <MyButton
                        v-tooltip="t('edit')"
                        type="button"
                        class="ml-2 flex h-6 w-6 items-center justify-center rounded-lg bg-transparent text-xs text-primary-500 drop-shadow-lg hover:bg-primary-200 dark:hover:bg-dark-800"
                        icon
                        size="micro"
                        scheme="primary"
                        @click="editSettlementTemplate(template)"
                    >
                        <mdi:pencil />
                    </MyButton>

                    <MyButton
                        v-tooltip="t('delete')"
                        type="button"
                        class="ml-2 flex h-6 w-6 items-center justify-center rounded-lg bg-transparent text-xs text-red-500 drop-shadow-lg hover:bg-primary-200 dark:hover:bg-dark-800"
                        icon
                        size="micro"
                        scheme="danger"
                        @click="deleteSettlementTemplate(template)"
                    >
                        <mdi:trash-can />
                    </MyButton>
                </div>
            </div>

            <MyButton scheme="primary" @click="settlementTemplateModalOpen = true">
                {{ t('createNew') }}
            </MyButton>

            <h3
                class="relative text-sm font-bold uppercase tracking-wide text-primary-400 dark:text-primary-300"
                v-text="t('fields')"
            />

            <MyRadioButtonGroup v-model="preferences.tourType" :label="t('tourTypeDescribed')">
                <MyRadioButton :label="t('required')" :value="FieldPreference.Required" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />
            </MyRadioButtonGroup>

            <MyRadioButtonGroup
                v-if="authStore.hasLicense(LicenseType.DeliveryManagementModule)"
                v-model="preferences.tourPicker"
                class="relative"
                :label="t('showTourPicker')"
            >
                <MyRadioButton :label="t('required')" :value="FieldPreference.Required" />
                <MyRadioButton :label="t('optional')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />

                <template #label>
                    {{ t('showTourPicker') }}
                    <mdi:information
                        v-tooltip="t('showTourPickerExplanation')"
                        class="absolute top-1 right-0"
                    />
                </template>
            </MyRadioButtonGroup>

            <MyRadioButtonGroup v-model="preferences.referenceNumber" :label="t('referenceNumber')">
                <MyRadioButton :label="t('required')" :value="FieldPreference.Required" />
                <MyRadioButton :label="t('optional')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />
            </MyRadioButtonGroup>

            <MyRadioButtonGroup v-model="preferences.vehicle" :label="t('vehicle')">
                <MyRadioButton :label="t('required')" :value="FieldPreference.Required" />
                <MyRadioButton :label="t('optional')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />
            </MyRadioButtonGroup>

            <MyRadioButtonGroup v-model="preferences.units" :label="t('units')">
                <MyRadioButton :label="t('required')" :value="FieldPreference.Required" />
                <MyRadioButton :label="t('optional')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />
            </MyRadioButtonGroup>

            <MyRadioButtonGroup v-model="preferences.kilometerCount" :label="t('kilometerCount')">
                <MyRadioButton :label="t('required')" :value="FieldPreference.Required" />
                <MyRadioButton :label="t('optional')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />
            </MyRadioButtonGroup>

            <MyRadioButtonGroup
                v-if="preferences.kilometerCount !== FieldPreference.Hidden"
                v-model="preferences.kilometerCountImage"
                :label="t('kilometerCountImage')"
            >
                <MyRadioButton :label="t('required')" :value="FieldPreference.Required" />
                <MyRadioButton :label="t('optional')" :value="FieldPreference.Optional" />
            </MyRadioButtonGroup>

            <MyRadioButtonGroup v-model="preferences.departureNote" :label="t('departureNote')">
                <MyRadioButton :label="t('required')" :value="FieldPreference.Required" />
                <MyRadioButton :label="t('optional')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />
            </MyRadioButtonGroup>
        </div>

        <ManageSettlementTemplateModal
            v-model="settlementTemplateModalOpen"
            :settlement-template="selectedSettlementTemplate"
            @saved="companyStore.fetchSettlementTemplates()"
        />
    </MyPanel>
</template>
