<script lang="ts">
interface Props {
    tab: CompaniesTab
}
</script>

<script lang="ts" setup>
import axios from 'axios'
import { computed, inject, onBeforeMount, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { usePaginatedTable } from '@/hooks/table/use-paginated-table'
import { useAuthStore } from '@/stores/auth-store'
import { Customer, LicenseType } from '@/types/company'
import { CompaniesTab, PaginatedResponse } from '@/types/general'
import { currentRouteModuleKey } from '@/types/global-injection-keys'
import { newMoneyFormatter, numberFormatter } from '@/utils/numbers'
import { phoneNumberString } from '@/utils/string'

import MyPalletIcon from '@/components/MyPalletIcon.vue'
import ActionRowItem from '@/components/table/ActionRowItem.vue'
import MyFilterButton from '@/components/table/MyFilterButton.vue'
import MyTable from '@/components/table/MyTable.vue'
import MyTableColumn from '@/components/table/MyTableColumn.vue'

type stateFilter = 'active' | 'all' | 'inactive' | 'new'

interface Params extends Record<string, unknown> {
    filter: { state: stateFilter; hauliers: string }
}

const props = defineProps<Props>()

const authStore = useAuthStore()
const { t } = useI18n()
const currentRouteModule = inject(currentRouteModuleKey)!

let moneyFormatter = newMoneyFormatter(authStore.company.options.currency || 'USD')

const reachedBalanceLimits = ref(0)
const { data, paginationData, params, loading, error, refetch } = usePaginatedTable<
    Customer,
    Params
>(
    async (params, abortController) => {
        if (
            currentRouteModule.value?.license === LicenseType.PackagingModule &&
            props.tab === CompaniesTab.Customers
        ) {
            fetchReachedBalanceLimits()
        }

        const response = await axios.get<PaginatedResponse<Customer>>(
            window.route('company.customers.index', {
                company: authStore.companyId,
            }),
            { params: params, signal: abortController.signal },
        )

        return response.data
    },
    { filter: { state: 'active', hauliers: props.tab } },
)
const tableId = computed(() => `${currentRouteModule.value?.name ?? 'packaging'}-customers`)
const phoneNumbers = computed(() => {
    return data.value.map(({ phoneNumber, customerCompany }) => {
        if (!phoneNumber && !customerCompany.phoneNumber) return ''

        return phoneNumberString(phoneNumber || customerCompany.phoneNumber)
    })
})

const entityName = computed(() => {
    const tabNames = {
        [CompaniesTab.Customers]: 'customers',
        [CompaniesTab.Hauliers]: 'hauliers',
        [CompaniesTab.Locations]: 'locations',
    }

    return t(tabNames[props.tab] || '')
})

function setFilter(value: string | number | null) {
    params.value.filter['state'] = value as stateFilter
    params.value.page = 1
}

async function fetchReachedBalanceLimits() {
    const response = await axios.get<{ count: number }>(
        window.route('packaging.company.balance-limits.reached-count', {
            company: authStore.companyId,
        }),
    )

    reachedBalanceLimits.value = response.data.count
}

watch(
    () => props.tab,
    () => {
        params.value.page = 1
        params.value.filter['hauliers'] = props.tab === CompaniesTab.Hauliers ? '1' : '0'
    },
)

onBeforeMount(async () => {
    moneyFormatter = newMoneyFormatter(authStore.company.options.currency || 'USD')
})

defineExpose({ refetch })
</script>

<template>
    <MyTable
        :error="error"
        :get-data="refetch"
        :loading="loading"
        :pagination-data="paginationData"
        :rows="data"
        :table-id="tableId"
        :entity-name="entityName"
    >
        <template #filters>
            <MyFilterButton
                filter-name="state"
                value="all"
                @selected="setFilter"
                v-text="t('all')"
            />
            <MyFilterButton
                filter-name="state"
                value="new"
                @selected="setFilter"
                v-text="t('new')"
            />
            <MyFilterButton
                filter-name="state"
                default
                value="active"
                @selected="setFilter"
                v-text="t('active')"
            />
            <MyFilterButton
                filter-name="state"
                value="inactive"
                @selected="setFilter"
                v-text="t('inactive')"
            />
            <MyFilterButton
                v-if="currentRouteModule?.license === LicenseType.PackagingModule"
                filter-name="state"
                class="relative"
                value="reached-balance-limit"
                @selected="setFilter"
            >
                {{ t('reachedBalanceLimit') }}

                <span
                    v-if="reachedBalanceLimits > 0"
                    class="absolute -top-1 -right-0.5 flex h-[20px] w-[20px] items-center justify-center rounded-full bg-red-800 text-white"
                    v-text="reachedBalanceLimits"
                />
            </MyFilterButton>
        </template>

        <template #actionRow="{ row }">
            <RouterLink
                :to="{
                    name: `${currentRouteModule?.name ?? 'packaging'}.customers.show`,
                    params: { id: row.id as string },
                }"
            >
                <ActionRowItem>
                    <mdi:eye />
                </ActionRowItem>
            </RouterLink>
        </template>

        <MyTableColumn :name="t('name')" property="name" />
        <MyTableColumn :name="t('vat')" property="customerCompany.vat" />
        <MyTableColumn :name="t('contactPerson')" property="contactPerson" />
        <MyTableColumn :name="t('address')" property="customerCompany.address" />
        <MyTableColumn :name="t('zipcode')" property="customerCompany.zipcode" />
        <MyTableColumn :name="t('city')" property="customerCompany.city" />
        <MyTableColumn :name="t('country')" property="customerCompany.country" />
        <MyTableColumn :name="t('phoneNumber')" property="phoneNumber" />
        <MyTableColumn :name="t('note')" property="note" />
        <MyTableColumn :name="t('department')" property="department" />
        <MyTableColumn :name="t('accountNumber')" property="accountNumber" />
        <MyTableColumn
            v-if="currentRouteModule?.license === LicenseType.PackagingModule"
            :name="t('balance')"
            property="moduleInfo.packagingBalance"
        />
        <MyTableColumn
            v-if="currentRouteModule?.license === LicenseType.PackagingModule"
            :name="t('balanceValue')"
            property="moduleInfo.packagingBalancePrice"
        />

        <template #name="{ row }">
            <div class="flex">
                <span class="w-full truncate" v-text="row.customerCompany.name" />

                <MyPalletIcon
                    v-if="row.customerCompany.mypalletCustomer"
                    v-tooltip="t('companyUsesMyPallet')"
                    class="ml-2"
                    style="width: 20px"
                />
            </div>
        </template>

        <template #[`moduleInfo.packagingBalance`]="{ row }">
            <div class="flex">
                <div
                    v-tooltip="
                        t(
                            (row.moduleInfo.packagingBalance as number) < 0
                                ? 'productsOwedExplanation'
                                : 'productsCreditExplanation',
                        )
                    "
                    class="w-full truncate"
                    v-text="numberFormatter.format(row.moduleInfo.packagingBalance ?? 0)"
                />

                <mdi:alarm
                    v-if="row.reachedBalanceLimit"
                    v-tooltip="t('customerReachedBalanceLimit')"
                    class="ml-2 inline-block text-red-500"
                />
            </div>
        </template>

        <template #[`moduleInfo.packagingBalancePrice`]="{ row }">
            <div
                v-tooltip="
                    t(
                        (row.moduleInfo.packagingBalancePrice as number) < 0
                            ? 'productsOwedExplanation'
                            : 'productsCreditExplanation',
                    )
                "
                class="truncate"
                v-text="moneyFormatter.format(row.moduleInfo.packagingBalancePrice ?? 0)"
            />
        </template>

        <template #note="{ row }">{{ row.notes[1] ?? '' }}</template>
        <template #department="{ row }">{{ row.notes[0] ?? '' }}</template>
        <template #phoneNumber="{ index }">{{ phoneNumbers[index] }}</template>
        <template #contactPerson="{ row }">{{
            row.contactPerson || row.customerCompany.contactPerson
        }}</template>
    </MyTable>
</template>
