<script lang="ts" setup>
import axios from 'axios'
import { inject, nextTick, onBeforeMount, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { notify } from '@kyvg/vue3-notification'

import { useConfirm } from '@/hooks/use-confirm'
import { useAuthStore } from '@/stores/auth-store'
import { Company, Customer, LicenseType } from '@/types/company'
import { ResourceResponse } from '@/types/general'
import { currentRouteModuleKey } from '@/types/global-injection-keys'
import { MyVatNumberInputRef } from '@/types/inputs'
import { LimitedCountryCode } from '@/utils/country-codes'

import CreateCustomerForm from '@/components/customers/CreateCustomerForm.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyVatNumberInput from '@/components/my-components/form/MyVatNumberInput.vue'

export interface Props {
    haulierAsDefault: boolean
}

const props = defineProps<Props>()

const router = useRouter()
const authStore = useAuthStore()
const confirm = useConfirm()
const { t } = useI18n()
const open = ref(false)
const loading = ref(false)
const country = ref('')
const isoCode = ref<LimitedCountryCode>('DK')
const vat = ref('')
const vatInputRef = ref<MyVatNumberInputRef>()
const company = ref<Partial<Company>>()

const currentRouteModule = inject(currentRouteModuleKey)!

function createPrivateCustomer() {
    company.value = { country: country.value }
}

async function findCompany() {
    if (!vatInputRef.value?.validate()) return

    loading.value = true
    try {
        const response = await axios.get<ResourceResponse<Company>>(
            window.route('company.customers.find', { company: authStore.companyId }),
            {
                params: { vat: vat.value },
            },
        )

        if (response.data.data.id === authStore.companyId) {
            notify({ type: 'error', text: t('notAllowedToAddYourselfAsCustomer') })
            return
        }

        company.value = response.data.data
    } catch (e) {
        if (!axios.isAxiosError(e)) throw e

        if (e.response?.status === 409) {
            await showExistingCustomerAlert(e.response.data)
        } else if (e.response?.status === 404) {
            company.value = { country: country.value, vat: vat.value }
        }
    } finally {
        loading.value = false
    }
}

async function showExistingCustomerAlert(customer: ResourceResponse<Customer>) {
    try {
        await confirm(
            t('customerAlreadyExists'),
            t('alreadyAddedCustomer', { customer: customer.data.customerCompany.name }),
            { confirmText: t('goToCustomer'), cancelText: t('close') },
        )

        await goToCustomer(customer.data.id)
    } catch (e) {
        open.value = false
    }
}

async function goToCustomer(id: string) {
    await router.push({
        name: `${currentRouteModule.value?.name ?? 'packaging'}.customers.show`,
        params: { id },
    })
}

async function resetModal(focusVat = false) {
    company.value = undefined
    vat.value = ''

    await nextTick()
    if (focusVat) vatInputRef.value?.focus()
}

onBeforeMount(() => {
    company.value = undefined
})

watch(open, (value) => {
    if (!value) resetModal()
})
</script>

<template>
    <MyModal v-model="open" :back-button="!!company" @back-button-clicked="resetModal(true)">
        <LoaderWrapper :visible="loading" class="rounded-xl" />

        <template #title>
            {{ t('createCustomer') }}
        </template>

        <form v-if="!company" @submit.prevent="findCompany">
            <MyVatNumberInput
                ref="vatInputRef"
                v-model="vat"
                autofocus
                @country-changed="isoCode = $event.isoCode"
            />

            <div class="mt-4 flex justify-between">
                <MyButton
                    v-if="authStore.hasLicense(LicenseType.PrivateCustomers)"
                    scheme="secondary"
                    type="button"
                    @click="createPrivateCustomer"
                >
                    <span v-text="t('privateCustomer')" />
                </MyButton>

                <MyButton class="ml-auto" scheme="primary" autofocus>
                    <span v-text="t('continue')" />

                    <mdi:chevron-right />
                </MyButton>
            </div>
        </form>

        <CreateCustomerForm
            v-else
            class="mt-4"
            :company="company"
            :country-iso-code="isoCode"
            :haulier-as-default="props.haulierAsDefault"
            @created="(c) => goToCustomer(c.id)"
        />
    </MyModal>

    <slot>
        <MyButton plain scheme="primary" size="small" @click="open = true">
            <mdi:plus-thick class="mr-1" />
            {{ t('createNew') }}
        </MyButton>
    </slot>
</template>
