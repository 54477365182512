<script lang="ts" setup>
import axios from 'axios'
import { reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@/stores/auth-store'
import { Employee, PortalType } from '@/types/user'
import { uuid } from '@/types/general'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'

export interface Props {
    employee?: Employee
    currentTab: PortalType
    companyId?: uuid
}

const emit = defineEmits<{ (e: 'closed', removed: boolean): void }>()
const props = defineProps<Props>()

const open = ref(false)
const { t } = useI18n()
const authStore = useAuthStore()
const removeState = reactive({
    web: false,
    app: false,
})
const loading = ref(false)

async function removeUser() {
    loading.value = true
    await axios.delete(
        window.route('company.employees.destroy', {
            company: props.companyId ?? authStore.companyId,
            employee: props.employee!.id,
        }),
        { data: removeState },
    )
    emit('closed', true)
    open.value = false
    loading.value = false
}

watch(open, () => {
    if (!open.value) {
        emit('closed', false)
    }
})
watch(
    () => props.employee,
    () => {
        open.value = !!props.employee
        if (props.employee) {
            const isWebTab = props.currentTab === PortalType.Webportal
            removeState.web = isWebTab || !props.employee.webportalEnabled
            removeState.app = !isWebTab || !props.employee.appEnabled
        }
    },
)
</script>

<template>
    <MyModal v-model="open" :max-width="400">
        <template #title>
            {{ t('removeEmployeeConfirmation', { employee: props.employee?.name }) }}
        </template>

        <LoaderWrapper :visible="loading" />

        <!-- It's is not possible to remove web users for other companies so we don't need to show the toggles -->
        <div
            v-if="
                props.employee?.appEnabled && props.employee?.webportalEnabled && !props.companyId
            "
        >
            <MyCheckbox v-model="removeState.web" :label="t('removeWebAccess')" />
            <MyCheckbox v-model="removeState.app" :label="t('removeAppAccess')" />
        </div>

        <div class="mt-5 flex justify-between">
            <MyButton scheme="action" @click="open = false" v-text="t('cancel')" />
            <MyButton scheme="primary" @click="removeUser" v-text="t('removeUser')" />
        </div>
    </MyModal>
</template>
