import { IncludedCompany, Location, LocationPoint } from '@/types/company'
import { FieldPreference, Dispatch, Model, uuid } from '@/types/general'
import { MinimalUser } from '@/types/user'

export enum ReceiptType {
    Dispatch = 0,
    Delivery = 1,
}
export interface ConsignmentNote {
    id?: string
    path?: string
    name?: string
    isImage?: boolean
    file?: File
}

export interface Receipt extends Model {
    id: uuid
    sendingUser: MinimalUser
    receivingUser?: MinimalUser
    sendingLocation: Location
    receivingLocation?: Location
    shipperLocation?: Location
    haulierLocation?: Location
    companyId: uuid | null
    sendingCompany: IncludedCompany
    receivingCompany?: IncludedCompany
    shipperCompany?: IncludedCompany
    haulierCompany?: IncludedCompany
    transactionId?: uuid
    shipmentId: uuid | null
    type: ReceiptType
    location: LocationPoint
    companyDetails: { economy: string | null }
    consignmentNotes: ConsignmentNote[]
    dispatches: Dispatch[]
    shipmentNumber?: string
    note?: string
    receiverName?: string
    receiverSignature?: string
    trailerNumber?: string
    truckNumber?: string
    hasDeviation: boolean
    images: { path: string }[]
    createdAt: string
    transpiredAt: string
    deletedAt?: string
    haulierHidden: boolean
}

export interface PODPreferences {
    dispatches: {
        startScreen: PodStartScreen
        fields: PodFieldPreferences
        haulierHidden: boolean
    }
    deliveries: {
        startScreen: PodStartScreen
        fields: PodFieldPreferences
        haulierHidden: boolean
    }
    pdfGenerationEmail: string | null
}

export interface PodFieldPreferences {
    receiver: FieldPreference
    consignmentNote: FieldPreference
    shipmentNumber: FieldPreference
    trailerNumber: FieldPreference
    truckNumber: FieldPreference
    signature: FieldPreference
    images: FieldPreference
}

export enum PodStartScreen {
    receiver = 0,
    consignmentNote = 1,
    details = 2,
}
