<script setup lang="ts">
import { CountUp } from 'countup.js'
import { onMounted, ref, watch } from 'vue'
import { useLocalStorage } from '@vueuse/core'

import { decimalSeparator, groupSeparator } from '@/utils/numbers'
import { useAuthStore } from '@/stores/auth-store'

import MyPanel from '@/components/my-components/MyPanel.vue'

const props = defineProps<{
    value: number
    title: string
    description?: string
    loading: boolean
    storageKey: string
}>()

const authStore = useAuthStore()
const storedValue = useLocalStorage(`${authStore.companyId}-${props.storageKey}`, 0)

let countUp: CountUp
const element = ref<HTMLElement>()

function animate() {
    if (!element.value || props.loading) return
    if (props.value === storedValue.value) return

    countUp = new CountUp(element.value, props.value, {
        separator: groupSeparator,
        decimal: decimalSeparator,
        startVal: storedValue.value,
    })
    countUp.start()
    storedValue.value = props.value
}

watch(() => props.loading, animate)
onMounted(animate)
</script>

<template>
    <MyPanel
        class="flex flex-col justify-center gap-6 p-6 xl:flex-row xl:items-center xl:justify-start xl:gap-0"
    >
        <div class="xl:grow">
            <h3 class="text-lg font-bold uppercase text-primary-400" v-text="title" />
            <p
                v-if="description"
                class="text-xs text-gray-500 xl:text-[11px]"
                v-text="description"
            />
        </div>
        <div class="flex items-center justify-start gap-2">
            <p
                ref="element"
                :class="{
                    'text-blue-400': props.value > 0,
                    'text-red-300': props.value < 0,
                    'text-gray-500': props.value === 0,
                }"
                class="text-left text-5xl font-bold"
                v-text="storedValue"
            />
        </div>
    </MyPanel>
</template>
