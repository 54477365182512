<script lang="ts">
interface Props {
    tag: string
    contenteditable?: boolean
    modelValue: string
    noHtml?: boolean
    noNewLines?: boolean
}
</script>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'

interface ClipEvent extends ClipboardEvent {
    originalEvent: ClipboardEvent
}

const props = withDefaults(defineProps<Props>(), {
    contenteditable: true,
    noHtml: true,
    noNl: false,
})

const emit = defineEmits({
    returned: String,
    'update:modelValue': String,
})

function replaceAll(str: string, search: string, replacement: string) {
    return str.split(search).join(replacement)
}

const element = ref<HTMLElement | null>()

function currentContent() {
    return props.noHtml ? element.value!.innerText : element.value!.innerHTML
}

function updateContent(newcontent: string) {
    if (props.noHtml) {
        element.value!.innerText = newcontent
    } else {
        element.value!.innerHTML = newcontent
    }
}

function update() {
    emit('update:modelValue', currentContent())
}

function onPaste(event: ClipEvent) {
    event.preventDefault()
    let text = (event.originalEvent || event).clipboardData?.getData('text/plain') ?? ''
    if (props.noNewLines) {
        text = replaceAll(text, '\r\n', ' ')
        text = replaceAll(text, '\n', ' ')
        text = replaceAll(text, '\r', ' ')
    }
    window.document.execCommand('insertText', false, text)
}
function onKeypress(event: KeyboardEvent) {
    if (event.key == 'Enter' && props.noNewLines) {
        event.preventDefault()
        emit('returned', currentContent())
    }
}

onMounted(() => {
    updateContent(props.modelValue ?? '')
})

watch(
    () => props.modelValue,
    (newval) => {
        if (newval != currentContent()) {
            updateContent(newval ?? '')
        }
    },
)

watch(
    () => props.noHtml,
    () => {
        updateContent(props.modelValue ?? '')
    },
)

watch(
    () => props.tag,
    () => {
        updateContent(props.modelValue ?? '')
    },
    { flush: 'post' },
)
</script>

<template>
    <component
        :is="props.tag"
        ref="element"
        :contenteditable="props.contenteditable"
        @input="update"
        @blur="update"
        @paste="onPaste"
        @keypress="onKeypress"
    >
    </component>
</template>
