<script lang="ts" setup>
import axios from 'axios'
import { computed, inject, onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { ResourceResponse } from '@/types/general'
import { currentCustomerKey, LocationBalanceRow } from '@/types/packaging'

import MyButton from '@/components/my-components/MyButton.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MyTable from '@/components/table/MyTable.vue'
import MyTableColumn from '@/components/table/MyTableColumn.vue'

export interface Props {
    productId: string
    locationId: string
}

const props = defineProps<Props>()
const emit = defineEmits<{ (e: 'updated'): void }>()

const { t } = useI18n()
const authStore = useAuthStore()
const customer = inject(currentCustomerKey)!
const balanceRows = ref<LocationBalanceRow[]>([])
const selectedLocations = ref<number[]>([])
const adjustments = ref<Record<string, number>>({})
const note = ref<string>()
const { data, submit, loading, errors } = useForm({
    customerCompanyId: customer.value!.customerCompany.id,
    productId: props.productId,
    locationId: props.locationId,
    note: note.value,
    balances: [] as Record<string, unknown>[],
})
const mypalletCustomer = computed(() => customer.value!.customerCompany.mypalletCustomer)
const disabledRows = computed(() => {
    return balanceRows.value
        .map((row, index) => {
            if (mypalletCustomer.value) {
                return row.balance <= 0 ? index : undefined
            }

            return row.balance === 0 ? index : undefined
        })
        .filter((index): index is number => index !== undefined)
})
const changes = computed(
    () => Object.values(adjustments.value).filter((v) => v + '' !== '' && v !== 0).length > 0,
)
const balances = computed(() => {
    const rows: Record<string, number> = {}

    balanceRows.value.forEach((row) => {
        rows[row.locationId] = row.balance - (adjustments.value[row.locationId] || 0)
    })

    return rows
})
const totalOwed = computed(() => {
    return balanceRows.value
        .filter((row, index) => selectedLocations.value.includes(index))
        .reduce((total, row) => total + row.owed, 0)
})

async function fetchBalance() {
    loading.value = true
    const params: Record<string, string> = {
        company: authStore.companyId,
        customer_company_id: customer.value!.customerCompany.id,
        product_id: props.productId,
        location_id: props.locationId,
    }

    const response = await axios.get<ResourceResponse<LocationBalanceRow[]>>(
        window.route('packaging.company.balance.per-location', params),
    )

    loading.value = false
    balanceRows.value = response.data.data
    adjustments.value = {}
    balanceRows.value.forEach((row) => {
        adjustments.value[row.locationId] = 0
    })
}

async function adjustBalance() {
    data.balances = balanceRows.value.map((row) => {
        return {
            location_id: row.locationId,
            adjustment: adjustments.value[row.locationId],
        }
    })

    data.note = note.value

    await submit(
        'PUT',
        window.route('packaging.company.balance.settle', { company: authStore.companyId }),
    )

    await fetchBalance()
    note.value = ''
    emit('updated')
}

async function resetBalance() {
    for (const row of balanceRows.value) {
        adjustments.value[row.locationId] = mypalletCustomer.value ? row.owed : row.balance
    }
    await adjustBalance()
    selectedLocations.value = []
}

onBeforeMount(async () => {
    await fetchBalance()
})
</script>

<template>
    <MyForm :errors="errors" @submit.prevent="adjustBalance">
        <MyTable
            v-model:row-selection="selectedLocations"
            :disabled-selection-rows="disabledRows"
            :loading="loading"
            :rows="balanceRows"
            enable-row-selection
            disable-footer
            disable-actions
            disable-search
            disable-ordering
            :row-height="60"
            row-key="locationId"
            table-id="location-balance-customer"
        >
            <MyTableColumn :min-width="200" :name="t('location')" property="locationName" />
            <template #locationName="{ row }">
                <div class="flex items-center">
                    <span class="truncate" v-text="row.locationName" />

                    <mdi:trash-can-outline
                        v-if="row.locationDeleted"
                        v-tooltip="t('locationDeleted')"
                        class="ml-2 text-red-600"
                    />
                </div>
            </template>
            <MyTableColumn :width="120" :name="t('adjustment')" property="adjustment" />
            <MyTableColumn :width="120" :name="t('newBalance')" property="newBalance" />
            <MyTableColumn :width="100" :name="t('balance')" property="balance" />

            <template #adjustment="{ row, index }">
                <MyInput
                    v-model.number="adjustments[row.locationId]"
                    v-tooltip="{
                        content: t('settleBalanceMyPalletCustomerDescription', {
                            company: customer!.customerCompany.name,
                        }),
                        disabled: !mypalletCustomer || row.balance > 0,
                    }"
                    :name="`balances.${index}.adjustment`"
                    style="width: 100px"
                    type="number"
                    :max="mypalletCustomer ? row.owed : undefined"
                    :min="mypalletCustomer ? 0 : undefined"
                    :disabled="mypalletCustomer && row.balance <= 0"
                    small
                />
            </template>

            <template #newBalance="{ row }">
                <span v-if="!adjustments[row.locationId]" class="text-gray-400 dark:text-gray-300">
                    --
                </span>

                <span
                    v-else
                    :class="{
                        'text-red-600': balances[row.locationId] < 0,
                        'text-green-500': balances[row.locationId] > 0,
                    }"
                    v-text="balances[row.locationId]"
                />
            </template>

            <template #balance="{ row }">
                <span
                    :class="{
                        'text-red-600': row.balance < 0,
                        'text-green-500': row.balance > 0,
                    }"
                    v-text="row.balance"
                />
            </template>
        </MyTable>

        <div class="sticky bottom-2 mt-2 flex items-end justify-between space-x-2">
            <div class="space-y-3">
                <MyInput
                    v-if="selectedLocations.length > 0 || changes"
                    v-model="note"
                    name="note"
                    :label="t('note')"
                    type="text"
                    :placeholder="t('note')"
                    @keypress.enter.prevent=""
                />
                <div
                    v-if="selectedLocations.length > 0"
                    v-tooltip="{
                        content: t('noOwedForLocations'),
                        disabled: !mypalletCustomer || totalOwed > 0,
                    }"
                >
                    <MyButton
                        type="button"
                        :disabled="mypalletCustomer && totalOwed <= 0"
                        scheme="secondary"
                        shadow
                        @click="resetBalance"
                        v-text="
                            t(mypalletCustomer ? 'resetOwedForSelected' : 'resetBalanceForSelected')
                        "
                    />
                </div>
            </div>

            <Transition name="fade">
                <MyButton v-if="changes" :disabled="loading" shadow scheme="primary">
                    <mdi:content-save class="mr-2" />

                    Save
                </MyButton>
            </Transition>
        </div>
    </MyForm>
</template>
