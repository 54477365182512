<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

import {
    MinimalShipment,
    Shipment,
    ShipmentEventType,
    ShipmentType,
} from '@/types/delivery-management'

const { t } = useI18n()
const props = defineProps<{
    shipment: Shipment | MinimalShipment
}>()

const isDelivery = computed(() => props.shipment.type === ShipmentType.Delivery)

const isInTransit = computed(() => {
    if (props.shipment.currentEventType === ShipmentEventType.TerminalLoad) {
        return true
    }

    return props.shipment.currentEventType === ShipmentEventType.Pickup
})

const status = computed(() => {
    const { delayed, handledAt, handledTooEarly, tour } = props.shipment
    let color = 'bg-green-400'
    let text = isDelivery.value ? t('delivered') : t('pickedUp')

    if (delayed && isDelivery.value) {
        color = 'bg-red-400'
        text = !handledAt ? t('delayed') : t('lateDelivery')
    } else if (handledTooEarly && isDelivery.value) {
        color = 'bg-red-400'
        text = t('earlyDelivery')
    } else if (isInTransit.value && !handledAt) {
        color = 'bg-blue-400'
        text = t('inTransit')
    } else if (!tour && !handledAt) {
        color = 'bg-blue-400'
        text = t('noAssignedTour')
    } else if (!handledAt) {
        color = 'bg-gray-400'
        text = isDelivery.value ? t('notDelivered') : t('notPickedUp')
    }

    return { color, text }
})
</script>
<template>
    <div class="flex items-center">
        <mdi:alert-circle
            v-if="props.shipment.unsuccessful"
            v-tooltip="t('shipmentWasUnsuccessful')"
            class="mr-1.5 -ml-[1px] text-red-500 flex-shrink-0 cursor-pointer hover:text-red-700"
            style="width: 15px"
        />
        <div v-else class="h-3 w-3 mr-2 flex-shrink-0 rounded-full" :class="status.color" />
        <span v-text="status.text" />
    </div>
</template>
