<script setup lang="ts">
import type { ResourceResponse } from '@/types/general'
import type { StockItem } from '@/types/stock'

import axios from 'axios'
import { storeToRefs } from 'pinia'
import { onMounted, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@/stores/auth-store'
import { useDashboardStore } from '@/stores/dashboard-store'

import TotalBalanceItem from '@/components/dashboard/TotalBalanceItem.vue'

const { t } = useI18n()
const { selectedLocation } = storeToRefs(useDashboardStore())
const authStore = useAuthStore()
const loading = ref(true)
const balance = reactive({ stock: 0, credit: 0 })

async function fetchBalance() {
    loading.value = true

    const { data: response } = await axios.get<ResourceResponse<StockItem[]>>(
        window.route('packaging.company.balance', {
            company: authStore.companyId,
            locationId: selectedLocation.value as string,
        }),
    )

    balance.stock = response.data.reduce((val, obj) => val + obj.balance, 0)
    balance.credit = response.data.reduce((val, obj) => val + obj.credit, 0)

    loading.value = false
}

onMounted(fetchBalance)
watch(selectedLocation, fetchBalance)
</script>

<script lang="ts">
export default { inheritAttrs: false }
</script>

<template>
    <div class="grid gap-6 lg:grid-cols-2" :class="$attrs.class">
        <TotalBalanceItem
            storage-key="stock"
            :loading="loading"
            :title="t('stock')"
            :description="t('dashboardStockDescription')"
            :value="balance.stock"
            shadow
        />

        <TotalBalanceItem
            storage-key="credit"
            :loading="loading"
            :title="t('credit')"
            :description="t('dashboardCreditDescription')"
            :value="balance.credit"
            shadow
        />
    </div>
</template>
