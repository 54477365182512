<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'

const { t } = useI18n()
</script>

<template>
    <CrumbsAndActions>
        <Breadcrumb current v-text="t('shipments')" />
    </CrumbsAndActions>
</template>
