import { RouteLocationRaw } from 'vue-router'

import { LicenseType } from '@/types/company'

interface DefaultModuleRoute {
    route: RouteLocationRaw
    licenses: LicenseType[]
}

export const moduleLicenses = [
    LicenseType.ECMRModule,
    LicenseType.DamageReportModule,
    LicenseType.DriverReportModule,
    LicenseType.PackagingModule,
    LicenseType.DeliveryManagementModule,
    LicenseType.PODModule,
]

export const defaultModuleRoutes: DefaultModuleRoute[] = [
    {
        route: { name: 'packaging.balance', params: { tab: 'customers' } },
        licenses: [LicenseType.PackagingModule, LicenseType.PackagingModuleReadonly],
    },
    {
        route: { name: 'pod.receipts' },
        licenses: [LicenseType.PODModule],
    },
    {
        route: { name: 'dr.reports' },
        licenses: [LicenseType.DriverReportModule],
    },
    {
        route: { name: 'dmr.reports' },
        licenses: [LicenseType.DamageReportModule],
    },
    {
        route: { name: 'dm.hubs' },
        licenses: [LicenseType.DeliveryManagementModule],
    },
]

export function firstModuleRoute(licenses: LicenseType[]): RouteLocationRaw {
    for (const route of defaultModuleRoutes) {
        const matchedLicenses = route.licenses.filter((license) => licenses.includes(license))
        if (matchedLicenses.length > 0) {
            return route.route
        }
    }

    return '/404'
}
