<script lang="ts" setup>
import axios from 'axios'
import { computed, onBeforeMount, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { usePaginatedTable } from '@/hooks/table/use-paginated-table'
import { useAuthStore } from '@/stores/auth-store'
import { useCompanyStore } from '@/stores/company-store'
import { PaginatedResponse, uuid } from '@/types/general'
import { DropdownStringOption } from '@/types/inputs'
import { newMoneyFormatter, numberFormatter } from '@/utils/numbers'

import ActionRowItem from '@/components/table/ActionRowItem.vue'
import MyFilterSelect from '@/components/table/MyFilterSelect.vue'
import MyTable from '@/components/table/MyTable.vue'
import MyTableColumn from '@/components/table/MyTableColumn.vue'

interface Params extends Record<string, unknown> {
    filter: {
        'location-id': uuid
    }
}

interface LocationBalanceRow extends Record<string, unknown> {
    id: uuid
    name: string
    address: string
    city: string
    zipcode: string
    country: string
    balance: number
    balancePrice: number
}

const { t } = useI18n()
const authStore = useAuthStore()
const companyStore = useCompanyStore()

let moneyFormatter = newMoneyFormatter(authStore.company.options.currency || 'USD')

const { data, paginationData, params, loading, error, refetch } = usePaginatedTable<
    LocationBalanceRow,
    Params
>(
    async (params, abortController) => {
        const response = await axios.get<PaginatedResponse<LocationBalanceRow>>(
            window.route('packaging.company.locations.balance', {
                company: authStore.companyId,
                location: params.filter['location-id'],
            }),
            { params: params, signal: abortController.signal },
        )

        return response.data
    },
    { filter: { 'location-id': companyStore.locations[0]?.id ?? '' } },
)

const locations = computed<DropdownStringOption[]>(() =>
    companyStore.locations.map(({ id, name }) => ({ value: id, label: name })),
)

watch(
    () => companyStore.loadingLocations,
    () => {
        if (!companyStore.loadingLocations && !params.value.filter['location-id']) {
            params.value.filter!['location-id'] = companyStore.locations[0]?.id
        }
    },
)

onBeforeMount(async () => {
    moneyFormatter = newMoneyFormatter(authStore.company.options.currency || 'USD')
})
</script>

<template>
    <MyTable
        :error="error"
        :get-data="refetch"
        :loading="loading"
        :pagination-data="paginationData"
        :rows="data"
        table-id="packaging-locations-balance"
    >
        <template #filters>
            <MyFilterSelect
                v-if="!companyStore.loadingLocations"
                :label="t('location')"
                filter-name="location-id"
                :options="locations"
                :default="companyStore.locations[0]?.id"
                @change="(id) => (params.filter['location-id'] = id || '')"
            />
        </template>

        <template #actionRow="{ row }">
            <RouterLink
                :to="{
                    name: `packaging.location-balance`,
                    query: {
                        'sender-location': params.filter['location-id'],
                        'receiver-location': row.id as uuid,
                    },
                }"
            >
                <ActionRowItem>
                    <mdi:eye />
                </ActionRowItem>
            </RouterLink>
        </template>

        <MyTableColumn :name="t('name')" property="name" />
        <MyTableColumn :name="t('address')" property="address" />
        <MyTableColumn :name="t('zipcode')" property="zipcode" />
        <MyTableColumn :name="t('city')" property="city" />
        <MyTableColumn :name="t('country')" property="country" />
        <MyTableColumn :name="t('balance')" property="balance" />
        <MyTableColumn :name="t('balanceValue')" property="balancePrice" />

        <template #balance="{ row }">
            <div
                v-tooltip="
                    t(row.balance < 0 ? 'productsOwedExplanation' : 'productsCreditExplanation')
                "
                class="truncate"
            >
                {{ numberFormatter.format(row.balance) }}
            </div>
        </template>

        <template #balancePrice="{ row }">
            <div
                v-tooltip="
                    t(
                        row.balancePrice < 0
                            ? 'productsOwedExplanation'
                            : 'productsCreditExplanation',
                    )
                "
                class="truncate"
            >
                {{ moneyFormatter.format(row.balancePrice) }}
            </div>
        </template>
    </MyTable>
</template>
