<script lang="ts" setup>
import { MenuItem } from '@headlessui/vue'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { useAuthStore } from '@/stores/auth-store'

import MyMenu from '@/components/my-components/MyMenu.vue'

const logoutForm = ref<HTMLFormElement>()
const csrfToken = window.csrfToken

const { t } = useI18n({ useScope: 'global' })
const authStore = useAuthStore()
const router = useRouter()

function logout() {
    logoutForm.value!.submit()
}
</script>

<template>
    <MyMenu class="text-primary-100" is-in-nav>
        <template #trigger>
            <div class="text-right leading-4 text-primary-100">
                <div class="font-semibold">
                    <span
                        class="hidden text-xs font-semibold uppercase lg:block"
                        v-text="authStore.user?.name"
                    />
                    <mdi:account class="block lg:hidden" />
                </div>
                <div class="hidden text-xs lg:block" v-text="authStore.company.name" />
            </div>
        </template>

        <MenuItem class="lg:hidden">
            <div class="py-2 px-3">
                <div class="text-sm font-semibold uppercase" v-text="authStore.user?.name" />
                <div class="text-xs" v-text="authStore.company.name" />
            </div>
        </MenuItem>

        <template v-for="company in authStore.companies">
            <MenuItem
                v-if="company.id !== authStore.companyId"
                :key="company.id"
                v-slot="{ active }"
            >
                <button
                    class="flex w-full py-2 px-3 text-left text-sm hover:bg-gray-200 hover:text-black dark:text-primary-100 dark:hover:bg-gray-800 dark:hover:text-primary-100"
                    :class="{ 'bg-green-500 text-white': active }"
                    @click="authStore.switchCompany(company.id)"
                    v-text="t('switchToCompany', { company: company.name })"
                />
            </MenuItem>
        </template>

        <MenuItem v-slot="{ active }">
            <button
                class="flex w-full items-center py-2 px-3 text-sm hover:bg-gray-200 hover:text-black dark:text-primary-100 dark:hover:bg-gray-800 dark:hover:text-primary-100"
                :class="{ 'bg-green-500 text-white': active }"
                @click="router.push({ name: 'profile' })"
                v-text="t('profile')"
            />
        </MenuItem>

        <MenuItem v-slot="{ active }">
            <button
                class="flex w-full items-center py-2 px-3 text-sm hover:bg-gray-200 hover:text-black dark:text-primary-100 dark:hover:bg-gray-800 dark:hover:text-primary-100"
                :class="{ 'bg-green-500 text-white': active }"
                @click="logout"
                v-text="t('logout')"
            />
        </MenuItem>
    </MyMenu>

    <form ref="logoutForm" action="/logout" method="POST">
        <input type="hidden" name="_token" :value="csrfToken" />
    </form>
</template>
