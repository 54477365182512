import { useThrottleFn } from '@vueuse/core'
import { nextTick, Ref, ref } from 'vue'

import { ColumnSize, InternalTableColumn } from '@/types/table'
import { getElementTransformPositions } from '@/utils/dom'

/**
 * Internal MyTable hook to handle column ordering
 * @param wrapperRef
 * @param columns
 * @param columnSizes
 * @param onChange Called when the column order changes
 */
export function useColumnOrdering(
    wrapperRef: Ref<HTMLElement | undefined>,
    columns: Ref<Map<string, InternalTableColumn>>,
    columnSizes: Ref<Map<string, ColumnSize>>,
    onChange: () => void,
) {
    const draggedColumn = ref<string>()
    let columnElement: HTMLDivElement | null = null
    // Position from mouse to the start of the column
    let mouseDelta = 0
    let wrapperOffsetX = wrapperRef.value?.offsetLeft ?? 0
    // Last position for the mouse when order was changed
    let lastOrderOffset = -Infinity

    function setColumnPosition(offsetX: number) {
        if (columnElement) columnElement.style.transform = `translateX(${offsetX - mouseDelta}px)`
    }

    const determineColumnOrder = useThrottleFn(async (offsetX: number) => {
        if (!draggedColumn.value) return

        if (Math.abs(lastOrderOffset - offsetX) < 30) return

        for (const [property, size] of columnSizes.value.entries()) {
            if (property === draggedColumn.value) continue

            if (
                offsetX >= size.x &&
                offsetX <= size.x + size.width &&
                offsetX - size.x < size.width * 0.8
            ) {
                const column = columns.value.get(property)!
                if (!column.draggable) continue

                const newOrder = column!.order
                column!.order = columns.value.get(draggedColumn.value!)!.order
                columns.value.get(draggedColumn.value!)!.order = newOrder
                lastOrderOffset = offsetX
                onChange()
                // This fixes some flicker because Vue also sets transform on render
                setColumnPosition(offsetX)
                await nextTick()
                setColumnPosition(offsetX)
                break
            }
        }
    }, 250)

    return {
        draggedColumn,
        onOrderStart(event: MouseEvent, property: string) {
            draggedColumn.value = property
            document.body.style.userSelect = 'none'
            wrapperOffsetX = wrapperRef.value?.offsetLeft ?? 0
            lastOrderOffset = -Infinity

            columnElement = (event.target as HTMLDivElement).closest('.my-table-column')

            if (columnElement) {
                const elementOffset = getElementTransformPositions(columnElement).x
                const scrollOffset = wrapperRef.value?.scrollLeft ?? 0
                mouseDelta = event.clientX - (elementOffset - scrollOffset)
            }
        },
        onOrder: (event: MouseEvent) => {
            if (!draggedColumn.value) return
            const offsetX = event.clientX - wrapperOffsetX
            const scrollOffset = wrapperRef.value?.scrollLeft ?? 0
            setColumnPosition(offsetX + scrollOffset)
            determineColumnOrder(offsetX + scrollOffset)
        },
        onOrderEnd() {
            draggedColumn.value = undefined
            if (columnElement) columnElement.style.transitionDuration = '.3s'
            setTimeout(() => {
                if (columnElement) columnElement.style.transitionDuration = ''
            }, 300)
        },
    }
}
