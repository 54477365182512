<script lang="ts" setup>
import { notify } from '@kyvg/vue3-notification'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'

export interface Props {
    title: string
    endpoint: string
    buttonText?: string
}

const props = defineProps<Props>()

const { t } = useI18n()
const authStore = useAuthStore()
const open = ref(false)
const { data, submit, errors, reset, loading } = useForm({ email: authStore.user?.email ?? '' })

async function startExport() {
    const response = await submit('post', props.endpoint)

    if (response) {
        open.value = false
        notify({ type: 'success', text: t('exportStarted') })
    }
}

watch(open, () => {
    if (!open.value) reset()
})
</script>

<template>
    <MyModal v-model="open">
        <LoaderWrapper :visible="loading" class="rounded-xl" />

        <template #title>
            {{ props.title }}
        </template>

        <MyForm :errors="errors" @submit.prevent="startExport">
            <MyInput v-model="data.email" :label="t('email')" autofocus />

            <div class="mt-4 flex justify-end">
                <MyButton scheme="primary" v-text="t('startExport')" />
            </div>
        </MyForm>
    </MyModal>

    <slot>
        <MyButton scheme="action" size="small" @click="open = true">
            <mdi:export class="mr-1" />
            {{ props.buttonText || t('export') }}
        </MyButton>
    </slot>
</template>
