<script lang="ts" setup>
import axios from 'axios'
import { computed, onBeforeMount, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useStorageCache } from '@/hooks/use-storage-cache'
import { useAuthStore } from '@/stores/auth-store'
import { useDashboardStore } from '@/stores/dashboard-store'
import { useProductStore } from '@/stores/product-store'
import { PaginatedResponse, ResourceResponse, uuid } from '@/types/general'
import { image } from '@/utils/assets'

import PageNavigationLink from '@/components/layout/navigation/PageNavigationLink.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'

interface BalanceRow {
    customerId: uuid
    customerCompanyId: uuid
    companyName: string
    total: number
    balances: Record<uuid, number>
}

interface LocationBalanceRow {
    id: uuid
    name: string
    total: number
    balances: Record<uuid, number>
}

const { t } = useI18n()
const authStore = useAuthStore()
const productStore = useProductStore()
const dashboardStore = useDashboardStore()
const loading = ref(false)
const customerBalance = ref<BalanceRow[]>([])
const locationBalance = ref<LocationBalanceRow[]>()
const pagination = ref({ pages: 1 })
const expandedRow = ref<uuid>()
const sortedBy = ref<uuid>()
const sortedDescending = ref(true)
const page = ref(1)
const cacheKey = computed(() => `customer-product-balance-${authStore.companyId}`)
const {
    value: cache,
    set: setCache,
    lastUpdate,
} = useStorageCache<PaginatedResponse<BalanceRow>>(cacheKey.value, 30)

async function toggleRow(id: string) {
    if (expandedRow.value === id) {
        expandedRow.value = undefined
        return
    }

    locationBalance.value = undefined
    expandedRow.value = id

    const params = {
        company: authStore.companyId,
        customerCompany: id,
    }
    const response = await axios.get<ResourceResponse<LocationBalanceRow[]>>(
        window.route('company.dashboard.product-balance.per-location', params),
    )

    locationBalance.value = response.data.data
}

async function sortBy(id?: string) {
    if (sortedBy.value === id) {
        sortedDescending.value = !sortedDescending.value
    } else {
        sortedBy.value = id
        sortedDescending.value = true
    }
    await fetchBalance()
}

async function fetchBalance(initial = false) {
    locationBalance.value = []
    expandedRow.value = undefined

    if (initial && cache.value) {
        customerBalance.value = cache.value.data
        pagination.value.pages = cache.value.meta.last_page
        return
    }

    loading.value = true
    const params: Record<string, string | number | boolean> = {
        company: authStore.companyId,
        defaults: true,
        'per-page': 10,
        page: page.value,
        'sort-direction': sortedDescending.value ? 'desc' : 'asc',
    }
    if (sortedBy.value) {
        params['sort-by'] = sortedBy.value
    }
    if (dashboardStore.selectedLocation) {
        params['location-id'] = dashboardStore.selectedLocation
    }

    const response = await axios.get<PaginatedResponse<BalanceRow>>(
        window.route('company.dashboard.product-balance', params),
    )

    customerBalance.value = response.data.data
    pagination.value.pages = response.data.meta.last_page
    loading.value = false

    // If it's the default values we'll cache the result
    if (
        page.value === 1 &&
        !sortedBy.value &&
        sortedDescending.value &&
        !dashboardStore.selectedLocation
    ) {
        setCache(response.data)
    }
}

onBeforeMount(async () => await fetchBalance(true))
watch(page, async () => await fetchBalance(false))
watch(
    () => dashboardStore.selectedLocation,
    async () => await fetchBalance(false),
)
</script>

<script lang="ts">
export default { inheritAttrs: false }
</script>

<template>
    <MyPanel :loading="loading" shadow :panel-class="$attrs.class as string" :title="t('balance')">
        <template #header>
            <div v-show="lastUpdate" class="flex items-center justify-end pr-3">
                <span class="mr-2 text-sm" v-text="t('lastUpdatedAt', { time: lastUpdate })" />

                <MyButton
                    v-tooltip="t('reloadData')"
                    icon
                    plain
                    scheme="light"
                    size="small"
                    @click="fetchBalance()"
                >
                    <mdi:reload />
                </MyButton>
            </div>
        </template>

        <div class="overflow-auto border-b-2 border-primary-100 dark:border-dark-600">
            <table class="w-full min-w-full table-fixed">
                <thead>
                    <tr class="border-collapse">
                        <td
                            class="text-slate-400 dark:text-slate-200 sticky left-0 w-60 bg-primary-50 px-3 pb-3 text-center align-middle text-xl font-semibold shadow-lg drop-shadow-lg dark:bg-dark-500 dark:shadow-black"
                        ></td>

                        <td
                            class="text-slate-400 dark:text-slate-200 w-24 cursor-pointer p-4 text-center align-bottom text-xs font-medium uppercase"
                            :class="sortedBy === undefined ? 'font-bold' : 'font-semibold'"
                            @click="sortBy(undefined)"
                        >
                            {{ t('total') }}

                            <mdi:chevron-down
                                v-if="sortedBy === undefined"
                                class="float-right transition-transform"
                                :class="{ 'rotate-180': !sortedDescending }"
                            />
                        </td>
                        <td
                            v-for="product in productStore.companyProducts"
                            :key="product.id"
                            class="text-slate-400 dark:text-slate-200 h-30 w-40 rounded-t-xl py-4 font-medium odd:bg-black/5 dark:border-dark-600 dark:odd:bg-black/20"
                            @click="sortBy(product.id)"
                        >
                            <div
                                class="flex h-full cursor-pointer flex-col items-center justify-between"
                            >
                                <img
                                    class="w-28 object-contain"
                                    :src="image(product.image)"
                                    :alt="product.name"
                                />

                                <div
                                    class="mt-2 flex items-center justify-between text-center text-xs uppercase"
                                    :class="sortedBy === product.id ? 'font-bold' : 'font-semibold'"
                                >
                                    {{ product.name }}
                                    <mdi:chevron-down
                                        v-if="sortedBy === product.id"
                                        class="transition-transform"
                                        :class="{ 'rotate-180': !sortedDescending }"
                                    />
                                </div>
                            </div>
                        </td>
                    </tr>
                </thead>

                <tbody>
                    <template v-for="row in customerBalance" :key="row.customerId">
                        <tr
                            class="group odd:bg-primary-100 hover:bg-primary-300 dark:odd:bg-dark-400 dark:hover:bg-dark-700"
                        >
                            <td
                                v-truncate-tooltip
                                class="sticky left-0 truncate bg-primary-50 px-3 py-2.5 shadow-lg drop-shadow-lg group-odd:bg-primary-100 group-hover:bg-primary-300 dark:bg-dark-500 dark:group-odd:bg-dark-400 dark:group-hover:bg-dark-700"
                            >
                                <mdi:chevron-right
                                    :class="{ 'rotate-90': expandedRow === row.customerCompanyId }"
                                    class="mr-4 inline-block cursor-pointer transition-transform"
                                    @click="toggleRow(row.customerCompanyId)"
                                />

                                <PageNavigationLink
                                    no-background
                                    no-padding
                                    regular-link
                                    class="font-semibold text-primary-400"
                                    :to="{
                                        name: 'packaging.customers.show',
                                        params: { id: row.customerId },
                                    }"
                                >
                                    {{ row.companyName }}
                                </PageNavigationLink>

                                <mdi:loading
                                    :class="{
                                        'opacity-0':
                                            expandedRow !== row.customerCompanyId ||
                                            locationBalance,
                                    }"
                                    class="absolute right-1 top-0 bottom-0 m-auto animate-spin text-gray-400 transition-opacity duration-500"
                                />
                            </td>

                            <td class="px-3 py-1.5 text-center font-semibold" v-text="row.total" />

                            <td
                                v-for="product in productStore.companyProducts"
                                :key="row.customerId + product.id"
                                class="px-3 py-1.5 text-center font-semibold odd:bg-black/5 dark:odd:bg-black/20"
                                v-text="row.balances[product.id] ?? 0"
                            />
                        </tr>

                        <template v-if="expandedRow === row.customerCompanyId && locationBalance">
                            <tr
                                v-for="location in locationBalance"
                                :key="location.id"
                                class="group bg-white even:bg-primary-50 hover:bg-primary-200 dark:bg-secondary-300 dark:even:bg-secondary-400 dark:hover:bg-black"
                            >
                                <td
                                    class="sticky left-0 truncate bg-white py-1.5 pl-12 pr-3 text-sm shadow-lg drop-shadow-lg group-even:bg-primary-50 group-hover:bg-primary-200 dark:bg-secondary-300 dark:group-even:bg-secondary-400 dark:group-hover:bg-black"
                                    v-text="location.name"
                                />

                                <td class="px-3 py-1.5 text-center" v-text="location.total" />

                                <td
                                    v-for="product in productStore.companyProducts"
                                    :key="location.id + product.id"
                                    class="px-3 py-1.5 text-center odd:bg-black/5 dark:odd:bg-black/20"
                                    v-text="location.balances[product.id] ?? 0"
                                />
                            </tr>
                        </template>
                    </template>

                    <div
                        v-if="!loading && customerBalance.length === 0"
                        class="m-4"
                        v-text="t('youHaveNoCustomers')"
                    />
                </tbody>
            </table>
        </div>

        <div class="m-4 flex items-center justify-end">
            <div class="flex items-center">
                <MyButton
                    :disabled="page <= 1"
                    icon
                    plain
                    scheme="light"
                    size="small"
                    @click="page--"
                >
                    <mdi:chevron-left />
                </MyButton>

                <div
                    class="mx-2 text-sm font-semibold uppercase"
                    v-text="t('pageOfPage', { page, total: pagination.pages })"
                />

                <MyButton
                    :disabled="page >= pagination.pages"
                    icon
                    plain
                    scheme="light"
                    size="small"
                    @click="page++"
                >
                    <mdi:chevron-right />
                </MyButton>
            </div>
        </div>
    </MyPanel>
</template>
