<script lang="ts" setup>
import { notify } from '@kyvg/vue3-notification'
import Datepicker from '@vuepic/vue-datepicker'
import axios from 'axios'
import dayjs from 'dayjs'
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { useCompanyStore } from '@/stores/company-store'
import { Customer, CustomerCompany, LicenseType } from '@/types/company'
import { IdResponse, uuid } from '@/types/general'
import { DropdownStringOption } from '@/types/inputs'
import { usePresetRangeList } from '@/hooks/use-preset-range-list'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInputLabel from '@/components/my-components/form/MyInputLabel.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'

export interface Props {
    modelValue: boolean
    customer: Customer<CustomerCompany>
}

interface Form {
    receiverCompanyId: uuid
    companyLocationIds: uuid[]
    receiverLocationIds: uuid[]
    period: Date[]
}

const emit = defineEmits<{ (e: 'update:modelValue', value: boolean): void }>()
const props = defineProps<Props>()

const { t } = useI18n()
const authStore = useAuthStore()
const companyStore = useCompanyStore()
const router = useRouter()
const presetRanges = usePresetRangeList()

const form = useForm<Form>({
    receiverCompanyId: props.customer.customerCompany.id,
    companyLocationIds: [],
    receiverLocationIds: [],
    period: [],
})

const receiverLocations = computed<DropdownStringOption[]>(() => {
    return props.customer.customerCompany.locations
        .filter((location) => location.active)
        .map((location) => ({
            value: location.id,
            label: location.name,
        }))
})
const companyLocations = computed<DropdownStringOption[]>(() => {
    let locations = companyStore.locations
    const agentLocations = authStore.company.locations
    if (authStore.hasLicense(LicenseType.AgentLocationFiltering) && agentLocations.length > 0) {
        locations = locations.filter((location) => agentLocations.includes(location.id))
    }

    return locations.map((location) => ({ value: location.id, label: location.name }))
})

async function generateStatement() {
    // We do this to ensure timezone is sent
    const startDate = form.data.period.length > 0 ? dayjs(form.data.period[0]).format() : null
    const endDate = form.data.period.length > 0 ? dayjs(form.data.period[1]).format() : null

    try {
        const response = await form.submit<IdResponse>(
            'POST',
            window.route('packaging.company.statements.store', { company: authStore.companyId }),
            { data: { ...form.data, startDate, endDate } },
        )

        if (!response) return

        router.push({
            name: 'packaging.statements.show',
            params: { statementId: response.data.id },
        })
    } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 409) {
            notify({
                title: t('noTransactionsFound'),
                text: t('noTransactionInPeriod'),
                type: 'error',
            })
        } else {
            notify({ title: t('unknownError'), text: t('tryAgainLater'), type: 'error' })
        }
    }
}

watch(
    () => props.modelValue,
    () => {
        let companyLocationIds: uuid[] = []
        let receiverLocationIds: uuid[] = []
        if (authStore.hasLicense(LicenseType.AgentLocationFiltering)) {
            companyLocationIds = companyLocations.value.map((l) => l.value!)
            receiverLocationIds = receiverLocations.value.map((l) => l.value!)
        }

        form.reset({
            receiverCompanyId: props.customer.customerCompany.id,
            companyLocationIds,
            receiverLocationIds,
        })
    },
)
</script>

<template>
    <MyModal :value="props.modelValue" @close="emit('update:modelValue', false)">
        <template #title>{{ t('generateStatement') }}</template>

        <LoaderWrapper :visible="form.loading.value" />

        <MyForm class="space-y-4" :errors="form.errors.value" @submit.prevent="generateStatement">
            <MySelect
                v-model="form.data.companyLocationIds"
                name="companyLocationIds"
                :label="t('companyLocations')"
                :options="companyLocations"
                :placeholder="t('allLocations')"
                multiple
                clear-button
            />

            <MySelect
                v-model="form.data.receiverLocationIds"
                name="receiverLocationIds"
                :label="t('customerLocations')"
                :options="receiverLocations"
                :placeholder="t('allLocations')"
                multiple
                clear-button
            />

            <div>
                <MyInputLabel v-text="t('selectFromToDate')" />

                <Datepicker
                    v-model="form.data.period"
                    class="input-field w-full rounded-xl border border-gray-300"
                    range
                    :enable-time-picker="false"
                    auto-apply
                    :preset-dates="presetRanges"
                    close-on-scroll
                    auto-position
                    position="left"
                    format="yyyy-MM-dd"
                    :transitions="false"
                    :placeholder="t('allTime')"
                    @cleared="form.data.period = []"
                />
            </div>

            <div class="flex justify-end">
                <MyButton :disabled="form.loading.value" scheme="primary">{{ t('save') }}</MyButton>
            </div>
        </MyForm>
    </MyModal>
</template>
