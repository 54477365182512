<script lang="ts" setup>
import { watch, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { Customer, CustomerCompany, LicenseType } from '@/types/company'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyEmailsInput from '@/components/my-components/form/MyEmailsInput.vue'
import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MyPhoneInput from '@/components/my-components/form/MyPhoneInput.vue'

export interface Props {
    modelValue: boolean
    customer: Customer<CustomerCompany>
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void
    (e: 'saved'): void
}>()

const { t } = useI18n()
const authStore = useAuthStore()

const emails = ref<string[]>([])

const { data, loading, submit, errors } = useForm({
    contactPerson: props.customer.contactPerson,
    email: props.customer.email,
    additionalEmails: props.customer.addtionalEmails,
    phoneNumber: props.customer.phoneNumber || '',
    notes: [props.customer.notes[0] || '', props.customer.notes[1] || ''],
    options: { haulier: false, sendHaulierDriverReports: false, ...props.customer.options },
    active: props.customer.active,
    accountNumber: props.customer.accountNumber || '',
})

async function onSubmit() {
    data.email = emails.value[0]
    data.additionalEmails = emails.value.slice(1, emails.value.length)

    const response = await submit(
        'PUT',
        window.route('company.customers.update', {
            company: authStore.companyId,
            customer: props.customer.id,
        }),
        { appendErrors: true },
    )

    if (response) emit('saved')
}

watch(
    () => props.modelValue,
    (open) => {
        if (!open) return
        emails.value = [data.email].concat(props.customer.additionalEmails)
        data.contactPerson = props.customer.contactPerson
        data.email = props.customer.email
        data.additionalEmails = props.customer.additionalEmails
        data.phoneNumber = props.customer.phoneNumber || ''
        data.notes = [props.customer.notes[0] || '', props.customer.notes[1] || '']
        data.accountNumber = props.customer.accountNumber || ''
        data.options.haulier = props.customer.options?.haulier ?? false
        data.options.sendHaulierDriverReports =
            props.customer.options?.sendHaulierDriverReports ?? false
    },
)
</script>

<template>
    <MyModal :value="props.modelValue" @close="emit('update:modelValue', false)">
        <template #title>
            {{ t('customerSettings') }}
        </template>
        <LoaderWrapper :visible="loading" class="rounded-xl" />

        <MyForm class="mt-5 space-y-4" :errors="errors" @submit.prevent="onSubmit">
            <MyInput
                v-model="data.contactPerson"
                name="contactPerson"
                :label="t('contactPerson')"
                autocomplete="name"
            />

            <MyEmailsInput v-model="emails" />

            <MyPhoneInput v-model="data.phoneNumber" name="phoneNumber" :label="t('phoneNumber')" />
            <MyInput v-model="data.notes[0]" name="department" :label="t('department')" />
            <MyInput v-model="data.notes[1]" name="remarks" :label="t('remarks')" />
            <MyInput
                v-model="data.accountNumber"
                name="accountNumber"
                :label="t('accountNumber')"
            />

            <div class="flex space-x-2">
                <MyCheckbox
                    v-model="data.options.haulier"
                    name="haulier"
                    :label="t('markAsHaulier')"
                />
                <mdi:information v-tooltip="t('haulierExplanation')" />
            </div>

            <div
                v-if="
                    data.options.haulier &&
                    !props.customer.customerCompany.mypalletCustomer &&
                    authStore.hasLicense(LicenseType.DriverReportModule)
                "
                class="flex space-x-2"
            >
                <MyCheckbox
                    v-model="data.options.sendHaulierDriverReports"
                    name="haulier"
                    :label="t('sendDriverReportsToHaulier')"
                />
                <mdi:information v-tooltip="t('sendDriverReportsToHaulierExplanation')" />
            </div>

            <div class="flex space-x-2">
                <MyCheckbox v-model="data.active" name="active" :label="t('active')" />
                <mdi:information v-tooltip="t('activeExplanation')" />
            </div>

            <div class="flex justify-end">
                <MyButton :disabled="loading" scheme="primary">
                    <span v-text="t('save')" />
                </MyButton>
            </div>
        </MyForm>
    </MyModal>
</template>
