import { CountryCode } from 'libphonenumber-js'

interface CountryCodeData {
    name: string
    countryCode: number
}

export interface CountryData {
    name: string
    isoCode: LimitedCountryCode
}

export type LimitedCountryCode = Exclude<CountryCode, 'TA' | 'AC'>

export const countryCodes: Record<LimitedCountryCode, CountryCodeData> = {
    AD: {
        name: 'Andorra',
        countryCode: 376,
    },
    AE: {
        name: 'United Arab Emirates',
        countryCode: 971,
    },
    AF: {
        name: 'Afghanistan',
        countryCode: 93,
    },
    AG: {
        name: 'Antigua and Barbuda',
        countryCode: 1,
    },
    AI: {
        name: 'Anguilla',
        countryCode: 1,
    },
    AL: {
        name: 'Albania',
        countryCode: 355,
    },
    AM: {
        name: 'Armenia',
        countryCode: 374,
    },
    AO: {
        name: 'Angola',
        countryCode: 244,
    },
    AR: {
        name: 'Argentina',
        countryCode: 54,
    },
    AS: {
        name: 'American Samoa',
        countryCode: 1,
    },
    AT: {
        name: 'Austria',
        countryCode: 43,
    },
    AU: {
        name: 'Australia',
        countryCode: 61,
    },
    AW: {
        name: 'Aruba',
        countryCode: 297,
    },
    AX: {
        name: 'Åland Islands',
        countryCode: 358,
    },
    AZ: {
        name: 'Azerbaijan',
        countryCode: 994,
    },
    BA: {
        name: 'Bosnia and Herzegovina',
        countryCode: 387,
    },
    BB: {
        name: 'Barbados',
        countryCode: 1,
    },
    BD: {
        name: 'Bangladesh',
        countryCode: 880,
    },
    BE: {
        name: 'Belgium',
        countryCode: 32,
    },
    BF: {
        name: 'Burkina Faso',
        countryCode: 226,
    },
    BG: {
        name: 'Bulgaria',
        countryCode: 359,
    },
    BH: {
        name: 'Bahrain',
        countryCode: 973,
    },
    BI: {
        name: 'Burundi',
        countryCode: 257,
    },
    BJ: {
        name: 'Benin',
        countryCode: 229,
    },
    BL: {
        name: 'Saint Barthélemy',
        countryCode: 590,
    },
    BM: {
        name: 'Bermuda',
        countryCode: 1,
    },
    BN: {
        name: 'Brunei',
        countryCode: 673,
    },
    BO: {
        name: 'Bolivia',
        countryCode: 591,
    },
    BQ: {
        name: 'Caribbean Netherlands',
        countryCode: 599,
    },
    BR: {
        name: 'Brazil',
        countryCode: 55,
    },
    BS: {
        name: 'Bahamas',
        countryCode: 1,
    },
    BT: {
        name: 'Bhutan',
        countryCode: 975,
    },
    BW: {
        name: 'Botswana',
        countryCode: 267,
    },
    BY: {
        name: 'Belarus',
        countryCode: 375,
    },
    BZ: {
        name: 'Belize',
        countryCode: 501,
    },
    CA: {
        name: 'Canada',
        countryCode: 1,
    },
    CC: {
        name: 'Cocos (Keeling) Islands',
        countryCode: 61,
    },
    CD: {
        name: 'DR Congo',
        countryCode: 243,
    },
    CF: {
        name: 'Central African Republic',
        countryCode: 236,
    },
    CG: {
        name: 'Republic of the Congo',
        countryCode: 242,
    },
    CH: {
        name: 'Switzerland',
        countryCode: 41,
    },
    CI: {
        name: "Côte d'Ivoire (Ivory Coast)",
        countryCode: 225,
    },
    CK: {
        name: 'Cook Islands',
        countryCode: 682,
    },
    CL: {
        name: 'Chile',
        countryCode: 56,
    },
    CM: {
        name: 'Cameroon',
        countryCode: 237,
    },
    CN: {
        name: 'China',
        countryCode: 86,
    },
    CO: {
        name: 'Colombia',
        countryCode: 57,
    },
    CR: {
        name: 'Costa Rica',
        countryCode: 506,
    },
    CU: {
        name: 'Cuba',
        countryCode: 53,
    },
    CV: {
        name: 'Cape Verde',
        countryCode: 238,
    },
    CW: {
        name: 'Curaçao',
        countryCode: 599,
    },
    CX: {
        name: 'Christmas Island',
        countryCode: 61,
    },
    CY: {
        name: 'Cyprus',
        countryCode: 357,
    },
    CZ: {
        name: 'Czechia',
        countryCode: 420,
    },
    DE: {
        name: 'Germany',
        countryCode: 49,
    },
    DJ: {
        name: 'Djibouti',
        countryCode: 253,
    },
    DK: {
        name: 'Denmark',
        countryCode: 45,
    },
    DM: {
        name: 'Dominica',
        countryCode: 1,
    },
    DO: {
        name: 'Dominican Republic',
        countryCode: 1,
    },
    DZ: {
        name: 'Algeria',
        countryCode: 213,
    },
    EC: {
        name: 'Ecuador',
        countryCode: 593,
    },
    EE: {
        name: 'Estonia',
        countryCode: 372,
    },
    EG: {
        name: 'Egypt',
        countryCode: 20,
    },
    EH: {
        name: 'Western Sahara',
        countryCode: 212,
    },
    ER: {
        name: 'Eritrea',
        countryCode: 291,
    },
    ES: {
        name: 'Spain',
        countryCode: 34,
    },
    ET: {
        name: 'Ethiopia',
        countryCode: 251,
    },
    FI: {
        name: 'Finland',
        countryCode: 358,
    },
    FJ: {
        name: 'Fiji',
        countryCode: 679,
    },
    FK: {
        name: 'Falkland Islands',
        countryCode: 500,
    },
    FM: {
        name: 'Micronesia',
        countryCode: 691,
    },
    FO: {
        name: 'Faroe Islands',
        countryCode: 298,
    },
    FR: {
        name: 'France',
        countryCode: 33,
    },
    GA: {
        name: 'Gabon',
        countryCode: 241,
    },
    GB: {
        name: 'United Kingdom',
        countryCode: 44,
    },
    GD: {
        name: 'Grenada',
        countryCode: 1,
    },
    GE: {
        name: 'Georgia',
        countryCode: 995,
    },
    GF: {
        name: 'French Guiana',
        countryCode: 594,
    },
    GG: {
        name: 'Guernsey',
        countryCode: 44,
    },
    GH: {
        name: 'Ghana',
        countryCode: 233,
    },
    GI: {
        name: 'Gibraltar',
        countryCode: 350,
    },
    GL: {
        name: 'Greenland',
        countryCode: 299,
    },
    GM: {
        name: 'Gambia',
        countryCode: 220,
    },
    GN: {
        name: 'Guinea',
        countryCode: 224,
    },
    GP: {
        name: 'Guadeloupe',
        countryCode: 590,
    },
    GQ: {
        name: 'Equatorial Guinea',
        countryCode: 240,
    },
    GR: {
        name: 'Greece',
        countryCode: 30,
    },
    GT: {
        name: 'Guatemala',
        countryCode: 502,
    },
    GU: {
        name: 'Guam',
        countryCode: 1,
    },
    GW: {
        name: 'Guinea-Bissau',
        countryCode: 245,
    },
    GY: {
        name: 'Guyana',
        countryCode: 592,
    },
    HK: {
        name: 'Hong Kong',
        countryCode: 852,
    },
    HN: {
        name: 'Honduras',
        countryCode: 504,
    },
    HR: {
        name: 'Croatia',
        countryCode: 385,
    },
    HT: {
        name: 'Haiti',
        countryCode: 509,
    },
    HU: {
        name: 'Hungary',
        countryCode: 36,
    },
    ID: {
        name: 'Indonesia',
        countryCode: 62,
    },
    IE: {
        name: 'Ireland',
        countryCode: 353,
    },
    IL: {
        name: 'Israel',
        countryCode: 972,
    },
    IM: {
        name: 'Isle of Man',
        countryCode: 44,
    },
    IN: {
        name: 'India',
        countryCode: 91,
    },
    IO: {
        name: 'British Indian Ocean Territory',
        countryCode: 246,
    },
    IQ: {
        name: 'Iraq',
        countryCode: 964,
    },
    IR: {
        name: 'Iran',
        countryCode: 98,
    },
    IS: {
        name: 'Iceland',
        countryCode: 354,
    },
    IT: {
        name: 'Italy',
        countryCode: 39,
    },
    JE: {
        name: 'Jersey',
        countryCode: 44,
    },
    JM: {
        name: 'Jamaica',
        countryCode: 1,
    },
    JO: {
        name: 'Jordan',
        countryCode: 962,
    },
    JP: {
        name: 'Japan',
        countryCode: 81,
    },
    KE: {
        name: 'Kenya',
        countryCode: 254,
    },
    KG: {
        name: 'Kyrgyzstan',
        countryCode: 996,
    },
    KH: {
        name: 'Cambodia',
        countryCode: 855,
    },
    KI: {
        name: 'Kiribati',
        countryCode: 686,
    },
    KM: {
        name: 'Comoros',
        countryCode: 269,
    },
    KN: {
        name: 'Saint Kitts and Nevis',
        countryCode: 1,
    },
    KP: {
        name: 'North Korea',
        countryCode: 850,
    },
    KR: {
        name: 'South Korea',
        countryCode: 82,
    },
    KW: {
        name: 'Kuwait',
        countryCode: 965,
    },
    KY: {
        name: 'Cayman Islands',
        countryCode: 1,
    },
    KZ: {
        name: 'Kazakhstan',
        countryCode: 7,
    },
    LA: {
        name: 'Laos',
        countryCode: 856,
    },
    LB: {
        name: 'Lebanon',
        countryCode: 961,
    },
    LC: {
        name: 'Saint Lucia',
        countryCode: 1,
    },
    LI: {
        name: 'Liechtenstein',
        countryCode: 423,
    },
    LK: {
        name: 'Sri Lanka',
        countryCode: 94,
    },
    LR: {
        name: 'Liberia',
        countryCode: 231,
    },
    LS: {
        name: 'Lesotho',
        countryCode: 266,
    },
    LT: {
        name: 'Lithuania',
        countryCode: 370,
    },
    LU: {
        name: 'Luxembourg',
        countryCode: 352,
    },
    LV: {
        name: 'Latvia',
        countryCode: 371,
    },
    LY: {
        name: 'Libya',
        countryCode: 218,
    },
    MA: {
        name: 'Morocco',
        countryCode: 212,
    },
    MC: {
        name: 'Monaco',
        countryCode: 377,
    },
    MD: {
        name: 'Moldova',
        countryCode: 373,
    },
    ME: {
        name: 'Montenegro',
        countryCode: 382,
    },
    MF: {
        name: 'Saint Martin',
        countryCode: 590,
    },
    MG: {
        name: 'Madagascar',
        countryCode: 261,
    },
    MH: {
        name: 'Marshall Islands',
        countryCode: 692,
    },
    MK: {
        name: 'North Macedonia',
        countryCode: 389,
    },
    ML: {
        name: 'Mali',
        countryCode: 223,
    },
    MM: {
        name: 'Myanmar',
        countryCode: 95,
    },
    MN: {
        name: 'Mongolia',
        countryCode: 976,
    },
    MO: {
        name: 'Macau',
        countryCode: 853,
    },
    MP: {
        name: 'Northern Mariana Islands',
        countryCode: 1,
    },
    MQ: {
        name: 'Martinique',
        countryCode: 596,
    },
    MR: {
        name: 'Mauritania',
        countryCode: 222,
    },
    MS: {
        name: 'Montserrat',
        countryCode: 1,
    },
    MT: {
        name: 'Malta',
        countryCode: 356,
    },
    MU: {
        name: 'Mauritius',
        countryCode: 230,
    },
    MV: {
        name: 'Maldives',
        countryCode: 960,
    },
    MW: {
        name: 'Malawi',
        countryCode: 265,
    },
    MX: {
        name: 'Mexico',
        countryCode: 52,
    },
    MY: {
        name: 'Malaysia',
        countryCode: 60,
    },
    MZ: {
        name: 'Mozambique',
        countryCode: 258,
    },
    NA: {
        name: 'Namibia',
        countryCode: 264,
    },
    NC: {
        name: 'New Caledonia',
        countryCode: 687,
    },
    NE: {
        name: 'Niger',
        countryCode: 227,
    },
    NF: {
        name: 'Norfolk Island',
        countryCode: 672,
    },
    NG: {
        name: 'Nigeria',
        countryCode: 234,
    },
    NI: {
        name: 'Nicaragua',
        countryCode: 505,
    },
    NL: {
        name: 'Netherlands',
        countryCode: 31,
    },
    NO: {
        name: 'Norway',
        countryCode: 47,
    },
    NP: {
        name: 'Nepal',
        countryCode: 977,
    },
    NR: {
        name: 'Nauru',
        countryCode: 674,
    },
    NU: {
        name: 'Niue',
        countryCode: 683,
    },
    NZ: {
        name: 'New Zealand',
        countryCode: 64,
    },
    OM: {
        name: 'Oman',
        countryCode: 968,
    },
    PA: {
        name: 'Panama',
        countryCode: 507,
    },
    PE: {
        name: 'Peru',
        countryCode: 51,
    },
    PF: {
        name: 'French Polynesia',
        countryCode: 689,
    },
    PG: {
        name: 'Papua New Guinea',
        countryCode: 675,
    },
    PH: {
        name: 'Philippines',
        countryCode: 63,
    },
    PK: {
        name: 'Pakistan',
        countryCode: 92,
    },
    PL: {
        name: 'Poland',
        countryCode: 48,
    },
    PM: {
        name: 'Saint Pierre and Miquelon',
        countryCode: 508,
    },
    PR: {
        name: 'Puerto Rico',
        countryCode: 1,
    },
    PS: {
        name: 'Palestine',
        countryCode: 970,
    },
    PT: {
        name: 'Portugal',
        countryCode: 351,
    },
    PW: {
        name: 'Palau',
        countryCode: 680,
    },
    PY: {
        name: 'Paraguay',
        countryCode: 595,
    },
    QA: {
        name: 'Qatar',
        countryCode: 974,
    },
    RE: {
        name: 'Réunion',
        countryCode: 262,
    },
    RO: {
        name: 'Romania',
        countryCode: 40,
    },
    RS: {
        name: 'Serbia',
        countryCode: 381,
    },
    RU: {
        name: 'Russia',
        countryCode: 7,
    },
    RW: {
        name: 'Rwanda',
        countryCode: 250,
    },
    SA: {
        name: 'Saudi Arabia',
        countryCode: 966,
    },
    SB: {
        name: 'Solomon Islands',
        countryCode: 677,
    },
    SC: {
        name: 'Seychelles',
        countryCode: 248,
    },
    SD: {
        name: 'Sudan',
        countryCode: 249,
    },
    SE: {
        name: 'Sweden',
        countryCode: 46,
    },
    SG: {
        name: 'Singapore',
        countryCode: 65,
    },
    SH: {
        name: 'Saint Helena, Ascension and Tristan da Cunha',
        countryCode: 290,
    },
    SI: {
        name: 'Slovenia',
        countryCode: 386,
    },
    SJ: {
        name: 'Svalbard and Jan Mayen',
        countryCode: 47,
    },
    SK: {
        name: 'Slovakia',
        countryCode: 421,
    },
    SL: {
        name: 'Sierra Leone',
        countryCode: 232,
    },
    SM: {
        name: 'San Marino',
        countryCode: 378,
    },
    SN: {
        name: 'Senegal',
        countryCode: 221,
    },
    SO: {
        name: 'Somalia',
        countryCode: 252,
    },
    SR: {
        name: 'Suriname',
        countryCode: 597,
    },
    SS: {
        name: 'South Sudan',
        countryCode: 211,
    },
    ST: {
        name: 'São Tomé and Príncipe',
        countryCode: 239,
    },
    SV: {
        name: 'El Salvador',
        countryCode: 503,
    },
    SX: {
        name: 'Sint Maarten',
        countryCode: 599,
    },
    SY: {
        name: 'Syria',
        countryCode: 963,
    },
    SZ: {
        name: 'Eswatini (Swaziland)',
        countryCode: 268,
    },
    TC: {
        name: 'Turks and Caicos Islands',
        countryCode: 1,
    },
    TD: {
        name: 'Chad',
        countryCode: 235,
    },
    TG: {
        name: 'Togo',
        countryCode: 228,
    },
    TH: {
        name: 'Thailand',
        countryCode: 66,
    },
    TJ: {
        name: 'Tajikistan',
        countryCode: 992,
    },
    TK: {
        name: 'Tokelau',
        countryCode: 690,
    },
    TL: {
        name: 'Timor-Leste',
        countryCode: 670,
    },
    TM: {
        name: 'Turkmenistan',
        countryCode: 993,
    },
    TN: {
        name: 'Tunisia',
        countryCode: 216,
    },
    TO: {
        name: 'Tonga',
        countryCode: 676,
    },
    TR: {
        name: 'Turkey',
        countryCode: 90,
    },
    TT: {
        name: 'Trinidad and Tobago',
        countryCode: 1,
    },
    TV: {
        name: 'Tuvalu',
        countryCode: 688,
    },
    TW: {
        name: 'Taiwan',
        countryCode: 886,
    },
    TZ: {
        name: 'Tanzania',
        countryCode: 255,
    },
    UA: {
        name: 'Ukraine',
        countryCode: 380,
    },
    UG: {
        name: 'Uganda',
        countryCode: 256,
    },
    US: {
        name: 'United States',
        countryCode: 1,
    },
    UY: {
        name: 'Uruguay',
        countryCode: 598,
    },
    UZ: {
        name: 'Uzbekistan',
        countryCode: 998,
    },
    VA: {
        name: 'Vatican City (Holy See)',
        countryCode: 379,
    },
    VC: {
        name: 'Saint Vincent and the Grenadines',
        countryCode: 1,
    },
    VE: {
        name: 'Venezuela',
        countryCode: 58,
    },
    VG: {
        name: 'British Virgin Islands',
        countryCode: 1,
    },
    VI: {
        name: 'United States Virgin Islands',
        countryCode: 1,
    },
    VN: {
        name: 'Vietnam',
        countryCode: 84,
    },
    VU: {
        name: 'Vanuatu',
        countryCode: 678,
    },
    WF: {
        name: 'Wallis and Futuna',
        countryCode: 681,
    },
    WS: {
        name: 'Samoa',
        countryCode: 685,
    },
    XK: {
        name: 'Kosovo',
        countryCode: 383,
    },
    YE: {
        name: 'Yemen',
        countryCode: 967,
    },
    YT: {
        name: 'Mayotte',
        countryCode: 262,
    },
    ZA: {
        name: 'South Africa',
        countryCode: 27,
    },
    ZM: {
        name: 'Zambia',
        countryCode: 260,
    },
    ZW: {
        name: 'Zimbabwe',
        countryCode: 263,
    },
}
