<template>
    <svg class="mypallet-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 193.168 144.917">
        <g transform="translate(0 .5)">
            <g transform="translate(-53.665 -77.791)">
                <g>
                    <path
                        class="light"
                        d="M149.859 184.042V130.3a96.786 96.786 0 0 0-.65-11.631 27.956 27.956 0 0 0-2.614-9.214 14.6 14.6 0 0 0-5.568-6.059 18.3 18.3 0 0 0-9.595-2.195 23.975 23.975 0 0 0-7.213 1.041 25.21 25.21 0 0 0-6.45 3.144 45.138 45.138 0 0 0-6.332 5.223q-3.186 3.12-6.874 7.252-1.151-8.748-5.757-12.7T86.143 101.2a22.537 22.537 0 0 0-12.553 4.227A78.324 78.324 0 0 0 60.272 117.1v-13.6h-6.606v80.534h6.6V124q3.528-3.691 6.79-6.753a61.863 61.863 0 0 1 6.372-5.3 31.7 31.7 0 0 1 6.1-3.49 15.139 15.139 0 0 1 5.99-1.273 16.656 16.656 0 0 1 5.871.922 8.031 8.031 0 0 1 3.994 3.534 20.507 20.507 0 0 1 2.3 7.329 84.009 84.009 0 0 1 .733 12.4v52.665h6.593V124q4.229-4.309 7.562-7.486a55.186 55.186 0 0 1 6.3-5.263 23.759 23.759 0 0 1 5.87-3.074 19.854 19.854 0 0 1 6.374-1 15.361 15.361 0 0 1 5.409.85 7.33 7.33 0 0 1 3.763 3.492 22.43 22.43 0 0 1 2.225 7.641 95.74 95.74 0 0 1 .727 13.283v51.589h6.614Z"
                        fill="#fafafa"
                    />
                    <path
                        class="light"
                        d="M148.061 219.986a1.729 1.729 0 0 1-1.9 1.5l-90.634.723a2.311 2.311 0 0 1 .039-4.543l90.621.791a1.728 1.728 0 0 1 1.872 1.53"
                        fill="#fafafa"
                    />
                    <path
                        class="dark"
                        d="M177.166 184.036V77.791h31.5a63.139 63.139 0 0 1 17.125 2.054 28.444 28.444 0 0 1 11.642 6.162v-.006a25.4 25.4 0 0 1 6.681 10.537 46.673 46.673 0 0 1 2.227 15.179 54.117 54.117 0 0 1-2.261 16.539 26.5 26.5 0 0 1-6.761 11.221 28.54 28.54 0 0 1-11.827 6.421 62.986 62.986 0 0 1-17.458 2.14h-16.668v36h-14.2Zm14.2-93.421v44.518h13.668c7.443 0 12.957-.734 16.389-2.182a14.46 14.46 0 0 0 7.6-7.06c1.6-3.234 2.411-7.9 2.411-13.856a30.189 30.189 0 0 0-1.382-9.633 15.909 15.909 0 0 0-4.145-6.641 17.62 17.62 0 0 0-7.483-3.859 44.047 44.047 0 0 0-11.4-1.286Z"
                        fill="#f4f6f6"
                        stroke="rgba(0,0,0,0)"
                        stroke-miterlimit="10"
                        stroke-width="1"
                    />
                    <path
                        class="dark"
                        d="M117.279 209.816a2.551 2.551 0 0 1 2.707-2.386l123.962 3.97c1.423.076 2.486 1.828 2.379 3.914s-1.351 3.723-2.773 3.648l-123.827-6.492a2.552 2.552 0 0 1-2.442-2.651"
                        fill="#fafafa"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<style>
.mypallet-icon .light {
    fill: #818892;
}
.mypallet-icon .dark {
    fill: #264b43;
}
</style>
