import { ref } from 'vue'

/**
 * Hook used to cache a given value for x amount of time
 * When the time has passed the value will not be returned
 * @param key localStorage key
 * @param minutes How many minutes for the cache to be used
 */
export function useStorageCache<Type>(key: string, minutes: number) {
    const timeKey = `${key}-last-update`
    const cache = ref<Type | null>(null)
    const lastUpdate = ref<string>()

    const lastUpdateTime = localStorage.getItem(timeKey)
    const expirationTime = new Date(lastUpdateTime ?? 0).getTime() + 1000 * 60 * minutes

    if (expirationTime && new Date(expirationTime) > new Date()) {
        const cacheValue = localStorage.getItem(key)

        cache.value = cacheValue ? JSON.parse(cacheValue) : null
    }

    function setLastUpdate(isoString?: string) {
        if (!isoString) {
            lastUpdate.value = undefined
            return
        }

        const date = new Date(isoString)
        const hour = (date.getHours() < 10 ? '0' : '') + date.getHours()
        const minute = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()

        lastUpdate.value = `${hour}:${minute}`
    }

    setLastUpdate(lastUpdateTime ?? undefined)

    return {
        value: cache,
        lastUpdate,
        set(value: Type) {
            const updateTime = new Date().toISOString()
            setLastUpdate(updateTime)
            localStorage.setItem(timeKey, updateTime)
            localStorage.setItem(key, JSON.stringify(value))
        },
    }
}
