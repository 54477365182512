import { parsePhoneNumber } from 'libphonenumber-js'

import { Address } from '@/types/form'

export function capitalize(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export function addressString(address: Omit<Address, 'latitude' | 'longitude'>): string {
    const fields = [address.address, address.zipcode + ' ' + address.city, address.country]

    return fields.filter((field) => !!field).join(', ')
}

export function phoneNumberString(phoneNumber: string): string {
    try {
        return parsePhoneNumber(phoneNumber).formatInternational()
    } catch (e) {
        return phoneNumber
    }
}
