import { useI18n } from 'vue-i18n'
import axios from 'axios'

import { MyButtonScheme } from '@/types/layout/my-button'
import { useConfirm } from '@/hooks/use-confirm'
import { useAuthStore } from '@/stores/auth-store'
import { uuid } from '@/types/general'

/**
 * Use to delete a delivery description.
 * When true is returned the delivery description has been deleted.
 */
export function useDeleteDeliveryDescription() {
    const { t } = useI18n()
    const confirm = useConfirm()
    const authStore = useAuthStore()

    return async function (deliveryDescriptionId: uuid) {
        try {
            await confirm(
                t('deleteEntityTitle', { entity: t('deliveryDescription') }),
                t('deleteEntityDescription', { entity: t('deliveryDescription') }),
                {
                    confirmText: t('yes'),
                    cancelText: t('no'),
                    confirmButtonScheme: MyButtonScheme.Warning,
                },
            )
        } catch {
            return
        }
        await axios.delete(
            window.route('dm.company.delivery-descriptions.destroy', {
                company: authStore.companyId,
                delivery_description: deliveryDescriptionId,
            }),
        )

        return true
    }
}
