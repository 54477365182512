<script lang="ts" setup>
import { computed } from 'vue'

import { IncludedTicket, IncludedDeviation, TourEvent } from '@/types/delivery-management'

import ShipmentEventContent from '@/components/delivery-management/tour-modal/ShipmentEventContent.vue'
import WaypointEventContent from '@/components/delivery-management/tour-modal/WaypointEventContent.vue'
import TourEventInformation from '@/components/delivery-management/tour-modal/TourEventInformation.vue'

export interface Props {
    tourEvents: TourEvent[]
    tickets?: IncludedTicket[]
    deviations?: IncludedDeviation[]
    isShipperCompany?: boolean
    isHubHaulier?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    isShipperCompany: false,
    isHubHauler: false,
    deviations: () => [],
    tickets: () => [],
})

const ticketsByShipment = computed(() => {
    if (props.isHubHaulier) return {}
    const result: Record<string, IncludedTicket[]> = {}
    for (const ticket of props.tickets ?? []) {
        if (!ticket.shipmentId) continue
        result[ticket.shipmentId!] ??= []
        result[ticket.shipmentId].push(ticket)
    }
    return result
})

const deviationsByShipment = computed(() => {
    if (props.isHubHaulier) return {}
    const result: Record<string, IncludedDeviation[]> = {}
    for (const deviation of props.deviations ?? []) {
        if (!deviation.shipmentId) continue
        result[deviation.shipmentId!] ??= []
        result[deviation.shipmentId].push(deviation)
    }
    return result
})
</script>

<template>
    <table class="mx-auto max-w-[1200px]">
        <tr v-for="(event, index) in tourEvents" :key="event.id">
            <td class="relative whitespace-nowrap">
                <TourEventInformation
                    :event="event"
                    :index="index"
                    :deviations="
                        event.shipment ? deviationsByShipment[event.shipment.id] : undefined
                    "
                    :tickets="event.shipment ? ticketsByShipment[event.shipment.id] : undefined"
                />
            </td>
            <td
                :data-id="'eventEntry-' + event.id"
                class="w-full rounded-xl p-0 transition-shadow duration-1000 ease-in-out scroll-mt-5"
            >
                <ShipmentEventContent
                    v-if="event.shipment"
                    :event="event"
                    :tickets="ticketsByShipment[event.shipment.id]"
                    :deviations="deviationsByShipment[event.shipment.id]"
                    :is-shipper-company="props.isShipperCompany"
                    :is-hub-haulier="props.isHubHaulier"
                />
                <WaypointEventContent v-if="event.waypoint" :event="event" />
            </td>
        </tr>
    </table>
</template>
