import { useThrottleFn } from '@vueuse/core'
import { Ref, ref } from 'vue'

import { ColumnSize, InternalTableColumn } from '@/types/table'

/**
 * Internal MyTable hook to handle events for column resizing.
 * @param columns
 * @param columnSizes
 * @param onChange Called when the column width changes.
 */
export function useColumnResizing(
    columns: Ref<Map<string, InternalTableColumn>>,
    columnSizes: Ref<Map<string, ColumnSize>>,
    onChange: () => void,
) {
    const lastPointerX = ref(0)
    const resizedColumn = ref<string>()
    let resizedColumnRef: InternalTableColumn | undefined = undefined

    return {
        resizedColumn,
        onResizeStart(property: string) {
            resizedColumn.value = property
            resizedColumnRef = columns.value.get(property) || undefined
            document.body.style.cursor = 'col-resize'
            document.body.style.userSelect = 'none'
        },
        onResize: useThrottleFn((e: MouseEvent) => {
            if (resizedColumn.value) {
                const delta = e.clientX - lastPointerX.value
                const columnSize = columnSizes.value.get(resizedColumn.value)!
                columnSize.width = Math.max(
                    columnSize.width + delta,
                    resizedColumnRef?.minWidth ?? 0,
                )

                onChange()
            }

            lastPointerX.value = e.clientX
        }, 10),
        onResizeEnd() {
            resizedColumn.value = undefined
            document.body.style.cursor = 'auto'
            document.body.style.userSelect = 'auto'
        },
    }
}
