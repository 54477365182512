<script lang="ts" setup>
import type { DropdownOption } from '@/types/inputs'

import Datepicker from '@vuepic/vue-datepicker'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    LineElement,
    LinearScale,
    PointElement,
    CategoryScale,
    ArcElement,
} from 'chart.js'
import { storeToRefs } from 'pinia'
import { computed, onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@/stores/auth-store'
import { useCompanyStore } from '@/stores/company-store'
import { useDashboardStore } from '@/stores/dashboard-store'
import { LicenseType } from '@/types/company'
import { usePresetRangeList } from '@/hooks/use-preset-range-list'

import CustomerProductBalanceWidget from '@/components/dashboard/CustomerProductBalanceWidget.vue'
import DashboardEmployees from '@/components/dashboard/DashboardEmployees.vue'
import LatestTransactions from '@/components/dashboard/LatestTransactions.vue'
import StockBalanceChart from '@/components/dashboard/StockBalanceChart.vue'
import TotalBalanceDisplay from '@/components/dashboard/TotalBalanceDisplay.vue'
import TransactionProductsChart from '@/components/dashboard/TransactionProductsChart.vue'
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    LineElement,
    LinearScale,
    PointElement,
    CategoryScale,
    ArcElement,
)

const { t } = useI18n()
const dashboardStore = useDashboardStore()
const companyStore = useCompanyStore()
const authStore = useAuthStore()
const { date, selectedLocation } = storeToRefs(dashboardStore)
const presetRanges = usePresetRangeList()

const locations = computed<DropdownOption[]>(() =>
    companyStore.locations.map(({ id, name }) => ({ value: id, label: name })),
)

onBeforeMount(() => {
    dashboardStore.initialize()
    companyStore.fetchCompany()
})
</script>

<template>
    <CrumbsAndActions>
        <Breadcrumb current v-text="t('dashboard')" />

        <template #actions>
            <MySelect
                v-model="selectedLocation"
                in-action
                :options="locations"
                :loading="companyStore.loadingLocations"
                group-class="max-w-[150px]"
                clear-button
                :placeholder="t('allLocations')"
            />

            <Datepicker
                v-model="date"
                input-class-name="w-64"
                range
                :enable-time-picker="false"
                auto-apply
                :clearable="false"
                :preset-dates="presetRanges"
                close-on-scroll
                position="left"
                format="yyyy-MM-dd"
                :transitions="false"
            />
        </template>
    </CrumbsAndActions>
    <div class="grid grid-flow-dense auto-rows-[minmax(100px,auto)] grid-cols-10 gap-8">
        <TotalBalanceDisplay
            v-if="authStore.hasLicense(LicenseType.Stock)"
            class="grid-item order-1 col-span-7"
        />
        <TransactionProductsChart class="grid-item order-3 col-span-7" />
        <CustomerProductBalanceWidget class="grid-item order-5 col-span-7" />
        <LatestTransactions class="grid-item order-6 col-span-10" />
        <DashboardEmployees class="grid-item order-4 col-span-3" />
        <StockBalanceChart
            v-if="authStore.hasLicense(LicenseType.Stock)"
            class="grid-item order-2 col-span-3 row-span-2"
        />
    </div>
</template>
