import { Handler } from 'mitt'
import { onBeforeUnmount } from 'vue'

import { emitter, Events } from '@/utils/mitt'

/**
 * Simple hook to listen to a global event
 * and make sure the listener is unbinded when the component is unmounted
 */
export function useMittListener<Key extends keyof Events>(
    event: Key,
    handler: Handler<Events[Key]>,
) {
    emitter.on(event, handler)

    onBeforeUnmount(() => emitter.off(event, handler))
}
