<script lang="ts">
export interface Props {
    customerCompanyId?: uuid
    locationId?: uuid
    comparedLocationId?: uuid
    endpoint?: string
    hideActions?: boolean
    hideLocationFilter?: boolean
    hideDateRange?: boolean
    // Whether to redirect to the transaction or view it inline
    inlineViewModal?: boolean
    onlyStockAdjustments?: boolean
    tableId?: string
}
</script>

<script lang="ts" setup>
import type { DropdownOption } from '@/types/inputs'
import type { DatePickerInstance } from '@vuepic/vue-datepicker'

import axios, { CanceledError } from 'axios'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouteLocationRaw, RouterLink, useRoute } from 'vue-router'
import Datepicker from '@vuepic/vue-datepicker'
import dayjs from 'dayjs'

import { usePaginatedTable } from '@/hooks/table/use-paginated-table'
import { useConfirm } from '@/hooks/use-confirm'
import { useMittListener } from '@/hooks/use-mitt-listener'
import { useAuthStore } from '@/stores/auth-store'
import { useCompanyStore } from '@/stores/company-store'
import { useTransactionStore } from '@/stores/transaction-store'
import { LicenseType } from '@/types/company'
import { PaginatedResponse, PermissionType, uuid } from '@/types/general'
import { MyButtonScheme } from '@/types/layout/my-button'
import { DataType, GetDataParameters } from '@/types/table'
import {
    TransactionFilter,
    TransactionType,
    TransactionState,
    MinimalTransaction,
    ShipmentStatus,
} from '@/types/transaction'
import { datetime } from '@/utils/dates'
import { usePresetRangeList } from '@/hooks/use-preset-range-list'

import UpdateEconomyModal from '@/components/packaging/transactions/UpdateEconomyModal.vue'
import BasicShareModal from '@/components/BasicShareModal.vue'
import TransactionModal from '@/components/packaging/transactions/TransactionModal.vue'
import TransactionStatusIcons from '@/components/packaging/transactions/TransactionStatusIcons.vue'
import TransactionTablePopover from '@/components/packaging/transactions/TransactionTablePopover.vue'
import ActionMenuItem from '@/components/table/ActionMenuItem.vue'
import ActionRowDivider from '@/components/table/ActionRowDivider.vue'
import ActionRowItem from '@/components/table/ActionRowItem.vue'
import MyFilterButton from '@/components/table/MyFilterButton.vue'
import MyFilterDivider from '@/components/table/MyFilterDivider.vue'
import MyFilterSelect from '@/components/table/MyFilterSelect.vue'
import MyTable from '@/components/table/MyTable.vue'
import MyTableColumn from '@/components/table/MyTableColumn.vue'
import MyButton from '@/components/my-components/MyButton.vue'

interface FetchTransactionParams extends Record<string, unknown>, GetDataParameters {
    filter: {
        state: TransactionFilter
        'location-id'?: uuid
        'compared-location-id'?: uuid
        transaction?: TransactionType
        'transaction-state'?: TransactionState
        'shipment-status'?: ShipmentStatus
        'customer-company-id'?: uuid
        'start-date': string | undefined
        'end-date': string | undefined
        type: TransactionType | undefined
    }
}

const props = withDefaults(defineProps<Props>(), {
    hideActions: false,
    inlineViewModal: false,
    hideLocationFilter: false,
    onlyStockAdjustments: false,
    tableId: 'transactions',
})

const confirm = useConfirm()
const { t } = useI18n()
const route = useRoute()
const transactionStore = useTransactionStore()
const authStore = useAuthStore()
const companyStore = useCompanyStore()
const { managerOpen } = storeToRefs(transactionStore)
const presetRanges = usePresetRangeList()
const datepicker = ref<DatePickerInstance>(null)

const selectedTransactionId = ref<uuid>()
const shareTransactionOpen = ref(false)
const viewTransactionOpen = ref(false)
const economyModalOpen = ref(false)
const loadingCount = ref(false)

const showLocationFilter = computed(() => {
    if (props.hideLocationFilter || props.locationId) {
        return false
    }

    if (authStore.hasLicense(LicenseType.AgentLocationFiltering)) {
        return authStore.company.locations.length === 0
    }

    return true
})
const locations = computed(() =>
    companyStore.locations.map(({ id, name }) => ({ label: name, value: id })),
)
const selectedTransaction = computed(() =>
    data.value.find((transaction) => transaction.id === selectedTransactionId.value),
)
const shareEndpoint = computed(() => {
    if (!authStore.user) return null

    return window.route('packaging.company.transactions.share', {
        company: authStore.companyId,
        transaction: selectedTransactionId.value ?? '',
    })
})
const transactionTypes = computed<DropdownOption[]>(() => [
    { value: '', label: t('all') },
    { value: 'default', label: t('transactionTypeDefault') },
    { value: 'settlement', label: t('transactionTypeSettlement') },
    { value: 'pallet-bank', label: t('palletBank') },
    { value: 'backend', label: t('transactionTypeBackend') },
    { value: 'location', label: t('transactionTypeLocation') },
    { value: 'transit', label: t('transactionTypeTransit') },
])

const shipmentStatus = computed<DropdownOption[]>(() => [
    { value: 'completed', label: t('shipmentStatusCompleted') },
    { value: 'awaiting', label: t('shipmentStatusAwaiting') },
    { value: 'mismatch', label: t('shipmentStatusMismatch') },
])

const transitProductDifferenceOptions = computed<DropdownOption[]>(() => [
    { value: 'with-product-difference', label: t('withProductDifference') },
    { value: 'without-product-difference', label: t('withoutProductDifference') },
])

const transitLocationTradeEnabled = computed(() => {
    const packagingLicense = authStore.licenses[LicenseType.PackagingModule]
    return packagingLicense?.transitLocationTrades === true
})

const { data, paginationData, params, loading, error, refetch, setPaginationData } =
    usePaginatedTable<MinimalTransaction, FetchTransactionParams>(
        async (params, abortController) => {
            let endpoint = props.endpoint
            if (!endpoint) {
                endpoint = window.route('packaging.company.transactions.lazy', {
                    company: authStore.companyId,
                })
            }

            const response = await axios.get<PaginatedResponse<MinimalTransaction>>(endpoint, {
                signal: abortController.signal,
                params: { ...params, count: 0 },
            })

            // We get the count after items request is done since it'll slow it down
            loadingCount.value = true
            axios
                .get<PaginatedResponse<MinimalTransaction>>(endpoint, {
                    params: { ...params, count: 1 },
                    signal: abortController.signal,
                })
                .then((response) => {
                    setPaginationData(response.data.meta)
                })
                .catch((error) => {
                    if (!(error instanceof CanceledError)) {
                        throw error
                    }
                })
                .finally(() => {
                    loadingCount.value = false
                })

            return response.data
        },
        {
            filter: {
                state: 'all',
                'customer-company-id': props.customerCompanyId,
                'location-id': props.locationId,
                'compared-location-id': props.comparedLocationId,

                'start-date': props.hideDateRange
                    ? undefined
                    : dayjs().subtract(6, 'months').startOf('day').toDate().toISOString(),
                'end-date': props.hideDateRange
                    ? undefined
                    : dayjs().endOf('day').toDate().toISOString(),
                type: props.onlyStockAdjustments ? TransactionType.StockAdjustment : undefined,
            },
        },
        { lazy: true },
    )

const date = ref<Date[]>(
    params.value.filter['start-date'] && params.value.filter['end-date']
        ? [
              dayjs(params.value.filter['start-date']).toDate(),
              dayjs(params.value.filter['end-date']).toDate(),
          ]
        : [],
)

function transactionLink(id: string): RouteLocationRaw {
    if (route.name?.toString()?.includes('customers.show')) {
        return {
            name: 'packaging.customers.transactions.show',
            params: { ...route.params, transactionId: id },
            query: route.query,
        }
    }

    return {
        name: 'packaging.transactions.show',
        params: { transactionId: id },
        query: route.query,
    }
}

function setFilter(value: string | number | null, filterName: string) {
    params.value.filter[filterName] = value as TransactionFilter
    params.value.page = 1
}

function clearDates() {
    datepicker.value?.closeMenu()
    delete params.value.filter['start-date']
    delete params.value.filter['end-date']
    date.value = []
}

async function deleteTransaction(id: string) {
    try {
        await confirm(
            t('deleteEntityTitle', { entity: t('transaction') }),
            t('deleteEntityDescription', { entity: t('transaction') }),
            {
                confirmText: t('yes'),
                cancelText: t('no'),
                confirmButtonScheme: MyButtonScheme.Warning,
            },
        )
    } catch {
        return
    }

    await transactionStore.delete(id)
    await refetch()
}

function shareTransaction(id: uuid) {
    selectedTransactionId.value = id
    shareTransactionOpen.value = true
}

function viewTransaction(id: uuid) {
    selectedTransactionId.value = id
    viewTransactionOpen.value = true
}

function updateEconomyNote(id: uuid) {
    selectedTransactionId.value = id
    economyModalOpen.value = true
}

useMittListener('fetchTransactions', () => refetch())
watch(managerOpen, () => {
    if (!managerOpen.value) refetch()
})
watch(
    () => [props.customerCompanyId, props.locationId, props.comparedLocationId],
    () => {
        params.value.filter['customer-company-id'] = props.customerCompanyId
        params.value.filter['location-id'] = props.locationId
        params.value.filter['compared-location-id'] = props.comparedLocationId
        params.value.page = 1
    },
)
watch(date, () => {
    if (!date.value[0] || !date.value[1]) return
    setFilter(dayjs(date.value[0]).startOf('day').toISOString(), 'start-date')
    setFilter(dayjs(date.value[1]).endOf('day').toISOString(), 'end-date')
})
watch(
    () => props.locationId,
    () => {
        refetch()
    },
)
</script>

<template>
    <BasicShareModal
        v-if="selectedTransaction && shareEndpoint"
        v-model="shareTransactionOpen"
        :entity="t('transaction')"
        :endpoint="shareEndpoint"
        :customer-company-id="
            selectedTransaction.receivingCompany.id === authStore.companyId
                ? selectedTransaction.sendingCompany.id
                : selectedTransaction.receivingCompany.id
        "
        @close="selectedTransactionId = undefined"
    />

    <TransactionModal
        v-if="viewTransactionOpen"
        :id="selectedTransactionId"
        v-model:open="viewTransactionOpen"
    />

    <UpdateEconomyModal
        v-if="economyModalOpen && selectedTransaction"
        v-model="economyModalOpen"
        :transaction-id="selectedTransaction.id"
        :economy="selectedTransaction.companyDetails.economy ?? ''"
    />

    <MyTable
        :error="error"
        :get-data="refetch"
        :loading="loading"
        :loading-count="loadingCount"
        :pagination-data="paginationData"
        :rows="data"
        :table-id="props.tableId"
        rounded
        :entity-name="t('transactions')"
        :has-time-range="!!params.filter['start-date']"
    >
        <template #filters>
            <Datepicker
                v-if="!props.hideDateRange"
                ref="datepicker"
                v-model="date"
                input-class-name="w-64"
                range
                :enable-time-picker="false"
                auto-apply
                :teleport="true"
                :clearable="false"
                :preset-dates="presetRanges"
                close-on-scroll
                position="left"
                format="yyyy-MM-dd"
                :transitions="false"
                :placeholder="t('allTime')"
                :config="{ closeOnAutoApply: true }"
            >
                <template #allTime>
                    <button
                        class="dp__btn dp--preset-range"
                        @click="clearDates()"
                        v-text="t('allTime')"
                    />
                </template>
            </Datepicker>

            <MyFilterDivider v-if="!props.hideDateRange" />

            <MyFilterButton
                filter-name="state"
                default
                value="all"
                @selected="setFilter"
                v-text="t('all')"
            />
            <MyFilterButton
                filter-name="state"
                value="confirmed"
                @selected="setFilter"
                v-text="t('confirmed')"
            />
            <MyFilterButton
                filter-name="state"
                value="declined"
                @selected="setFilter"
                v-text="t('declined')"
            />
            <MyFilterButton
                filter-name="state"
                value="not-confirmed"
                @selected="setFilter"
                v-text="t('notConfirmed')"
            />
            <MyFilterButton
                v-if="
                    authStore.hasLicense([
                        LicenseType.TransactionDrafts,
                        LicenseType.MultipleTransactionDrafts,
                    ])
                "
                filter-name="state"
                value="draft"
                @selected="setFilter"
                v-text="t('drafts')"
            />

            <MyFilterDivider />

            <MyFilterSelect
                v-if="showLocationFilter"
                :label="t('locations')"
                :options="locations"
                filter-name="location-id"
                @change="setFilter"
            />
            <MyFilterSelect
                :label="t('transactions')"
                :options="transactionTypes"
                filter-name="transaction"
                @change="setFilter"
            />
            <MyFilterSelect
                :label="t('shipmentStatus')"
                :options="shipmentStatus"
                filter-name="shipment-status"
                @change="setFilter"
            />

            <MyFilterSelect
                v-if="transitLocationTradeEnabled"
                :label="t('transitProductDifference')"
                :options="transitProductDifferenceOptions"
                :dropdown-width="240"
                filter-name="product-difference"
                @change="setFilter"
            />
        </template>

        <template #actionRow="{ row }">
            <ActionRowItem v-if="props.inlineViewModal" @click="viewTransaction(row.id)">
                <mdi:eye />
            </ActionRowItem>

            <RouterLink v-else :to="transactionLink(row.id)">
                <ActionRowItem>
                    <mdi:eye />
                </ActionRowItem>
            </RouterLink>
        </template>

        <template
            v-if="
                authStore.hasPermission(PermissionType.ManageTransactions) &&
                !authStore.isReadonly(LicenseType.PackagingModuleReadonly) &&
                !props.hideActions
            "
            #actionMenu="{ row }"
        >
            <ActionMenuItem
                v-if="
                    authStore.hasLicense(LicenseType.BackendTransactions) &&
                    row.type !== TransactionType.Reset &&
                    row.state !== TransactionState.Draft &&
                    row.state !== TransactionState.IncomingDraft
                "
                class="flex cursor-pointer items-center rounded-lg py-2 px-3 text-sm hover:bg-primary-500 hover:text-primary-50 dark:hover:bg-dark-300"
                @click="transactionStore.duplicate(row.id)"
            >
                <mdi:content-duplicate class="mr-2" />
                {{ t('duplicate') }}
            </ActionMenuItem>

            <ActionMenuItem
                v-if="
                    row.state !== TransactionState.Draft &&
                    row.state !== TransactionState.IncomingDraft
                "
                class="flex cursor-pointer items-center rounded-lg py-2 px-3 text-sm hover:bg-primary-500 hover:text-primary-50 dark:hover:bg-dark-300"
                @click="shareTransaction(row.id)"
            >
                <mdi:receipt class="mr-2" />
                {{ t('share') }}
            </ActionMenuItem>

            <ActionRowDivider />

            <ActionMenuItem
                v-if="transactionStore.canEditTransaction(row)"
                class="flex cursor-pointer items-center rounded-lg py-2 px-3 text-sm hover:bg-primary-500 hover:text-primary-50 dark:hover:bg-dark-300"
                @click="transactionStore.edit(row.id)"
            >
                <mdi:file-edit class="mr-2" />
                {{ t(row.state === TransactionState.IncomingDraft ? 'confirm' : 'edit') }}
            </ActionMenuItem>

            <ActionMenuItem
                v-if="row.sendingCompany.id === authStore.companyId"
                class="flex cursor-pointer items-center rounded-lg py-2 px-3 text-sm hover:bg-primary-500 hover:text-primary-50 dark:hover:bg-dark-300"
                @click="deleteTransaction(row.id)"
            >
                <mdi:trash-can class="mr-2" />
                {{ t('delete') }}
            </ActionMenuItem>
        </template>

        <MyTableColumn
            :draggable="false"
            :sortable="false"
            name=""
            property="statuses"
            class="justify-start gap-1"
        />

        <template #statuses="{ row }">
            <TransactionStatusIcons :transaction="row" />
        </template>

        <!--  SENDER COLUMNS  -->
        <MyTableColumn :name="t('sending', { property: t('user') })" property="sendingUser.name" />
        <MyTableColumn
            :name="t('sending', { property: t('company') })"
            property="sendingCompany.name"
        />
        <MyTableColumn
            :name="t('sending', { property: t('location') })"
            property="sendingLocation.name"
            filterable
        />
        <MyTableColumn
            v-if="authStore.hasLicense(LicenseType.AgentLocationFiltering)"
            :name="t('agentNumber')"
            property="agentNumber"
        />
        <MyTableColumn
            :name="t('sending', { property: t('address') })"
            property="sendingLocation.address"
            hidden
        />
        <MyTableColumn
            :name="t('sending', { property: t('zipcode') })"
            property="sendingLocation.zipcode"
            hidden
        />
        <MyTableColumn
            :name="t('sending', { property: t('city') })"
            property="sendingLocation.city"
            hidden
        />
        <MyTableColumn
            :name="t('sending', { property: t('country') })"
            property="sendingLocation.country"
            hidden
        />
        <!--  SENDER COLUMNS ENDS -->

        <!--  RECEIVER COLUMNS  -->
        <MyTableColumn :name="t('receiving', { property: t('user') })" property="receivingUser" />
        <template #receivingUser="{ row }">
            {{ row.receivingUser?.name ?? row.details?.receiverName }}
        </template>
        <MyTableColumn
            :name="t('receiving', { property: t('company') })"
            property="receivingCompany.name"
        />
        <MyTableColumn
            :name="t('receiving', { property: t('location') })"
            property="receivingLocation.name"
            filterable
        />
        <MyTableColumn
            :name="t('receiving', { property: t('address') })"
            property="receivingLocation.address"
            hidden
        />
        <MyTableColumn
            :name="t('receiving', { property: t('zipcode') })"
            property="receivingLocation.zipcode"
            hidden
        />
        <MyTableColumn
            :name="t('receiving', { property: t('city') })"
            property="receivingLocation.city"
            hidden
        />
        <MyTableColumn
            :name="t('receiving', { property: t('country') })"
            property="receivingLocation.country"
            hidden
        />
        <MyTableColumn :name="t('haulier')" property="haulierCompany.name" />
        <MyTableColumn :name="t('shipper')" property="shipperCompany.name" hidden />
        <MyTableColumn
            :name="t('destinationLocation')"
            property="destinationLocation.name"
            hidden
        />

        <!--  PRODUCTS COLUMNS  -->
        <MyTableColumn :width="120" :name="t('received')" property="productsReceived" />
        <MyTableColumn :width="120" :name="t('delivered')" property="productsDelivered" />
        <template #productsReceived="{ row }">
            <TransactionTablePopover :row="row" :value="row.productsReceived" />
        </template>
        <template #productsDelivered="{ row }">
            <TransactionTablePopover :row="row" :value="row.productsDelivered" />
        </template>
        <!--  PRODUCTS COLUMNS ENDS  -->

        <!--  DETAILS COLUMNS  -->
        <MyTableColumn :name="t('note')" property="details.note" />
        <MyTableColumn :name="t('trailerNumber')" property="details.trailerNumber" hidden />
        <MyTableColumn :name="t('truckNumber')" property="details.truckNumber" hidden />

        <MyTableColumn
            :name="t('palletReceiptNumber')"
            property="details.palletReceiptNumber"
            hidden
        />
        <MyTableColumn :name="t('shippingCode')" property="details.shippingCode" hidden />
        <!--  DETAILS COLUMNS ENDS  -->

        <MyTableColumn
            :min-width="170"
            :name="t('confirmedAt')"
            property="confirmedAt"
            hidden
            :filterable="{ dataType: DataType.Date }"
        />
        <template #confirmedAt="{ row }">
            <span v-if="row.confirmedAt" v-text="datetime(row.confirmedAt)" />
        </template>
        <MyTableColumn
            :min-width="170"
            :name="t('transpiredAt')"
            property="transpiredAt"
            :filterable="{ dataType: DataType.Date }"
        />
        <template #transpiredAt="{ row }">
            <span v-text="datetime(row.transpiredAt)" />
        </template>

        <MyTableColumn :name="t('economy')" property="companyDetails.economy" hidden />
        <template #[`companyDetails.economy`]="{ row }">
            <div class="group/item flex items-center">
                <span class="w-full truncate" v-text="row.companyDetails.economy" />

                <span
                    v-if="
                        !authStore.isReadonly(LicenseType.PackagingModuleReadonly) &&
                        authStore.hasPermission(PermissionType.ManageTransactions)
                    "
                    class="cursor-pointer pl-3 text-gray-600 opacity-0 transition-opacity group-hover/item:opacity-100 dark:text-primary-50"
                    @click="updateEconomyNote(row.id)"
                >
                    <mdi:pencil />
                </span>
            </div>
        </template>
        <template #noDataButton>
            <MyButton
                v-if="params.filter['start-date']"
                plain
                scheme="light"
                size="small"
                @click="clearDates()"
            >
                <mdi:magnify class="mr-2 h-4 w-4" />
                {{ t('searchAllTime') }}
            </MyButton>
        </template>
    </MyTable>
</template>
