<script lang="ts" setup>
import { provide, toRef, ref } from 'vue'

import { formErrorsKey } from '@/types/global-injection-keys'

export interface Props {
    errors?: Record<string, string[]>
}

const props = withDefaults(defineProps<Props>(), {
    errors: () => ({}),
})
const formRef = ref<HTMLFormElement>()
const errorsProp = toRef(props, 'errors')

provide(formErrorsKey, errorsProp)

defineExpose({
    focus() {
        const firstInput = formRef.value?.querySelector('input, select, button') as
            | HTMLInputElement
            | undefined
        firstInput?.focus()
    },
})
</script>

<template>
    <form ref="formRef">
        <slot />
    </form>
</template>
