import type { ResourceResponse } from '@/types/general'
import type { SettlementTemplate } from '@/types/driver-report'

import axios from 'axios'
import { defineStore } from 'pinia'

import { type IncludedCompany, LicenseType, type Location, type OwnCompany } from '@/types/company'
import { useAuthStore } from '@/stores/auth-store'
import { DropdownOption } from '@/types/inputs'

interface CompanyStoreState {
    company: OwnCompany | null
    clients: IncludedCompany[]
    locations: Location[]
    loading: boolean
    loadingLocations: boolean
    settlementTemplates: SettlementTemplate[]
    loadingSettlementTemplates: boolean
}

export const useCompanyStore = defineStore('CompanyStore', {
    state: (): CompanyStoreState => ({
        company: null,
        clients: [],
        locations: [],
        loading: false,
        loadingLocations: false,
        settlementTemplates: [],
        loadingSettlementTemplates: false,
    }),

    getters: {
        settlementTemplateOptions(): DropdownOption[] {
            return this.settlementTemplates.map((template) => ({
                value: template.id,
                label: template.name,
            }))
        },
    },

    actions: {
        async fetchCompany() {
            if (this.company) return

            this.loading = true
            const authStore = useAuthStore()

            const { data: response } = await axios.get<ResourceResponse<OwnCompany>>(
                window.route('company.show', { company: authStore.companyId }),
            )

            this.company = response.data
            this.clients = this.company.clients
            this.company.phoneNumber = this.company.phoneNumber || ''
            this.loading = false
        },

        async fetchLocations(query = '') {
            const authStore = useAuthStore()
            if (!authStore.companyId) return

            this.loadingLocations = true

            const { data: response } = await axios.get<ResourceResponse<Location[]>>(
                window.route('company.locations.index', { company: authStore.companyId, query }),
            )

            this.locations = response.data
            this.loadingLocations = false
        },

        async fetchSettlementTemplates() {
            const authStore = useAuthStore()
            if (!authStore.companyId || !authStore.hasLicense(LicenseType.DriverReportModule)) {
                return
            }

            this.loadingSettlementTemplates = true

            const response = await axios.get<ResourceResponse<SettlementTemplate[]>>(
                window.route('dr.company.settlement-templates.index', authStore.companyId),
            )

            this.settlementTemplates = response.data.data

            this.loadingSettlementTemplates = false
        },
    },
})
