import { MyButtonScheme } from '@/types/layout/my-button'
import { MinimalUser } from '@/types/user'
import { LocationPoint } from '@/types/company'

export interface ErrorResponse {
    errors: Record<string, string[]>
    message: string
}

export type uuid = string

export interface PaginatedResponse<Model> {
    data: Model[]
    meta: ApiPaginationData
}

export interface ResourceResponse<Model> {
    data: Model
}

export type IdResponse = ResourceResponse<{ id: uuid }>

export interface ApiPaginationData {
    first_page_url: string
    last_page_url: string
    next_page_url: string | null
    prev_page_url: string | null
    path: string
    from: number
    to: number
    per_page: number
    last_page: number
    current_page: number
    total: number
}

export interface PaginationData {
    from: number
    to: number
    perPage: number
    lastPage: number
    currentPage: number
    total: number
}

export interface PaginationParameters {
    page?: number
    'per-page'?: number
    sort?: string
}

export interface ElementInputEvent extends MouseEvent {
    target: HTMLInputElement & EventTarget
}

export interface FocusEvent extends MouseEvent {
    relatedTarget: HTMLElement
}

export interface DynamicScrollerRef {
    scrollToItem(index: number): void

    scrollToBottom(): void
}

export interface ConfirmOptions {
    confirmText?: string
    cancelText?: string
    confirmButtonScheme?: MyButtonScheme
}

export interface Confirm {
    title: string
    description?: string
    options: ConfirmOptions
    resolve: (value: boolean) => void
    reject: () => void
}

export type Model = Record<string, unknown>

export interface MinimalResource {
    id: string
    name: string
}

export enum CompaniesTab {
    Customers = '0',
    Hauliers = '1',
    Locations = '2',
}

export interface CompaniesTableRef {
    refetch(parameters?: Record<string, unknown>): void
}

export interface TargetInputEvent extends InputEvent {
    target: HTMLElement
}

export enum TransportObjectTabs {
    Vehicles = 'vehicles',
    Units = 'units',
}

export enum FieldPreference {
    Required = 0,
    Optional = 1,
    Hidden = 2,
}

export enum PermissionType {
    ManageTransactions = 'manageTransactions',
    ManageCompany = 'manageCompany',
    ManageCustomers = 'manageCustomers',
    CreateBankStatements = 'createBankStatements',
    ResetCustomerBalance = 'resetCustomerBalance',
}

export interface FileAttachment extends Model {
    id: uuid
    path: string
    name: string | null
    type: number | null
    note: string | null
}

export interface Dispatch {
    id: uuid
    companyId: string
    user: MinimalUser
    emails: string[]
    createdAt: string
}

export interface Marker {
    location: LocationPoint
    content?: string
    label?: string | number
    fillColor?: string
    markerId?: string
    href?: string
}
