<script lang="ts" setup>
import { computed } from 'vue'
import { LocationAsRelativeRaw, RouteLocationRaw, RouterLinkProps, useRoute } from 'vue-router'

export interface Props extends RouterLinkProps {
    to: RouteLocationRaw
    customActiveClass?: string
    noBackground?: boolean
    regularLink?: boolean
    noPadding?: boolean
}

const props = withDefaults(defineProps<Props>(), { noBackground: false, regularLink: false })

const route = useRoute()
const active = computed(() => {
    const toName = ((props.to as LocationAsRelativeRaw)?.name || '') as string

    if (!toName) return false

    const currentRouteName = (route.name || '') as string

    const isCompanyRoute = toName === 'packaging.balance' || toName.endsWith('.companies')
    if (isCompanyRoute && currentRouteName.endsWith('customers.show')) {
        return true
    }

    return currentRouteName.includes(toName)
})
</script>

<template>
    <RouterLink
        :to="props.to"
        :class="{
            'lg:rounded-t-xl lg:hover:bg-primary-300': !noBackground,
            'text-right font-semibold uppercase lg:text-center lg:normal-case': !regularLink,
            'lg:py-2 lg:px-5': !noPadding,
            'text-primary-500 shadow-xl lg:bg-primary-100 lg:dark:bg-dark-600': active,
        }"
        exact-active-class="pointer-events-none"
        class="cursor-pointer border-transparent transition-all dark:text-primary-100 lg:hover:text-primary-500"
    >
        <slot />
    </RouterLink>
</template>
