import { debouncedWatch } from '@vueuse/core'
import axios from 'axios'
import { ref, WatchSource } from 'vue'
import { useI18n } from 'vue-i18n'

import { User } from '@/types/user'

export function useUsernameCheck<T extends string>(username: WatchSource<T>) {
    const { t } = useI18n()
    const isChecked = ref(false)
    const isAvailable = ref(false)
    const isLoading = ref(false)
    const message = ref('')

    debouncedWatch(
        username,
        (value) => {
            isChecked.value = true
            if (value.length < 3) {
                isAvailable.value = false
                message.value = t('usernameNotAvailable', {
                    username: value,
                })
                return
            }

            isLoading.value = true

            axios
                .get<User>(window.route('users.check-username'), {
                    params: { username: value },
                })
                .then(() => {
                    isAvailable.value = true
                    message.value = t('usernameAvailable', {
                        username: value,
                    })
                })
                .catch(() => {
                    isAvailable.value = false
                    message.value = t('usernameNotAvailable', {
                        username: value,
                    })
                })
                .finally(() => {
                    isLoading.value = false
                })
        },
        { debounce: 500 },
    )

    return {
        message,
        isChecked,
        isAvailable,
        isLoading,
        resetCheck() {
            isChecked.value = false
        },
    }
}
