<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { TourDriver } from '@/types/delivery-management'
import { datetime } from '@/utils/dates'

interface Props {
    driver: TourDriver
}

const props = defineProps<Props>()

const { t } = useI18n()

const tooltip = computed(() => {
    const driverName = props.driver.user.name
    if (props.driver.confirmedAt) {
        return t('driverConfirmedAt', {
            driver: driverName,
            date: datetime(props.driver.confirmedAt),
        })
    } else if (props.driver.declinedAt) {
        return t('driverDeclinedAt', {
            driver: driverName,
            date: datetime(props.driver.declinedAt),
        })
    }

    return t('driverPendingConfirmation', { driver: driverName })
})
</script>

<template>
    <div
        v-tooltip="tooltip"
        class="h-3 w-3 flex-shrink-0 rounded-full"
        :class="{
            'bg-blue-400': !driver.confirmedAt && !driver.declinedAt,
            'bg-green-400': driver.confirmedAt !== null && !driver.declinedAt,
            'bg-red-400': driver.declinedAt !== null && !driver.confirmedAt,
        }"
    />
</template>
