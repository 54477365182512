<script lang="ts" setup>
import type { StockProductBalance, StockItem } from '@/types/stock'

import axios from 'axios'
import { computed, onBeforeMount, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { newMoneyFormatter, numberFormatter } from '@/utils/numbers'
import { useAuthStore } from '@/stores/auth-store'
import { useCompanyStore } from '@/stores/company-store'
import { useProductStore } from '@/stores/product-store'
import { image } from '@/utils/assets'
import { PermissionType, ResourceResponse } from '@/types/general'
import { DropdownOption } from '@/types/inputs'

import MySelect from '@/components/my-components/form/MySelect.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import ContentHeading from '@/components/layout/ContentHeading.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import AdjustStockModal from '@/components/packaging/balance/AdjustStockModal.vue'

const emit = defineEmits<{
    (e: 'locationChanged', locationId: string | null): void
}>()

const { t } = useI18n()
const authStore = useAuthStore()
const companyStore = useCompanyStore()
const productStore = useProductStore()
const balance = ref<StockItem[]>([])
const stockAdjustmentItem = ref<{ id: string; balance: number }>()
const stockAdjustmentOpen = ref(false)
const selectedLocationId = ref<string | null>(null)
const fetchingBalance = ref(false)
const keys: (keyof StockProductBalance)[] = ['price', 'stock', 'credit', 'total', 'totalValue']

let moneyFormatter = newMoneyFormatter(authStore.company.options.currency || 'USD')

const locations = computed<DropdownOption[]>(() =>
    companyStore.locations.map(({ id, name }) => ({ value: id, label: name })),
)

const selectedProducts = computed<StockProductBalance[]>(() =>
    productStore.companyProducts.map((product) => {
        const balanceItem = balance.value.find((item) => item.productId === product.id)
        const stock = balanceItem?.balance ?? 0
        const credit = balanceItem?.credit ?? 0
        const total = stock + credit

        return {
            image: product.image,
            id: product.id,
            name: product.name,
            price: product.price,
            stock,
            credit,
            total,
            totalValue: total * product.price,
        }
    }),
)

async function fetchBalance() {
    fetchingBalance.value = true
    const params: Record<string, string> = { company: authStore.companyId }
    if (selectedLocationId.value) params.locationId = selectedLocationId.value

    const response = await axios.get<ResourceResponse<StockItem[]>>(
        window.route('packaging.company.balance', params),
    )

    balance.value = response.data.data
    fetchingBalance.value = false
}

function adjustStock(item: StockProductBalance) {
    const balanceItem = balance.value.find(({ productId }) => productId === item.id)
    stockAdjustmentItem.value = { id: item.id, balance: balanceItem?.balance ?? 0 }
    stockAdjustmentOpen.value = true
}

onBeforeMount(async () => {
    moneyFormatter = newMoneyFormatter(authStore.company.options.currency || 'USD')
})

onBeforeMount(async () => {
    await Promise.all([await fetchBalance(), productStore.fetchProducts()])
})

watch(selectedLocationId, () => {
    fetchBalance()
    emit('locationChanged', selectedLocationId.value)
})
watch(stockAdjustmentOpen, async () => {
    if (!stockAdjustmentOpen.value) await fetchBalance()
})
</script>

<template>
    <AdjustStockModal
        v-model="stockAdjustmentOpen"
        :product-id="stockAdjustmentItem?.id"
        :current-balance="stockAdjustmentItem?.balance"
        :location-id="selectedLocationId || undefined"
    />

    <MySelect
        v-model="selectedLocationId"
        :label="t('location')"
        :options="locations"
        :loading="companyStore.loadingLocations"
        group-class="max-w-xs mb-2"
        clear-button
        :placeholder="t('allLocations')"
    />

    <MyPanel class="relative max-w-full overflow-x-auto" shadow>
        <LoaderWrapper :visible="productStore.fetching || fetchingBalance" />
        <section class="flex">
            <aside
                class="sticky left-0 z-10 inline-block min-w-[150px] rounded-tl-xl bg-primary-50 pt-[160px] text-xs uppercase shadow-lg dark:bg-dark-500"
            >
                <article
                    v-for="key in keys"
                    :key="'key-' + key"
                    class="flex h-12 items-center px-6 font-semibold odd:bg-primary-100 odd:dark:bg-dark-600"
                    v-text="t(key)"
                />
            </aside>
            <div>
                <header class="flex">
                    <section
                        v-for="product in selectedProducts"
                        :key="'header-' + product.id"
                        class="flex h-[160px] w-[250px] flex-col items-center justify-start gap-3 border-r border-primary-200 px-4 last:border-r-0 dark:border-dark-400"
                    >
                        <img
                            :alt="product.name"
                            :src="image(product.image, 'thumbnail')"
                            class="max-h-[120px] max-w-[200px]"
                        />
                        <ContentHeading
                            v-truncate-tooltip
                            class="w-full truncate text-center"
                            v-text="product.name"
                        />
                    </section>
                </header>
                <section
                    v-for="key in keys"
                    :key="key"
                    class="group flex even:bg-primary-100 even:dark:bg-dark-600"
                >
                    <article
                        v-for="product in selectedProducts"
                        :key="key + '-' + product.id"
                        class="relative flex h-12 min-w-[250px] items-center justify-center border-r border-primary-200 px-6 underline-offset-2 last:border-r-0 group-last:font-semibold group-last:underline group-last:decoration-double dark:border-dark-400"
                    >
                        <span
                            v-if="key === 'price' || key === 'totalValue'"
                            v-text="moneyFormatter.format(product[key] as number)"
                        />

                        <span
                            v-else-if="key === 'credit' && product[key] < 0"
                            v-tooltip="t('productsOwedExplanation')"
                            class="text-red-500"
                            v-text="numberFormatter.format(product[key] as number)"
                        />

                        <span
                            v-else-if="key === 'credit' && product[key] > 0"
                            v-tooltip="t('productsCreditExplanation')"
                            class="text-green-500"
                            v-text="numberFormatter.format(product[key] as number)"
                        />

                        <span v-else v-text="numberFormatter.format(product[key] as number)" />

                        <MyButton
                            v-if="
                                selectedLocationId &&
                                key === 'stock' &&
                                authStore.hasPermission(PermissionType.ManageCompany)
                            "
                            v-tooltip="t('adjustStock')"
                            class="absolute right-0 ml-2"
                            small
                            icon
                            @click="adjustStock(product)"
                        >
                            <mdi:pencil />
                        </MyButton>
                    </article>
                </section>
            </div>
        </section>
    </MyPanel>
</template>
