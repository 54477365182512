import { Ref } from 'vue'

import { InternalTableColumn } from '@/types/table'

export function setColumnOrder(columns: Ref<Map<string, InternalTableColumn>>) {
    const sortedColumns = Array.from(columns.value.values()).sort((a, b) => {
        if (a.order === b.order) return 0

        return a.order > b.order ? 1 : -1
    })
    let order = 0

    for (const column of sortedColumns) {
        columns.value.get(column.property)!.order = order
        order++
    }
}

/**
 * Returns the value for a given property of a given row
 * When dots are used in the property name, the value is retrieved from the nested object
 * @param row
 * @param property
 */
export function getPropertyValue(row: Record<string, unknown>, property: string): unknown {
    if (property.includes('.')) {
        const [key, ...rest] = property.split('.')

        if (!(key in row) || !row[key]) return undefined

        return getPropertyValue(row[key] as Record<string, unknown>, rest.join('.'))
    }

    return row[property] ?? undefined
}
