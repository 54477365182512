<script lang="ts" setup>
import { Menu, MenuButton, MenuItems } from '@headlessui/vue'

export interface Props {
    hideArrow?: boolean
    isInNav?: boolean
    width?: string
}

const props = withDefaults(defineProps<Props>(), {
    isInNav: false,
})
</script>

<template>
    <Menu as="div" class="relative h-full">
        <MenuButton
            as="button"
            class="flex h-full"
            :class="{ 'items-start p-2.5 hover:bg-white/10': isInNav, 'item-center': !isInNav }"
        >
            <slot name="trigger" />
            <mdi:chevron-down v-if="!props.hideArrow" class="ml-2 hidden h-4 w-4 lg:block" />
        </MenuButton>

        <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
        >
            <MenuItems
                :class="props.width || 'w-40'"
                class="absolute right-0 z-30 mt-2 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-xl bg-primary-100 text-gray-800 shadow-xl ring-0 ring-black ring-opacity-5 focus:outline-none dark:divide-gray-800 dark:bg-gray-900"
            >
                <slot />
            </MenuItems>
        </transition>
    </Menu>
</template>
