<script lang="ts">
interface Props {
    modelValue: string
    defaultCountry?: string
    autofocus?: boolean
}
</script>

<script lang="ts" setup>
import { MaskOptions } from 'maska'
import { onBeforeMount, ref, nextTick, reactive } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@/stores/auth-store'
import { MyInputRef, MyVatNumberInputRef } from '@/types/inputs'
import { countryCodes, CountryData, LimitedCountryCode } from '@/utils/country-codes'

import MyCountrySelect from '@/components/my-components/form/MyCountrySelect.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'

const masks: Record<string, string> = {
    DK: 'DK########',
    AT: 'ATU########',
    BE: 'BE##########',
    DE: 'DE#########',
    SE: 'SE##0',
    NO: 'NO#########',
}

const props = withDefaults(defineProps<Props>(), { autofocus: false })
const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void
    (e: 'countryChanged', country: CountryData): void
}>()

const authStore = useAuthStore()
const { t } = useI18n()
const country = ref('')
const isoCode = ref<LimitedCountryCode>('DK')
const vat = ref(props.modelValue)
const maskOptions = reactive<MaskOptions>({ mask: '' })
const vatError = ref<string>()
const vatInputRef = ref<MyInputRef>()

function countryChanged(country: CountryData) {
    // In case the vat already contains the new country code we'll avoid resetting it
    vat.value = vat.value.startsWith(country.isoCode) ? vat.value : country.isoCode
    isoCode.value = country.isoCode
    maskOptions.mask = masks[country.isoCode] || '!' + country.isoCode + '##0'
    vatInputRef.value?.input.focus()
    emit('countryChanged', country)
}

function focus() {
    vatInputRef.value?.input.focus()
}

function validate(): boolean {
    vatError.value = undefined
    const regex = (maskOptions.mask as string)
        .replace(/#/g, '\\d')
        .replace(/0/g, '\\d*')
        .replace('!', '')
    if (!vat.value || !new RegExp(regex).test(vat.value)) {
        vatError.value = t('invalidVat')

        return false
    }

    return true
}

function handlePaste(evt: ClipboardEvent) {
    const pastedData = evt.clipboardData?.getData('text/plain') ?? ''
    const pastedCountry = pastedData.slice(0, 2) ?? ''
    const matchedCountry = countryCodes[pastedCountry as LimitedCountryCode]
    if (matchedCountry && pastedCountry !== isoCode.value) {
        evt.preventDefault()
        vat.value = pastedData
        country.value = matchedCountry.name
    }
}

onBeforeMount(async () => {
    if (props.modelValue) {
        const regex = new RegExp(/^([a-zA-Z]+)(.*)/)

        const [, iso] = props.modelValue.match(regex) || []

        country.value = countryCodes[iso as LimitedCountryCode]?.name ?? ''
        countryChanged({
            isoCode: country.value ? (iso as LimitedCountryCode) : 'DK',
            name: country.value,
        })
        await nextTick()
        vat.value = props.modelValue
        return
    } else if (props.defaultCountry) {
        const countryObject = Object.entries(countryCodes).find(
            ([, data]) => data.name === props.defaultCountry,
        )

        if (countryObject) {
            country.value = countryObject[1].name
            countryChanged({
                isoCode: countryObject[0] as LimitedCountryCode,
                name: countryObject[1].name,
            })
            return
        }
    }

    const isoCode = authStore.company.vat.substring(0, 2) as LimitedCountryCode
    country.value = countryCodes[isoCode in countryCodes ? isoCode : 'DK'].name
    countryChanged({ isoCode, name: country.value })
})

defineExpose<MyVatNumberInputRef>({ validate, focus })
</script>

<template>
    <div class="mt-2 flex justify-between space-x-3">
        <MyCountrySelect v-model="country" :label="t('country')" @change="countryChanged" />

        <MyInput
            ref="vatInputRef"
            v-model="vat"
            v-maska:[maskOptions]
            data-maska-tokens="0:\d:multiple"
            :error="vatError"
            :disabled="!country"
            :label="t('vat')"
            name="vat"
            :autofocus="props.autofocus"
            @change="emit('update:modelValue', vat)"
            @paste="handlePaste"
        />
    </div>
</template>
