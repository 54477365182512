<script lang="ts">
interface Props {
    modelValue: boolean
    transportObject?: TransportObject
    type: TransportObjectType
}
</script>

<script setup lang="ts">
import type { MinimalResource, ResourceResponse, uuid } from '@/types/general'
import type { Except } from 'type-fest'

import Datepicker from '@vuepic/vue-datepicker'
import axios, { Method } from 'axios'
import { watch, computed, ref, onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { TransportObject, TransportObjectType } from '@/types/transport-object'
import { LicenseType } from '@/types/company'
import { DropdownOption } from '@/types/inputs'

import MySelect from '@/components/my-components/form/MySelect.vue'
import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MyInputLabel from '@/components/my-components/form/MyInputLabel.vue'
import MyErrorMessage from '@/components/my-components/form/MyErrorMessage.vue'

type Form = Except<
    TransportObject,
    'companyId' | 'id' | 'templateName' | 'templateDeleted' | 'updatedAt' | 'ownerCompany'
>

const props = defineProps<Props>()
const authStore = useAuthStore()
const { t } = useI18n()
const emit = defineEmits<{
    (e: 'close'): void
    (e: 'saved'): void
}>()

const informationToggle = ref(false)
const templates = ref<DropdownOption[]>([])
const owners = ref<DropdownOption[]>([])
const { data, submit, errors, reset, loading } = useForm<Form>({
    active: true,
    registrationNumber: '',
    nickname: '',
    type: props.type,
    department: '',
    email: '',
    freightWeight: 0,
    manufacturer: '',
    model: '',
    name: '',
    note: '',
    registrationDate: '',
    service: '',
    vin: '',
    weight: 0,
    templateId: null,
    phoneNumber: '',
    ownerName: '',
    length: 0,
    width: 0,
    height: 0,
    mot: '',
    tir: '',
    maxEur: 0,
    maxLdr: 0,
    maxM3: 0,
    ownerCompanyId: null,
})

const createTransportObjectRoute = computed(() => {
    if (!authStore.companyId) return ''

    return window.route('company.transport-objects.store', {
        company: authStore.companyId,
    })
})

const updateTransportObjectRoute = computed(() => {
    if (!authStore.companyId) return ''

    return window.route('company.transport-objects.update', {
        company: authStore.companyId,
        transport_object: props.transportObject || '',
    })
})

const titleBasedOnType = computed(() => {
    const entity = props.type === TransportObjectType.Vehicle ? t('vehicle') : t('unit')

    if (props.transportObject) {
        return t('updateEntity', { entity })
    } else {
        return t('createEntity', { entity })
    }
})

async function fetchTemplates() {
    loading.value = true
    const response = await axios.get<{ name: string; id: uuid }[]>(
        window.route('minimal.companies.templates', {
            company: authStore.companyId,
            type: props.type,
        }),
    )

    loading.value = false

    templates.value = response.data.map(({ id, name }) => ({ value: id, label: name }))
}

async function fetchOwners() {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.customers', { company: authStore.companyId }),
    )

    owners.value = response.data.map((customer) => ({
        value: customer.id,
        label: customer.name,
    }))
}

async function onSubmit() {
    const method: Method = props.transportObject ? 'PUT' : 'POST'
    const route = props.transportObject ? updateTransportObjectRoute : createTransportObjectRoute
    data.templateId = data.templateId ?? null
    const response = await submit<ResourceResponse<TransportObject>>(method, route.value)

    if (response !== undefined) {
        emit('close')
        emit('saved')
    }
}

watch(
    () => props.modelValue,
    () => {
        if (!props.modelValue) return

        if (props.type !== data.type || templates.value.length === 0) {
            fetchTemplates()
        }

        reset()

        data.type = props.type
        if (props.transportObject) {
            data.active = props.transportObject.active
            data.registrationNumber = props.transportObject.registrationNumber ?? ''
            data.nickname = props.transportObject.nickname ?? ''
            data.department = props.transportObject.department ?? ''
            data.email = props.transportObject.email ?? ''
            data.freightWeight = props.transportObject.freightWeight ?? 0
            data.manufacturer = props.transportObject.manufacturer ?? ''
            data.model = props.transportObject.model ?? ''
            data.name = props.transportObject.name ?? ''
            data.note = props.transportObject.note ?? ''
            data.registrationDate = props.transportObject.registrationDate ?? ''
            data.service = props.transportObject.service ?? ''
            data.vin = props.transportObject.vin ?? ''
            data.weight = props.transportObject.weight ?? 0
            data.templateId = props.transportObject.templateId
            data.phoneNumber = props.transportObject.phoneNumber ?? ''
            data.ownerName = props.transportObject.ownerName ?? ''
            data.length = props.transportObject.length ?? 0
            data.width = props.transportObject.width ?? 0
            data.height = props.transportObject.height ?? 0
            data.mot = props.transportObject.mot ?? ''
            data.tir = props.transportObject.tir ?? ''
            data.maxEur = props.transportObject.maxEur ?? 0
            data.maxLdr = props.transportObject.maxLdr ?? 0
            data.maxM3 = props.transportObject.maxM3 ?? 0
            data.ownerCompanyId = props.transportObject.ownerCompanyId
        }
    },
)

onBeforeMount(() => {
    if (authStore.hasLicense(LicenseType.TransportObjectOwnerCompany)) fetchOwners()
})
</script>

<template>
    <MyModal :value="props.modelValue" :max-width="650" @close="emit('close')">
        <LoaderWrapper :visible="loading" class="rounded-xl" />
        <template #title>
            {{ titleBasedOnType }}
        </template>
        <MyForm :errors="errors" @submit.prevent="onSubmit">
            <div class="space-y-2">
                <div
                    v-if="authStore.hasLicense(LicenseType.DamageReportModule)"
                    class="flex space-x-3"
                >
                    <MySelect
                        v-model="data.templateId"
                        group-class="w-full"
                        :label="t('template')"
                        clear-button
                        :options="templates"
                    />
                    <MyInput
                        v-model="data.registrationNumber"
                        name="registrationNumber"
                        type="text"
                        :label="t('registrationNumber')"
                    />
                </div>
                <div class="flex space-x-3">
                    <MyInput
                        v-if="!authStore.hasLicense(LicenseType.DamageReportModule)"
                        v-model="data.registrationNumber"
                        name="registrationNumber"
                        type="text"
                        :label="t('registrationNumber')"
                    />
                    <div>
                        <MyInputLabel v-text="t('registrationDate')" />
                        <Datepicker
                            v-model="data.registrationDate"
                            class="input-field rounded-xl border border-gray-300"
                            input-class-name="w-[290px]"
                            name="registrationDate"
                            close-on-scroll
                            :enable-time-picker="false"
                            auto-apply
                            :clearable="false"
                            auto-position
                            position="left"
                            format="yyyy-MM-dd"
                            :transitions="false"
                            :placeholder="t('registrationDate')"
                        />
                        <MyErrorMessage input-name="registrationDate" :label="t('arrival')" />
                    </div>
                    <MyCheckbox
                        v-model="data.active"
                        class="-mb-[20px]"
                        name="active"
                        :label="t('active')"
                    />
                </div>
                <div class="flex space-x-3">
                    <MyInput
                        v-model="data.nickname"
                        name="nickname"
                        type="text"
                        :label="t('nickname')"
                    />
                    <MyInput v-model="data.vin" name="vin" type="text" :label="t('vin')" />
                </div>
                <div class="flex space-x-3">
                    <MyInput
                        v-model="data.manufacturer"
                        name="manufacturer"
                        type="text"
                        :label="t('manufacturer')"
                    />

                    <MyInput v-model="data.model" name="model" type="text" :label="t('model')" />
                </div>
                <div class="flex space-x-3">
                    <MyInput
                        v-model="data.department"
                        name="department"
                        type="text"
                        :label="t('department')"
                    />

                    <MySelect
                        v-if="authStore.hasLicense(LicenseType.TransportObjectOwnerCompany)"
                        v-model="data.ownerCompanyId"
                        group-class="w-full"
                        :label="t('owner')"
                        clear-button
                        searchable
                        :options="owners"
                    />

                    <MyInput
                        v-else
                        v-model="data.ownerName"
                        name="ownerName"
                        type="text"
                        :label="t('owner')"
                    />
                </div>

                <div class="flex space-x-3">
                    <MyInput v-model="data.note" name="note" type="text" :label="t('note')" />
                </div>

                <div class="flex space-x-3">
                    <div class="w-full">
                        <MyInputLabel v-text="t('serviceDate')" />
                        <Datepicker
                            v-model="data.service"
                            class="input-field rounded-xl border border-gray-300"
                            input-class-name="w-[190px]"
                            name="serviceDate"
                            close-on-scroll
                            :enable-time-picker="false"
                            auto-apply
                            :clearable="false"
                            auto-position
                            position="left"
                            format="yyyy-MM-dd"
                            :transitions="false"
                            :placeholder="t('service')"
                        />
                        <MyErrorMessage input-name="service" :label="t('service')" />
                    </div>
                    <div class="w-full">
                        <MyInputLabel v-text="t('mot')" />
                        <Datepicker
                            v-model="data.mot"
                            class="input-field rounded-xl border border-gray-300"
                            input-class-name="w-[190px]"
                            name="mot"
                            close-on-scroll
                            :enable-time-picker="false"
                            auto-apply
                            :clearable="false"
                            auto-position
                            position="left"
                            format="yyyy-MM-dd"
                            :transitions="false"
                            :placeholder="t('mot')"
                        />
                        <MyErrorMessage input-name="mot" :label="t('mot')" />
                    </div>
                    <div class="w-full">
                        <MyInputLabel v-text="t('tir')" />
                        <Datepicker
                            v-model="data.tir"
                            class="input-field rounded-xl border border-gray-300"
                            input-class-name="w-[190px]"
                            name="tir"
                            close-on-scroll
                            :enable-time-picker="false"
                            auto-apply
                            :clearable="false"
                            auto-position
                            position="left"
                            format="yyyy-MM-dd"
                            :transitions="false"
                            :placeholder="t('tir')"
                        />
                        <MyErrorMessage input-name="tir" :label="t('tir')" />
                    </div>
                </div>

                <div class="flex flex-col">
                    <div
                        class="mt-2 flex cursor-pointer justify-between"
                        @click="informationToggle = !informationToggle"
                    >
                        <h5
                            class="mb-2 select-none text-base"
                            v-text="t('additionalInformation')"
                        />

                        <mdi:chevron-down
                            class="ml-1 transition duration-300"
                            :class="{ 'rotate-180': informationToggle }"
                        />
                    </div>

                    <hr class="mb-2" />

                    <div v-if="informationToggle" class="space-y-2">
                        <div class="flex space-x-3">
                            <MyInput
                                v-model="data.length"
                                name="length"
                                type="text"
                                :label="t('length')"
                            />
                            <MyInput
                                v-model="data.height"
                                name="height"
                                type="text"
                                :label="t('height')"
                            />
                            <MyInput
                                v-model="data.width"
                                name="width"
                                type="text"
                                :label="t('width')"
                            />
                        </div>

                        <div class="flex space-x-3">
                            <MyInput
                                v-model="data.maxEur"
                                name="maxEur"
                                type="text"
                                :label="t('maxEur')"
                            />
                            <MyInput
                                v-model="data.maxLdr"
                                name="maxLdr"
                                type="text"
                                :label="t('maxLdr')"
                            />
                            <MyInput
                                v-model="data.maxM3"
                                name="maxM3"
                                type="text"
                                :label="t('maxM3')"
                            />
                        </div>

                        <div class="flex space-x-3">
                            <MyInput
                                v-model="data.weight"
                                name="weight"
                                type="text"
                                :label="t('weight')"
                            />
                            <MyInput
                                v-model="data.freightWeight"
                                name="freightWeight"
                                type="text"
                                :label="t('freightWeight')"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4 flex justify-end gap-6">
                <MyButton
                    :disabled="loading"
                    scheme="primary"
                    v-text="props.transportObject ? t('update') : t('create')"
                />
            </div>
        </MyForm>
    </MyModal>
</template>
