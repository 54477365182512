<script lang="ts" setup>
import { notify } from '@kyvg/vue3-notification'
import Datepicker, { DatePickerInstance } from '@vuepic/vue-datepicker'
import axios from 'axios'
import dayjs from 'dayjs'
import { ref, watch, computed, onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouteLocationRaw, RouterLink, useRoute } from 'vue-router'

import { usePaginatedTable } from '@/hooks/table/use-paginated-table'
import { useConfirm } from '@/hooks/use-confirm'
import { useAuthStore } from '@/stores/auth-store'
import { Report, ReportFilter, SettlementTemplateRate, TourType } from '@/types/driver-report'
import { MinimalResource, PaginatedResponse, uuid } from '@/types/general'
import { DropdownStringOption } from '@/types/inputs'
import { MyButtonScheme } from '@/types/layout/my-button'
import { GetDataParameters } from '@/types/table'
import { datetime, differenceFromMinutes } from '@/utils/dates'
import { useMittListener } from '@/hooks/use-mitt-listener'
import { useLazyLoadedList } from '@/hooks/use-lazy-loaded-list'
import { usePresetRangeList } from '@/hooks/use-preset-range-list'

import StopReportModal from '@/components/driver-reports/StopReportModal.vue'
import BasicShareModal from '@/components/BasicShareModal.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import ActionMenuItem from '@/components/table/ActionMenuItem.vue'
import ActionRowItem from '@/components/table/ActionRowItem.vue'
import MyFilterButton from '@/components/table/MyFilterButton.vue'
import MyFilterDivider from '@/components/table/MyFilterDivider.vue'
import MyFilterSelect from '@/components/table/MyFilterSelect.vue'
import MyTable from '@/components/table/MyTable.vue'
import MyTableColumn from '@/components/table/MyTableColumn.vue'

interface FetchReportsParams extends Record<string, unknown>, GetDataParameters {
    filter: {
        'user-id': uuid | undefined
        state: ReportFilter
        'start-date': string | undefined
        'end-date': string | undefined
    }
}

interface Stats {
    duration: number
    totalKm: number
    reportCount: number
    restingTime: number
    breakTime: number
    settlementTemplateName: string | null
    settlementTemplateRates: {
        rate: SettlementTemplateRate | null
        duration: number
    }[]
}

const { t } = useI18n()
const authStore = useAuthStore()
const route = useRoute()
const confirm = useConfirm()

const presetRanges = usePresetRangeList()
const employees = useLazyLoadedList<DropdownStringOption>(fetchEmployees)
const selectedReports = ref<number[]>([])
const selectedReportId = ref<uuid>()
const stats = ref<Stats>()
const datepicker = ref<DatePickerInstance>(null)
const shareReportOpen = ref(false)
const stopReportOpen = ref(false)

const { data, paginationData, params, loading, error, refetch } = usePaginatedTable<
    Report,
    FetchReportsParams
>(
    async (params, abortController) => {
        const response = await axios.get<PaginatedResponse<Report>>(
            window.route('dr.company.reports.index', {
                company: authStore.companyId,
            }),
            { params: params, signal: abortController.signal },
        )

        return response.data
    },
    {
        filter: {
            state: 'all',
            'user-id': undefined,
            'start-date': dayjs().subtract(13, 'days').startOf('day').toDate().toISOString(),
            'end-date': dayjs().endOf('day').toDate().toISOString(),
        },
    },
)

const date = ref<Date[]>(
    params.value.filter['start-date'] && params.value.filter['end-date']
        ? [
              dayjs(params.value.filter['start-date']).toDate(),
              dayjs(params.value.filter['end-date']).toDate(),
          ]
        : [],
)

const disabledRows = computed(() => {
    return data.value
        .map((row, index) => {
            return row.settlementId || !row.endedAt ? index : undefined
        })
        .filter((index): index is number => index !== undefined)
})

const shareEndpoint = computed(() => {
    if (!authStore.companyId) return ''

    return window.route('dr.company.reports.share', {
        company: authStore.companyId,
        report: selectedReportId.value ?? '',
    })
})

async function settleReports(id?: uuid[]) {
    try {
        await confirm(t('settleReport'), t('settleMultipleReportsDescription'), {
            confirmText: t('yes'),
            cancelText: t('no'),
            confirmButtonScheme: MyButtonScheme.Primary,
        })
    } catch {
        return
    }

    const reportsToSettle = selectedReports.value.map((index) => {
        return data.value[index].id
    })

    const response = await axios.put<uuid[]>(
        window.route('dr.company.reports.settle', {
            company: authStore.companyId,
        }),
        { reportIds: id ? id : reportsToSettle },
    )

    selectedReports.value = []

    if (response) {
        notify({ type: 'success', text: t('reportsSettled') })
    }

    await refetch()
}

async function calculateStats() {
    if (!params.value.filter['user-id']) {
        stats.value = undefined
        return
    }
    const reportsToCalc = selectedReports.value.map((index) => {
        return data.value[index].id
    })

    const response = await axios.get<Stats>(
        window.route('dr.company.reports.stats', { company: authStore.companyId }),
        {
            params: { reportIds: reportsToCalc, filter: params.value.filter },
        },
    )

    stats.value = response.data
}

async function fetchEmployees(): Promise<DropdownStringOption[]> {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.employees', { company: authStore.companyId }),
    )

    return response.data.map((employee) => ({
        value: employee.id,
        label: employee.name,
    }))
}

function showReport(id: string): RouteLocationRaw {
    return {
        name: 'dr.reports.show',
        params: { reportId: id },
        query: route.query,
    }
}

function stopReport(id: uuid) {
    selectedReportId.value = id
    stopReportOpen.value = true
}

function shareReport(id: uuid) {
    selectedReportId.value = id
    shareReportOpen.value = true
}

function setFilter(value: string | number | null, filterName: string) {
    params.value.filter[filterName] = value
    params.value.page = 1
}

function clearDates() {
    datepicker.value?.closeMenu()
    date.value = []
    delete params.value.filter['start-date']
    delete params.value.filter['end-date']
}

onBeforeMount(() => {
    if (params.value.filter['user-id']) employees.fetch()
})

useMittListener('fetchDriverReports', () => refetch())
watch(date, () => {
    if (!date.value[0] || !date.value[1]) return
    setFilter(dayjs(date.value[0]).startOf('day').toISOString(), 'start-date')
    setFilter(dayjs(date.value[1]).endOf('day').toISOString(), 'end-date')
})

watch(selectedReports, () => {
    calculateStats()
})

watch(
    () => [
        params.value.filter['user-id'],
        params.value.filter['state'],
        params.value.filter['start-date'],
        params.value.filter['end-date'],
    ],
    () => {
        calculateStats()
    },
)
</script>

<template>
    <MyTable
        v-model:row-selection="selectedReports"
        table-id="dr-reports"
        :rows="data"
        :disabled-selection-rows="disabledRows"
        :enable-row-selection="params.filter['user-id'] !== undefined"
        :pagination-data="paginationData"
        :loading="loading"
        :error="error"
        :get-data="refetch"
        class="print:hidden"
        :entity-name="t('reports')"
        :has-time-range="!!params.filter['start-date']"
    >
        <template #filters>
            <Datepicker
                ref="datepicker"
                v-model="date"
                input-class-name="w-64"
                range
                :enable-time-picker="false"
                auto-apply
                :teleport="true"
                :clearable="false"
                :preset-dates="presetRanges"
                close-on-scroll
                position="left"
                format="yyyy-MM-dd"
                :transitions="false"
                :placeholder="t('allTime')"
                :config="{ closeOnAutoApply: true }"
            >
                <template #allTime>
                    <button
                        class="dp__btn dp--preset-range"
                        @click="clearDates()"
                        v-text="t('allTime')"
                    />
                </template>
            </Datepicker>

            <MyFilterDivider />

            <MyFilterButton
                filter-name="state"
                default
                value="all"
                @selected="setFilter"
                v-text="t('all')"
            />

            <MyFilterButton
                filter-name="state"
                value="done"
                @selected="setFilter"
                v-text="t('done')"
            />

            <MyFilterButton
                filter-name="state"
                value="enRoute"
                @selected="setFilter"
                v-text="t('enRoute')"
            />

            <MyFilterButton
                filter-name="state"
                value="settled"
                @selected="setFilter"
                v-text="t('settled')"
            />

            <MyFilterDivider />

            <MyFilterSelect
                :label="t('employees')"
                :options="employees.items.value"
                :loading="employees.fetching.value"
                filter-name="user-id"
                @focus="employees.fetch()"
                @change="setFilter"
            />
        </template>

        <template #actionRow="{ row }">
            <RouterLink :to="showReport(row.id as uuid)">
                <ActionRowItem>
                    <mdi:eye />
                </ActionRowItem>
            </RouterLink>
        </template>

        <template #actionMenu="{ row }">
            <ActionMenuItem
                v-if="row.endedAt && !row.settlementId"
                class="flex cursor-pointer items-center rounded-lg py-2 px-3 text-sm hover:bg-primary-500 hover:text-primary-50 dark:hover:bg-dark-300"
                @click="settleReports([row.id])"
            >
                <mdi:file-document-check-outline class="mr-2" />
                {{ t('settleReport') }}
            </ActionMenuItem>
            <ActionMenuItem
                class="flex cursor-pointer items-center rounded-lg py-2 px-3 text-sm hover:bg-primary-500 hover:text-primary-50 dark:hover:bg-dark-300"
                @click="shareReport(row.id as string)"
            >
                <mdi:share class="mr-2" />
                {{ t('shareEntity', { entity: t('report') }) }}
            </ActionMenuItem>
            <ActionMenuItem
                v-if="!row.endedAt"
                class="flex cursor-pointer items-center rounded-lg py-2 px-3 text-sm hover:bg-primary-500 hover:text-primary-50 dark:hover:bg-dark-300"
                @click="stopReport(row.id)"
            >
                <mdi:alert class="mr-2" />
                {{ t('stopReport') }}
            </ActionMenuItem>
        </template>
        <MyTableColumn :name="t('settled')" property="settled" :width="100" />
        <template #settled="{ row }">
            <div class="flex justify-center">
                <span v-if="row.settlementId" class="text-green-500"><mdi:check-bold /></span>
            </div>
        </template>
        <MyTableColumn :name="t('employee')" property="user.name" />
        <MyTableColumn :name="t('haulier')" property="haulierCompany.name" />

        <MyTableColumn :name="t('startedAt')" property="startedAt" />
        <template #startedAt="{ row }">{{ datetime(row.startedAt) }}</template>

        <MyTableColumn :name="t('endedAt')" property="endedAt" />
        <template #endedAt="{ row }">
            <span v-if="row.endedAt === null" v-text="t('enRoute')" />
            <span v-else>
                {{ datetime(row.endedAt || '') }}
            </span>
        </template>

        <MyTableColumn :name="t('tourType')" property="tourType" />
        <template #tourType="{ row }">
            <span v-if="row.tourType === TourType.Domestic" v-text="t('domestic')" />
            <span v-if="row.tourType === TourType.Export" v-text="t('export')" />
        </template>

        <MyTableColumn :name="t('stops')" property="eventCount" />
        <MyTableColumn :name="t('startNote')" property="startNote" hidden />
        <MyTableColumn :name="t('endNote')" property="endNote" hidden />
        <MyTableColumn :name="t('vehicle')" property="vehicle.registrationNumber" />
        <MyTableColumn :name="t('referenceNumber')" property="referenceNumber" />
        <template #noDataButton>
            <MyButton
                v-if="params.filter['start-date']"
                plain
                scheme="light"
                size="small"
                @click="clearDates()"
            >
                <mdi:magnify class="mr-2 h-4 w-4" />
                {{ t('searchAllTime') }}
            </MyButton>
        </template>
    </MyTable>

    <Teleport v-if="params.filter['user-id'] !== undefined" to="#summary">
        <div class="mt-5 block w-1/6 rounded-lg bg-primary-50 p-5 shadow dark:bg-dark-500">
            <h1
                class="mb-2 font-bold uppercase tracking-wide text-primary-400"
                v-text="
                    selectedReports.length > 0 ? t('totalForSelected') : t('totalForAllReports')
                "
            />

            <div class="grid grid-cols-3 gap-y-2">
                <div class="flex flex-col">
                    <span
                        class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                        v-text="t('km')"
                    />
                    <span
                        class="text-md font-bold text-primary-500 dark:text-primary-100"
                        v-text="stats?.totalKm"
                    />
                </div>

                <div class="col-start-3 flex flex-col">
                    <span
                        class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                        v-text="t('reports')"
                    />
                    <span
                        class="text-md font-bold text-primary-500 dark:text-primary-100"
                        v-text="stats?.reportCount"
                    />
                </div>

                <div class="flex flex-col">
                    <span
                        class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                        v-text="t('duration')"
                    />
                    <span
                        v-tooltip="t('hourMinuteFormatExplanation')"
                        class="text-md font-bold text-primary-500 dark:text-primary-100"
                        v-text="differenceFromMinutes(stats?.duration ?? 0)"
                    />
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                        v-text="t('restingTime')"
                    />
                    <span
                        v-tooltip="t('hourMinuteFormatExplanation')"
                        class="text-md font-bold text-primary-500 dark:text-primary-100"
                        v-text="differenceFromMinutes(stats?.restingTime ?? 0)"
                    />
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                        v-text="t('breakTime')"
                    />
                    <span
                        v-tooltip="t('hourMinuteFormatExplanation')"
                        class="text-md font-bold text-primary-500 dark:text-primary-100"
                        v-text="differenceFromMinutes(stats?.breakTime ?? 0)"
                    />
                </div>
            </div>

            <div v-if="stats?.settlementTemplateName" class="mt-1 flex w-full flex-col">
                <span
                    class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                    v-text="`${t('settlementTemplate')} (${stats?.settlementTemplateName})`"
                />

                <div class="mt-1 flex flex-wrap">
                    <div
                        v-for="templateRate in stats?.settlementTemplateRates"
                        :key="templateRate.rate?.id"
                        :style="{ backgroundColor: templateRate.rate?.color ?? '#333' }"
                        class="mb-2 mr-2 flex items-center rounded-lg px-2 py-1 text-xs text-white"
                    >
                        <span
                            class="mr-2 font-bold"
                            v-text="templateRate.rate?.name ?? t('noRate')"
                        />
                        <span
                            v-tooltip="t('hourMinuteFormatExplanation')"
                            v-text="differenceFromMinutes(templateRate?.duration ?? 0)"
                        />
                    </div>
                </div>
            </div>

            <div v-if="selectedReports.length > 0" class="flex flex-col items-center">
                <hr class="my-4 w-full" />

                <MyButton plain scheme="primary" size="small" @click="settleReports()">
                    <mdi:file-document-check-outline class="mr-1" />
                    {{ t('settleSelected') }}
                </MyButton>
            </div>
        </div>
    </Teleport>

    <BasicShareModal v-model="shareReportOpen" :entity="t('report')" :endpoint="shareEndpoint" />

    <StopReportModal
        v-if="stopReportOpen && selectedReportId"
        v-model="stopReportOpen"
        :report-id="selectedReportId"
        @close="stopReportOpen = false"
    />
</template>
