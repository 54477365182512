import type { WindowUser } from '@/types/user'
import type { PermissionType } from '@/types/general'

import * as Sentry from '@sentry/vue'
import axios, { AxiosResponse } from 'axios'
import { defineStore } from 'pinia'

import { LicenseType, type UserCompany } from '@/types/company'
import { DeliveryManagementPreferences } from '@/types/delivery-management'

const currentCompanyKey = 'mypallet.currentCompanyId'

type licenseObject = Partial<Record<LicenseType, Record<string, unknown>>>

interface AuthStoreState {
    user: WindowUser | null
    isAdmin: boolean
    isImpersonating: boolean
    companyId: string
    companies: UserCompany[]
    licenses: licenseObject
}

let licensePromise: Promise<AxiosResponse<licenseObject>> | null = null

export const useAuthStore = defineStore('AuthStore', {
    state(): AuthStoreState {
        let companyId = localStorage.getItem(currentCompanyKey) || window.defaultCompanyId
        const hasCompany = !!(window.companies || []).find(({ id }) => id === companyId)
        if (!hasCompany) {
            companyId = window.defaultCompanyId
        }

        Sentry.setContext('currentCompany', { id: companyId })

        return {
            user: window.user,
            isAdmin: window.isAdmin,
            isImpersonating: window.isImpersonating,
            companies: window.companies ?? [],
            licenses: {},
            companyId,
        }
    },
    getters: {
        isLoggedIn: (state) => !!state.user,
        company: (state) => state.companies.find((company) => company.id === state.companyId)!,
        licenseList: (state) =>
            Object.keys(state.licenses).map((license): LicenseType => parseInt(license, 10)),
        hasCompany() {
            return !!this.company
        },
    },
    actions: {
        switchCompany(companyId: string) {
            localStorage.setItem(currentCompanyKey, companyId)
            window.location.href = '/'
        },
        async fetchLicenses() {
            if (!this.user || !this.companyId) return

            licensePromise = axios.get<licenseObject>(
                window.route('company.licenses', { company: this.companyId }),
            )

            const response = await licensePromise

            this.licenses = response.data
        },
        async licensesLoaded(): Promise<void> {
            if (!licensePromise) return

            await licensePromise
        },
        updateUser(newUser: Partial<WindowUser>) {
            this.user = { ...this.user, ...newUser } as WindowUser
        },
        isReadonly(readonlyLicense: LicenseType): boolean {
            return this.hasLicense(readonlyLicense)
        },
        hasPermission(permission: PermissionType): boolean {
            return this.company?.permissions.includes(permission) ?? false
        },
        /**
         * Checks if the current company has the given license.
         * When multiple licenses are provided it'll return true if the company just has one of them
         * @param license
         */
        hasLicense(license: LicenseType | LicenseType[]) {
            if (Array.isArray(license)) {
                return license.filter((value) => this.licenseList.includes(value)).length > 0
            }

            return this.licenseList.includes(license)
        },
        /**
         * Sets currency for the current company
         */
        setCurrency(currency: string) {
            // Because we have the reference to the company object we can update it like so
            this.company.options.currency = currency
        },
        deliveryManagementPreferences(): DeliveryManagementPreferences | null {
            const preferences = this.licenses[LicenseType.DeliveryManagementModule] as
                | DeliveryManagementPreferences
                | undefined

            return preferences ?? null
        },
    },
})
