<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { useAuthStore } from '@/stores/auth-store'
import { LicenseType } from '@/types/company'
import { PermissionType } from '@/types/general'

import BasicExportModal from '@/components/import-export/BasicExportModal.vue'
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import TransactionsTable from '@/components/packaging/transactions/TransactionsTable.vue'
import ManageTransaction from '@/components/packaging/transactions/manage/ManageTransaction.vue'

const { t } = useI18n()
const authStore = useAuthStore()
const route = useRoute()
const exportEndpoint = computed(() => {
    if (!authStore.companyId) return ''

    return window.route('packaging.company.transactions.export', {
        company: authStore.companyId,
        ...route.query,
    })
})
</script>

<template>
    <CrumbsAndActions v-if="authStore.companyId">
        <Breadcrumb :to="{ name: 'packaging' }" v-text="t('packaging')" />
        <Breadcrumb current v-text="t('transactions')" />
        <template #actions>
            <BasicExportModal
                :title="t('exportModel', { model: t('transactions') })"
                :endpoint="exportEndpoint"
            />

            <ManageTransaction
                v-if="
                    authStore.hasLicense(LicenseType.BackendTransactions) &&
                    authStore.hasPermission(PermissionType.ManageTransactions)
                "
            />
        </template>
    </CrumbsAndActions>

    <RouterView />

    <MyPanel v-if="authStore.companyId" shadow>
        <TransactionsTable />
    </MyPanel>
</template>
