import dayjs from 'dayjs'
import { defineStore } from 'pinia'

import { useAuthStore } from '@/stores/auth-store'
import { LicenseType } from '@/types/company'

import { useProductStore } from './product-store'

interface DashboardStoreState {
    date: Date[]
    selectedLocation: string | null
}

export const useDashboardStore = defineStore('DashboardStore', {
    state: (): DashboardStoreState => ({
        date: [dayjs().subtract(6, 'days').startOf('day').toDate(), dayjs().endOf('day').toDate()],
        selectedLocation: null,
    }),
    getters: {
        startDate: (state) => dayjs(state.date[0]),
        endDate: (state) => dayjs(state.date[1]),
    },
    actions: {
        initialize() {
            const authStore = useAuthStore()
            if (authStore.hasLicense(LicenseType.PackagingModule)) {
                useProductStore().fetchCompanyProducts()
            }
        },
    },
})
