<script lang="ts" setup>
import { computed, onBeforeMount, watch } from 'vue'
import { LocationQueryRaw, useRoute, useRouter } from 'vue-router'

import MyPanel from '@/components/my-components/MyPanel.vue'

export interface Props {
    tabs: Record<string, string>
    queryParam?: string
    defaultTab?: string
    small?: boolean
    // Hides the tabs header when only one tab is provided
    hideSingleTab?: boolean
}

const router = useRouter()
const route = useRoute()
const emit = defineEmits<{ (e: 'change', tab: string): void }>()

const props = withDefaults(defineProps<Props>(), {
    queryParam: 'tab',
})

const hideTabs = computed(() => props.hideSingleTab && Object.keys(props.tabs).length <= 1)

function getCurrentTab(): string {
    return (
        (route.query[props.queryParam] as string) || props.defaultTab || Object.keys(props.tabs)[0]
    )
}

function selectTab(tab: string, mergeQuery = false) {
    let query: LocationQueryRaw = { [props.queryParam]: tab }
    if (mergeQuery) {
        query = { ...route.query, ...query }
    }

    router.replace({ query })
    emit('change', tab)
}

onBeforeMount(() => {
    selectTab(getCurrentTab(), true)
})

watch(route, () => {
    if (props.queryParam in route.query) return

    // In the case the tab is not in the route we'll re-add it
    selectTab(getCurrentTab())
})
</script>

<template>
    <MyPanel v-bind="$attrs" :hide-tabs="hideTabs">
        <template #tabs>
            <nav
                class="flex w-full justify-between border-b border-primary-200 bg-gradient-radial-to-br from-primary-500 via-primary-500 to-green-500 uppercase dark:border-dark-400 dark:from-primary-700 dark:via-primary-600 dark:to-green-700"
            >
                <div
                    v-for="(name, key) in props.tabs"
                    :key="key"
                    :class="{
                        'pointer-events-none cursor-default bg-transparent text-white':
                            key === route.query[props.queryParam],
                        'cursor-pointer bg-primary-50 hover:bg-transparent hover:text-white dark:bg-dark-500 dark:hover:bg-transparent':
                            key !== route.query[props.queryParam],
                        'py-2.5': props.small,
                        'py-1.5 lg:py-4': !props.small,
                    }"
                    class="block w-full border-r border-primary-200 px-3 text-center text-xs font-semibold transition-colors last:border-r-0 dark:border-dark-400 lg:text-sm"
                    @click="selectTab(key)"
                >
                    <slot name="tabHeader" :tab-name="name" :tab-key="key">
                        {{ name }}
                    </slot>
                </div>
            </nav>
        </template>

        <slot v-if="route.query[props.queryParam]" :tab-key="route.query[props.queryParam]" />
    </MyPanel>
</template>
