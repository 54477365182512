import { ref, watch } from 'vue'
import { LocationQueryValue, useRoute } from 'vue-router'

export function useFilterQuery() {
    const route = useRoute()
    const filters = ref(parseFilters(route.query.filter))

    watch(route, () => {
        filters.value = parseFilters(route.query.filter)
    })

    return {
        filters,
        hasFilter: (filter: string) => filter in filters.value,
        getFilter: (filter: string) => filters.value[filter],
    }
}

function parseFilters(
    queryParam: LocationQueryValue | LocationQueryValue[],
): Record<string, string | null | undefined> {
    const filterParam = queryParam || {}
    const filters = (filterParam instanceof Object ? filterParam : {}) as Record<string, string>

    return filters
}
