<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { Company, Customer, LicenseType } from '@/types/company'
import { Address } from '@/types/form'
import { ResourceResponse } from '@/types/general'
import { MyFormRef } from '@/types/inputs'
import { image } from '@/utils/assets'
import { LimitedCountryCode } from '@/utils/country-codes'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyAddressInput from '@/components/my-components/form/MyAddressInput.vue'
import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MyPhoneInput from '@/components/my-components/form/MyPhoneInput.vue'

export interface Props {
    company: Partial<Company>
    haulierAsDefault: boolean
    countryIsoCode: LimitedCountryCode
}

const { t } = useI18n()
const props = defineProps<Props>()
const emit = defineEmits<{ (e: 'created', customer: Customer): void }>()

const authStore = useAuthStore()
const formRef = ref<MyFormRef>()
const addressInput = ref('')
const { data, loading, submit, errors } = useForm({
    name: '',
    email: '',
    contactPerson: '',
    ...props.company,
    phoneNumber: props.company.phoneNumber ?? '',
    accountNumber: '',
    options: { haulier: props.haulierAsDefault, sendHaulierDriverReports: false },
    customerCompanyId: props.company.id,
})

const privateCustomer = computed(() => !props.company.vat)

async function onSubmit() {
    if (!props.company.id) {
        const response = await submit<ResourceResponse<Company>>(
            'POST',
            window.route('companies.store', { forCompanyId: authStore.companyId }),
        )
        if (!response) return
        data.customerCompanyId = response.data.id
    }
    const response = await submit<ResourceResponse<Customer>>(
        'POST',
        window.route('company.customers.store', { company: authStore.companyId }),
    )

    if (response) {
        emit('created', response.data)
    }
}

function addressChanged(address: Address) {
    data.address = address.address
    data.city = address.city
    data.zipcode = address.zipcode + ''
    data.country = address.country
    data.location = { latitude: address.latitude, longitude: address.longitude }
}

onMounted(() => {
    formRef.value?.focus()
})
</script>

<template>
    <div>
        <LoaderWrapper :visible="loading" class="rounded-xl" />

        <div v-if="props.company.id" class="flex justify-between space-x-4">
            <div class="w-full">
                <img
                    :alt="props.company.name"
                    :src="
                        image(
                            props.company.logo ?? 'cf6c449d-282b-49e9-838e-eb395c168700',
                            'thumbnail',
                        )
                    "
                    class="mx-auto mb-3 h-24 w-auto rounded-full shadow-xl"
                />
                <h1
                    class="text-center text-lg font-semibold uppercase"
                    v-text="props.company.name"
                />
                <p class="text-center text-sm">
                    <span class="font-semibold uppercase" v-text="t('vat')" />
                    {{ props.company.vat }}
                </p>
            </div>

            <div
                class="flex w-1/2 flex-col items-center justify-center border-l border-primary-200 text-sm"
            >
                <h2 class="mb-2 text-xs font-semibold uppercase" v-text="t('address')" />
                <p v-text="props.company.address" />
                <p>
                    <span v-text="props.company.zipcode" />,
                    <span v-text="props.company.city" />
                </p>
                <p v-text="props.company.country" />
            </div>
        </div>

        <MyForm ref="formRef" class="mt-5 space-y-4" :errors="errors" @submit.prevent="onSubmit">
            <template v-if="!props.company.id">
                <MyInput v-model="data.name" name="name" :label="t('customerName')" />

                <MyAddressInput
                    v-model="addressInput"
                    name="address"
                    :label="t('address')"
                    :country-iso-code="privateCustomer ? undefined : props.countryIsoCode"
                    @change="addressChanged"
                />
            </template>

            <div class="flex justify-between space-x-4">
                <MyInput v-model="data.email" name="email" :label="t('email')" type="email" />

                <MyInput
                    v-model="data.contactPerson"
                    name="contactPerson"
                    :label="t('contactPerson')"
                    autocomplete="name"
                />
            </div>

            <MyInput
                v-model="data.accountNumber"
                name="accountNumber"
                :label="t('accountNumber')"
            />

            <MyPhoneInput
                v-model="data.phoneNumber"
                name="phoneNumber"
                :label="t('phoneNumber')"
                :default-country="props.countryIsoCode"
            />

            <div v-if="!privateCustomer" class="flex space-x-2">
                <MyCheckbox
                    v-model="data.options.haulier"
                    name="haulier"
                    :label="t('markAsHaulier')"
                />
                <mdi:information v-tooltip="t('haulierExplanation')" />
            </div>

            <div
                v-if="
                    data.options.haulier &&
                    !props.company?.mypalletCustomer &&
                    authStore.hasLicense(LicenseType.DriverReportModule)
                "
                class="flex space-x-2"
            >
                <MyCheckbox
                    v-model="data.options.sendHaulierDriverReports"
                    name="haulier"
                    :label="t('sendDriverReportsToHaulier')"
                />
                <mdi:information v-tooltip="t('sendDriverReportsToHaulierExplanation')" />
            </div>

            <div class="flex justify-end">
                <MyButton :disabled="loading" scheme="primary" autofocus>
                    <span v-text="t('save')" />
                </MyButton>
            </div>
        </MyForm>
    </div>
</template>
