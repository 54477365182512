<script lang="ts" setup>
import axios from 'axios'
import { onBeforeMount, ref, watch, computed, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { notify } from '@kyvg/vue3-notification'

import { datetime, differenceFromMinutes } from '@/utils/dates'
import { LicenseType } from '@/types/company'
import { Marker, ResourceResponse, uuid } from '@/types/general'
import { useAuthStore } from '@/stores/auth-store'
import { printElement } from '@/utils/print-element'
import {
    Tour,
    TourImageType,
    DeviationState,
    ShipmentType,
    OptimizationType,
} from '@/types/delivery-management'
import { ReportType } from '@/types/damage-report'
import { localAsset } from '@/utils/assets'
import { imageViewerOpenKey } from '@/types/global-injection-keys'
import { TransportObjectType } from '@/types/transport-object'
import { reportTypes } from '@/utils/damage-report'
import { ActivityLog } from '@/types/activity-log'
import { tourTypes } from '@/utils/type-translations'
import { useMittListener } from '@/hooks/use-mitt-listener'

import MyPanel from '@/components/my-components/MyPanel.vue'
import ActivityLogItem from '@/components/settings/ActivityLogItem.vue'
import MyFileAttachment from '@/components/my-components/MyFileAttachment.vue'
import MyImageViewer from '@/components/my-components/MyImageViewer.vue'
import TourEventEntries from '@/components/delivery-management/tour-modal/TourEventEntries.vue'
import ShipmentModal from '@/components/delivery-management/ShipmentModal.vue'
import ContentHeading from '@/components/layout/ContentHeading.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import ManageTourModal from '@/components/delivery-management/manage-tour/ManageTourModal.vue'
import MapComponent from '@/components/MapComponent.vue'
import TourDriverStatus from '@/components/delivery-management/TourDriverStatus.vue'

export interface Props {
    id?: string
    open?: boolean
}

const emit = defineEmits<{ (e: 'update:open', value: boolean): void }>()
const props = withDefaults(defineProps<Props>(), { open: true })

const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const authStore = useAuthStore()
const imageViewerOpen = inject(imageViewerOpenKey)!

const modalContent = ref<HTMLDivElement>()

const tour = ref<Tour | null>(null)
const loading = ref(false)
const manageTourModalOpen = ref(false)
const shipmentModalOpen = ref(false)
const selectedShipmentId = ref<string>()
const activityLogs = ref<ActivityLog[]>([])
const showActivityLogs = ref(false)
const processingTour = ref(false)
const calculatingEta = ref(false)

const tourDriverConfirmationEnabled = computed(() => {
    return authStore.deliveryManagementPreferences()?.tourDriverConfirmation ?? false
})

const isTourOwner = computed(() => {
    return (
        tour.value?.company.id === authStore.companyId ||
        tour.value?.shipperCompany?.id === authStore.companyId
    )
})

const canManageTour = computed(() => {
    if (isTourOwner.value) return true

    if (!tour.value?.haulierCanManage) return false
    if (tour.value?.haulierCompany?.id === authStore.companyId) return true

    return !!tour.value.hub?.hauliers.map((haulier) => haulier.id).includes(authStore.companyId)
})

const tourHasEvents = computed(() => {
    return tour.value?.events.length !== 0
})

const tourDeviations = computed(() => {
    if (!tour.value?.deviations || !isTourOwner.value) return []
    return tour.value?.deviations.filter((deviation) => deviation.shipmentId === null) ?? []
})

const tourTickets = computed(() => {
    if (!tour.value?.tickets || !isTourOwner.value) return []
    return tour.value?.tickets.filter((ticket) => ticket.shipmentId === null) ?? []
})

const startImages = computed(
    () =>
        tour.value?.images.filter(
            (image) => image.type === TourImageType.TourStart && image.path,
        ) ?? null,
)
const endImages = computed(
    () =>
        tour.value?.images.filter((image) => image.type === TourImageType.TourEnd && image.path) ??
        null,
)

const units = computed(() => {
    return (
        tour.value?.transportObjects.filter((unit) => unit.type === TransportObjectType.Unit) ?? []
    )
})

const tourDuration = computed(() => {
    if (!tour.value?.optimizationDetails?.duration) return ''

    const minutes = differenceFromMinutes(tour.value.optimizationDetails.duration / 60)
    return t('estimatedDrivingDuration', { duration: minutes })
})

const vehicles = computed(() => {
    return (
        tour.value?.transportObjects.filter(
            (vehicle) => vehicle.type === TransportObjectType.Vehicle,
        ) ?? []
    )
})

const categories = computed(() => {
    if (tour.value?.categories === '') return
    return tour.value?.categories
})

const canOptimizeTour = computed(() => {
    return isTourOwner.value && authStore.hasLicense(LicenseType.DeliveryManagementTourOptimization)
})

const canCalculateTourETA = computed(() => {
    return isTourOwner.value && authStore.hasLicense(LicenseType.DeliveryManagementTourETA)
})

const deviationCompany = computed(() => {
    return tour.value?.shipperCompany
        ? tour.value?.shipperCompany.id === authStore.companyId
        : tour.value?.company.id === authStore.companyId
})

const markers = computed<Marker[]>(() => {
    const markerList: Marker[] = []

    if (!tour.value?.events) return markerList

    tour.value.events.forEach((event, index) => {
        const shipment = event.shipment

        if (shipment) {
            let destinationCompany = shipment.receivingCompany.name
            let destinationLocation = shipment.receivingLocation.location
            let destinationAddress = shipment.receivingLocation.address
            let destinationName = shipment.receivingLocation.name
            if (shipment.type === ShipmentType.Pickup) {
                destinationCompany = shipment.sendingCompany.name
                destinationLocation = shipment.sendingLocation.location
                destinationAddress = shipment.sendingLocation.address
                destinationName = shipment.sendingLocation.name
            }

            markerList.push({
                location: shipment.addressLocation ?? destinationLocation,
                content: tooltipContent(
                    destinationCompany,
                    shipment.address ?? destinationAddress,
                    shipment.addressName ?? destinationName,
                ),
                markerId: event.id,
                label: index + 1,
            })
        } else if (event.waypoint) {
            const arrivalLocation = event.waypoint.arrivalAddressLocation
            const departureLocation = event.waypoint.departureAddressLocation

            markerList.push({
                location: arrivalLocation,
                content: tooltipContent(event.waypoint.name, event.waypoint.arrivalAddress),
                markerId: event.id,
                label: index + 1,
            })

            if (departureLocation) {
                markerList.push({
                    location: departureLocation,
                    content: tooltipContent(
                        event.waypoint.name,
                        event.waypoint.departureAddress ?? '-',
                    ),
                    markerId: event.id,
                    label: index + 1,
                })
            }
        }
    })
    return markerList
})

async function fetchTour(id?: uuid) {
    activityLogs.value = []
    showActivityLogs.value = false
    loading.value = true
    const response = await axios.get<ResourceResponse<Tour>>(
        window.route('dm.company.tours.show', {
            company: authStore.companyId,
            tour: id ?? props.id!,
        }),
    )
    tour.value = response.data.data
    loading.value = false
}

async function toggleLogsForTour() {
    showActivityLogs.value = !showActivityLogs.value
    if (activityLogs.value.length !== 0 || !showActivityLogs.value) return
    loading.value = true
    const response = await axios.get<ResourceResponse<ActivityLog[]>>(
        window.route('activity-logs.find', {
            model: tour.value?.id ?? '',
        }),
    )

    activityLogs.value = response.data.data
    loading.value = false
}

async function optimizeTour() {
    processingTour.value = true
    try {
        await axios.put<ResourceResponse<Tour>>(
            window.route('dm.company.tours.optimize', {
                tour: tour.value?.id,
                company: authStore.company.id,
            }),
        )
        fetchTour(tour.value?.id)
        notify({ type: 'success', text: t('optimizeSuccess') })
    } catch (error) {
        notify({ type: 'error', text: t('failedOptimization') })
        throw error
    } finally {
        processingTour.value = false
    }
}

async function calculateTourEta() {
    calculatingEta.value = true
    try {
        await axios.put<ResourceResponse<Tour>>(
            window.route('dm.company.tours.calculate-eta', {
                tour: tour.value?.id,
                company: authStore.company.id,
            }),
        )
        fetchTour(tour.value?.id)
        notify({ type: 'success', text: t('calculateEtaSuccess') })
    } catch (error) {
        notify({ type: 'error', text: t('failedEtaCalculation') })
        throw error
    } finally {
        calculatingEta.value = false
    }
}

function isCurrentDriver(driverId: string): boolean {
    return driverId === tour.value?.currentDriver?.id && tour.value?.endedAt === null
}

// Map Related
function tooltipContent(name: string, address: string, locationName?: string): string {
    return `
        <div class="flex flex-col space-y-1">
          <span class="text-sm font-semibold text-primary-500 dark:text-primary-300">
            ${name}
          </span>
          <div class="flex flex-col">
            <span class="text-xs text-primary-500 dark:text-primary-300">
                ${locationName ?? ''}
            </span>
            <span class="text-xs text-primary-500 dark:text-primary-300">
              ${address ?? '-'}
            </span>
          </div>
        </div>
      `
}

function print() {
    printElement(modalContent.value!)
}

function onClose() {
    if (imageViewerOpen.value) return
    if (props.id) {
        emit('update:open', false)
    } else {
        router.replace({ name: 'dm.tours', query: route.query })
    }
}

function scrollToEvent(markerId: string) {
    const eventElement = document.querySelector(`[data-id='eventEntry-${markerId}']`)
    if (!eventElement) return
    eventElement.scrollIntoView({ behavior: 'smooth' })
    eventElement.classList.add(
        'shadow-xl',
        'shadow-blue-400/80',
        'transition-shadow',
        'duration-1000',
        'ease-in-out',
    )
    setTimeout(() => {
        eventElement.classList.remove('shadow-xl', 'shadow-blue-400/80')
    }, 2000)
}

onBeforeMount(async () => {
    if (route.params.tourId) fetchTour(route.params.tourId as uuid)
    if (props.id) await fetchTour()
})
watch(() => props.id, fetchTour)
watch(
    () => route.params.tourId,
    () => {
        if (route.params.tourId) fetchTour(route.params.tourId as uuid)
    },
)
useMittListener('fetchTours', () => fetchTour(route.params.tourId as uuid | undefined))
</script>

<template>
    <MyModal :value="props.open" :max-width="tourHasEvents ? 1400 : 700" @close="onClose">
        <LoaderWrapper :visible="loading" />
        <div v-if="tour" ref="modalContent">
            <div class="mb-6 flex justify-between">
                <ContentHeading>
                    <h1 class="text-xl font-bold text-primary-400">{{ tour.name }}</h1>
                    <div class="mt-1 flex space-x-2 text-xs">
                        <h4 class="font-semibold text-primary-500 dark:text-primary-300">
                            {{ t('type') }}:
                        </h4>
                        <h4 class="font-medium dark:text-primary-400">
                            {{ tourTypes[tour.type] }}
                        </h4>
                    </div>
                </ContentHeading>

                <div v-if="canManageTour" class="flex space-x-2 print:hidden items-center">
                    <div
                        v-tooltip="{
                            disabled: tour.endedAt === null,
                            content: t('tourEndedExplanation'),
                        }"
                        class="flex space-x-2"
                    >
                        <MyButton
                            v-if="!tour.optimizationDetails && canOptimizeTour"
                            v-tooltip="t('optimizeTourExplanation')"
                            scheme="primary"
                            size="small"
                            :loading="processingTour"
                            :disabled="tour.events.length === 0 && tour.endedAt !== null"
                            @click="optimizeTour()"
                        >
                            {{ t('optimizeTour') }}
                            <mdi:lightning-bolt />
                        </MyButton>

                        <MyButton
                            v-if="canCalculateTourETA"
                            v-tooltip="t('calculateTourETAExplanation')"
                            scheme="primary"
                            size="small"
                            :loading="calculatingEta"
                            :disabled="tour.events.length === 0 && tour.endedAt !== null"
                            @click="calculateTourEta()"
                        >
                            {{ t('calculateTourETA') }}
                            <mdi:lightning-bolt />
                        </MyButton>

                        <MyButton
                            scheme="primary"
                            size="small"
                            :disabled="tour.endedAt !== null"
                            @click="manageTourModalOpen = true"
                        >
                            {{ t('editTour') }}
                        </MyButton>
                    </div>

                    <MyButton icon plain scheme="light" size="small" @click="print()">
                        <mdi:printer />
                    </MyButton>
                </div>
            </div>

            <section
                class="flex w-full items-start rounded-lg bg-white dark:bg-dark-500"
                :class="{ 'mb-6': tourHasEvents }"
            >
                <div class="flex flex-col w-1/2" :class="{ 'w-full': !tourHasEvents }">
                    <div class="mx-6 mt-6 mb-2 grid w-full grid-cols-3 gap-4">
                        <div class="col-span-3 flex flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('company')"
                            />
                            <span
                                class="text-xl font-semibold text-primary-500 dark:text-primary-100"
                                v-text="tour.company.name ?? '-'"
                            />
                        </div>

                        <div class="flex break-words flex-col">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                v-text="t('categories')"
                            />
                            <span
                                class="text-sm text-primary-500 dark:text-primary-100"
                                v-text="categories ?? '-'"
                            />
                        </div>

                        <div class="flex flex-col">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                v-text="t('drivers')"
                            />
                            <div
                                v-if="tour.drivers.length !== 0"
                                class="text-sm text-primary-500 dark:text-primary-100"
                            >
                                <div
                                    v-for="driver in tour.drivers"
                                    :key="driver.user.id"
                                    class="flex space-x-1 items-center"
                                >
                                    <span
                                        :class="{
                                            'font-semibold': isCurrentDriver(driver.user.id),
                                        }"
                                        v-text="driver.user.name"
                                    />
                                    <mdi:truck-fast-outline
                                        v-if="isCurrentDriver(driver.user.id)"
                                        v-tooltip="t('currentDriver')"
                                    />

                                    <TourDriverStatus
                                        v-if="tourDriverConfirmationEnabled"
                                        :driver="driver"
                                    />
                                </div>
                            </div>
                            <span v-else class="text-sm text-primary-500 dark:text-primary-100">
                                -
                            </span>
                        </div>

                        <div class="flex flex-col break-words mr-8">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                v-text="t('shipper')"
                            />
                            <span
                                class="text-sm text-primary-500 dark:text-primary-100"
                                v-text="tour.shipperCompany?.name ?? '-'"
                            />
                        </div>

                        <div class="flex flex-col">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                v-text="t('haulier')"
                            />
                            <span
                                class="text-sm text-primary-500 dark:text-primary-100"
                                v-text="tour.haulierCompany?.name ?? '-'"
                            />
                        </div>

                        <div class="flex flex-col">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                v-text="t('hub')"
                            />
                            <span
                                class="text-sm text-primary-500 dark:text-primary-100"
                                v-text="tour.hub?.name ?? '-'"
                            />
                        </div>

                        <div class="flex flex-col">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                v-text="t('plannedAt')"
                            />
                            <span
                                class="text-sm text-primary-500 dark:text-primary-100"
                                v-text="datetime(tour.plannedAt)"
                            />
                        </div>

                        <div class="flex flex-col">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                v-text="t('startedAt')"
                            />
                            <span
                                class="text-sm text-primary-500 dark:text-primary-100"
                                v-text="tour.startedAt ? datetime(tour.startedAt) : t('notStarted')"
                            />
                        </div>

                        <div class="flex flex-col">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                v-text="t('endedAt')"
                            />
                            <span
                                class="text-sm text-primary-500 dark:text-primary-100"
                                v-text="tour.endedAt ? datetime(tour.endedAt) : t('notEnded')"
                            />
                        </div>

                        <div></div>

                        <div v-if="vehicles.length !== 0" class="flex flex-col break-words mr-8">
                            <div class="flex space-x-1.5 items-center">
                                <span
                                    class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                    v-text="t('vehicles')"
                                />
                            </div>

                            <div
                                v-for="vehicle in vehicles"
                                :key="vehicle.id"
                                class="flex flex-col text-sm text-primary-500 dark:text-primary-100"
                            >
                                <RouterLink
                                    class="hover:text-primary-400"
                                    :to="{
                                        name: 'transport-objects.show',
                                        params: { transportObjectId: vehicle.id },
                                    }"
                                >
                                    <div class="flex items-center">
                                        <span v-text="vehicle.registrationNumber" />
                                        <mdi:truck-cargo-container class="ml-1.5" />
                                    </div>
                                </RouterLink>
                            </div>
                        </div>

                        <div v-if="units.length !== 0" class="flex flex-col break-words mr-8">
                            <div class="flex space-x-1.5 items-center">
                                <span
                                    class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                    v-text="t('units')"
                                />
                            </div>

                            <div
                                v-for="unit in units"
                                :key="unit.id"
                                class="flex flex-col text-sm text-primary-500 dark:text-primary-100"
                            >
                                <RouterLink
                                    class="hover:text-primary-400"
                                    :to="{
                                        name: 'transport-objects.show',
                                        params: { transportObjectId: unit.id },
                                    }"
                                >
                                    <div class="flex items-center">
                                        <span v-text="unit.registrationNumber" />
                                        <mdi:truck-trailer class="ml-1.5" />
                                    </div>
                                </RouterLink>
                            </div>
                        </div>

                        <div
                            v-if="tour.reports?.length !== 0"
                            class="flex flex-col break-words mr-8"
                        >
                            <div class="flex space-x-1.5 items-center">
                                <span
                                    class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                    v-text="t('damageReports')"
                                />
                            </div>

                            <RouterLink
                                v-for="report in tour.reports"
                                :key="report.id"
                                v-tooltip="t(reportTypes[report.type])"
                                class="inline-flex w-fit text-sm text-primary-500 dark:text-primary-100 dark:hover:text-primary-400 hover:text-primary-400"
                                :to="{
                                    name: 'dmr.reports.show',
                                    params: { reportId: report.id },
                                }"
                            >
                                <span
                                    v-text="report.transportObject?.registrationNumber ?? t('view')"
                                />

                                <div class="flex ml-1">
                                    <mdi:walk
                                        :class="{
                                            '-scale-x-100': report.type === ReportType.DropOff,
                                        }"
                                    />
                                    <mdi:truck-cargo-container
                                        v-if="
                                            report.transportObject.type ===
                                            TransportObjectType.Vehicle
                                        "
                                    />

                                    <mdi:truck-trailer v-else />
                                </div>
                            </RouterLink>
                        </div>

                        <div v-if="tour.images.length === 0" />

                        <div
                            v-if="tour.driverReports.length > 0"
                            class="flex flex-col break-words mr-8"
                        >
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                v-text="t('driverReports')"
                            />

                            <RouterLink
                                v-for="report in tour.driverReports"
                                :key="report.id"
                                class="mb-0.5 text-sm text-primary-500 hover:text-primary-400 dark:text-primary-100 dark:hover:text-primary-400"
                                :to="{
                                    name: 'dr.reports.show',
                                    params: { reportId: report.id },
                                }"
                            >
                                <span v-if="report.startedAt" v-text="datetime(report.startedAt)" />
                            </RouterLink>
                        </div>

                        <div v-if="deviationCompany" class="flex flex-col col-span-3">
                            <span
                                v-if="tourDeviations.length > 0"
                                class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                v-text="t('deviations')"
                            />
                            <div v-for="deviation in tourDeviations" :key="deviation.id">
                                <div class="flex items-center space-x-1">
                                    <span
                                        v-if="deviation.tags"
                                        class="text-sm font-semibold text-primary-400 cursor-pointer hover:text-primary-500 dark:text-primary-100"
                                        @click="
                                            router.push({
                                                name: 'dm.deviations.show',
                                                params: { deviationId: deviation.id },
                                            })
                                        "
                                    >
                                        {{ deviation.tags.map((tag) => t(tag.name)).join(', ') }}
                                    </span>
                                    <span
                                        v-if="deviation.state === DeviationState.Closed"
                                        class="rounded-full h-3 w-3 bg-gray-400 flex-shrink-0"
                                    />
                                    <span
                                        v-if="deviation.state === DeviationState.Open"
                                        class="rounded-full h-3 w-3 bg-yellow-500 flex-shrink-0"
                                    />

                                    <span
                                        v-if="deviation.state === DeviationState.Solved"
                                        class="rounded-full h-3 w-3 bg-green-500 flex-shrink-0"
                                    />
                                </div>
                            </div>
                        </div>

                        <div v-else class="flex flex-col col-span-3">
                            <span
                                v-if="tourTickets.length > 0"
                                class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                v-text="t('tickets')"
                            />
                            <div v-for="ticket in tourTickets" :key="ticket.id">
                                <div class="flex items-center space-x-1">
                                    <span
                                        v-if="ticket.tags"
                                        class="text-sm font-semibold text-primary-400 cursor-pointer hover:text-primary-500 dark:text-primary-100"
                                        @click="
                                            router.push({
                                                name: 'dm.tickets.show',
                                                params: { ticketId: ticket.id },
                                            })
                                        "
                                    >
                                        {{ ticket.tags.map((tag) => t(tag.name)).join(', ') }}
                                    </span>
                                    <span
                                        v-if="ticket.state === DeviationState.Closed"
                                        class="rounded-full h-3 w-3 bg-gray-400 flex-shrink-0"
                                    />
                                    <span
                                        v-if="ticket.state === DeviationState.Open"
                                        class="rounded-full h-3 w-3 bg-yellow-500 flex-shrink-0"
                                    />

                                    <span
                                        v-if="ticket.state === DeviationState.Solved"
                                        class="rounded-full h-3 w-3 bg-green-500 flex-shrink-0"
                                    />
                                </div>
                            </div>
                        </div>

                        <img
                            class="qrcode col-span-3 ml-auto hidden print:block"
                            style="width: 180px; height: 180px"
                        />
                    </div>
                    <div
                        v-if="startImages?.length !== 0 || endImages?.length !== 0"
                        class="grid grid-cols-2 gap-5"
                    >
                        <div v-if="startImages?.length !== 0" class="pl-6">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                v-text="t('tourStartImages')"
                            />
                            <MyImageViewer class="grid grid-cols-3 gap-3 mr-2 mb-5 mt-2">
                                <MyFileAttachment
                                    v-for="file in startImages"
                                    :key="file.id"
                                    class="cursor-pointer !min-h-[5.5rem] !min-w-[5.5rem] !max-h-[5.5rem] !max-w-[5.5rem]"
                                    :file="file"
                                />
                            </MyImageViewer>
                        </div>

                        <div v-if="endImages?.length !== 0" class="pl-6">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                v-text="t('tourEndImages')"
                            />
                            <MyImageViewer class="grid grid-cols-3 gap-3 mr-4 mb-5 mt-2">
                                <MyFileAttachment
                                    v-for="file in endImages"
                                    :key="file.id"
                                    class="cursor-pointer !min-h-[5.5rem] !min-w-[5.5rem] !max-h-[5.5rem] !max-w-[5.5rem]"
                                    :file="file"
                                />
                            </MyImageViewer>
                        </div>
                    </div>
                </div>

                <MapComponent
                    v-if="tourHasEvents"
                    class="w-96 h-96 grow rounded-r-lg overflow-hidden"
                    :markers="markers"
                    connect-markers
                    @marker-clicked="(eventId) => scrollToEvent(eventId)"
                />
            </section>

            <h1
                v-if="tourHasEvents"
                class="text-center text-xl font-bold uppercase tracking-wide text-primary-400"
                v-text="t('stops')"
            />

            <section v-if="tour.optimizationDetails" class="flex flex-col items-center">
                <h3
                    v-if="tour.optimizationDetails.type === OptimizationType.Optimized"
                    class="flex items-center gap-1"
                >
                    {{ t('optimizedHeadline') }} <mdi:lightning-bolt class="text-yellow-400" />
                </h3>

                <h3
                    v-if="tour.optimizationDetails.type === OptimizationType.ETACalculated"
                    class="flex items-center gap-1"
                >
                    {{ t('etaCalculatedHeadline') }}
                    <mdi:timer-outline class="text-green-400" />
                </h3>

                <div class="mb-2 space-x-2">
                    <span v-text="t('stopsCount', { count: tour.events.length })" />
                    <span>|</span>
                    <span v-tooltip="t('hourMinuteFormatExplanation')">
                        {{ tourDuration }}
                    </span>
                    <span>|</span>
                    <span>
                        {{ Math.round(tour.optimizationDetails.distance / 1000) }}
                        {{ t('km').toLowerCase() }}
                    </span>
                </div>
            </section>

            <TourEventEntries
                v-if="tour"
                :tour-events="tour.events"
                :is-hub-haulier="canManageTour && !isTourOwner"
                :is-shipper-company="tour.shipperCompany?.id === authStore.companyId"
                :deviations="tour.deviations"
                :tickets="tour.tickets"
            />

            <img
                :src="localAsset('assets/begreenprintlabel.png')"
                alt="begreen"
                class="absolute bottom-0 right-0 w-32 h-32 hidden opacity-30 print:block"
            />

            <div
                v-if="
                    authStore.companyId === tour?.company.id ||
                    authStore.companyId === tour?.shipperCompany?.id
                "
            >
                <div
                    class="mt-10 flex justify-center"
                    :class="{ 'mb-6': showActivityLogs && activityLogs.length !== 0 }"
                >
                    <h1 class="text-xl font-bold uppercase tracking-wide text-primary-400">
                        {{ t('activityLogs') }}
                    </h1>
                </div>
                <div v-if="activityLogs.length !== 0" class="w-full flex justify-center">
                    <MyPanel v-if="showActivityLogs" panel-class="w-1/2" shadow>
                        <ActivityLogItem
                            v-for="log in activityLogs"
                            :key="log.id"
                            :log="log"
                            hide-advanced-details
                        />
                    </MyPanel>
                </div>

                <div
                    v-if="!activityLogs.length && showActivityLogs && !loading"
                    class="w-full mt-5 mb-6 flex justify-center"
                >
                    {{ t('noActivityLogsFound') }}
                </div>
                <div
                    class="w-full mb-4 flex justify-center"
                    :class="{ 'mt-10': showActivityLogs, 'mt-4': !showActivityLogs }"
                >
                    <MyButton
                        scheme="action"
                        type="button"
                        class="dark:text-primary-50 dark:bg-dark-400 dark:hover:bg-dark-300 bg-primary-200"
                        size="small"
                        @click="toggleLogsForTour()"
                    >
                        <mdi:clipboard-list-outline class="h-5 w-5" />
                        <span
                            class="font-semibold"
                            v-text="showActivityLogs ? t('hideActivityLog') : t('showActivityLog')"
                        />
                    </MyButton>
                </div>
            </div>
        </div>

        <h1
            v-if="!tour && !loading"
            class="my-auto text-center text-4xl font-bold"
            v-text="t('tourNotFound')"
        />
    </MyModal>

    <ShipmentModal
        v-if="selectedShipmentId"
        :id="selectedShipmentId"
        v-model:open="shipmentModalOpen"
        @update:open="selectedShipmentId = undefined"
    />

    <ManageTourModal
        v-if="manageTourModalOpen"
        v-model="manageTourModalOpen"
        :tour-id="tour?.id"
        :is-hub-haulier="canManageTour && !isTourOwner"
        @close="manageTourModalOpen = false"
        @saved="fetchTour(tour?.id)"
    />
</template>
