<script lang="ts" setup>
import { nextTick, onMounted, ref, watch } from 'vue'

export interface Props {
    modelValue?: boolean
    value?: boolean
    maxWidth?: number
    backButton?: boolean
    noShadow?: boolean
    noPadding?: boolean
    light?: boolean
}

const props = withDefaults(defineProps<Props>(), { backButton: false })
const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void
    (e: 'close', value: void): void
    (e: 'backButtonClicked', value: void): void
}>()

const modalRef = ref<HTMLDivElement>()

function close() {
    emit('update:modelValue', false)
    emit('close')
}

async function focusAutofocusedElement() {
    if (!props.modelValue && !props.value) return

    await nextTick()
    const element = modalRef.value?.querySelector('[autofocus]') as HTMLElement
    element?.focus()
}

onMounted(focusAutofocusedElement)

watch(() => [props.modelValue, props.value], focusAutofocusedElement)
</script>

<template>
    <Teleport to="#modals">
        <Transition appear name="modal-scale">
            <div
                v-if="props.modelValue || props.value"
                ref="modalRef"
                :style="{
                    maxWidth: (props.maxWidth || 500) + 'px',
                }"
                :class="{
                    'shadow-2xl shadow-dark-700': !props.noShadow,
                    'shadow-none': props.noShadow,
                    'p-6': !props.noPadding,
                    'bg-primary-50 dark:bg-dark-300': props.light,
                }"
                class="my-modal absolute left-0 right-0 mx-auto my-8 mt-20 w-full transform rounded-xl bg-primary-100 text-left align-middle transition-all dark:bg-dark-600"
                @close="close"
            >
                <h3 class="relative text-lg font-semibold uppercase text-primary-400">
                    <mdi:chevron-left
                        v-if="props.backButton"
                        class="absolute top-0 bottom-0 m-auto mr-2 cursor-pointer"
                        @click="emit('backButtonClicked')"
                    />

                    <span
                        class="block font-bold transition-transform"
                        :style="{ transform: props.backButton ? 'translate(25px)' : '' }"
                    >
                        <slot name="title" />
                    </span>
                </h3>

                <slot />
            </div>
        </Transition>
    </Teleport>
</template>
