<script lang="ts" setup>
import { Menu, MenuButton, MenuItems } from '@headlessui/vue'

export interface Props {
    menuItemsClass?: string
}

const props = defineProps<Props>()
</script>

<template>
    <Menu as="div" class="relative">
        <MenuButton
            as="div"
            class="flex h-7 w-7 cursor-pointer items-center justify-center rounded-xl hover:bg-primary-200 dark:hover:bg-dark-400"
        >
            <slot name="button">
                <mdi:dots-horizontal />
            </slot>
        </MenuButton>

        <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
        >
            <MenuItems
                unmount
                as="div"
                :class="props.menuItemsClass"
                class="absolute z-10 mt-1 overflow-x-hidden overflow-y-scroll rounded-xl border border-primary-100 bg-primary-50 shadow-lg outline-none dark:border-dark-500 dark:bg-dark-400 dark:shadow-gray-900/60"
            >
                <div class="p-1">
                    <slot />
                </div>
            </MenuItems>
        </transition>
    </Menu>
</template>
