export type LanguageCode =
    | 'en'
    | 'da'
    | 'bg'
    | 'de'
    | 'cs'
    | 'es'
    | 'fr'
    | 'hu'
    | 'lt'
    | 'lv'
    | 'nl'
    | 'no'
    | 'pl'
    | 'pt'
    | 'ro'
    | 'ru'
    | 'sk'
    | 'sv'
    | 'uk'
    | 'is'
    | 'it'

export const languagesCodes: Record<LanguageCode, string> = {
    en: 'English',
    da: 'Danish',
    bg: 'Bulgarian',
    de: 'German',
    cs: 'Czech',
    es: 'Spanish',
    fr: 'French',
    hu: 'Hungarian',
    lt: 'Lithuanian',
    lv: 'Latvian',
    nl: 'Dutch',
    no: 'Norwegian',
    pl: 'Polish',
    pt: 'Portuguese',
    ro: 'Romanian',
    ru: 'Russian',
    sk: 'Slovak',
    sv: 'Swedish',
    uk: 'Ukrainian',
    is: 'Icelandic',
    it: 'Italian',
}
