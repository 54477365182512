// File is symlinked to nova-components/frontend/src/types/layout
// It is not possible to reference other files in this project

export enum MyButtonScheme {
    Default = 'default',
    Primary = 'primary',
    Secondary = 'secondary',
    Success = 'success',
    Info = 'info',
    Warning = 'warning',
    Danger = 'danger',
    Light = 'light',
    Dark = 'dark',
    Action = 'action',
}

export enum MyButtonSize {
    Micro = 'micro',
    Small = 'small',
    Medium = 'medium',
    Large = 'large',
}
