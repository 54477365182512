<script lang="ts" setup>
import { MenuItem } from '@headlessui/vue'

const props = defineProps<{ active?: boolean }>()
</script>

<template>
    <MenuItem class="hover:bg-gray-200 hover:dark:bg-gray-600">
        <button
            :class="{ 'bg-green-500 text-white': props.active }"
            class="flex w-full items-center py-2 px-3 text-sm dark:text-primary-100"
        >
            <slot />
        </button>
    </MenuItem>
</template>
