import { createTooltip, destroyTooltip } from 'floating-vue'
import { nextTick, ObjectDirective } from 'vue'

import { ElementInputEvent } from '@/types/general'

let currentTooltipElement: HTMLElement

function onMouseOver(e: MouseEvent) {
    if (currentTooltipElement) destroyTooltip(currentTooltipElement)

    const target = (e as ElementInputEvent).target
    if (target.offsetWidth < target.scrollWidth) {
        currentTooltipElement = target
        createTooltip(target, target.innerText, {})

        nextTick(() => {
            target.dispatchEvent(new MouseEvent('focus', { bubbles: true }))
        })
    }
}

/**
 * Checks if column is smaller than its content and displays a tooltip if it's on mouse over.
 */

export const truncateTooltip: ObjectDirective<HTMLElement> = {
    mounted(el) {
        el.addEventListener('mouseover', onMouseOver)
    },
    unmounted(el) {
        el.removeEventListener('mouseover', onMouseOver)
    },
}
