export const numberFormatter = new Intl.NumberFormat()
export const decimalSeparator = getSeparator('decimal')
export const groupSeparator = getSeparator('group')

function getSeparator(separatorType: Intl.NumberFormatPartTypes): string {
    const numberWithGroupAndDecimalSeparator = 1000.1
    const parts = numberFormatter.formatToParts(numberWithGroupAndDecimalSeparator)

    return parts.find((part) => part.type === separatorType)?.value ?? ''
}

export function newMoneyFormatter(currency: string): Intl.NumberFormat {
    return Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: 0,
    })
}
