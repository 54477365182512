<script lang="ts">
interface Props {
    modelValue: boolean
    currentAgentId?: uuid
    deviationId?: uuid
    ticketId?: uuid
}
</script>

<script setup lang="ts">
import type { MinimalResource, ResourceResponse, uuid } from '@/types/general'

import { watch, computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from 'axios'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { DropdownStringOption } from '@/types/inputs'
import { Deviation, Ticket } from '@/types/delivery-management'
import { useLazyLoadedList } from '@/hooks/use-lazy-loaded-list'

import MySelect from '@/components/my-components/form/MySelect.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'

interface Form {
    userId: uuid
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'close'): void
    (e: 'saved'): void
}>()

const authStore = useAuthStore()
const { t } = useI18n()
const { data, submit, errors, reset, loading } = useForm<Form>({
    userId: props.currentAgentId ?? authStore.user!.id,
})

const agents = useLazyLoadedList<DropdownStringOption>(fetchAgents)

const title = computed(() => {
    if (props.deviationId) {
        return t('assignUserToDeviation')
    } else {
        return t('assignUserToTicket')
    }
})

async function fetchAgents(): Promise<DropdownStringOption[]> {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.employees', { company: authStore.companyId, web: true }),
    )

    return response.data.map((agent) => ({ value: agent.id, label: agent.name }))
}

async function onSubmit() {
    const deviationAssignRoute = window.route('dm.company.deviations.assign-user', {
        company: authStore.companyId,
        deviation: props.deviationId ?? '',
    })

    const ticketAssignRoute = window.route('dm.company.tickets.assign-user', {
        company: authStore.companyId,
        ticket: props.ticketId ?? '',
    })

    const response = await submit<ResourceResponse<Deviation | Ticket>>(
        'put',
        props.deviationId ? deviationAssignRoute : ticketAssignRoute,
    )

    if (response !== undefined) {
        emit('close')
        emit('saved')
    }
}

onMounted(() => {
    agents.fetch()
})

watch(
    () => props.modelValue,
    () => {
        if (!props.modelValue) return
        reset({ userId: props.currentAgentId ?? authStore.user!.id })
    },
)
</script>

<template>
    <MyModal :value="props.modelValue" :max-width="650" @close="emit('close')">
        <LoaderWrapper :visible="loading" class="rounded-xl" />
        <template #title>
            {{ title }}
        </template>
        <MyForm :errors="errors" @submit.prevent="onSubmit">
            <div class="space-y-2">
                <div class="flex space-x-3">
                    <MySelect
                        v-model="data.userId"
                        name="userId"
                        group-class="w-full"
                        :label="t('agent')"
                        clear-button
                        :options="agents.items.value"
                        :loading="agents.fetching.value"
                        searchable
                    />
                </div>
            </div>
            <div class="mt-4 flex justify-end gap-6">
                <MyButton :disabled="loading" scheme="primary" v-text="t('assignUser')" />
            </div>
        </MyForm>
    </MyModal>
</template>
