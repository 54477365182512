<script lang="ts" setup>
import { ElementInputEvent } from '@/types/general'

export interface Props {
    value: string
    modelValue: string
    label: string
}

const props = defineProps<Props>()

const emit = defineEmits<{
    (e: 'update:modelValue', value: string | number | null): void
}>()
</script>

<script lang="ts">
export default { inheritAttrs: false }
</script>

<template>
    <label class="inline-flex items-center gap-2">
        <input
            v-bind="$attrs"
            :value="props.value"
            class="dark:checked:actove:bg-primary-400 appearance-none border-2 border-primary-300 bg-transparent shadow-none checked:bg-primary-500 focus:shadow-none focus:ring-0 focus:ring-offset-0 checked:focus:bg-primary-500 checked:active:bg-primary-500 dark:border-dark-300 dark:checked:bg-primary-400 dark:checked:focus:bg-primary-400"
            type="radio"
            @input="emit('update:modelValue', ($event as ElementInputEvent).target.value)"
        />
        <span class="text-sm">{{ props.label }}</span>
    </label>
</template>
