<script lang="ts">
interface Props {
    modelValue: boolean
    hubId?: uuid
}
</script>

<script setup lang="ts">
import type { MinimalResource, ResourceResponse, uuid } from '@/types/general'

import axios, { Method } from 'axios'
import { watch, computed, ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { DropdownStringOption } from '@/types/inputs'
import { Hub } from '@/types/delivery-management'

import MySelect from '@/components/my-components/form/MySelect.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'

interface Form {
    name: string
    shipperCompanyId: uuid | null
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'close'): void
    (e: 'saved'): void
}>()

const authStore = useAuthStore()
const { t } = useI18n()
const { data, submit, errors, reset, loading } = useForm<Form>({
    name: '',
    shipperCompanyId: null,
})

const shippers = ref<DropdownStringOption[]>([])
const hub = ref<Hub>()

const title = computed(() => {
    if (props.hubId) {
        return t('updateEntity', { entity: t('hub') })
    } else {
        return t('createEntity', { entity: t('hub') })
    }
})

async function fetchHub() {
    const response = await axios.get<ResourceResponse<Hub>>(
        window.route('dm.company.hubs.show', {
            company: authStore.companyId,
            hub: props.hubId ?? '',
        }),
    )

    hub.value = response.data.data

    data.name = hub.value.name
    data.shipperCompanyId = hub.value.shipperCompany?.id ?? null
}

async function fetchShippers() {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.customers', {
            company: authStore.companyId,
            haulier: true,
        }),
    )

    shippers.value = response.data.map((customer) => ({
        value: customer.id,
        label: customer.name,
    }))
}

async function onSubmit() {
    const method: Method = props.hubId ? 'PUT' : 'POST'
    const createRoute = window.route('dm.company.hubs.store', {
        company: authStore.companyId,
    })
    const updateRoute = window.route('dm.company.hubs.update', {
        company: authStore.companyId,
        hub: props.hubId ?? '',
    })
    data.shipperCompanyId = data.shipperCompanyId ?? null

    const response = await submit<ResourceResponse<Hub>>(
        method,
        props.hubId ? updateRoute : createRoute,
    )

    if (response !== undefined) {
        emit('close')
        emit('saved')
    }
}

onMounted(() => {
    if (props.modelValue) {
        fetchHub()
        fetchShippers()
    }
})

watch(
    () => props.modelValue,
    () => {
        if (!props.modelValue) return

        reset()
    },
)
</script>

<template>
    <MyModal :value="props.modelValue" :max-width="650" @close="emit('close')">
        <LoaderWrapper :visible="loading" class="rounded-xl" />
        <template #title>
            {{ title }}
        </template>
        <MyForm :errors="errors" @submit.prevent="onSubmit">
            <div class="space-y-2">
                <div class="flex space-x-3">
                    <MyInput
                        v-model="data.name"
                        name="name"
                        type="text"
                        placeholder="name"
                        :label="t('name')"
                        autofocus
                    />
                    <MySelect
                        v-model="data.shipperCompanyId"
                        name="shipperCompanyId"
                        group-class="w-full"
                        :label="t('shipper')"
                        clear-button
                        :options="shippers"
                        searchable
                    />
                </div>
            </div>
            <div class="mt-4 flex justify-end gap-6">
                <MyButton
                    :disabled="loading"
                    scheme="primary"
                    v-text="props.hubId ? t('update') : t('create')"
                />
            </div>
        </MyForm>
    </MyModal>
</template>
