import { InjectionKey } from 'vue'

export interface WizardStep {
    title: string
    id: string
    submit?: () => boolean
    loading?: boolean
}

export const insertFormWizardStepKey: InjectionKey<(step: WizardStep) => void> =
    Symbol('insertFormWizardStep')

export const updateFormWizardStepKey: InjectionKey<(step: WizardStep) => void> =
    Symbol('updateFormWizardStep')

export interface Address {
    address: string
    city: string
    country: string
    zipcode: string
    latitude: number
    longitude: number
}
