<script lang="ts" setup>
import { v4 as uuid } from 'uuid'
import { nextTick, ref } from 'vue'

import { Confirm } from '@/types/general'
import { MyFormRef } from '@/types/inputs'
import { emitter } from '@/utils/mitt'

import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'

interface ConfirmWithId extends Confirm {
    id: string
}

const confirms = ref<ConfirmWithId[]>([])
const forms = ref<MyFormRef[]>([])

emitter.on('showConfirm', async (confirm) => {
    confirms.value.push({ ...confirm, id: uuid() })

    await nextTick()

    forms.value[forms.value.length - 1]?.focus()
})

function closeDialog(confirm: ConfirmWithId, confirmed: boolean) {
    if (confirmed) {
        confirm.resolve(true)
    } else {
        confirm.reject()
    }

    confirms.value = confirms.value.filter((c) => c.id !== confirm.id)
}
</script>

<template>
    <div>
        <MyModal
            v-for="confirm in confirms"
            :key="confirm.id"
            :value="true"
            @close="closeDialog(confirm, false)"
        >
            <template #title>{{ confirm.title }}</template>

            <p v-if="confirm.description" class="my-4" v-text="confirm.description" />

            <MyForm
                ref="forms"
                class="flex flex-row-reverse justify-start"
                @submit.prevent="closeDialog(confirm, true)"
            >
                <MyButton
                    :scheme="confirm.options.confirmButtonScheme || 'primary'"
                    v-text="confirm.options.confirmText"
                />

                <MyButton
                    type="button"
                    @click="closeDialog(confirm, false)"
                    v-text="confirm.options.cancelText"
                />
            </MyForm>
        </MyModal>
    </div>
</template>
