<script lang="ts" setup>
import { computed } from 'vue'

import { useFilterQuery } from '@/hooks/use-filter-query'

import MyButton from '@/components/my-components/MyButton.vue'

export interface Props {
    value: string | number | null
    filterName: string
    default?: boolean
    badgeValue?: string | number
}

const emit = defineEmits<{
    (e: 'selected', value: string | number | null, filterName: string): void
}>()

const props = defineProps<Props>()
const { filters } = useFilterQuery()

const isActive = computed(() => {
    const value = filters.value[props.filterName]
    if (!value && value !== '0' && props.default) return true

    return value === props.value
})
</script>

<template>
    <MyButton
        class="flex-shrink-0"
        plain
        scheme="light"
        size="small"
        :active="isActive"
        @click="emit('selected', props.value, props.filterName)"
    >
        <slot />
    </MyButton>
</template>
