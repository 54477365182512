<script lang="ts">
interface Props {
    modelValue: boolean
    deviation?: Deviation
    ticket?: Ticket
}
</script>

<script setup lang="ts">
import type { ResourceResponse, uuid } from '@/types/general'

import { watch, computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from 'axios'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { DropdownStringOption } from '@/types/inputs'
import { Deviation, MinimalCompanyTag, Ticket } from '@/types/delivery-management'
import { useLazyLoadedList } from '@/hooks/use-lazy-loaded-list'

import MySelect from '@/components/my-components/form/MySelect.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'

interface Form {
    tags: uuid[]
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'close'): void
    (e: 'saved'): void
}>()

const authStore = useAuthStore()
const { t } = useI18n()
const { data, submit, errors, reset, loading } = useForm<Form>({
    tags: props.deviation
        ? props.deviation.tags.map((e) => e.id)
        : props.ticket?.tags.map((e) => e.id) ?? [],
})

const title = computed(() => {
    if (props.deviation) {
        return t('updateDeviationTags')
    } else {
        return t('updateTicketTags')
    }
})

const tags = useLazyLoadedList<DropdownStringOption>(fetchTags)

async function fetchTags(): Promise<DropdownStringOption[]> {
    const response = await axios.get<ResourceResponse<MinimalCompanyTag[]>>(
        window.route('dm.company.tags.index', {
            company: props.deviation
                ? props.deviation?.tour?.shipperCompany?.id
                : props.ticket?.company.id,
        }),
    )

    return response.data.data.map((tag) => ({ value: tag.id, label: t(tag.name) }))
}

async function onSubmit() {
    const deviationTagsRoute = window.route('dm.company.deviations.update-tags', {
        company: authStore.companyId,
        deviation: props.deviation ?? '',
    })

    const ticketTagsRoute = window.route('dm.company.tickets.update-tags', {
        company: authStore.companyId,
        ticket: props.ticket ?? '',
    })

    const response = await submit<ResourceResponse<Deviation | Ticket>>(
        'put',
        props.deviation ? deviationTagsRoute : ticketTagsRoute,
    )

    if (response !== undefined) {
        emit('close')
        emit('saved')
    }
}

onMounted(() => {
    tags.fetch()
})

watch(
    () => props.modelValue,
    () => {
        if (!props.modelValue) return
        reset()
    },
)
</script>

<template>
    <MyModal :value="props.modelValue" :max-width="400" @close="emit('close')">
        <LoaderWrapper :visible="loading" class="rounded-xl" />
        <template #title>
            {{ title }}
        </template>
        <MyForm :errors="errors" @submit.prevent="onSubmit">
            <div class="space-y-2">
                <div class="flex flex-col space-y-4">
                    <MySelect
                        v-model="data.tags"
                        name="tags"
                        group-class="w-full"
                        :label="t('tags')"
                        multiple
                        :options="tags.items.value"
                        :loading="tags.fetching.value"
                        searchable
                    />
                </div>
            </div>
            <div class="mt-4 flex justify-end gap-6">
                <MyButton :disabled="loading" scheme="primary" v-text="t('updateTags')" />
            </div>
        </MyForm>
    </MyModal>
</template>
