<script lang="ts" setup>
import { computed, onBeforeMount, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { useCompanyStore } from '@/stores/company-store'
import { uuid } from '@/types/general'
import { DropdownStringOption } from '@/types/inputs'

import MyPanel from '@/components/my-components/MyPanel.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import BalanceTable from '@/components/packaging/balance/BalanceTable.vue'
import TransactionsTable from '@/components/packaging/transactions/TransactionsTable.vue'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const companyStore = useCompanyStore()

const senderLocationId = ref<uuid | null>((route.query['sender-location'] as string) ?? null)
const receiverLocationId = ref<uuid | null>((route.query['receiver-location'] as string) ?? null)

const locations = computed<DropdownStringOption[]>(() =>
    companyStore.locations.map(({ id, name }) => ({ value: id, label: name })),
)
const receiverLocationIds = computed<DropdownStringOption[]>(() =>
    locations.value.filter((location) => location.value !== senderLocationId.value),
)

function senderLocationChanged() {
    router.push({ query: { ...route.query, 'sender-location': senderLocationId.value } })
    if (senderLocationId.value == receiverLocationId.value) {
        receiverLocationId.value = null
    }
}

function receiverLocationChanged() {
    router.push({ query: { ...route.query, 'receiver-location': receiverLocationId.value } })
}

onBeforeMount(() => {
    if (!senderLocationId.value) {
        senderLocationId.value = companyStore.locations[0]?.id || null
    }
})
watch(
    () => companyStore.loadingLocations,
    () => {
        if (!companyStore.loadingLocations && !senderLocationId.value) {
            senderLocationId.value = companyStore.locations[0].id
        }
    },
)
</script>

<template>
    <h1 class="text-center text-xl font-bold" v-text="t('compareLocationBalance')" />

    <div class="mx-auto mt-5 flex w-full max-w-2xl items-center justify-center">
        <MySelect
            v-model="senderLocationId"
            group-class="w-full"
            :options="locations"
            @change="senderLocationChanged"
        />

        <mdi:arrow-right class="mx-5 flex-shrink-0" />

        <MySelect
            v-model="receiverLocationId"
            group-class="w-full"
            :options="receiverLocationIds"
            :placeholder="t('selectALocation')"
            @change="receiverLocationChanged"
        />
    </div>

    <div v-if="senderLocationId && receiverLocationId" class="mt-10 space-y-5">
        <MyPanel
            :title="t('balance')"
            bordered
            invisible
            panel-class="max-w-5xl lg:col-span-2 mx-auto"
        >
            <BalanceTable
                :sender-location-id="senderLocationId"
                :receiver-location-id="receiverLocationId"
            />
        </MyPanel>

        <MyPanel :title="t('transactions')">
            <TransactionsTable
                :location-id="senderLocationId"
                :compared-location-id="receiverLocationId"
                inline-view-modal
            />
        </MyPanel>
    </div>
</template>
