import { debouncedWatch } from '@vueuse/core'
import axios from 'axios'
import { ref, Ref, watch } from 'vue'

import { useAuthStore } from '@/stores/auth-store'
import { ColumnSize, InternalTableColumn } from '@/types/table'

export interface ColumnPreferences {
    columns: ColumnPreference[]
}

interface ColumnPreference {
    property: string
    width: number
    order: number
    hidden: boolean
}

// We'll keep the preferences in a local variables
// as we want them to be re-fetched on refresh
// They'll not be reactive anyway
const preferences = new Map<string, ColumnPreferences>(
    Object.entries(window.user?.table_preferences?.options ?? {}),
)

// How long to wait before saving the preferences
const debounce = 300

export function useColumnPreferences(
    tableId: () => string,
    columns: Ref<Map<string, InternalTableColumn>>,
    columnSizes: Ref<Map<string, ColumnSize>>,
    disabled = false,
) {
    const authStore = useAuthStore()
    const columnPreferences = ref<ColumnPreferences>(
        preferences.get(tableId()) || {
            columns: [],
        },
    )
    const initialized = ref(false)

    if (disabled) {
        columnPreferences.value = { columns: [] }
        return {
            columnPreferences,
            onInitialized() {},
        }
    }

    function constructPreferenceObject(): ColumnPreferences {
        return {
            columns: Array.from(columns.value.values()).map((column) => {
                const size = columnSizes.value.get(column.property)
                return {
                    property: column.property,
                    width: size?.width ?? 0,
                    order: column.order,
                    hidden: column.hidden,
                }
            }),
        }
    }

    debouncedWatch(
        [columns, columnSizes],
        async () => {
            if (!initialized.value) return
            if (!authStore.user) return

            columnPreferences.value = constructPreferenceObject()
            if (JSON.stringify(columnPreferences) === JSON.stringify(preferences.get(tableId()))) {
                return
            }

            preferences.set(tableId(), columnPreferences.value)

            await axios.put(
                window.route('users.table-preferences.update', {
                    id: authStore.user.id,
                }),
                { preferences: JSON.stringify(Object.fromEntries(preferences)) },
            )
        },
        { deep: true, debounce },
    )

    watch(tableId, (value) => {
        columnPreferences.value = preferences.get(value) || { columns: [] }
        initialized.value = false
    })

    return {
        columnPreferences,
        onInitialized: () => {
            setTimeout(() => {
                initialized.value = true
            }, debounce + 10)
        },
    }
}
