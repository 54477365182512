<script lang="ts" setup>
import axios from 'axios'
import { computed, inject, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@/stores/auth-store'
import { ResourceResponse } from '@/types/general'
import { currentCustomerKey, LocationBalanceRow, Product } from '@/types/packaging'
import { image } from '@/utils/assets'

import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import SettleCustomerBalanceTable from '@/components/packaging/balance/SettleCustomerBalanceTable.vue'
import MyTable from '@/components/table/MyTable.vue'
import MyTableColumn from '@/components/table/MyTableColumn.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'

export interface Props {
    product?: Product
}

const props = defineProps<Props>()
const emit = defineEmits<{ (e: 'closed', updated: boolean): void }>()

const customer = inject(currentCustomerKey)!
const { t } = useI18n()
const authStore = useAuthStore()
const loading = ref(false)
const modalOpen = ref(false)
const note = ref<string>()
const balanceRows = ref<LocationBalanceRow[]>([])
const selectedLocationId = ref<string>()
const selectedLocations = ref<number[]>([])
const mypalletCustomer = computed(() => customer.value!.customerCompany.mypalletCustomer)
const disabledRows = computed(() => {
    return balanceRows.value
        .map((row, index) => {
            if (mypalletCustomer.value) {
                return row.owed <= 0 ? index : undefined
            }

            return row.balance === 0 ? index : undefined
        })
        .filter((index): index is number => index !== undefined)
})
const totalOwed = computed(() => {
    return balanceRows.value
        .filter((row, index) => selectedLocations.value.includes(index))
        .reduce((total, row) => total + row.owed, 0)
})
let updated = false

async function fetchBalance() {
    loading.value = true
    const params: Record<string, string> = {
        company: authStore.companyId,
        customer_company_id: customer.value!.customerCompany.id,
        product_id: props.product!.id,
    }

    const response = await axios.get<ResourceResponse<LocationBalanceRow[]>>(
        window.route('packaging.company.balance.per-location', params),
    )

    loading.value = false
    balanceRows.value = response.data.data
}

async function resetBalance() {
    loading.value = true
    const data: Record<string, unknown> = {
        customer_company_id: customer.value!.customerCompany.id,
        product_id: props.product!.id,
        note: note.value,
        locations: selectedLocations.value
            .filter((index) => {
                if (mypalletCustomer.value) {
                    return balanceRows.value[index].owed > 0
                }

                return balanceRows.value[index].balance !== 0
            })
            .map((index) => balanceRows.value[index].locationId),
    }

    try {
        await axios.delete(
            window.route('packaging.company.balance.reset', { company: authStore.companyId }),
            {
                data,
            },
        )
    } catch (e) {
        if (axios.isAxiosError(e)) {
            console.error(e)
        }
    }

    updated = true
    selectedLocations.value = []
    loading.value = false
    await fetchBalance()
}

async function settleLocation(locationId: string) {
    selectedLocationId.value = locationId
    selectedLocations.value = []
}

async function balanceUpdated() {
    updated = true

    await fetchBalance()
}

watch(
    () => props.product,
    () => {
        if (props.product) {
            modalOpen.value = true
            fetchBalance()
        }
    },
)
watch(modalOpen, () => {
    if (!modalOpen.value) {
        selectedLocationId.value = undefined
        emit('closed', updated)
    }
})
</script>

<template>
    <MyModal v-model="modalOpen" :max-width="720">
        <template #title>
            <div class="flex items-center">
                <div
                    class="overflow-hidden transition-all"
                    :style="{ width: selectedLocationId ? '32px' : '0px' }"
                >
                    <MyButton icon small @click="selectedLocationId = undefined">
                        <mdi:chevron-left />
                    </MyButton>
                </div>

                <h1 v-text="t('settleBalance')" />
            </div>
        </template>

        <div v-if="props.product">
            <div class="mt-3 mb-5 flex justify-between">
                <div>
                    <h3 v-if="!selectedLocationId" class="text-lg" v-text="t('yourLocations')" />

                    <h3 v-else class="text-lg" v-text="t('customerLocations')" />

                    <p class="mt-2 max-w-sm text-sm" v-text="t('settleBalanceDescription')" />

                    <p
                        v-if="mypalletCustomer"
                        class="max-w-sm whitespace-pre-wrap text-sm"
                        v-text="
                            t('settleBalanceMyPalletCustomerDescription', {
                                company: customer!.customerCompany.name,
                            })
                        "
                    />
                </div>

                <div
                    class="flex max-w-xs flex-col items-center rounded-lg bg-dark-100 p-2 dark:bg-dark-700"
                >
                    <img
                        class="mb-2 h-20"
                        :src="image(props.product.image)"
                        :alt="props.product.name"
                    />

                    {{ props.product.name }}
                </div>
            </div>

            <template v-if="!selectedLocationId">
                <MyTable
                    v-model:row-selection="selectedLocations"
                    :disabled-selection-rows="disabledRows"
                    :loading="loading"
                    :rows="balanceRows"
                    disable-footer
                    disable-actions
                    disable-search
                    disable-ordering
                    enable-row-selection
                    row-key="locationId"
                    table-id="location-balance"
                >
                    <MyTableColumn :min-width="200" :name="t('location')" property="locationName" />
                    <template #locationName="{ row }">
                        <div class="flex items-center">
                            <span class="truncate" v-text="row.locationName" />

                            <mdi:trash-can-outline
                                v-if="row.locationDeleted"
                                v-tooltip="t('locationDeleted')"
                                class="ml-2 text-red-600"
                            />
                        </div>
                    </template>
                    <MyTableColumn :width="80" :name="t('balance')" property="balance" />

                    <template #balance="{ row }">
                        <div
                            v-if="row.balance < 0"
                            v-tooltip="t('productsOwedExplanation')"
                            class="truncate"
                        >
                            {{ row.balance }}
                        </div>

                        <div
                            v-if="row.balance > 0"
                            v-tooltip="t('productsCreditExplanation')"
                            class="truncate"
                        >
                            {{ row.balance }}
                        </div>
                    </template>

                    <template #actionRow="{ row }">
                        <!-- We show one of the buttons when loading to make sure auto sizing is not cutting of the balance column -->
                        <MyButton
                            v-if="!loading && mypalletCustomer && row.owed > 0"
                            :disabled="loading"
                            class="my-2"
                            size="small"
                            scheme="primary"
                            @click="settleLocation(row.locationId)"
                        >
                            {{ t('settleOwed') }}
                            <mdi:chevron-right />
                        </MyButton>

                        <MyButton
                            v-if="loading || (!mypalletCustomer && row.balance !== 0)"
                            :disabled="loading"
                            class="my-2"
                            :class="{ 'opacity-0 invisible': loading }"
                            size="small"
                            scheme="primary"
                            @click="settleLocation(row.locationId)"
                        >
                            {{ t('settleBalance') }}
                            <mdi:chevron-right />
                        </MyButton>
                    </template>
                </MyTable>

                <Transition name="fade">
                    <div
                        v-if="selectedLocations.length > 0"
                        class="sticky bottom-2 mt-2 flex justify-between space-x-2"
                    >
                        <div class="flex flex-col space-y-3">
                            <MyInput
                                v-model="note"
                                name="note"
                                :label="t('note')"
                                type="text"
                                :placeholder="t('note')"
                            />

                            <!-- Tooltip can't be shown when button is also disabled -->
                            <div
                                v-tooltip="{
                                    content: t('noOwedForLocations'),
                                    disabled: !mypalletCustomer || totalOwed > 0,
                                }"
                            >
                                <MyButton
                                    :disabled="mypalletCustomer && totalOwed <= 0"
                                    scheme="secondary"
                                    shadow
                                    @click="resetBalance"
                                >
                                    <span
                                        v-if="mypalletCustomer"
                                        v-text="t('resetOwedForSelected')"
                                    />
                                    <span v-else v-text="t('resetBalanceForSelected')" />
                                </MyButton>
                            </div>
                        </div>
                    </div>
                </Transition>
            </template>

            <SettleCustomerBalanceTable
                v-else
                :product-id="props.product!.id"
                :location-id="selectedLocationId"
                @updated="balanceUpdated"
            />
        </div>
    </MyModal>
</template>
