<script lang="ts" setup>
import axios from 'axios'
import { inject, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { notify } from '@kyvg/vue3-notification'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { Customer, CustomerCompany } from '@/types/company'
import { MinimalResource, uuid } from '@/types/general'
import { currentRouteModuleKey } from '@/types/global-injection-keys'
import { DropdownOption } from '@/types/inputs'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'

export interface Props {
    modelValue: boolean
    customer: Customer<CustomerCompany>
}

interface FormData {
    newCompanyId: uuid | null
    newLocationId: uuid | null
}

const props = defineProps<Props>()
const emit = defineEmits<{ (e: 'update:modelValue', value: boolean): void }>()

const authStore = useAuthStore()
const router = useRouter()
const { t } = useI18n()
const customerCompanies = ref<DropdownOption[]>([])
const locations = ref<DropdownOption[]>([])
const fetchingCustomers = ref(false)
const fetchingLocations = ref(false)
const {
    data,
    loading: transferring,
    errors,
    submit,
} = useForm<FormData>({ newCompanyId: null, newLocationId: null })
const currentRouteModule = inject(currentRouteModuleKey)!

async function fetchCustomers() {
    fetchingCustomers.value = true
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.customers', {
            company: authStore.companyId,
            'without-new': true,
        }),
    )

    customerCompanies.value = response.data
        .filter((customer) => customer.id !== props.customer.customerCompany.id)
        .map((customer) => ({
            value: customer.id,
            label: customer.name,
        }))
    fetchingCustomers.value = false
}

async function fetchLocations() {
    if (!data.newCompanyId) return

    fetchingLocations.value = true
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.locations', { company: data.newCompanyId }),
    )

    locations.value = response.data.map((location) => ({
        value: location.id,
        label: location.name,
    }))
    data.newLocationId = response.data[0]?.id ?? null
    fetchingLocations.value = false
}

async function onSubmit() {
    const params = { company: authStore.companyId, customer: props.customer.id }
    try {
        const response = await submit<{ customerId: string; hasHaulierTransactions: boolean }>(
            'POST',
            window.route('company.customers.merge', params),
        )
        if (response) {
            notify({ type: 'success', text: t('entityMerged', { entity: t('customer') }) })

            if (response.hasHaulierTransactions) {
                notify({ type: 'warning', text: t('customerHasHaulierTransactions') })
            }

            router.replace({
                name: `${currentRouteModule.value?.name ?? 'packaging'}.customers.show`,
                params: { id: response.customerId },
            })
        }
    } catch (e) {
        if (!axios.isAxiosError(e)) throw e

        if (e.response && e.response.status === 409) {
            notify({ type: 'error', text: t('transactionCountExceeded') })
            emit('update:modelValue', false)
        } else {
            throw e
        }
    }
}

watch(
    () => props.modelValue,
    async () => {
        if (!props.modelValue) return

        data.newCompanyId = null
        data.newLocationId = null
        await fetchCustomers()
    },
)

watch(
    () => data.newCompanyId,
    async () => {
        data.newLocationId = null
        await fetchLocations()
    },
)
</script>

<template>
    <MyModal :value="props.modelValue" @close="emit('update:modelValue', false)">
        <LoaderWrapper :visible="transferring" class="rounded-xl" />

        <template #title>
            {{ t('transferCustomerData') }}
        </template>

        <p v-text="t('transferCustomerDataExplanation')" />

        <MyForm :errors="errors" class="mt-2 space-y-2" @submit.prevent="onSubmit">
            <MySelect
                v-model="data.newCompanyId"
                :label="t('company')"
                name="newCompanyId"
                :options="customerCompanies"
                :loading="fetchingCustomers"
                searchable
            />
            <MySelect
                v-model="data.newLocationId"
                :label="t('location')"
                name="newLocationId"
                :options="locations"
                :disabled="data.newCompanyId === null"
                :loading="fetchingLocations"
                searchable
            />

            <div class="mt-3 flex justify-end">
                <MyButton :disabled="transferring" scheme="primary" v-text="t('transfer')" />
            </div>
        </MyForm>
    </MyModal>
</template>
