<script lang="ts" setup>
import { notify } from '@kyvg/vue3-notification'
import { onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from 'axios'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { uuid } from '@/types/general'
import { emitter } from '@/utils/mitt'
import { DropdownOption } from '@/types/inputs'

import MySelect from '@/components/my-components/form/MySelect.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'

export interface Props {
    modelValue: boolean
    reportId: uuid
    departure: string | null
}

const props = defineProps<Props>()
const departures = ref<DropdownOption[]>([])

const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void
}>()

const auth = useAuthStore()
const { t } = useI18n()

const form = useForm({ departure: props.departure })

async function fetchDepartureList() {
    if (!auth.isLoggedIn) return

    const response = await axios.get<string[]>(
        window.route('dmr.company.departure-list.index', {
            company: auth.companyId,
        }),
    )

    departures.value = response.data.map((name) => ({ value: name, label: name }))
}

function close() {
    emit('update:modelValue', false)
}

async function save() {
    const response = await form.submit(
        'PUT',
        window.route('dmr.company.reports.departure.update', {
            company: auth.companyId,
            report: props.reportId,
        }),
    )

    if (response) {
        close()
        emitter.emit('fetchDamageReports')
        notify({ type: 'success', text: t('departureUpdated') })
    }
}

onMounted(fetchDepartureList)

watch(
    () => props.modelValue,
    () => form.reset({ departure: props.departure }),
)
</script>

<template>
    <MyModal :value="props.modelValue" @close="close">
        <template #title>
            {{ t('updateEntity', { entity: t('departure') }) }}
        </template>

        <LoaderWrapper :visible="form.loading.value" immediate />

        <MyForm :errors="form.errors.value" @submit.prevent="save">
            <MySelect
                v-model="form.data.departure"
                group-class="w-full mt-2"
                clear-button
                :options="departures"
            />

            <div class="mt-4 flex justify-end">
                <MyButton :disabled="form.loading.value" scheme="primary" v-text="t('save')" />
            </div>
        </MyForm>
    </MyModal>
</template>
