import { Ref } from 'vue'
import { useI18n } from 'vue-i18n'

export function useBalanceOwedHelpers(companyName: Ref<string>, customerName: Ref<string>) {
    const { t } = useI18n()

    return {
        /**
         * Returns class colors for the balance. If above zero it's green and red below zero.
         * @param balance
         */
        getBalanceColor(balance: number): string {
            return balance >= 0 ? 'text-green-500 dark:text-primary-400' : 'text-red-500'
        },
        /**
         * Generates text to describe if company owes customer or customer owes company
         * When balance is above zero customer will owe the company
         */
        balanceOwedDescription(balance: number) {
            if (balance === 0) {
                return t('statementCreditorDebitorNoDebt', {
                    creditor: customerName.value,
                    debtor: companyName.value,
                })
            } else if (balance > 0) {
                return t('statementCreditorDebitorDebt', {
                    debtor: customerName.value,
                    creditor: companyName.value,
                    balance: balance,
                })
            }

            return t('statementCreditorDebitorDebt', {
                debtor: companyName.value,
                creditor: customerName.value,
                balance: balance * -1,
            })
        },
    }
}
