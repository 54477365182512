<script setup lang="ts">
import type { ChartData, ChartDataset, ChartOptions } from 'chart.js'

import axios from 'axios'
import { storeToRefs } from 'pinia'
import { onMounted, ref, watch, computed } from 'vue'
import { Line } from 'vue-chartjs'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@/stores/auth-store'
import { useDashboardStore } from '@/stores/dashboard-store'
import { useProductStore } from '@/stores/product-store'
import { ResourceResponse } from '@/types/general'

import MyPanel from '@/components/my-components/MyPanel.vue'

enum IntervalType {
    day = 'day',
    week = 'week',
    month = 'month',
}

interface DashboardTransactionProduct {
    period: string
    delivered: number
    deliveredFilled: number
    received: number
    receivedFilled: number
}

interface DashboardTransaction {
    productId: string
    values: DashboardTransactionProduct[]
}

interface DashboardTransactions {
    intervalType: IntervalType
    periodKeys: string[]
    periodProducts: DashboardTransaction[]
}

const { t } = useI18n()
const authStore = useAuthStore()
const productStore = useProductStore()
const { date, selectedLocation, startDate, endDate } = storeToRefs(useDashboardStore())
const loading = ref(false)
const transactionData = ref<DashboardTransactions>()
const chartOptions = ref<ChartOptions<'line'>>({
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            beginAtZero: true,
            grid: {
                drawOnChartArea: false,
                lineWidth: 4,
            },
            ticks: {
                padding: 10,
                color: '#707070',
                font: {
                    weight: 'bold',
                    family: 'Poppins',
                },
            },
        },
        y: {
            beginAtZero: true,
            grid: {
                drawOnChartArea: false,
                lineWidth: 4,
            },
            ticks: {
                padding: 10,
                color: '#707070',
                font: {
                    weight: 'bold',
                    family: 'Poppins',
                },
            },
        },
    },
    plugins: {
        legend: {
            align: 'end',
            position: 'top',
            labels: {
                usePointStyle: true,
                boxHeight: 5,
                color: '#707070',
                font: {
                    family: 'Poppins',
                },
            },
        },
        tooltip: {
            backgroundColor: '#1a1a1a',
            xAlign: 'center',
            yAlign: 'bottom',
            padding: 10,
            caretPadding: 5,
            usePointStyle: true,
        },
    },
})

const chartData = computed<ChartData<'line'>>(() => {
    if (!transactionData.value) return { labels: [], datasets: [] }
    const labels = transactionData.value.periodKeys.map((key) =>
        transactionData.value!.intervalType === IntervalType.week ? t('weekLabel', { key }) : key,
    )

    const datasets: ChartDataset<'line'>[] = transactionData.value.periodProducts.map((product) => {
        const data: string | number[] = []

        product.values.forEach((value) => {
            data.push(value.delivered + value.received)
        })

        return {
            label: productStore.findCompanyProduct(product.productId)?.name ?? '',
            data,
            borderWidth: 3,
            borderColor: productStore.colors[product.productId] ?? '#A8B1BD',
            backgroundColor: productStore.colors[product.productId] ?? '#A8B1BD',
            pointBorderColor: productStore.colors[product.productId] ?? '#A8B1BD',
            pointRadius: 4,
            tension: 0.4,
        }
    })

    return { labels, datasets }
})

async function fetchTransactions() {
    loading.value = true

    const { data: response } = await axios.post<ResourceResponse<DashboardTransactions>>(
        window.route('company.dashboard.transactions-products', { company: authStore.companyId }),
        {
            startDate: startDate.value.format('YYYY-MM-DD'),
            endDate: endDate.value.format('YYYY-MM-DD'),
            location: selectedLocation.value,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
    )

    transactionData.value = response.data

    loading.value = false
}

onMounted(fetchTransactions)
watch([date, selectedLocation], fetchTransactions)
</script>

<script lang="ts">
export default { inheritAttrs: false }
</script>

<template>
    <MyPanel invisible :loading="loading" :panel-class="$attrs.class as string" class="flex">
        <div class="w-full">
            <Line :height="500" :data="chartData" :options="chartOptions" />
        </div>
    </MyPanel>
</template>
