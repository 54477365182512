<script lang="ts" setup>
import type { Except } from 'type-fest'

import { useI18n } from 'vue-i18n'

import { Company, Location } from '@/types/company'
import { image } from '@/utils/assets'
import { uuid } from '@/types/general'

import MyPanel from '@/components/my-components/MyPanel.vue'

export interface Props {
    company: Except<Company, 'locations'>
    creator?: string
    locations: Location[]
    selectedLocationId: uuid | null
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'location-selected', value: uuid | null): void
}>()

const { t } = useI18n()
</script>

<template>
    <MyPanel padded class="grid grid-cols-[2fr_1fr]">
        <div class="flex flex-col justify-between">
            <div>
                <h2
                    class="text-md font-bold uppercase text-green-500"
                    v-text="props.company.name"
                />
                <p class="text-sm font-semibold dark:text-primary-200">
                    {{ props.company.vat }}
                </p>
                <p class="text-sm font-semibold dark:text-primary-200">
                    {{ props.company.address }}
                </p>
            </div>

            <div v-if="props.locations.length > 0" class="space-y-1 text-sm lg:mt-10">
                <b
                    class="font-bold"
                    v-text="t(props.locations.length === 1 ? 'location' : 'locations')"
                />

                <div v-for="location in props.locations" :key="location.id">
                    <div class="group flex flex-col">
                        <div class="flex items-center text-sm">
                            <span v-text="location.name" />

                            <mdi:filter-outline
                                v-if="selectedLocationId !== location.id"
                                v-tooltip="t('filterByLocation')"
                                class="invisible ml-1 cursor-pointer text-green-500 group-hover:visible"
                                @click="emit('location-selected', location.id)"
                            />

                            <mdi:filter
                                v-else
                                v-tooltip="t('filterByLocation')"
                                class="ml-1 cursor-pointer text-green-500"
                                @click="emit('location-selected', null)"
                            />
                        </div>

                        <div class="text-xs">
                            <span class="mr-1 font-semibold" v-text="t('address') + ':'" />
                            <span v-text="location.address" />
                        </div>

                        <div v-if="location.pNumber" class="flex text-xs">
                            <span class="mr-1 font-semibold" v-text="t('pNumber') + ':'" />
                            <span v-text="location.pNumber" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col items-end justify-between">
            <div
                class="flex h-[100px] w-[120px] items-center justify-center overflow-hidden rounded-xl bg-primary-200 p-4 dark:bg-dark-400"
            >
                <img
                    class="h-full w-full object-contain"
                    :src="image(props.company.logo ?? 'cf6c449d-282b-49e9-838e-eb395c168700')"
                    :alt="props.company.name"
                />
            </div>

            <div class="text-right text-xs lg:mt-3">
                <span
                    v-if="creator"
                    class="whitespace-pre-line"
                    v-text="t('createdByUser', { user: props.creator })"
                />
            </div>
        </div>
    </MyPanel>
</template>
