<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core'
import { computed, ref } from 'vue'
import { RouteLocationRaw, useRoute } from 'vue-router'

import { useAuthStore } from '@/stores/auth-store'
import { LicenseType } from '@/types/company'
import { moduleLicenses } from '@/utils/licenses'
import { useAlarmStore } from '@/stores/alarm-store'
import { isEnvironment } from '@/utils/environment'

import LocaleDropdown from '@/components/LocaleDropdown.vue'
import ProfileDropdown from '@/components/auth/ProfileDropdown.vue'
import NavigationMenuLink from '@/components/layout/navigation/NavigationMenuLink.vue'

const authStore = useAuthStore()
const alarmStore = useAlarmStore()
const route = useRoute()

// The testing environment is called mptesting
const environment = window.environment.replace('mp', '').replace('development', 'dev')
const isNavigationOpen = ref(false)
const navBar = ref<HTMLDivElement>()

const alarmRoute = computed<RouteLocationRaw | null>(() => {
    if (route.name?.toString().includes('packaging')) {
        return { name: 'packaging.alarms' }
    } else if (route.name?.toString().includes('dmr')) {
        return { name: 'dmr.alarms' }
    } else if (route.name?.toString().includes('dm.')) {
        return { name: 'dm.alarms' }
    }

    if (authStore.hasLicense([LicenseType.PackagingModule, LicenseType.PackagingModuleReadonly])) {
        return { name: 'packaging.alarms' }
    } else if (authStore.hasLicense(LicenseType.DamageReportModule)) {
        return { name: 'dmr.alarms' }
    } else if (authStore.hasLicense(LicenseType.DeliveryManagementModule)) {
        return { name: 'dm.alarms' }
    }

    return null
})

function toggleNavigation() {
    isNavigationOpen.value = !isNavigationOpen.value
}

function closeNavigation() {
    isNavigationOpen.value = false
}

onClickOutside(navBar, closeNavigation)
</script>

<template>
    <div class="mx-auto max-w-[2200px] lg:p-6">
        <div
            ref="navBar"
            class="relative grid grid-cols-2 items-center rounded-xl bg-transparent px-4 dark:shadow-dark-700 lg:grid-cols-[1fr_2fr_1fr] lg:bg-gradient-to-r lg:from-primary-500 lg:via-primary-500 lg:to-green-500 lg:px-6 lg:shadow-xl lg:dark:from-dark-800 lg:dark:via-dark-800 lg:dark:to-primary-500"
        >
            <div
                v-if="isEnvironment(['development', 'staging', 'mptesting'])"
                class="environment-banner rounded-l-xl"
                :class="environment"
            >
                <div v-text="environment" />
            </div>

            <div>
                <RouterLink class="w-auto py-2.5" to="/">
                    <img
                        alt="myPallet Logo"
                        class="order-1 inline-flex h-[20px] lg:h-[30px]"
                        src="https://imagedelivery.net/Dw1gWFsgRYaCpgB4c6PD1Q/94800510-1c2c-43d1-9663-03353d2a7000/public"
                    />
                </RouterLink>
            </div>

            <nav
                :class="{
                    hidden: !isNavigationOpen,
                    invisible: authStore.user?.reset_password === true,
                }"
                class="order-3 col-span-2 mt-4 w-full space-y-3 rounded-xl bg-primary-700/30 p-6 text-xs lg:order-2 lg:col-span-1 lg:mt-0 lg:flex lg:justify-center lg:space-x-3 lg:space-y-0 lg:bg-transparent lg:p-0 lg:py-2.5"
            >
                <NavigationMenuLink
                    v-if="!authStore.company.options.dashboardDisabled"
                    :to="authStore.hasLicense(moduleLicenses) ? { name: 'dashboard' } : ''"
                    exact
                    translation="dashboard"
                    requires-module-license
                    :activated="
                        authStore.hasLicense([
                            LicenseType.PackagingModule,
                            LicenseType.PackagingModuleReadonly,
                        ])
                    "
                >
                    <mdi:view-dashboard />
                </NavigationMenuLink>
                <NavigationMenuLink
                    :to="{ name: 'packaging' }"
                    translation="packaging"
                    :activated="
                        authStore.hasLicense([
                            LicenseType.PackagingModule,
                            LicenseType.PackagingModuleReadonly,
                        ])
                    "
                >
                    <mdi:warehouse />
                </NavigationMenuLink>
                <NavigationMenuLink
                    :to="{ name: 'dmr' }"
                    translation="damageReports"
                    :activated="authStore.hasLicense(LicenseType.DamageReportModule)"
                >
                    <mdi:truck-cargo-container />
                </NavigationMenuLink>
                <NavigationMenuLink
                    :to="{ name: 'dr' }"
                    translation="driverReports"
                    :activated="authStore.hasLicense(LicenseType.DriverReportModule)"
                >
                    <mdi:timer-marker-outline />
                </NavigationMenuLink>
                <NavigationMenuLink
                    v-slot="{ isActive }"
                    :to="{ name: 'dm' }"
                    translation="deliveryManagement"
                    :activated="authStore.hasLicense(LicenseType.DeliveryManagementModule)"
                >
                    <mdi:map-marker />
                    <span
                        :class="isActive ? 'bg-green-500' : 'bg-primary-500'"
                        class="gro absolute top-[3px] right-0 left-0 mx-auto flex h-[12px] w-[12px] items-center justify-center rounded-full text-[8px] transition group-hover:bg-green-500 group-active:bg-primary-500"
                    >
                        <mdi:package-variant-closed />
                    </span>
                </NavigationMenuLink>
                <NavigationMenuLink
                    :to="{ name: 'pod' }"
                    translation="pod"
                    :activated="authStore.hasLicense(LicenseType.PODModule)"
                >
                    <mdi:file-document-check-outline />
                </NavigationMenuLink>
                <NavigationMenuLink
                    :to="
                        authStore.hasLicense(moduleLicenses) &&
                        !authStore.isReadonly(LicenseType.PackagingModuleReadonly)
                            ? { name: 'settings' }
                            : ''
                    "
                    translation="settings"
                    requires-module-license
                    :activated="
                        authStore.hasLicense(moduleLicenses) &&
                        !authStore.isReadonly(LicenseType.PackagingModuleReadonly)
                    "
                >
                    <mdi:cog />
                </NavigationMenuLink>
            </nav>

            <div class="order-2 flex h-full items-start justify-end lg:w-full">
                <RouterLink
                    v-if="authStore.companyId && alarmRoute"
                    :to="alarmRoute"
                    class="relative mr-2 flex h-full cursor-pointer items-center p-2.5 text-primary-100 hover:bg-white/10"
                >
                    <mdi:bell v-if="alarmStore.counts.total > 0" class="h-5 w-5" />
                    <mdi:bell-outline v-else class="h-5 w-5" />

                    <span
                        v-if="alarmStore.counts.total > 0"
                        class="absolute right-0 top-1 flex h-5 min-w-[20px] items-center justify-center rounded-full bg-red-500 px-0.5 text-center text-xs"
                        v-text="alarmStore.counts.total"
                    />
                </RouterLink>

                <LocaleDropdown class="text-primary-100" />
                <ProfileDropdown />
                <button
                    class="-mr-2 flex h-full w-10 items-center justify-center lg:hidden"
                    @click.prevent="toggleNavigation"
                >
                    <mdi:menu v-if="!isNavigationOpen" class="text-primary-100" />
                    <mdi:close v-else class="text-primary-100" />
                </button>
            </div>
        </div>
    </div>
</template>
