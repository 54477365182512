import { MinimalTransportObject } from '@/types/transport-object'
import { Company, Location, LocationPoint } from '@/types/company'
import { FieldPreference, Model, uuid } from '@/types/general'
import { MinimalUser } from '@/types/user'

export type ReportFilter = 'all' | 'done' | 'enRoute' | 'settled' | 'invoiced'

export interface Report extends Model {
    id: uuid
    events: ReportEvent[]
    user: MinimalUser
    location: Location
    company: Omit<Company, 'locations'>
    haulierLocation?: Location
    haulierCompany?: Omit<Company, 'locations'>
    tours: { id: uuid; name: string | null }[]
    tourType: number
    referenceNumber?: string
    startedAt: string
    endedAt: string | null
    vehicle?: MinimalTransportObject
    restingTime: number
    settlementTemplateName: string | null
    settlementTemplateRates: {
        rate: SettlementTemplateRate | null
        duration: number
    }[]
    damageReportPickupId: uuid | null
    damageReportDropOffId: uuid | null
    unitRegistrationNumbers: Record<uuid, string>
}

export interface ReportEvent extends Model {
    id: uuid
    actions: EventAction[]
    customerLocation?: Location
    customerCompany?: Company
    tourEvent: { id: uuid; shipmentId: uuid } | null
    tourId: uuid | null
    location: LocationPoint
    type: number
    waitTime: number
    arrivalNote?: string
    departureNote?: string
    referenceNumber: string | null
    kilometerCount: number
    kilometerCountImage: string
    shipmentLoaded: boolean
    shipmentUnloaded: boolean
    images: EventImage[]
    address?: string
    startedAt: string
    endedAt: string | null
}

export interface MinimalReport {
    id: uuid
    settlementId?: uuid
    user: MinimalUser
    location: Location
    eventCount: number
    company: Omit<Company, 'locations'>
    haulierCompany?: Omit<Company, 'locations'>
    haulierLocation?: Location
    tourType: number
    referenceNumber?: string
    startedAt: string
    vehicle?: MinimalTransportObject
}

export enum TourType {
    Domestic = 0,
    Export = 1,
    LineHaul = 2,
}

export enum EventType {
    ReportStart = 0,
    ReportEnd = 1,
    Customer = 2,
    Traffic = 3,
    Break = 4,
    Other = 5,
    Resting = 6,
    StartTour = 7,
}

export interface EventImage {
    id: string
    type: EventImageType
    path: string
}

export interface EventAction {
    actionableId: uuid
    type: EventActionType
    damageReportId: uuid | null
}

export enum EventImageType {
    Arrival,
    Departure,
}

export enum EventActionType {
    PackagingTransaction = 0,
    PODReceipt = 1,
    AttachUnit = 2,
    DetachUnit = 3,
}

export type weekday =
    | 'monday'
    | 'tuesday'
    | 'wednesday'
    | 'thursday'
    | 'friday'
    | 'saturday'
    | 'sunday'

export interface DriverReportPreferences {
    tourType: FieldPreference
    referenceNumber: FieldPreference
    kilometerCountImage: FieldPreference
    tourPicker: FieldPreference
    vehicle: FieldPreference
    units: FieldPreference
    kilometerCount: FieldPreference
    departureNote: FieldPreference
}

export enum SettlementTemplateType {
    Timebased = 0,
    Hourbased = 1,
}

export interface SettlementTemplateRate {
    id: uuid
    name: string
    color: string
}

export interface Timeslot {
    start: number
    end: number
    rate: uuid
}

export interface SettlementTemplate extends Model {
    id: uuid
    name: string
    type: SettlementTemplateType
    rates: SettlementTemplateRate[]
    days: Record<weekday, Timeslot[]>
}
