<script lang="ts" setup>
import { inject, ref } from 'vue'

import { radioGroupValueChangedKey, radioGroupPropsKey } from '@/types/inputs'

export interface Props {
    value: string | number | boolean
    label: string
}

const props = defineProps<Props>()

const focused = ref(false)

const radioData = inject(radioGroupPropsKey)
const valueChanged = inject(radioGroupValueChangedKey)

function onChange() {
    if (valueChanged) valueChanged(props.value)
}
</script>

<template>
    <div
        class="relative flex w-full cursor-pointer select-none justify-center items-center border border-green-500 py-1 px-5 transition-colors first:rounded-l-lg first:border first:border-r-0 last:rounded-r-lg last:border last:border-l-0"
        :class="{
            'bg-green-500 text-white': radioData?.modelValue === props.value,
            'ring-2 ring-inset ring-green-400': focused,
        }"
    >
        <input
            class="absolute left-0 top-0 h-full w-full cursor-pointer rounded-none opacity-0"
            type="radio"
            :value="props.value"
            :name="radioData?.name ?? radioData?.id"
            :disabled="radioData?.disabled"
            @focus="focused = true"
            @blur="focused = false"
            @change="onChange"
        />

        {{ props.label }}
    </div>
</template>
