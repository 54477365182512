import { ComputedRef, InjectionKey, Ref } from 'vue'

import { LicenseType } from '@/types/company'

export interface CurrentRouteModule {
    name: string
    license: LicenseType
}

// Injection keys used with Vue's provide/injects
export const formErrorsKey: InjectionKey<Ref<Record<string, string[]>>> = Symbol('form-errors')
export const currentRouteModuleKey: InjectionKey<ComputedRef<CurrentRouteModule | undefined>> =
    Symbol('current-route-module')
export const imageViewerOpenKey: InjectionKey<Ref<boolean>> = Symbol('image-viewer-open')
