<script lang="ts">
export interface Props extends /** @vue-ignore */ InputHTMLAttributes {
    modelValue: string | null
    id?: string
    disabled?: boolean
    groupClass?: HTMLClass
    error?: string
    label?: string
    helper?: string
    autofocus?: boolean
    defaultCountry?: LimitedCountryCode
}

export default { inheritAttrs: false }
</script>

<script lang="ts" setup>
import { parsePhoneNumber } from 'libphonenumber-js'
import min from 'libphonenumber-js/metadata.min.json'
import { ref, computed, watch, nextTick, onBeforeMount, InputHTMLAttributes } from 'vue'
import { useI18n } from 'vue-i18n'
import { MaskOptions } from 'maska'

import { locales } from '@/i18n/i18n'
import { DropdownOption, HTMLClass, MyInputRef } from '@/types/inputs'
import { countryCodes, LimitedCountryCode } from '@/utils/country-codes'

import MyErrorMessage from '@/components/my-components/form/MyErrorMessage.vue'
import MyHelperMessage from '@/components/my-components/form/MyHelperMessage.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MyInputLabel from '@/components/my-components/form/MyInputLabel.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'

const props = withDefaults(defineProps<Props>(), { disabled: false })
const emit = defineEmits<{
    (e: 'update:modelValue', value: string | number | null): void
    (e: 'focus', event: FocusEvent): void
    (e: 'blur', event: FocusEvent): void
}>()

const { locale } = useI18n()
const inputRef = ref<MyInputRef>()
const inputValue = ref('')
const isoCode = ref<LimitedCountryCode>('DK')
const focused = ref(false)
const fullNumber = computed(() => {
    const stripped = inputValue.value.replace(/\s/g, '')
    // We'll avoid returning "+45" if no value has been entered
    if (stripped.length === 0) return ''

    return `+${countryCode.value}${stripped}`
})
const countryCode = computed(() => countryCodes[isoCode.value].countryCode)
const countryCodeOptions = computed<DropdownOption[]>(() =>
    Object.entries(countryCodes).map(([iso, { name }]) => ({
        label: name,
        value: iso,
    })),
)
const inputMask = computed<MaskOptions>(() => {
    const [, , , , patterns] = min.countries[isoCode.value] ?? [0, 0, 0, 0, 0]
    if (patterns === 0) {
        return { mask: '##############' }
    }

    const regex = new RegExp(/\(\\d(?:{(\d+(?:,\d+)?)})?\)/g)
    const mask = (patterns as string[][]).map(([pattern]) => {
        return pattern
            .replace(regex, (_, count?: string) => {
                if (count?.includes(',')) {
                    // If we encounter \d{3,4} we take the last digit
                    count = count.split(',').slice(-1)[0]
                }
                return new Array(parseInt(count || '0', 10)).fill('#').join('') + ' '
            })
            .trim()
    })

    // Maska can't handle a single value in an array
    return { mask: mask.length === 1 ? mask[0] : mask }
})

async function parseNumber() {
    if (!props.modelValue) return
    if (props.modelValue === `+${countryCode.value}${inputValue.value}`) return

    try {
        const number = parsePhoneNumber(props.modelValue)
        isoCode.value = (number.country as LimitedCountryCode) || isoCode.value
        // We need to wait for next tick because watch for isoCode is called
        await nextTick()
        inputValue.value = number.nationalNumber
        emit('update:modelValue', fullNumber.value)
    } catch (e) {
        isoCode.value = locales[locale.value].flag.toUpperCase() as LimitedCountryCode
    }
}

async function inputChanged() {
    const oldValue = fullNumber.value
    await nextTick()
    if (oldValue != fullNumber.value) {
        emit('update:modelValue', fullNumber.value)
    }
}

function inputFocused(e: FocusEvent) {
    emit('focus', e)
    focused.value = true
}

function inputBlurred(e: FocusEvent) {
    emit('blur', e)
    focused.value = false
}

watch(isoCode, () => {
    if (inputRef.value) {
        inputRef.value!.input.focus()
        inputValue.value = ''
        emit('update:modelValue', fullNumber.value)
    }
})

watch(
    () => props.modelValue,
    (value) => {
        if (value !== fullNumber.value) {
            parseNumber()
        }
    },
)

onBeforeMount(() => {
    if (props.modelValue?.includes('+')) {
        parseNumber()
        return
    }

    inputValue.value = props.modelValue || ''
    if (props.defaultCountry) {
        isoCode.value = props.defaultCountry
    } else {
        isoCode.value = locales[locale.value].flag.toUpperCase() as LimitedCountryCode
    }
})

defineExpose({ focus: () => inputRef.value?.input.focus() })
</script>

<template>
    <div class="group group w-full" :class="props.groupClass">
        <MyInputLabel v-if="props.label" v-text="props.label" />
        <div
            class="flex rounded-xl"
            :class="{ 'cursor-not-allowed': props.disabled, 'shadow-md': focused }"
        >
            <MySelect
                v-model="isoCode"
                group-class="flex-shrink-0"
                :options="countryCodeOptions"
                :disabled="props.disabled"
                options-class="min-w-[250px]"
                inline-search
                :tabindex="-1"
            >
                <button
                    type="button"
                    class="relative z-10 flex w-full items-center space-x-2 rounded-l-xl border border-r-0 border-gray-300 py-2 pl-2 outline-none focus:ring-1 dark:border-transparent"
                    :class="{
                        'cursor-pointer bg-white dark:bg-dark-400': !props.disabled,
                        'bg-primary-50 dark:bg-dark-600': props.disabled,
                    }"
                >
                    <img
                        :alt="isoCode"
                        :src="`https://flagcdn.com/h20/${isoCode.toLowerCase()}.png`"
                        class="h-[24px] w-[40px] object-contain"
                        :class="{ 'opacity-60': props.disabled }"
                    />

                    <div class="text-sm">+{{ countryCode }}</div>
                </button>

                <template #option="{ option, selected }">
                    <div class="flex">
                        <img
                            :key="option.value"
                            :alt="option.value"
                            :src="`https://flagcdn.com/h20/${option.value.toLowerCase()}.png`"
                            class="mr-2 h-[24px] w-[40px] object-contain"
                        />

                        {{ option.label }}
                    </div>

                    <span
                        v-if="selected"
                        class="absolute inset-y-0 right-0 flex items-center pr-3 text-primary-600"
                    >
                        <mdi:check aria-hidden="true" class="h-5 w-5" />
                    </span>
                </template>
            </MySelect>

            <MyInput
                ref="inputRef"
                v-model="inputValue"
                v-maska:[inputMask]
                class="rounded-l-none border-l-0 pl-2 disabled:border-l-0"
                v-bind="$attrs"
                :disabled="props.disabled"
                :autofocus="props.autofocus"
                shadow=""
                @blur="inputBlurred"
                @focus="inputFocused"
                @input="inputChanged"
            />
        </div>
        <MyHelperMessage :helper="props.helper" />
        <MyErrorMessage :error="props.error" :input-name="props.name" :label="props.label" />
    </div>
</template>
