<script lang="ts" setup>
import { useRoute } from 'vue-router'

import StatementsTable from '@/components/packaging/statement/StatementsTable.vue'

const route = useRoute()
</script>

<template>
    <RouterView v-if="route.params.statementId" />

    <StatementsTable v-else />
</template>
