<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'

import { datetime } from '@/utils/dates'
import {
    ShipmentType,
    EventType,
    TourEvent,
    IncludedDeviation,
    IncludedTicket,
} from '@/types/delivery-management'

export interface Props {
    event: TourEvent
    deviations?: IncludedDeviation[]
    tickets?: IncludedTicket[]
    index: number
}

const { t } = useI18n()
const props = defineProps<Props>()

const shipmentTypes = computed<Record<ShipmentType, string>>(() => {
    return {
        [ShipmentType.Delivery]: t('delivery'),
        [ShipmentType.Return]: t('return'),
        [ShipmentType.Pickup]: t('pickup'),
    }
})

const eventTypes = computed<Record<EventType, string>>(() => {
    return {
        [EventType.Shipment]: t('shipment'),
        [EventType.Bridge]: t('bridge'),
        [EventType.Ferry]: t('ferry'),
    }
})

const isReturnOrPickup = computed(() => {
    if (!props.event.shipment) return false
    if (props.event.shipment.type === ShipmentType.Pickup) return true
    if (props.event.shipment.type === ShipmentType.Return) return true

    return false
})

const minutesDelayed = computed(() => {
    if (!props.event.liveEstimatedAt || !props.event.estimatedAt) {
        return 0
    }

    return dayjs(props.event.liveEstimatedAt).diff(dayjs(props.event.estimatedAt), 'minute')
})

const shipmentIsEarly = computed(() => minutesDelayed.value < 0)
</script>

<template>
    <div class="mr-10 flex flex-col items-end text-right">
        <span class="font-semibold mr-3.5" v-text="index + 1" />

        <mdi:package-up
            v-if="event.type === EventType.Shipment && isReturnOrPickup"
            class="h-10 w-10 text-primary-300"
        />
        <mdi:package-down
            v-else-if="event.type === EventType.Shipment"
            class="h-10 w-10 text-primary-300"
        />
        <mdi:ferry v-else-if="event.type === EventType.Ferry" class="h-10 w-10 text-primary-300" />
        <mdi:bridge
            v-else-if="event.type === EventType.Bridge"
            class="h-10 w-10 text-primary-300"
        />

        <div
            v-if="event.shipment && event.type === EventType.Shipment"
            class="flex space-x-1 justify-end"
        >
            <span
                v-if="event.shipment.unsuccessful"
                class="text-sm font-semibold"
                v-text="t('unsuccessful')"
            />
            <span class="text-sm font-semibold" v-text="shipmentTypes[event.shipment?.type]" />
        </div>

        <span v-if="event.waypoint" v-text="eventTypes[event.type]" />
        <span v-else-if="event.handledAt" v-text="datetime(event.handledAt)" />
        <template v-else-if="event.estimatedAt || event.shipment?.plannedAt">
            <div class="flex items-center justify-end">
                <span class="mr-2 text-sm font-semibold text-blue-400">ETA</span>
                <span v-if="event.estimatedAt" v-text="datetime(event.estimatedAt)" />
                <span
                    v-else-if="event.shipment?.plannedAt"
                    v-text="datetime(event.shipment?.plannedAt)"
                />
            </div>
            <div
                v-if="event.liveEstimatedAt && minutesDelayed !== 0"
                v-tooltip.right="t('liveEstimationExplanation')"
                :class="[
                    'flex items-center text-sm -mt-1 font-semibold group relative',
                    shipmentIsEarly ? 'text-green-500' : 'text-red-500',
                ]"
            >
                <span class="relative flex h-3 w-3 mr-1">
                    <span
                        :class="[
                            'animate-ping absolute left-0 right-0 top-0 bottom-0 m-auto h-2.5 w-2.5 rounded-full opacity-75',
                            shipmentIsEarly ? 'bg-green-500' : 'bg-red-500',
                        ]"
                    />
                    <span
                        :class="[
                            'relative inline-flex rounded-full h-full w-full ',
                            shipmentIsEarly ? 'bg-green-400' : 'bg-red-400',
                        ]"
                    />
                </span>

                <div
                    class="absolute opacity-0 group-hover:opacity-100 transition-all group-hover:translate-y-5 right-0"
                    v-text="datetime(event.liveEstimatedAt)"
                />

                <span>
                    {{
                        shipmentIsEarly
                            ? t('minutesEarly', { count: Math.abs(minutesDelayed) })
                            : t('minutesLate', { count: Math.abs(minutesDelayed) })
                    }}
                </span>
            </div>
        </template>
        <span v-else v-text="t('notDeliveredYet')" />
    </div>

    <div class="absolute top-0 right-0 mr-2 flex h-full flex-col items-center">
        <div
            v-if="event.shipment?.unsuccessful"
            class="h-6 w-6 flex-shrink-0 rounded-full bg-red-400"
        />

        <div
            v-else-if="event.shipment && (props.deviations || props.tickets)"
            class="h-6 w-6 flex-shrink-0 rounded-full"
            :class="{
                'border-[3px] border-dashed border-yellow-400': !event.handledAt,
                'bg-yellow-400': event.handledAt,
            }"
        />

        <div
            v-else
            class="h-6 w-6 flex-shrink-0 rounded-full"
            :class="{
                'border-[3px] border-dashed border-primary-400': !event.handledAt,
                'bg-primary-400': event.handledAt,
            }"
        />

        <div class="my-2 h-full w-0.5 bg-gray-500" />
    </div>
</template>
