<script lang="ts" setup>
import axios from 'axios'
import { onBeforeMount, ref, watch, computed, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { datetime } from '@/utils/dates'
import { Marker, ResourceResponse, uuid } from '@/types/general'
import { useAuthStore } from '@/stores/auth-store'
import { printElement } from '@/utils/print-element'
import { Deviation, DeviationState, ShipmentType } from '@/types/delivery-management'
import { image as imageHelper } from '@/utils/assets'
import { imageViewerOpenKey } from '@/types/global-injection-keys'
import { emitter } from '@/utils/mitt'
import { markerColors } from '@/utils/maps'

import MapComponent from '@/components/MapComponent.vue'
import UpdateTagsModal from '@/components/delivery-management/UpdateTagsModal.vue'
import UpdateStateModal from '@/components/delivery-management/UpdateStateModal.vue'
import AssignUserModal from '@/components/delivery-management/AssignUserModal.vue'
import MyMenuItem from '@/components/my-components/MyMenuItem.vue'
import DeviationLogs from '@/components/delivery-management/DeviationLogs.vue'
import EscalateDeviationModal from '@/components/delivery-management/EscalateDeviationModal.vue'
import ContentHeading from '@/components/layout/ContentHeading.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyMenu from '@/components/my-components/MyMenu.vue'

export interface Props {
    id?: string
    open?: boolean
}

const emit = defineEmits<{ (e: 'update:open', value: boolean): void }>()
const props = withDefaults(defineProps<Props>(), { open: true })

const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const authStore = useAuthStore()

const imageViewerOpen = inject(imageViewerOpenKey)!

const modalContent = ref<HTMLDivElement>()
const deviation = ref<Deviation | null>(null)
const escalateModalOpen = ref(false)
const assignModalOpen = ref(false)
const updateStateModalOpen = ref(false)
const updateTagsModalOpen = ref(false)
const loading = ref(false)

const deviationStates = computed<Record<DeviationState, string>>(() => {
    return {
        [DeviationState.Open]: t('open'),
        [DeviationState.Closed]: t('closed'),
        [DeviationState.Solved]: t('solved'),
    }
})

const markers = computed<Marker[]>(() => {
    const markerList: Marker[] = []
    if (!deviation.value) return markerList

    if (deviation.value.shipment) {
        const receivingLocation =
            deviation.value.shipment.addressLocation ??
            deviation.value.shipment.receivingLocation.location

        markerList.push({
            location: receivingLocation,
            content: t('receivingLocation'),
        })

        if (deviation.value.location) {
            markerList.push({
                location: deviation.value.location,
                content:
                    deviation.value.shipment.type === ShipmentType.Delivery
                        ? t('deliveryLocation')
                        : t('pickupLocation'),
                fillColor: markerColors.green,
            })
        }
    } else if (deviation.value.location) {
        markerList.push({
            location: deviation.value.location,
        })
    }

    return markerList
})

async function fetchDeviation(id?: uuid) {
    loading.value = true
    try {
        const response = await axios.get<ResourceResponse<Deviation>>(
            window.route('dm.company.deviations.show', {
                company: authStore.companyId,
                deviation: id ?? props.id!,
            }),
        )
        deviation.value = response.data.data
    } finally {
        if (authStore.user?.id === deviation.value?.user?.id) {
            emitter.emit('fetchAlarms')
        }

        loading.value = false
    }
}

function refetchDeviation() {
    emitter.emit('fetchDeviations')
    if (deviation.value) fetchDeviation(deviation.value.id)
}

function print() {
    printElement(modalContent.value!)
}

function onClose() {
    if (imageViewerOpen.value) return
    if (props.id) {
        emit('update:open', false)
    } else {
        router.replace({ name: 'dm.deviations', query: route.query })
    }
}

onBeforeMount(async () => {
    if (route.params.deviationId) fetchDeviation(route.params.deviationId as uuid)
    else if (props.id) fetchDeviation()
})
watch(() => props.id, fetchDeviation)
watch(
    () => route.params.deviationId,
    () => {
        if (route.params.deviationId) fetchDeviation(route.params.deviationId as uuid)
    },
)
</script>

<template>
    <MyModal :value="props.open" :max-width="1000" @close="onClose">
        <LoaderWrapper :visible="loading" />
        <div v-if="deviation" ref="modalContent">
            <div class="absolute right-0 space-x-2 px-6">
                <div v-if="authStore.companyId" class="flex space-x-2 print:hidden">
                    <MyButton icon plain scheme="light" size="small" @click="print()">
                        <mdi:printer />
                    </MyButton>

                    <MyMenu hide-arrow class="relative z-20" width="w-60">
                        <template #trigger>
                            <MyButton icon plain scheme="light" size="small">
                                <mdi:dots-vertical />
                            </MyButton>
                        </template>

                        <MyMenuItem
                            v-if="deviation.ticketId === null"
                            @click="escalateModalOpen = true"
                        >
                            <mdi:alert-box-outline class="mr-2" />
                            {{ t('escalateDeviation') }}
                        </MyMenuItem>

                        <MyMenuItem @click="assignModalOpen = true">
                            <mdi:account-alert class="mr-2" />
                            {{ t('assignUser') }}
                        </MyMenuItem>

                        <MyMenuItem
                            v-if="deviation.tour.shipperCompany?.id === authStore.companyId"
                            @click="updateStateModalOpen = true"
                        >
                            <mdi:sign-direction class="mr-2" />
                            {{ t('changeStatus') }}
                        </MyMenuItem>
                    </MyMenu>
                </div>
            </div>

            <section class="mb-6 grid grid-cols-2 gap-6 w-full">
                <div class="space-y-6">
                    <div class="flex items-center">
                        <span
                            class="text-xl font-semibold uppercase text-primary-400"
                            v-text="t('deviation')"
                        />
                        <div v-if="deviation.number">
                            <span
                                class="text-xl font-semibold uppercase mx-1.5 text-primary-400"
                                v-text="'-'"
                            />
                            <span
                                class="text-xl font-semibold uppercase text-primary-400"
                                v-text="deviation.number"
                            />
                        </div>
                    </div>

                    <div class="flex flex-col">
                        <span
                            class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                            v-text="t('tags')"
                        />
                        <div class="group/item flex">
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="deviation.tags.map((tag) => t(tag.name)).join(', ') || '-'"
                            />

                            <span
                                class="cursor-pointer pl-3 text-gray-600 opacity-0 transition-opacity group-hover/item:opacity-100 dark:text-primary-50"
                            >
                                <mdi:pencil @click="updateTagsModalOpen = true" />
                            </span>
                        </div>
                    </div>

                    <div class="flex justify-between space-x-2">
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('date')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="datetime(deviation.createdAt)"
                            />
                        </div>
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('status')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="deviationStates[deviation.state]"
                            />
                        </div>
                    </div>

                    <div class="flex justify-between space-x-2">
                        <div class="flex w-full flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('tour')"
                            />

                            <RouterLink
                                :to="{
                                    name: 'dm.tours.show',
                                    params: { tourId: deviation.tour.id },
                                }"
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="deviation.tour.name"
                            />
                        </div>
                    </div>

                    <div class="flex justify-between space-x-2">
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('shipment')"
                            />

                            <RouterLink
                                v-if="deviation.shipment"
                                :to="{
                                    name: 'dm.shipments.show',
                                    params: { shipmentId: deviation.shipment.id },
                                }"
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="deviation.shipment?.shipmentNumber ?? t('view')"
                            />
                            <span
                                v-else
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                            >
                                -
                            </span>
                        </div>
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('references')"
                            />

                            <span
                                class="text-sm font-semibold text-primary-500 dark:text-primary-50"
                                v-text="deviation?.shipment?.references ?? '-'"
                            />
                        </div>
                    </div>

                    <div class="flex justify-between space-x-2">
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('agent')"
                            />

                            <div class="flex items-center space-x-2 mt-2">
                                <img
                                    v-if="deviation.user?.profileImage"
                                    :alt="deviation.user?.name"
                                    class="w-8 rounded-full object-cover"
                                    :src="imageHelper(deviation.user.profileImage)"
                                />

                                <span
                                    class="text-sm font-semibold text-primary-500 dark:text-primary-50"
                                    v-text="deviation.user?.name ?? '-'"
                                />
                            </div>
                        </div>
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('ticket')"
                            />
                            <RouterLink
                                v-if="deviation.ticketId"
                                :to="{
                                    name: 'dm.tickets.show',
                                    params: { ticketId: deviation?.ticketId },
                                }"
                                class="text-md uppercase font-semibold text-primary-500 dark:text-primary-100"
                                v-text="t('view')"
                            />

                            <span
                                v-else
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                            >
                                -
                            </span>
                        </div>
                    </div>

                    <div
                        v-if="deviation.shipment"
                        class="mx-auto flex w-full break-inside-avoid flex-col justify-center"
                    >
                        <span
                            class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                            v-text="t('receiver')"
                        />
                        <div
                            class="flex flex-col h-14 mt-1 text-primary-500 rounded-t-xl bg-primary-50 px-3 py-2 dark:bg-dark-500 print:px-0"
                        >
                            <div class="flex">
                                <span
                                    class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                    v-text="deviation.shipment.receivingCompany.name"
                                />
                                <div
                                    v-if="
                                        deviation.shipment.receivingLocation.name !==
                                        deviation.shipment.receivingCompany.name
                                    "
                                    class="flex items-center"
                                >
                                    <span class="mx-1 dark:text-primary-100">-</span>
                                    <span
                                        class="text-sm dark:text-primary-100"
                                        v-text="deviation.shipment.receivingLocation.name"
                                    />
                                </div>
                            </div>

                            <span
                                v-if="deviation.shipment.address"
                                class="text-xs dark:text-primary-100"
                                v-text="deviation.shipment.address"
                            />
                            <span v-else class="text-xs dark:text-primary-100">
                                {{
                                    deviation.shipment.receivingLocation.address +
                                    ', ' +
                                    deviation.shipment.receivingLocation.city +
                                    ' ' +
                                    deviation.shipment.receivingLocation.zipcode +
                                    ', ' +
                                    deviation.shipment.receivingLocation.country
                                }}
                            </span>
                        </div>

                        <MapComponent
                            :markers="markers"
                            class="relative h-[200px] rounded-b-xl shadow-xl print:shadow-none overflow-hidden"
                        />
                    </div>

                    <MapComponent
                        v-else-if="markers.length > 0"
                        :markers="markers"
                        class="relative h-[200px] rounded-b-xl shadow-xl print:shadow-none overflow-hidden"
                    />

                    <div class="flex justify-between space-x-2">
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('driver')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="deviation.driver?.name ?? '-'"
                            />
                        </div>

                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('haulier')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="deviation.tour.haulierCompany?.name ?? '-'"
                            />
                        </div>
                    </div>

                    <div
                        v-if="Object.values(deviation.colliNumbers).length > 0"
                        class="flex flex-col"
                    >
                        <span
                            class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                            v-text="t('colli')"
                        />

                        <div v-for="colli in deviation.colliNumbers" :key="colli" v-text="colli" />
                    </div>
                </div>

                <div>
                    <ContentHeading class="text-xl font-bold" v-text="t('logs')" />

                    <DeviationLogs
                        class="mt-4"
                        :model-id="deviation.id"
                        :logs="deviation.logs"
                        :colli-numbers="deviation.colliNumbers"
                        :is-ticket="false"
                        @log-added="fetchDeviation(route.params.deviationId as uuid | undefined)"
                    />
                </div>
            </section>

            <EscalateDeviationModal
                v-model="escalateModalOpen"
                :deviation="deviation"
                @deviation-escalated="fetchDeviation(route.params.deviationId as uuid | undefined)"
            />

            <AssignUserModal
                v-if="deviation && assignModalOpen"
                v-model="assignModalOpen"
                :deviation-id="deviation.id"
                :current-agent-id="deviation.user?.id"
                @saved="refetchDeviation"
                @close="assignModalOpen = false"
            />

            <UpdateStateModal
                v-if="deviation && updateStateModalOpen"
                v-model="updateStateModalOpen"
                :deviation="deviation"
                @saved="refetchDeviation"
                @close="updateStateModalOpen = false"
            />

            <UpdateTagsModal
                v-if="deviation && updateTagsModalOpen"
                v-model="updateTagsModalOpen"
                :deviation="deviation"
                @saved="refetchDeviation"
                @close="updateTagsModalOpen = false"
            />
        </div>

        <h1
            v-if="!deviation && !loading"
            class="my-auto text-center text-4xl font-bold"
            v-text="t('deviationNotFound')"
        />
    </MyModal>
</template>
