import { Model, uuid } from '@/types/general'
import { ReportAlarm, Template } from '@/types/damage-report'
import { MinimalUser } from '@/types/user'

export interface MinimalTransportObject extends Model {
    id: uuid
    active: boolean
    type: TransportObjectType
    companyId: uuid
    registrationNumber?: string
    nickname?: string
    companyLogo?: string | null
    companyName?: string
    templateId: uuid | null
    templateName: string | null
    ownerCompanyId: uuid | null
}

export interface TransportObject extends MinimalTransportObject {
    department?: string
    email?: string
    freightWeight: number
    manufacturer?: string
    model?: string
    name?: string
    note?: string
    registrationDate?: string
    service?: string
    vin?: string
    weight?: number
    width: number
    length: number
    phoneNumber?: string
    ownerName?: string
    height: number
    maxEur?: number
    maxLdr?: number
    maxM3?: number
    mot?: string
    tir?: string
    updatedAt: string
    templateName: string | null
    templateDeleted: boolean | null
    ownerCompany: { id: uuid; name: string } | null
}

export interface ExtendedTransportObject extends TransportObject {
    incidents: ReportIncident[] | null
    template: Template | null
    incidentAlarms: ReportAlarm[]
}

export interface ReportIncident {
    id: uuid
    reportId: uuid
    masterTemplateSectionId: uuid
    type: IncidentType
    note: string | null
    fixedAt: string | null
    fixedNote: string | null
    fixedByUser: MinimalUser | null
    user: MinimalUser
    updatedAt: string
    createdAt: string
    images: string[]
}

export interface ReportIncidentImage {
    id: uuid
    reportIncidentId: uuid
    path: string
}

export enum IncidentType {
    Scratch = 0,
    Dent = 1,
    Hole = 2,
    Missing = 3,
}

export enum TransportObjectType {
    Vehicle = 0,
    Unit = 1,
}
