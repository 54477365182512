<script setup lang="ts">
import type { ResourceResponse } from '@/types/general'
import type { MinimalTransaction } from '@/types/transaction'

import axios from 'axios'
import { storeToRefs } from 'pinia'
import { onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@/stores/auth-store'
import { useDashboardStore } from '@/stores/dashboard-store'

import MyPanel from '@/components/my-components/MyPanel.vue'
import TransactionModal from '@/components/packaging/transactions/TransactionModal.vue'
import TransactionTablePopover from '@/components/packaging/transactions/TransactionTablePopover.vue'

const { t } = useI18n()
const open = ref(false)
const authStore = useAuthStore()

const { date, selectedLocation, startDate, endDate } = storeToRefs(useDashboardStore())
const transactions = ref<MinimalTransaction[]>([])
const selectedTransactionId = ref<string>()

const loading = ref(false)

function viewTransaction(id: string) {
    selectedTransactionId.value = id
    open.value = true
}

async function fetchTransactions() {
    loading.value = true

    const { data: response } = await axios.post<ResourceResponse<MinimalTransaction[]>>(
        window.route('company.dashboard.transactions-latest', {
            'per-page': 10,
            company: authStore.companyId,
        }),
        {
            startDate: startDate.value.toISOString(),
            endDate: endDate.value.toISOString(),
            filter: { location: selectedLocation.value },
        },
    )

    transactions.value = response.data

    loading.value = false
}

onMounted(fetchTransactions)

watch([date, selectedLocation], fetchTransactions)
</script>

<script lang="ts">
export default { inheritAttrs: false }
</script>

<template>
    <TransactionModal
        v-if="selectedTransactionId"
        :id="selectedTransactionId"
        v-model:open="open"
        @close="selectedTransactionId = undefined"
    />

    <MyPanel
        shadow
        :panel-class="$attrs.class as string"
        class="flex flex-col pb-6 pt-0"
        :title="t('latestTransactions')"
        :loading="loading"
    >
        <div class="mb-6 grow border-t border-b border-primary-200">
            <table class="w-full">
                <thead>
                    <tr
                        class="border-b border-primary-200 bg-primary-100 text-left text-xs dark:border-dark-600 dark:bg-dark-500"
                    >
                        <th
                            class="p-2.5 font-semibold"
                            v-text="t('receiving', { property: t('company') })"
                        />
                        <th
                            class="border-x border-primary-200 p-2.5 font-semibold"
                            v-text="t('receiving', { property: t('location') })"
                        />
                        <th
                            class="border-x border-primary-200 p-2.5 font-semibold"
                            v-text="t('receiving', { property: t('user') })"
                        />
                        <th
                            class="border-x border-primary-200 p-2.5 font-semibold"
                            v-text="t('received')"
                        />
                        <th
                            class="border-x border-primary-200 p-2.5 font-semibold"
                            v-text="t('delivered')"
                        />
                        <th
                            class="border-x border-primary-200 p-2.5 font-semibold"
                            v-text="t('total')"
                        />
                        <th class="p-2.5 font-semibold" />
                    </tr>
                </thead>
                <tbody v-if="transactions">
                    <tr
                        v-for="transaction in transactions"
                        :key="transaction.id"
                        class="border-t border-primary-200 first:border-t-0 odd:bg-primary-100 dark:odd:bg-dark-400"
                    >
                        <td
                            class="truncate border-r border-primary-200 p-2.5"
                            v-text="transaction.receivingCompany.name"
                        />
                        <td
                            class="truncate border-r border-primary-200 p-2.5"
                            v-text="transaction.receivingLocation.name"
                        />
                        <td
                            class="truncate border-r border-primary-200 p-2.5"
                            v-text="
                                transaction.receivingUser?.name ??
                                transaction.details?.receiverName ??
                                '-'
                            "
                        />
                        <td class="truncate border-r border-primary-200 p-2.5 text-center">
                            <TransactionTablePopover
                                :row="transaction"
                                :value="transaction.productsReceived"
                            />
                        </td>
                        <td class="truncate border-r border-primary-200 p-2.5 text-center">
                            <TransactionTablePopover
                                :row="transaction"
                                :value="transaction.productsDelivered"
                            />
                        </td>
                        <td class="truncate border-r border-primary-200 p-2.5 text-center">
                            <TransactionTablePopover
                                :row="transaction"
                                :value="transaction.productsTotal"
                            />
                        </td>
                        <td class="p-2.5 pr-1.5" @click="viewTransaction(transaction.id)">
                            <button class="cursor-pointer px-4 py-2 text-xs hover:text-blue-500">
                                <mdi:eye />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div
                v-if="!transactions"
                class="p-6 text-center text-sm font-bold uppercase text-primary-400"
                v-text="t('noDataToShow')"
            />
        </div>
        <div class="text-center">
            <RouterLink
                class="text-sm font-semibold text-blue-500 underline decoration-2 underline-offset-2"
                :to="{ name: 'packaging.transactions' }"
                v-text="t('goTo', { to: t('transactions') })"
            />
        </div>
    </MyPanel>
</template>
