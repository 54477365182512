<script lang="ts" setup>
import { HTMLAttributes } from 'vue'
import { useI18n } from 'vue-i18n'

import { image as imageHelper } from '@/utils/assets'
import { MasterTemplate } from '@/types/damage-report'
import { transportObjectTypes } from '@/utils/type-translations'

export interface Props extends /** @vue-ignore */ HTMLAttributes {
    masterTemplate: MasterTemplate
}

const props = withDefaults(defineProps<Props>(), {})
const { t } = useI18n()
</script>

<template>
    <div
        class="h-auto w-[238px] rounded-lg bg-primary-50 shadow hover:cursor-pointer hover:opacity-70 hover:shadow-md dark:bg-dark-500"
    >
        <img
            v-if="props.masterTemplate.primaryImage?.path"
            :src="imageHelper(props.masterTemplate.primaryImage.path)"
            class="h-36 w-full max-w-sm rounded-t-lg object-contain p-3 pb-0"
        />

        <div class="flex h-1/5 items-center justify-between p-3">
            <span
                class="font-semibold uppercase text-primary-400"
                v-text="props.masterTemplate.name"
            />
            <span
                class="text-xs font-semibold uppercase text-primary-500"
                v-text="t(transportObjectTypes[props.masterTemplate.type])"
            />
        </div>
    </div>
</template>
