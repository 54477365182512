export function localAsset(path: string): string {
    return window.localAssetUrl + path
}

export function asset(path: string): string {
    if (path.startsWith('https://')) {
        return path
    }

    return window.assetUrl + path
}

export function image(path: string, variant = 'thumbnail'): string {
    if (path.startsWith('https://') || path.startsWith('blob:')) {
        return path
    }

    const assetUrl = import.meta.env.VITE_CLOUDFLARE_IMAGES_ACCOUNT_URL

    return `${assetUrl}/${path}${variant ? '/' + variant : ''}`
}
