<script lang="ts" setup>
import type { SetOptional } from 'type-fest'

import { RouterLinkProps } from 'vue-router'

export interface Props extends /** @vue-ignore */ SetOptional<RouterLinkProps, 'to'> {
    current?: boolean
}

const props = defineProps<Props>()
</script>

<template>
    <template v-if="!props.current">
        <RouterLink
            :to="props.to!"
            class="cursor-pointer text-xs font-semibold uppercase text-primary-400 underline-offset-2 hover:underline"
            v-bind="$attrs"
        >
            <slot />
        </RouterLink>
        <mdi:chevron-right class="mx-2" />
    </template>
    <span
        v-else
        class="text-xs font-semibold uppercase last:text-primary-500 last:dark:text-primary-300"
    >
        <slot />
    </span>
</template>
