<script setup lang="ts">
import { notify } from '@kyvg/vue3-notification'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

interface Props {
    type?: string
    multiple?: boolean
    noBorder?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    type: 'image/*',
})

const emit = defineEmits<{
    (e: 'selected', files: File): void
    (e: 'selected:multiple', files: File[]): void
}>()
const error = ref('')
const failedExtension = ref()
const isActive = ref(false)
const fileInput = ref<HTMLInputElement>()
const { t } = useI18n()

function toggleActive() {
    isActive.value = !isActive.value
}

function handleDrop(event: DragEvent) {
    error.value = ''
    toggleActive()
    if (!event.dataTransfer || !event.dataTransfer.files) return

    if (!props.multiple && event.dataTransfer.files.length > 1) {
        error.value = 'multipleFileError'
        return
    }

    handleFileList(event.dataTransfer.files)
}

function handleChange() {
    error.value = ''

    if (!fileInput.value || !fileInput.value.files) return

    handleFileList(fileInput.value.files)

    fileInput.value.value = ''
}

function handleFileList(fileList: FileList) {
    const fileTypeArray: File[] = []
    const regexs = props.type.split(',').map((type) => new RegExp(type))

    Array.from(fileList).forEach((file) => {
        let success = false
        for (const regex of regexs) {
            if (regex.test(file.type)) {
                success = true
                break
            }
        }

        if (!success) {
            failedExtension.value = file.name.substring(
                file.name.lastIndexOf('.') + 1,
                file.name.length,
            )
            notify({
                title: t('unsupportedFileType', { filetype: failedExtension.value }),
                type: 'error',
            })
            return
        }

        fileTypeArray.push(file)
    })

    if (props.multiple) {
        emit('selected:multiple', fileTypeArray)
    } else {
        emit('selected', fileTypeArray[0])
    }
}
</script>

<template>
    <label
        v-bind="$attrs"
        ref="dropzone"
        :class="{
            'border-primary-50 bg-primary-300 text-primary-50': isActive,
            'border-primary-300 bg-transparent text-black': !isActive,
            'border-2 border-dashed': !props.noBorder,
        }"
        class="flex cursor-pointer flex-col items-center justify-center gap-2 transition-all"
        @dragenter.prevent="toggleActive"
        @dragleave.prevent="toggleActive"
        @dragover.prevent
        @drop.prevent="handleDrop"
    >
        <slot>
            <span class="font-semibold dark:text-gray-300" v-text="t('dragAndDropFiles')" />
            <span class="text-xs font-semibold dark:text-gray-500" v-text="t('or')" />
            <span
                :class="{
                    'bg-primary-50': isActive,
                    'bg-primary-300': !isActive,
                }"
                class="cursor-pointer rounded-lg py-2 px-5 text-black transition-all hover:bg-primary-200"
                v-text="t('selectFiles')"
            />
        </slot>

        <input
            ref="fileInput"
            type="file"
            :multiple="multiple"
            :accept="props.type"
            class="hidden"
            @change="handleChange()"
        />
    </label>
    <p v-if="error" class="py-2 px-3 text-xs font-semibold text-red-500" v-text="t(error)" />
</template>
