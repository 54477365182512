<script lang="ts" setup>
import { computed, onBeforeMount, ref, watch } from 'vue'

import { DropdownOption, HTMLClass } from '@/types/inputs'
import { countryCodes, CountryData, LimitedCountryCode } from '@/utils/country-codes'

import MySelect from '@/components/my-components/form/MySelect.vue'

export interface Props {
    modelValue: string
    disabled?: boolean
    groupClass?: HTMLClass
    error?: string
    label?: string
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void
    (e: 'change', value: CountryData): void
}>()

const isoCode = ref<LimitedCountryCode>('DK')
const countryCodeOptions = computed<DropdownOption[]>(() =>
    Object.entries(countryCodes).map(([iso, { name }]) => ({
        label: name,
        value: iso,
    })),
)

onBeforeMount(() => {
    if (!props.modelValue) return

    const country = Object.entries(countryCodes).find(([, { name }]) => {
        return name === props.modelValue
    })
    if (country) {
        isoCode.value = country[0] as LimitedCountryCode
    }
})

watch(isoCode, () => {
    if (isoCode.value) {
        emit('update:modelValue', countryCodes[isoCode.value]!.name)
        emit('change', { isoCode: isoCode.value, name: countryCodes[isoCode.value]!.name })
    }
})

watch(
    () => props.modelValue,
    () => {
        const country = Object.entries(countryCodes).find(([, { name }]) => {
            return name === props.modelValue
        })
        if (country) {
            isoCode.value = country[0] as LimitedCountryCode
        }
    },
)
</script>

<template>
    <MySelect
        v-model="isoCode"
        :label="props.label"
        group-class="flex-shrink-0"
        :options="countryCodeOptions"
        :disabled="props.disabled"
        options-class="min-w-[250px]"
        searchable
    >
        <template #option="{ option, selected }">
            <div class="flex">
                <img
                    :key="option.value"
                    :alt="option.value"
                    :src="`https://flagcdn.com/h20/${option.value.toLowerCase()}.png`"
                    class="mr-2 h-[24px] w-[40px] object-contain"
                />

                {{ option.label }}
            </div>

            <span
                v-if="selected"
                class="absolute inset-y-0 right-0 flex items-center pr-3 text-primary-600"
            >
                <mdi:check aria-hidden="true" class="h-5 w-5" />
            </span>
        </template>

        <template v-if="isoCode" #prefixIcon>
            <img
                :key="isoCode"
                :alt="isoCode"
                :src="`https://flagcdn.com/h20/${isoCode!.toLowerCase()}.png`"
                class="mr-2 h-[24px] w-[40px] object-contain"
            />
        </template>
    </MySelect>
</template>
