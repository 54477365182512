<script lang="ts" setup>
import { FileAttachment } from '@/types/general'
import { asset, image as imageHelper } from '@/utils/assets'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'

interface Props {
    file: FileAttachment
    uploading?: boolean
    removable?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'onRemoveClicked'): void
}>()
</script>

<template>
    <div class="relative rounded-lg overflow-hidden min-h-[8rem] max-h-[12rem]">
        <LoaderWrapper :visible="props.uploading" immediate />

        <slot name="overlay" />

        <span
            v-if="props.removable"
            class="absolute top-2 right-2 z-10 cursor-pointer text-red-500 drop-shadow-lg transition-all hover:text-red-400"
            @click="emit('onRemoveClicked')"
        >
            <mdi:trash-can />
        </span>

        <a
            v-if="file.name?.includes('.pdf') || file.path?.includes('.pdf')"
            class="relative h-full flex flex-col items-center justify-center bg-primary-300"
            :href="asset(file.path!)"
            target="_blank"
            rel="noopener noreferrer"
        >
            <span class="flex grow items-center justify-center text-center text-3xl text-red-400">
                <mdi:file-pdf-box />
            </span>

            <div
                v-truncate-tooltip
                class="mb-1 w-full flex-shrink-0 truncate px-3 text-center"
                v-text="file.name"
            />
        </a>
        <img
            v-else
            class="w-full h-full bg-primary-300 object-cover"
            :src="imageHelper(file.path!, 'thumbnail')"
            :alt="file.name || ''"
            :data-src="imageHelper(file.path!, 'public')"
        />
    </div>
</template>
