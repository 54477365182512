import { defineStore } from 'pinia'
import axios from 'axios'

import { WebRole } from '@/types/company'
import { DropdownOption } from '@/types/inputs'
import { ResourceResponse } from '@/types/general'
import { useAuthStore } from '@/stores/auth-store'
import { EmployeeRole } from '@/types/user'

interface EmployeeStoreState {
    appRoles: EmployeeRole[]
    fetchingAppRoles: boolean
    webRoles: WebRole[]
    fetchingWebRoles: boolean
}

export const useEmployeeRoleStore = defineStore('EmployeeRoleStore', {
    state(): EmployeeStoreState {
        return {
            appRoles: [],
            fetchingAppRoles: false,
            webRoles: [],
            fetchingWebRoles: false,
        }
    },

    getters: {
        appRoleOptions(): DropdownOption[] {
            return this.appRoles.map((role) => ({ value: role.id, label: role.name }))
        },
        webRoleOptions(): DropdownOption[] {
            return this.webRoles.map((role) => ({ value: role.id, label: role.name }))
        },
    },

    actions: {
        async fetchAppRoles(force = false) {
            if (this.appRoles.length > 0 && !force) return

            const authStore = useAuthStore()

            if (!authStore.companyId) return

            this.fetchingAppRoles = true
            const response = await axios.get<ResourceResponse<EmployeeRole[]>>(
                window.route('company.employee-roles.index', [authStore.companyId]),
            )

            this.appRoles = response.data.data
            this.fetchingAppRoles = false
        },
        async fetchWebRoles(force = false) {
            if (this.webRoles.length > 0 && !force) return

            const authStore = useAuthStore()
            if (!authStore.companyId) return

            this.fetchingWebRoles = true

            const response = await axios.get<ResourceResponse<WebRole[]>>(
                window.route('company.web-roles.index', authStore.companyId),
            )

            this.webRoles = response.data.data

            this.fetchingWebRoles = false
        },
    },
})
