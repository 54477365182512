<script lang="ts" setup>
import { Head } from '@vueuse/head'
import axios from 'axios'
import { computed, onBeforeMount, provide, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useIntervalFn } from '@vueuse/core'

import { useAuthStore } from '@/stores/auth-store'
import { useCompanyStore } from '@/stores/company-store'
import { LicenseType } from '@/types/company'
import {
    CurrentRouteModule,
    currentRouteModuleKey,
    imageViewerOpenKey,
} from '@/types/global-injection-keys'
import { useAlarmStore } from '@/stores/alarm-store'
import { useMittListener } from '@/hooks/use-mitt-listener'
import { isEnvironment } from '@/utils/environment'
import ResetPassword from '@/pages/auth/ResetPassword.vue'

import GuestLayout from '@/layouts/GuestLayout.vue'
import LoginLayout from '@/layouts/LoginLayout.vue'
import PageLayout from '@/layouts/PageLayout.vue'

import AdminNavigation from '@/components/layout/navigation/AdminNavigation.vue'
import LocaleDropdown from '@/components/LocaleDropdown.vue'
import Logout from '@/components/auth/Logout.vue'
import ModalContainer from '@/components/layout/ModalContainer.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyConfirmContainer from '@/components/my-components/MyConfirmContainer.vue'

const { t } = useI18n()
const authStore = useAuthStore()
const companyStore = useCompanyStore()
const alarmStore = useAlarmStore()
const route = useRoute()

const fetchingLicenses = ref(false)
const imageViewerOpen = ref(false)

const moduleRouteNames: Record<string, LicenseType> = {
    ecmr: LicenseType.ECMRModule,
    dmr: LicenseType.DamageReportModule,
    dr: LicenseType.DriverReportModule,
    packaging: LicenseType.PackagingModule,
    dm: LicenseType.DeliveryManagementModule,
    pod: LicenseType.PODModule,
}

const currentRouteModule = computed<CurrentRouteModule | undefined>(() => {
    const routeName = route.matched[0]?.name?.toString() ?? ''
    const moduleLicense = moduleRouteNames[routeName]

    if (!moduleLicense) return

    return { name: routeName, license: moduleLicense }
})

const joinMyPallet = computed(() => {
    if (!isEnvironment('local')) return false
    return authStore.user?.email.includes('@mypallet.')
})

async function attachUser() {
    await axios.post<string>(window.route('users.attach'))
    window.location.href = '/'
}

async function fetchLicenses() {
    fetchingLicenses.value = true
    await authStore.fetchLicenses()
    fetchingLicenses.value = false
}

onBeforeMount(async () => {
    await Promise.all([fetchLicenses(), companyStore.fetchLocations(), alarmStore.fetchCounts()])
})
useIntervalFn(alarmStore.fetchCounts, 60_000)
useMittListener('fetchAlarms', alarmStore.fetchCounts)

provide(currentRouteModuleKey, currentRouteModule)
provide(imageViewerOpenKey, imageViewerOpen)
</script>

<template>
    <Head>
        <title v-if="$route.meta?.title">
            {{ t('pageTitle', { title: t($route.meta.title) }) }}
        </title>
    </Head>

    <AdminNavigation />

    <PageLayout v-if="authStore.isLoggedIn && authStore.hasCompany">
        <div v-if="authStore.user?.reset_password" class="flex w-full justify-center">
            <div class="w-full max-w-sm">
                <ResetPassword />
            </div>
        </div>

        <RouterView v-else-if="!fetchingLicenses" />
    </PageLayout>

    <LoginLayout v-else-if="!authStore.isLoggedIn && route.meta.layout === 'login'">
        <RouterView />
    </LoginLayout>

    <GuestLayout v-else-if="!authStore.isLoggedIn">
        <RouterView />
    </GuestLayout>

    <div v-else class="flex h-screen w-full flex-col items-center justify-center">
        <div class="absolute right-12 top-12 flex gap-2 text-black dark:text-primary-50">
            <LocaleDropdown />
            <Logout />
        </div>

        <h1 class="text-4xl" v-text="t('noCompanyAssigned')" />

        <p class="my-5 text-xl text-gray-700 dark:text-gray-300" v-text="t('contactSupport')" />

        <div v-if="joinMyPallet" class="flex flex-col items-center">
            <span class="mb-2 text-sm text-gray-500">
                Your email is registered with Mypallet. Click to join the MyPallet company
            </span>

            <MyButton
                plain
                scheme="primary"
                type="button"
                class="w-40"
                @click="attachUser()"
                v-text="'Join MyPallet'"
            />
        </div>
    </div>

    <!-- Vue Notifications - it does not export the component so we can't import it -->
    <notifications classes="my-notification" :duration="5000" position="bottom right" />

    <ModalContainer />
    <MyConfirmContainer />
</template>
