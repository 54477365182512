<script lang="ts" setup>
import axios from 'axios'
import { computed, onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@/stores/auth-store'
import { ResourceResponse, uuid } from '@/types/general'
import { Template } from '@/types/damage-report'
import { useConfirm } from '@/hooks/use-confirm'
import { MyButtonScheme } from '@/types/layout/my-button'
import { transportObjectTypes } from '@/utils/type-translations'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import ManageTemplateModal from '@/components/damage-reports/ManageTemplateModal.vue'
import TemplateCard from '@/components/damage-reports/TemplateCard.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'

const { t } = useI18n()
const searchQuery = ref('')
const authStore = useAuthStore()
const confirm = useConfirm()
const loading = ref(false)
const allTemplates = ref<Template[]>([])
const selectedTemplateId = ref<uuid>()
const manageTemplateModalOpen = ref(false)

const selectedTemplate = computed(() => {
    if (!selectedTemplateId.value) return undefined
    return allTemplates.value!.find(({ id }) => id === selectedTemplateId.value)
})

const templates = computed<Template[]>(() => {
    if (!searchQuery.value) return allTemplates.value

    const query = searchQuery.value.toLowerCase()
    return allTemplates.value.filter((template) => {
        if (template.name.toLowerCase().includes(query)) return true
        if (template.masterTemplate.name.toLowerCase().includes(query)) return true
        if (t(transportObjectTypes[template.masterTemplate.type]).toLowerCase().includes(query))
            return true
        return false
    })
})

async function fetchTemplates() {
    loading.value = true
    const response = await axios.get<ResourceResponse<Template[]>>(
        window.route('dmr.company.templates.index', { company: authStore.companyId }),
    )
    loading.value = false

    allTemplates.value = response.data.data
}

async function deleteTemplate(template: Template) {
    try {
        await confirm(
            t('deleteEntityTitle', { entity: t('template') }),
            t('deleteTemplateDescription'),
            {
                confirmText: t('yes'),
                cancelText: t('no'),
                confirmButtonScheme: MyButtonScheme.Warning,
            },
        )
    } catch {
        return
    }

    await axios.delete(
        window.route('dmr.company.templates.destroy', {
            company: authStore.companyId,
            template: template.id,
        }),
    )
    await fetchTemplates()
}

function selectTemplateObject(id: string) {
    selectedTemplateId.value = id
}

function updateTemplate(template: Template) {
    selectTemplateObject(template.id)
    manageTemplateModalOpen.value = true
}

async function manageTemplateModalClosed() {
    manageTemplateModalOpen.value = false
    selectedTemplateId.value = undefined
    fetchTemplates()
}

onBeforeMount(() => {
    fetchTemplates()
})
</script>

<template>
    <CrumbsAndActions>
        <Breadcrumb :to="{ name: 'dmr.reports' }" v-text="t('reports')" />
        <Breadcrumb current v-text="t('templates')" />

        <template #actions>
            <div class="flex items-center space-x-3">
                <MyInput
                    v-model="searchQuery"
                    :background="'bg-primary-50 focus:bg-white dark:bg-dark-500 dark:focus:bg-dark-700'"
                    :placeholder="t('search')"
                    class="!w-56 pt-1.5 pb-1.5"
                    type="search"
                >
                    <template #icon>
                        <mdi:magnify />
                    </template>
                </MyInput>
                <MyButton
                    class="w-full"
                    plain
                    scheme="primary"
                    size="small"
                    @click="manageTemplateModalOpen = true"
                >
                    <mdi:plus-thick class="mr-1" />
                    {{ t('addTemplate') }}
                </MyButton>
            </div>
        </template>
    </CrumbsAndActions>

    <LoaderWrapper :visible="loading" class="rounded-xl" />
    <div v-if="templates.length > 0" class="flex w-full flex-wrap gap-7">
        <div v-for="template in templates" :key="template.name">
            <TemplateCard :template="template" class="group" @click="updateTemplate(template)">
                <template #actions>
                    <MyButton
                        class="absolute bottom-2 right-2 hidden h-6 w-6 items-center justify-center rounded-full text-xs text-white shadow-lg shadow-primary-600 drop-shadow-lg hover:bg-primary-200 group-hover:block dark:hover:bg-dark-800"
                        icon
                        size="micro"
                        scheme="danger"
                        @click.stop="deleteTemplate(template)"
                    >
                        <mdi:trash-can class="mt-1" />
                    </MyButton>
                </template>
            </TemplateCard>
        </div>
    </div>

    <div
        v-if="templates.length === 0 && !loading"
        class="flex h-full w-full items-center justify-center"
        v-text="t('noTemplatesFound')"
    ></div>

    <ManageTemplateModal
        v-model="manageTemplateModalOpen"
        :template="selectedTemplate"
        @close="manageTemplateModalClosed"
    />
</template>
