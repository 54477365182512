import { ref } from 'vue'
import { v4 } from 'uuid'
import { notify } from '@kyvg/vue3-notification'
import { useI18n } from 'vue-i18n'

import { FileAttachment, uuid } from '@/types/general'
import { cloudflareUpload } from '@/utils/upload'

interface Params {
    addFile: (file: FileAttachment) => void
    updateFile: (fileId: uuid, filepath: string) => void
}

export function useFileUploader(params: Params) {
    const { t } = useI18n()

    const filesUploading = ref<uuid[]>([])

    function uploadFiles(files: File[]) {
        for (const file of files) {
            const id = v4()
            const isImage = new RegExp('image/*').test(file.type)
            const path = isImage ? URL.createObjectURL(file) : ''
            params.addFile({ id: id, type: 0, name: file.name, path: path, note: '' })
            uploadFile(file, id)
        }
    }

    async function uploadFile(file: File, fileId: uuid) {
        filesUploading.value.push(fileId)
        const isImage = new RegExp('image/*').test(file.type)

        try {
            if (isImage) {
                const filepath = await cloudflareUpload(file)
                params.updateFile(fileId, filepath)
            } else {
                const data = await window.Vapor.store(file, { visibility: 'public-read' })
                params.updateFile(fileId, data.key as string)
            }
        } catch {
            notify({ title: t('unknownError'), type: 'error' })
        } finally {
            filesUploading.value = filesUploading.value.filter((id) => id !== fileId)
        }
    }

    return {
        filesUploading,
        uploadFiles,
    }
}
