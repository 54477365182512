import { LanguageCode } from '@/utils/language-codes'
import {
    TransportObjectType,
    MinimalTransportObject,
    ExtendedTransportObject,
} from '@/types/transport-object'
import { MinimalResource, Model, Dispatch, uuid } from '@/types/general'
import { IncludedCompany, Location, LocationPoint } from '@/types/company'
import { MinimalUser } from '@/types/user'
import { Alarm } from '@/types/alarm'

export type ReportFilter = 'all' | 'withAlarms' | 'withoutAlarms'

export interface Report extends Model {
    id: uuid
    user: MinimalUser
    company: IncludedCompany
    companyLocation: Location
    customerCompany: IncludedCompany | null
    haulierCompany: IncludedCompany | null
    haulierLocation: Location | null
    transportObject: ExtendedTransportObject
    template: Template
    fields: ExtendedReportField[]
    tour: MinimalResource | null
    vehicle?: MinimalTransportObject
    reason: ReportReason | null
    type: ReportType
    location: LocationPoint
    address: string
    note: string | null
    notes: ReportNote[]
    wheels: number[][] | null
    createdAt: string
    updatedAt: string
    transpiredAt: string
    departure: string | null
    lastReportId: uuid | null
    alarms: ReportAlarm[]
    dispatches: Dispatch[]
    isAutomaticDropOff: boolean
}

export interface MinimalReport extends Model {
    id: uuid
    user: MinimalUser
    company: IncludedCompany
    companyLocation: Location
    customerCompany: IncludedCompany | null
    haulierCompany: IncludedCompany | null
    haulierLocation: Location | null
    transportObject: MinimalTransportObject
    vehicle: MinimalTransportObject | null
    template: { id: uuid; name: string } | null
    reason: ReportReason | null
    location: LocationPoint
    address: string
    type: ReportType
    note: string
    wheels: number[][] | null
    hasNewIncidents: boolean
    hasTireThresholdAlarm: boolean
    hasFieldAlarm: boolean
    createdAt: string
    updatedAt: string
    transpiredAt: string
    departure: string | null
    isAutomaticDropOff: boolean
}

export interface MinimalTourReport {
    id: uuid
    type: ReportType
    transportObject: { id: uuid; type: TransportObjectType; registrationNumber: string }
}

export enum ReportReason {
    LoadingShip = 0,
    UnLoadingShip = 1,
    Terminal = 2,
    PTI = 3,
}

export interface MasterTemplate {
    id: string
    name: string
    axles: number
    type: TransportObjectType
    images?: MasterTemplateImage[]
    primaryImage?: MasterTemplateImage
}

export interface MasterTemplateImage {
    id: uuid
    masterTemplateId: string
    name: string
    path: string
    primaryImage: boolean
    companyLogoAngle: string
    sections: MasterTemplateSection[]
}

export interface Template {
    id: uuid
    companyId: uuid
    masterTemplate: MasterTemplate
    name: string
    tireThreadCheck: boolean
    tireThreadThreshold?: number
    fields: TemplateField[]
}

export interface WithSections {
    sections: MasterTemplateSection[]
}

export interface MasterTemplateSection {
    id: uuid
    coordinates: string
    name: string
}

export interface TemplateField {
    id?: uuid
    templateId?: uuid
    name: string
    type: TemplateFieldType
    required: boolean
    imageRequired: boolean
    alarmType: AlarmType | null
    alarmValue: number | null
    order: number
    translations: Record<LanguageCode, string>
}

export interface DefaultTemplateField {
    id?: uuid
    name: string
    type: TemplateFieldType
    translations: Record<LanguageCode, string>
}

export interface ReportField {
    id: uuid
    reportId: uuid
    templateFieldId: uuid
    value: string
    notPossibleToCheck: boolean
}

export interface ExtendedReportField extends ReportField {
    templateField: TemplateField
    lastReportValue: string | null
    lastReportNotPossibleToCheck: boolean | null
    images: ReportFieldImage[]
}

export enum TemplateFieldType {
    Number = 0,
    Boolean = 1,
    // Select = 2,
    Text = 3,
    TankLevel = 4,
}

export enum AlarmType {
    Below = 0,
    Above = 1,
    Equals = 2,
    Filled = 3,
}

export enum ReportType {
    Pickup = 0,
    DropOff = 1,
}

export interface CompanyLogoAngle {
    coordinates: string
    matrix: string
}

export interface ReportFieldImage {
    id: uuid
    reportFieldId: uuid
    path: string
}

export interface ReportNote {
    id: uuid
    reportId: uuid
    masterTemplateSectionId: uuid
    value: string
}

export enum ReportPage {
    Incidents = 0,
    TireThreads = 1,
    Fields = 2,
}

export enum VehicleReportPreference {
    Required = 0,
    Optional = 1,
    Off = 2,
}

export interface DamageReportPreferences {
    sendReportsToOwners: boolean
    requireCustomerCompany: boolean
    tourSelectable: boolean
    createVehiclesInApp: boolean
    createUnitsInApp: boolean
}

export interface ReportAlarm
    extends Pick<Alarm, 'id' | 'type' | 'comment' | 'resolvedAt' | 'mutedAt' | 'description'> {
    description: ReportAlarmDescription
}

interface ReportAlarmDescription extends Record<string, unknown> {
    incidentId?: uuid
}
