import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

import { InputVariable } from '@/types/inputs'
import { EmailTemplateType } from '@/types/emails'

interface EmailTemplateOptions {
    title: string
    emailSubjectShown: boolean
    emailBodyShown: boolean
    variables: InputVariable[]
}

export function useEmailTemplateOptions() {
    const { t } = useI18n()

    return computed<Record<EmailTemplateType, EmailTemplateOptions>>(() => {
        return {
            [EmailTemplateType.PackagingStatement]: {
                title: t('packagingStatement'),
                emailSubjectShown: false,
                emailBodyShown: true,
                variables: [
                    { value: 'customerName', description: t('customerNameVariable') },
                    {
                        value: 'customerContactPerson',
                        description: t('customerContactPersonVariable'),
                    },
                    { value: 'companyName', description: t('companyNameVariable') },
                ],
            },
            [EmailTemplateType.OwnerDamageReportEmail]: {
                title: t('ownerReportEmail'),
                emailSubjectShown: true,
                emailBodyShown: true,
                variables: [
                    { value: 'customerName', description: t('customerNameVariable') },
                    { value: 'companyName', description: t('companyNameVariable') },
                    { value: 'registrationNumber', description: t('registrationNumber') },
                ],
            },
        }
    })
}
