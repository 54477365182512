<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

import { TourEvent } from '@/types/delivery-management'

import MyPanel from '@/components/my-components/MyPanel.vue'

export interface Props {
    event: TourEvent
}

defineProps<Props>()

const { t } = useI18n()
</script>

<template>
    <MyPanel bordered panel-class="mt-4" class="grid grid-cols-11 gap-2 pb-2">
        <div class="col-span-4 ml-6">
            <h3
                class="relative text-left pt-4 text-sm font-bold uppercase tracking-wide text-primary-400"
                v-text="t('waypoint')"
            />

            <div class="flex flex-col justify-center py-2 space-y-3">
                <div class="flex flex-col">
                    <span
                        class="text-sm font-semibold uppercase tracking-wide text-gray-500"
                        v-text="t('name')"
                    />
                    <div>
                        <span v-text="event.waypoint!.name ?? '-'" />
                    </div>
                </div>

                <div v-if="event.waypoint!.description" class="flex flex-col">
                    <span
                        class="text-sm font-semibold uppercase tracking-wide text-gray-500"
                        v-text="t('description')"
                    />

                    <span v-text="event.waypoint!.description" />
                </div>
            </div>
        </div>

        <div class="col-span-3 col-start-5">
            <h3
                class="relative pt-4 text-sm font-bold uppercase tracking-wide text-primary-400"
                v-text="t('arrival')"
            />
            <div class="flex flex-col justify-center py-2">
                <div class="flex flex-col">
                    <span
                        class="text-sm font-semibold uppercase tracking-wide text-gray-500"
                        v-text="t('address')"
                    />
                    <span v-text="event.waypoint!.arrivalAddress" />
                </div>
            </div>
        </div>

        <div v-if="event.waypoint!.departureAddress" class="col-span-3 col-start-8">
            <h3
                class="relative pt-4 text-sm font-bold uppercase tracking-wide text-primary-400"
                v-text="t('departure')"
            />
            <div class="flex flex-col justify-center py-2">
                <div class="flex flex-col">
                    <span
                        class="text-sm font-semibold uppercase tracking-wide text-gray-500"
                        v-text="t('address')"
                    />
                    <span v-text="event.waypoint!.departureAddress" />
                </div>
            </div>
        </div>
    </MyPanel>
</template>
