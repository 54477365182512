<script lang="ts">
export interface Props extends /** @vue-ignore */ ButtonHTMLAttributes {
    scheme?:
        | 'default'
        | 'primary'
        | 'secondary'
        | 'success'
        | 'info'
        | 'warning'
        | 'danger'
        | 'light'
        | 'dark'
        | 'action' // Remember to update Enum
    size?: 'micro' | 'small' | 'medium' | 'large' // Remember to update Enum
    pill?: boolean
    plain?: boolean
    disabled?: boolean
    shadow?: boolean
    icon?: boolean
    active?: boolean
    loading?: boolean
}
</script>

<script lang="ts" setup>
import { ButtonHTMLAttributes, computed } from 'vue'

import { MyButtonScheme, MyButtonSize } from '@/types/layout/my-button'

const props = withDefaults(defineProps<Props>(), {
    scheme: MyButtonScheme.Default,
    size: MyButtonSize.Medium,
    loading: false,
})

const getPlainScheme = (scheme: MyButtonScheme) => {
    switch (scheme) {
        case MyButtonScheme.Primary:
            return props.active
                ? `text-primary-100 bg-primary-400`
                : `bg-primary-300 text-primary-500 hover:text-primary-100 hover:bg-primary-400 active:bg-primary-300`
        case MyButtonScheme.Secondary:
            return props.active
                ? `text-secondary-800 bg-secondary-50`
                : `bg-secondary-100 text-secondary-500 hover:text-secondary-800 hover:bg-secondary-50 active:bg-secondary-300`
        case MyButtonScheme.Success:
            return props.active
                ? `text-green-800 bg-green-200`
                : `bg-green-300 text-green-600 hover:text-green-800 hover:bg-green-200 active:bg-green-300`
        case MyButtonScheme.Info:
            return props.active
                ? `text-blue-800 bg-blue-200`
                : `bg-blue-300 text-blue-600 hover:text-blue-800 hover:bg-blue-200 active:bg-blue-300`
        case MyButtonScheme.Warning:
            return props.active
                ? `text-yellow-800 bg-yellow-200`
                : `bg-yellow-300 text-yellow-600 hover:text-yellow-800 hover:bg-yellow-200 active:bg-yellow-300`
        case MyButtonScheme.Danger:
            return props.active
                ? `text-red-800 bg-red-200`
                : `bg-red-300 text-red-600 hover:text-red-800 hover:bg-red-200 active:bg-red-300`
        case MyButtonScheme.Light:
            return props.active
                ? `bg-primary-200 text-dark-700 dark:text-primary-50 dark:bg-dark-400`
                : `bg-primary-50 text-dark-700 hover:bg-primary-200 active:bg-primary-50 dark:text-primary-50 dark:bg-dark-500 dark:hover:bg-dark-400 dark:active:bg-dark-600`
        case MyButtonScheme.Dark:
            return props.active
                ? `text-primary-50 bg-dark-400 dark:bg-primary-200 dark:text-dark-700`
                : `text-primary-50 bg-dark-500 hover:bg-dark-400 active:bg-dark-600 dark:bg-primary-50 dark:text-dark-700 dark:hover:bg-primary-200 dark:active:bg-primary-50`
        default:
            return ''
    }
}
const getSolidScheme = (scheme: MyButtonScheme) => {
    switch (scheme) {
        case MyButtonScheme.Primary:
            return props.active
                ? `text-primary-50 bg-primary-400`
                : `bg-primary-500 text-primary-50 hover:bg-primary-400 active:bg-primary-500`
        case MyButtonScheme.Secondary:
            return props.active
                ? `text-primary-50 bg-secondary-400`
                : `bg-secondary-600 text-primary-50 hover:bg-secondary-400 active:bg-secondary-600`
        case MyButtonScheme.Success:
            return props.active
                ? `text-primary-50 bg-green-400`
                : `bg-green-500 text-primary-50 hover:bg-green-400 active:bg-green-500`
        case MyButtonScheme.Info:
            return props.active
                ? `text-primary-50 bg-blue-400`
                : `bg-blue-500 text-primary-50 hover:bg-blue-400 active:bg-blue-500`
        case MyButtonScheme.Warning:
            return props.active
                ? `text-primary-50 bg-yellow-400 pointer-events-none`
                : `bg-yellow-500 text-primary-50 hover:bg-yellow-400 active:bg-yellow-500`
        case MyButtonScheme.Danger:
            return props.active
                ? `text-primary-50 bg-red-400`
                : `bg-red-500 text-primary-50 hover:bg-red-400 active:bg-red-500`
        case MyButtonScheme.Light:
            return props.active
                ? `bg-primary-200 text-dark-700 dark:text-primary-50 dark:bg-dark-600`
                : `bg-white text-dark-700 hover:bg-primary-100 active:bg-white dark:bg-dark-800 dark:text-primary-50 dark:hover:bg-dark-500 dark:active:bg-dark-800`
        case MyButtonScheme.Dark:
            return props.active
                ? `text-primary-50 bg-dark-600 dark:bg-primary-200 dark:text-dark-700`
                : `bg-dark-800 text-primary-50 hover:bg-dark-500 active:bg-dark-800 dark:bg-white dark:text-dark-700 dark:hover:bg-primary-100 dark:active:bg-white`
        case MyButtonScheme.Action:
            return props.active
                ? `text-primary-100 bg-primary-400 dark:bg-dark-400 dark:text-primary-50`
                : `hover:bg-primary-300 hover:text-primary-500 dark:text-primary-100`
        default:
            return ''
    }
}

const getScheme = (scheme: MyButtonScheme) => {
    if (props.plain) return getPlainScheme(scheme)

    return getSolidScheme(scheme)
}

const classList = computed(() => {
    const classes: string[] = []

    if (props.shadow) {
        classes.push('shadow-lg drop-shadow-lg')
    }

    if (props.icon) {
        if (props.size === MyButtonSize.Micro) {
            classes.push('text-xs w-6 h-6')
        } else if (props.size === MyButtonSize.Small) {
            classes.push('text-xs w-8 h-8')
        } else if (props.size === MyButtonSize.Large) {
            classes.push('text-lg w-12 h-12')
        } else {
            classes.push('text-sm w-10 h-10')
        }
    } else if (props.size === MyButtonSize.Small) {
        classes.push('h-8 px-3 text-xs')
    } else if (props.size === MyButtonSize.Large) {
        classes.push('text-lg h-12 px-7')
    } else {
        classes.push('h-10 px-5 text-sm')
    }

    let borderRadius = 'rounded-xl'

    if (props.pill) {
        borderRadius = 'rounded-full'
    } else if (props.size === MyButtonSize.Small) {
        borderRadius = 'rounded-lg'
    }

    classes.push(borderRadius)

    if (props.disabled || props.loading) {
        classes.push('opacity-60 cursor-not-allowed pointer-events-none')
    }

    classes.push(getScheme(props.scheme as MyButtonScheme))

    return classes
})
</script>

<template>
    <button
        :class="[classList]"
        class="box-border inline-flex items-center justify-center space-x-1 font-semibold uppercase transition-colors"
    >
        <span class="inline-flex items-center justify-center space-x-1 font-semibold">
            <slot />
        </span>

        <span v-if="props.loading" class="ml-3">
            <mdi:loading class="animate-spin" />
        </span>
    </button>
</template>
