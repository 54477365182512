<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouteRecordRaw, useRoute } from 'vue-router'

import { useAuthStore } from '@/stores/auth-store'

import Copyright from '@/components/layout/Copyright.vue'
import NavigationMenu from '@/components/layout/navigation/NavigationMenu.vue'
import PageNavigationLink from '@/components/layout/navigation/PageNavigationLink.vue'

const route = useRoute()
const authStore = useAuthStore()
const { t } = useI18n()

const isNavigationOpen = ref(false)
const navBar = ref<HTMLElement | null>(null)

const topRoute = computed<RouteRecordRaw>(() => route.matched[0])
const routeChildren = computed(() => {
    const routes = topRoute.value?.children ?? []

    return routes.filter((route) => {
        if (route.meta?.hideInNavigation) return false

        const requiredLicense = route.meta?.requiredLicense
        if (requiredLicense && !authStore.hasLicense(requiredLicense)) {
            return false
        }

        if (route.name === 'dm.deviations' && authStore.company?.options.hideDeliveryDeviations) {
            return false
        }

        return true
    })
})

function closeNavigation() {
    isNavigationOpen.value = false
}
function toggleNavigation() {
    isNavigationOpen.value = !isNavigationOpen.value
}

onClickOutside(navBar, closeNavigation)
</script>

<template>
    <div
        class="mb-6"
        :class="{
            'bg-primary-200 text-sm dark:bg-dark-700': routeChildren.length >= 1,
        }"
    >
        <div
            v-if="topRoute"
            class="m-4 rounded-xl bg-gradient-radial-to-br from-primary-500 via-primary-500 to-green-500 shadow-xl dark:from-secondary-900 dark:via-secondary-900 dark:to-primary-500 lg:m-0 lg:bg-transparent lg:bg-none lg:shadow-none"
        >
            <NavigationMenu />

            <header
                v-if="!topRoute.meta?.hideHeader"
                class="overflow-hidden p-4 lg:p-0 lg:pt-5"
                :class="{ 'lg:hidden': !topRoute.children || topRoute.children.length <= 1 }"
            >
                <div
                    class="relative w-full text-sm lg:flex lg:grid-cols-[1fr_2fr_1fr] lg:items-center lg:justify-center"
                >
                    <h1 class="flex grow lg:hidden">
                        <span class="relative mr-2 text-primary-100 lg:text-primary-400">
                            <mdi:view-dashboard v-if="topRoute.name === 'dashboard'" />
                            <mdi:warehouse v-else-if="topRoute.name === 'packaging'" />
                            <mdi:truck-cargo-container
                                v-else-if="topRoute.name === 'damageReports'"
                            />
                            <template v-else-if="topRoute.name === 'tracking'">
                                <mdi:map-marker />
                                <span
                                    class="absolute top-[5px] right-0 left-0 mx-auto flex h-[12px] w-[12px] items-center justify-center rounded-full bg-primary-50 text-[8px] transition"
                                >
                                    <mdi:package-variant-closed />
                                </span>
                            </template>
                            <mdi:file-document-edit v-else-if="topRoute.name === 'ecmr'" />
                            <mdi:truck-fast v-else-if="topRoute.name === 'tours'" />
                            <mdi:chart-areaspline v-else-if="topRoute.name === 'marketplace'" />
                            <mdi:cog v-else-if="topRoute.name === 'settings'" />
                        </span>
                        <span
                            class="block grow font-semibold uppercase text-primary-100 lg:text-secondary-700 lg:dark:text-primary-100"
                            v-text="$route.meta.title"
                        />
                    </h1>

                    <nav
                        v-if="topRoute.children && topRoute.children.length > 1"
                        ref="navBar"
                        class="flex-col items-end lg:flex lg:h-full lg:w-full lg:items-center lg:justify-end lg:justify-self-stretch"
                    >
                        <button
                            class="absolute right-0 top-0 block text-primary-100 lg:hidden"
                            @click.prevent="toggleNavigation"
                        >
                            <mdi:dots-vertical
                                :class="{ 'rotate-90': isNavigationOpen }"
                                class="rotate-0 transform transition-all"
                            />
                        </button>

                        <div
                            :class="isNavigationOpen ? 'flex' : 'hidden'"
                            class="flex-col pt-4 pr-2 text-primary-50 lg:flex lg:flex-row lg:space-x-1.5 lg:p-0 lg:text-black"
                        >
                            <PageNavigationLink
                                v-for="child in routeChildren"
                                :key="`PageNavigationLink${child.name as string}`"
                                :to="{
                                    name: child.name,
                                    params: child.meta!.defaultParams ?? {},
                                }"
                                v-text="t(child.meta!.title)"
                            />
                        </div>
                    </nav>
                </div>
            </header>
        </div>
    </div>
    <main class="pb:8 relative mx-auto flex w-full grow flex-col px-4 lg:px-6 lg:pb-6">
        <slot />
    </main>

    <Copyright />
</template>
