import { useI18n } from 'vue-i18n'
import { VueDatePickerProps } from '@vuepic/vue-datepicker'
import dayjs from 'dayjs'
import { computed, ComputedRef } from 'vue'

export function usePresetRangeList(): ComputedRef<VueDatePickerProps['presetDates']> {
    const { t } = useI18n()

    const presetRanges = computed(() => {
        return [
            {
                label: t('last7days'),
                value: [dayjs().subtract(6, 'days').toDate(), dayjs().toDate()],
            },
            {
                label: t('thisMonth'),
                value: [dayjs().startOf('month').toDate(), dayjs().endOf('month').toDate()],
            },
            {
                label: t('previousMonth'),
                value: [
                    dayjs().subtract(1, 'month').startOf('month').toDate(),
                    dayjs().subtract(1, 'month').endOf('month').toDate(),
                ],
            },
            {
                label: t('thisYear'),
                value: [dayjs().startOf('year').toDate(), dayjs().endOf('year').toDate()],
            },
            {
                label: t('previousYear'),
                value: [
                    dayjs().subtract(1, 'year').startOf('year').toDate(),
                    dayjs().subtract(1, 'year').endOf('year').toDate(),
                ],
            },
            // This is only visible when the allTime slot is added
            {
                label: t('allTime'),
                value: [],
                slot: 'allTime',
            },
        ]
    })

    return presetRanges
}
