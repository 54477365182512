<script lang="ts" setup>
import axios from 'axios'
import { onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { ResourceResponse, uuid } from '@/types/general'
import { Employee } from '@/types/user'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'

export interface Props {
    userId: uuid
    companyId: uuid
}

interface Form {
    name: string
    employeeNumber: string | null
}

const emit = defineEmits<{ (e: 'closed', updated: boolean): void }>()
const props = defineProps<Props>()

const { t } = useI18n()
const authStore = useAuthStore()

const open = ref(true)
const fetching = ref(false)
const uploading = ref(false)
const { data, loading, errors, submit } = useForm<Form>({
    name: '',
    employeeNumber: '',
})

async function fetchEmployee() {
    fetching.value = true
    const { data: response } = await axios.get<ResourceResponse<Employee>>(
        window.route('company.employees.show', {
            company: props.companyId,
            employee: props.userId,
            actingCompanyId: authStore.companyId,
        }),
    )

    data.name = response.data.name
    data.employeeNumber = response.data.employeeNumber ?? ''
    fetching.value = false
}

async function onSubmit() {
    const response = await submit(
        'put',
        window.route('company.employees.update-employee-number', {
            employee: props.userId,
            company: props.companyId,
            actingCompanyId: authStore.companyId,
        }),
    )
    if (response) emit('closed', true)
}

onBeforeMount(() => fetchEmployee())
</script>

<template>
    <MyModal v-model="open" @close="emit('closed', false)">
        <template #title>
            {{ t('editEmployeeNumber') }}
        </template>

        <LoaderWrapper :visible="loading || fetching" />

        <MyForm class="mt-2 space-y-2" :errors="errors" @submit.prevent="onSubmit">
            <LoaderWrapper :visible="uploading" class="rounded-xl" />

            <MyInput
                v-model="data.employeeNumber"
                name="employeeNumber"
                :label="t('employeeNumber')"
                type="text"
                :placeholder="t('employeeNumber')"
                autofocus
            />

            <div>
                <MyButton :disabled="loading" class="mt-2 w-full" scheme="primary">
                    {{ t('update') }}
                </MyButton>
            </div>
        </MyForm>
    </MyModal>
</template>
