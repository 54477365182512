/**
 * Checks if the provided environment matches the current one
 * When multiple environments are given it will return true if one of them matches
 * @param environments
 */
export function isEnvironment(environments: string | string[]): boolean {
    if (!Array.isArray(environments)) {
        environments = [environments]
    }

    for (const env of environments) {
        if (env === window.environment) return true
    }

    return false
}
