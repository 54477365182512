import dayjs, { extend } from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'

extend(localizedFormat)

export function localeDate(date: string): string {
    return dayjs(Date.parse(date)).format('YYYY-MM-DD')
}

export function localeTime(date: string): string {
    return dayjs(Date.parse(date)).format('HH:mm')
}

export function datetime(date: string): string {
    return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
}

export function differenceBetweenDates(
    startDate: string,
    endDate: string | null,
    rest = 0,
): string {
    const minutes = Math.abs(dayjs(startDate).diff(endDate || dayjs(), 'minutes', true))

    return differenceFromMinutes(minutes - rest)
}

export function differenceFromMinutes(totalMinutes: number): string {
    const hours = Math.floor(totalMinutes / 60)
    const minutes = Math.round(totalMinutes % 60)

    const hourText = hours.toString().padStart(2, '0')
    const minuteText = minutes.toString().padStart(2, '0')

    return hourText + ':' + minuteText
}
