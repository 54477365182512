<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { toRef } from 'vue'

import { image } from '@/utils/assets'
import { useBalanceOwedHelpers } from '@/hooks/packaging/use-balance-owed-description'

import MyPanel from '@/components/my-components/MyPanel.vue'

export interface Props {
    delivered: number
    received: number
    primo: number
    productName?: string
    productImage?: string
    companyName: string
    receiverName: string
}

const props = defineProps<Props>()

const { t } = useI18n()

const companyName = toRef(props.companyName)
const receiverName = toRef(props.receiverName)

const { balanceOwedDescription, getBalanceColor } = useBalanceOwedHelpers(companyName, receiverName)
</script>

<template>
    <MyPanel>
        <div class="flex justify-between">
            <div class="flex-1 px-6 pt-6">
                <h2
                    class="text-base mb-1.5 font-bold uppercase text-green-500"
                    v-text="props.productName || t('total')"
                />
                <p class="flex w-full justify-between gap-1.5">
                    {{ t('primo') }}
                    <span
                        class="mb-1.5 flex-1 border-b-2 border-dotted border-primary-500 dark:border-primary-400"
                    />
                    <span
                        v-tooltip="balanceOwedDescription(primo)"
                        class="font-semibold"
                        :class="getBalanceColor(props.primo)"
                        v-text="primo"
                    />
                </p>
                <p class="flex w-full justify-between gap-1.5">
                    {{ t('ultimo') }}
                    <span
                        class="mb-1.5 flex-1 border-b-2 border-dotted border-primary-500 dark:border-primary-400"
                    />
                    <span
                        v-tooltip="
                            balanceOwedDescription(props.primo + props.delivered - props.received)
                        "
                        class="font-semibold"
                        :class="getBalanceColor(props.primo + props.delivered - props.received)"
                        v-text="props.primo + props.delivered - props.received"
                    />
                </p>
            </div>
            <div
                class="flex h-[100px] w-[150px] items-center justify-center overflow-hidden rounded-bl-xl bg-primary-200 text-5xl text-primary-50 dark:bg-dark-400"
            >
                <mdi:sigma v-if="!props.productImage" />
                <img
                    v-else
                    class="h-full w-full object-contain p-4"
                    :src="image(props.productImage)"
                    :alt="props.productName"
                />
            </div>
        </div>
        <div class="grid grid-cols-3 gap-3 p-6 text-[8px] uppercase text-primary-400">
            <p
                class="flex justify-between rounded-lg bg-primary-100 px-3 py-2 font-semibold dark:text-primary-100 dark:bg-black"
            >
                {{ t('received') }}
                <span
                    class="font-bold text-primary-500 dark:text-primary-100"
                    v-text="props.received"
                />
            </p>
            <p
                class="flex justify-between rounded-lg bg-primary-100 px-3 py-2 font-semibold dark:text-primary-100 dark:bg-black"
            >
                {{ t('delivered') }}
                <span
                    class="font-bold text-primary-500 dark:text-primary-100"
                    v-text="props.delivered"
                />
            </p>
            <p
                v-tooltip="balanceOwedDescription(props.delivered - props.received)"
                class="flex justify-between rounded-lg bg-primary-100 px-3 py-2 font-semibold dark:text-primary-100 dark:bg-black"
            >
                {{ t('diff') }}
                <span
                    class="font-bold"
                    :class="getBalanceColor(props.delivered - props.received)"
                    v-text="props.delivered - props.received"
                />
            </p>
        </div>
    </MyPanel>
</template>
