<script lang="ts">
interface Props {
    onlyAppUsers?: boolean
    minimalHeader?: boolean
    companyId?: uuid
    // Readonly is used in combination with companyId to disallow editing employees for myPallet customers
    readonly?: boolean
}
</script>

<script lang="ts" setup>
import type { Employee } from '@/types/user'

import { notify } from '@kyvg/vue3-notification'
import axios from 'axios'
import { computed, onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import {
    PermissionType,
    type PaginatedResponse,
    type uuid,
    type MinimalResource,
} from '@/types/general'
import { usePaginatedTable } from '@/hooks/table/use-paginated-table'
import { useConfirm } from '@/hooks/use-confirm'
import { useAuthStore } from '@/stores/auth-store'
import { useCompanyStore } from '@/stores/company-store'
import { PortalType, UserType } from '@/types/user'
import { userTypeOptions } from '@/utils/employees'
import { phoneNumberString } from '@/utils/string'
import { LicenseType } from '@/types/company'
import { DropdownStringOption } from '@/types/inputs'

import BasicExportModal from '@/components/import-export/BasicExportModal.vue'
import BasicImportModal from '@/components/import-export/BasicImportModal.vue'
import CreateEmployeeModal from '@/components/employees/CreateEmployeeModal.vue'
import RemoveEmployeeModal from '@/components/employees/RemoveEmployeeModal.vue'
import UpdateEmployeeModal from '@/components/employees/UpdateEmployeeModal.vue'
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import MyTabs from '@/components/my-components/MyTabs.vue'
import ActionMenuItem from '@/components/table/ActionMenuItem.vue'
import MyFilterSelect from '@/components/table/MyFilterSelect.vue'
import MyTable from '@/components/table/MyTable.vue'
import MyTableColumn from '@/components/table/MyTableColumn.vue'
import UpdateEmployeeNumberModal from '@/components/employees/UpdateEmployeeNumberModal.vue'

interface Filter {
    web?: boolean
    app?: boolean
    userType?: string
}

interface Params extends Record<string, unknown> {
    filter: Filter
}

const props = withDefaults(defineProps<Props>(), {
    onlyAppUsers: false,
    minimalHeader: false,
    readonly: false,
})

const { t } = useI18n()
const authStore = useAuthStore()
const route = useRoute()
const confirm = useConfirm()
const companyStore = useCompanyStore()

let tab = (route.query['user-type'] as PortalType) ?? PortalType.Webportal
if (props.onlyAppUsers) {
    tab = PortalType.App
}

const currentTab = ref<PortalType>(tab)
const employeeToUpdate = ref<uuid>()
const employeeNumberToUpdate = ref<uuid>()
const employeeToRemove = ref<Employee>()
const userTypes = userTypeOptions(t)
const companyLocations = ref<DropdownStringOption[]>([])

const actingCompanyId = computed(() => {
    if (props.companyId && props.companyId !== authStore.companyId) {
        return authStore.companyId
    }

    return undefined
})

const { data, paginationData, refetch, params, loading, error } = usePaginatedTable<
    Employee,
    Params
>(
    async (params, abortController) => {
        const requestParams = JSON.parse(JSON.stringify(params))
        if (currentTab.value === PortalType.Webportal) {
            requestParams.filter.web = true
        } else {
            requestParams.filter.app = true
        }
        if (props.companyId && props.companyId !== authStore.companyId) {
            requestParams.actingCompanyId = authStore.companyId
        }

        const response = await axios.get<PaginatedResponse<Employee>>(
            window.route('company.employees.index', {
                company: props.companyId || authStore.companyId,
            }),
            { params: requestParams, signal: abortController.signal },
        )

        return response.data
    },
    { filter: { userType: undefined }, actingCompanyId: actingCompanyId.value },
)

const exportEndpoint = computed(() =>
    window.route('company.employees.export', { company: props.companyId || authStore.companyId }),
)
const importEndpoint = computed(() =>
    window.route('company.employees.import', { company: props.companyId || authStore.companyId }),
)

const tabs = computed(() => {
    let tabs: Record<string, string> = { app: t('appUsers') }
    if (!props.onlyAppUsers) {
        tabs = { webportal: t('webportalUsers'), app: t('appUsers') }
    }

    return tabs
})

const phoneNumbers = computed(() =>
    data.value.map(({ phoneNumber }) => (phoneNumber ? phoneNumberString(phoneNumber) : '')),
)

const locations = computed<DropdownStringOption[]>(() => {
    if (!props.companyId || props.companyId === authStore.companyId) {
        return companyStore.locations.map(({ id, name }) => ({
            value: id,
            label: name,
        }))
    }

    return companyLocations.value
})
const employeeLocations = computed<string[]>(() => {
    return data.value.map((employee) => {
        return locations.value
            .filter((location) => employee.locations.includes(location.value ?? ''))
            .map((location) => location.label)
            .join(', ')
    })
})

async function fetchLocations(): Promise<void> {
    if (!props.companyId || props.companyId === authStore.companyId) {
        return
    }

    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.locations', {
            company: props.companyId,
        }),
    )

    companyLocations.value = response.data.map((location) => ({
        value: location.id,
        label: location.name,
    }))
}

function setFilter(value: string | number | null, filterName: string) {
    params.value.filter[filterName] = value
    params.value.page = 1
}

function setTab(tab: PortalType) {
    currentTab.value = tab
    refetch()
}

function setEmployeeToUpdate(userId: uuid) {
    employeeToUpdate.value = userId
}

function setEmployeeNumberToUpdate(userId: uuid) {
    employeeNumberToUpdate.value = userId
}

async function resetEmployeePassword(userId: uuid) {
    const data = { key: 'id', value: userId }

    try {
        await confirm(t('areYouSure'), t('resetPasswordConfirm'))
    } catch (_) {
        return
    }

    loading.value = true

    const response = await axios.post(window.route('password.reset-link'), data)

    if (response) {
        notify({ type: 'success', text: t('passwordResetEmailSent') })
    }

    loading.value = false
}

async function sendWelcomeEmail(userId: string) {
    try {
        await confirm(t('areYouSure'), t('sendWelcomeEmailConfirm'))
    } catch (_) {
        return
    }

    loading.value = true
    const response = await axios.post(
        window.route(
            'company.employees.send-welcome-email',
            props.companyId || authStore.companyId,
        ),
        { userId },
    )

    if (response) {
        notify({ type: 'success', text: t('welcomeEmailSentToUser') })
    }

    loading.value = false
}

function setEmployeeToRemove(employee: Employee) {
    employeeToRemove.value = employee
}

function closeUpdateModal(updated: boolean) {
    employeeToUpdate.value = undefined
    if (updated) refetch()
}

function closeUpdateEmployeeNumberModal(updated: boolean) {
    employeeNumberToUpdate.value = undefined
    if (updated) refetch()
}

function closeRemoveModal(removed: boolean) {
    if (removed) refetch()

    // Wait for modal transition to finish
    setTimeout(() => {
        employeeToRemove.value = undefined
    }, 500)
}

function setUserTypeFilter(value: string | null) {
    params.value.filter.userType = value ?? undefined
}

onBeforeMount(() => fetchLocations())
</script>

<template>
    <CrumbsAndActions v-if="!props.minimalHeader">
        <Breadcrumb :to="{ name: 'settings' }" v-text="t('settings')" />
        <Breadcrumb current v-text="t('employees')" />

        <template v-if="authStore.hasPermission(PermissionType.ManageCompany)" #actions>
            <BasicImportModal
                :title="t('importModel', { model: t('employees') })"
                template="employees"
                :endpoint="importEndpoint"
                @import-finished="refetch()"
            />
            <BasicExportModal
                :title="t('exportModel', { model: t('employees') })"
                :endpoint="exportEndpoint"
            />

            <CreateEmployeeModal :current-tab="currentTab" @created="refetch()" />
        </template>
    </CrumbsAndActions>

    <UpdateEmployeeModal
        :company-id="props.companyId"
        :user-id="employeeToUpdate"
        @closed="closeUpdateModal"
    />
    <RemoveEmployeeModal
        :current-tab="currentTab"
        :employee="employeeToRemove"
        :company-id="props.companyId"
        @closed="closeRemoveModal"
    />
    <UpdateEmployeeNumberModal
        v-if="employeeNumberToUpdate && props.companyId"
        :user-id="employeeNumberToUpdate"
        :company-id="props.companyId"
        @closed="closeUpdateEmployeeNumberModal"
    />

    <div
        v-if="props.companyId && !props.readonly"
        class="flex items-center justify-end space-x-2.5 py-4 px-4"
    >
        <BasicImportModal
            :title="t('importModel', { model: t('employees') })"
            template="employees"
            :company-id="props.companyId"
            :endpoint="importEndpoint"
            @import-finished="refetch()"
        />

        <CreateEmployeeModal
            :company-id="props.companyId"
            :current-tab="currentTab"
            @created="refetch()"
        />
    </div>

    <MyTabs
        :tabs="tabs"
        query-param="user-type"
        shadow
        hide-single-tab
        @change="setTab($event as PortalType)"
    >
        <MyTable
            :error="error"
            :get-data="refetch"
            :loading="loading"
            :pagination-data="paginationData"
            :rows="data"
            :table-id="currentTab === PortalType.Webportal ? 'employees-web' : 'employees-app'"
            :entity-name="t('employees')"
        >
            <template #filters>
                <MyFilterSelect
                    v-if="currentTab === PortalType.App"
                    :label="t('userType')"
                    :options="userTypes"
                    filter-name="userType"
                    @change="(value) => setUserTypeFilter(value)"
                />
                <MyFilterSelect
                    :label="t('locations')"
                    :options="locations"
                    filter-name="location-id"
                    @change="setFilter"
                />
            </template>

            <template v-if="props.readonly" #actionMenu="{ row }">
                <ActionMenuItem @click="setEmployeeNumberToUpdate(row.id)">
                    <span class="mr-2"><mdi:pencil /></span>
                    {{ t('editEmployeeNumber') }}
                </ActionMenuItem>
            </template>

            <template
                v-else-if="authStore.hasPermission(PermissionType.ManageCompany) || props.companyId"
                #actionMenu="{ row }"
            >
                <ActionMenuItem @click="setEmployeeToUpdate(row.id)">
                    <span class="mr-2"><mdi:pencil /></span>
                    {{ t('edit') }}
                </ActionMenuItem>

                <ActionMenuItem @click="resetEmployeePassword(row.id)">
                    <span class="mr-2"><mdi:password /></span>
                    {{ t('resetPassword') }}
                </ActionMenuItem>

                <ActionMenuItem @click="sendWelcomeEmail(row.id)">
                    <span class="mr-2"><mdi:email-newsletter /></span>
                    {{ t('sendWelcomeEmail') }}
                </ActionMenuItem>

                <ActionMenuItem @click="setEmployeeToRemove(row)">
                    <span class="mr-2"><mdi:trash-can /></span>
                    {{ t('remove') }}
                </ActionMenuItem>
            </template>

            <MyTableColumn :name="t('name')" property="name" />

            <MyTableColumn :name="t('username')" property="username" />
            <MyTableColumn v-if="!readonly" :name="t('phoneNumber')" property="phoneNumber" />
            <template #phoneNumber="{ index }">{{ phoneNumbers[index] }}</template>

            <MyTableColumn :name="t('email')" property="email" />

            <MyTableColumn
                v-if="currentTab === PortalType.App"
                :name="t('userType')"
                property="userType"
            />
            <template #userType="{ row }">
                <span v-if="row.userType === UserType.Driver" v-text="t('driver')" />
                <span v-if="row.userType === UserType.Warehouse" v-text="t('warehouse')" />
                <span v-if="row.userType === UserType.ShippingAgent" v-text="t('shippingAgent')" />
                <span v-if="row.userType === UserType.Office" v-text="t('office')" />
                <span v-if="row.userType === UserType.Yard" v-text="t('yard')" />
                <span v-if="row.userType === UserType.TugMaster" v-text="t('tugMaster')" />
                <span v-if="row.userType === UserType.TugMasterSlim" v-text="t('tugMasterSlim')" />
                <span v-if="row.userType === UserType.TntKiosk" v-text="t('tntKiosk')" />
            </template>
            <MyTableColumn
                v-if="
                    currentTab === PortalType.App &&
                    authStore.hasLicense(LicenseType.DamageReportModule)
                "
                :name="t('userRole')"
                property="appRoleName"
            />
            <MyTableColumn
                v-if="currentTab === PortalType.Webportal"
                :name="t('userRole')"
                property="webRoleName"
            />

            <MyTableColumn :name="t('employeeNumber')" property="employeeNumber" />

            <MyTableColumn
                v-if="
                    currentTab === PortalType.App &&
                    authStore.hasLicense(LicenseType.DriverReportModule)
                "
                :name="t('settlementTemplate')"
                property="settlementTemplateName"
            />

            <MyTableColumn :name="t('locations')" property="locations" />
            <template #locations="{ index }">
                {{ employeeLocations[index] }}
            </template>
        </MyTable>
    </MyTabs>
</template>
