<script lang="ts">
interface Props {
    modelValue: boolean
    shipmentId: uuid
    shipmentType: ShipmentType
}
</script>

<script setup lang="ts">
import type { FileAttachment, ResourceResponse, uuid } from '@/types/general'

import { Method } from 'axios'
import { watch } from 'vue'
import { useI18n } from 'vue-i18n'
import Datepicker from '@vuepic/vue-datepicker'
import dayjs from 'dayjs'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { Shipment, ShipmentType } from '@/types/delivery-management'
import { emitter } from '@/utils/mitt'
import { useFileUploader } from '@/hooks/use-file-uploader'

import MyFileAttachment from '@/components/my-components/MyFileAttachment.vue'
import MyImageViewer from '@/components/my-components/MyImageViewer.vue'
import MyTextarea from '@/components/my-components/form/MyTextarea.vue'
import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'
import MyInputLabel from '@/components/my-components/form/MyInputLabel.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyFileSelect from '@/components/my-components/MyFileSelect.vue'

interface Form {
    handledAt: string | null
    images: FileAttachment[]
    deliveryNote: string | null
    unsuccessful: boolean
}

const props = defineProps<Props>()

const emit = defineEmits<{
    (e: 'close'): void
    (e: 'saved'): void
}>()

const authStore = useAuthStore()
const { t } = useI18n()
const { data, submit, errors, reset, loading } = useForm<Form>({
    deliveryNote: null,
    handledAt: dayjs().toISOString(),
    images: [],
    unsuccessful: false,
})

const { filesUploading, uploadFiles } = useFileUploader({
    addFile(file) {
        data.images.push(file)
    },
    updateFile(fileId, path) {
        data.images = data.images.map((file) => {
            if (file.id === fileId) return { ...file, path }

            return file
        })
    },
})

function removeFile(file: FileAttachment) {
    data.images = data.images.filter((f) => f !== file)
}

async function onSubmit() {
    const method: Method = 'PUT'
    const deliveryRoute = window.route('dm.company.shipments.handle', {
        company: authStore.companyId,
        shipment: props.shipmentId,
    })
    const response = await submit<ResourceResponse<Shipment>>(method, deliveryRoute)

    if (response !== undefined) {
        emitter.emit('fetchShipments')
        emit('close')
        emit('saved')
    }
}

watch(
    () => props.modelValue,
    () => {
        if (!props.modelValue) return

        reset()
    },
)
</script>

<template>
    <MyModal :value="props.modelValue" :max-width="400" @close="emit('close')">
        <LoaderWrapper :visible="loading" class="rounded-xl" />
        <template #title>
            {{
                shipmentType === ShipmentType.Delivery ? t('markAsDelivered') : t('markAsPickedUp')
            }}
        </template>
        <MyForm :errors="errors" @submit.prevent="onSubmit">
            <div class="flex flex-col w-full space-y-3">
                <div class="flex w-full flex-col">
                    <MyInputLabel v-text="t('handledAt')" />
                    <Datepicker
                        v-model="data.handledAt"
                        class="input-field w-full rounded-xl border border-gray-300"
                        name="handledAt"
                        close-on-scroll
                        enable-time-picker
                        auto-apply
                        :clearable="false"
                        auto-position
                        position="left"
                        format="yyyy-MM-dd HH:mm"
                        :transitions="false"
                        :placeholder="t('selectDate')"
                    />
                </div>

                <MyTextarea
                    v-model="data.deliveryNote"
                    :placeholder="t('deliveryNote')"
                    :label="t('deliveryNote')"
                />

                <MyCheckbox
                    v-model="data.unsuccessful"
                    name="shipment"
                    :label="t('shipmentWasUnsuccessful')"
                />

                <MyFileSelect
                    no-border
                    type="image/*,application/pdf"
                    multiple
                    class="min-w-fit self-start"
                    @selected:multiple="uploadFiles"
                >
                    <mdi:attachment class="dark:text-primary-50" />
                </MyFileSelect>

                <MyImageViewer v-if="data.images.length > 0" class="grid grid-cols-3 gap-3 mt-2">
                    <MyFileAttachment
                        v-for="file in data.images"
                        :key="file.id"
                        class="cursor-pointer"
                        :file="file"
                        removable
                        :uploading="filesUploading.includes(file.id)"
                        @on-remove-clicked="removeFile(file)"
                    />
                </MyImageViewer>
            </div>

            <div class="mt-4 flex justify-end gap-6">
                <MyButton
                    :disabled="loading || filesUploading.length > 0"
                    scheme="primary"
                    v-text="t('update')"
                />
            </div>
        </MyForm>
    </MyModal>
</template>
