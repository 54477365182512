<script setup lang="ts">
import type { CompanyOptions, OwnCompany } from '@/types/company'
import type { Address } from '@/types/form'
import type { ResourceResponse } from '@/types/general'

import { notify } from '@kyvg/vue3-notification'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { useCompanyStore } from '@/stores/company-store'
import { DropdownOption, DropdownStringOption } from '@/types/inputs'
import { image as imageHelper } from '@/utils/assets'
import { currencies } from '@/utils/currencies'
import { cloudflareUpload } from '@/utils/upload'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyFileSelect from '@/components/my-components/MyFileSelect.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyAddressInput from '@/components/my-components/form/MyAddressInput.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MyPhoneInput from '@/components/my-components/form/MyPhoneInput.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import MyVatNumberInput from '@/components/my-components/form/MyVatNumberInput.vue'

interface FormCompanyOptions extends CompanyOptions {
    timezone: string
}

interface Form extends OwnCompany {
    options: FormCompanyOptions
}

const timezones: DropdownStringOption[] = Intl.supportedValuesOf('timeZone').map((timezone) => ({
    value: timezone,
    label: timezone,
}))

const { t } = useI18n()

const open = ref(false)
const companyStore = useCompanyStore()
const address = ref('')
const authStore = useAuthStore()
const uploading = ref(false)

const { data, submit, errors, reset, loading } = useForm<Form>({
    ...(companyStore.company! || {}),
    options: {
        ...(companyStore.company?.options || { currency: 'USD' }),
        timezone: companyStore.company?.options?.timezone ?? 'Europe/Copenhagen',
    },
})
const currencyOptions: DropdownOption[] = currencies.map((currency) => ({
    value: currency,
    label: currency,
}))

async function update() {
    const response = await submit<ResourceResponse<OwnCompany>>(
        'put',
        window.route('company.update', { company: authStore.companyId }),
    )

    if (response) {
        open.value = false
        notify({ type: 'success', text: t('companyUpdated') })
        companyStore.$state.company = response.data
        authStore.setCurrency(data.options.currency)
    }
}

async function handleImageUpload(file: File) {
    uploading.value = true
    try {
        data.logo = await cloudflareUpload(file)
        uploading.value = false
    } catch (e) {
        notify({ title: t('unknownError'), type: 'error' })
    }
}

function addressChanged(address: Address) {
    data.address = address.address
    data.city = address.city
    data.zipcode = address.zipcode + ''
    data.country = address.country
    data.location = { latitude: address.latitude, longitude: address.longitude }
}

function openModal() {
    reset({
        ...(companyStore.company! || {}),
        options: {
            ...(companyStore.company?.options || { currency: 'USD' }),
            timezone: companyStore.company?.options?.timezone ?? 'Europe/Copenhagen',
        },
    })
    address.value = `${data.address}, ${data.city}, ${data.country}`

    if (!data.phoneNumber) data.phoneNumber = ''

    open.value = true
}

function cancelEdit() {
    reset()
    address.value = ''
}

watch(open, () => {
    if (!open.value) cancelEdit()
})
</script>

<template>
    <MyButton plain scheme="primary" size="small" @click="openModal">
        <mdi:pencil class="mr-1" />
        {{ t('editCompany') }}
    </MyButton>

    <MyModal v-model="open" @close="cancelEdit">
        <LoaderWrapper :visible="loading" class="rounded-xl" />
        <h2
            class="mb-4 text-center text-xl font-bold uppercase text-primary-400"
            v-text="t('editCompany')"
        />
        <MyForm :errors="errors" @submit.prevent="update">
            <div class="space-y-2">
                <div class="flex justify-center">
                    <LoaderWrapper :visible="uploading" class="rounded-xl" />

                    <MyFileSelect
                        class="w-fit rounded-xl"
                        type="image/*"
                        @selected="handleImageUpload"
                    >
                        <p
                            class="absolute z-10 text-xs font-semibold dark:text-gray-300"
                            v-text="t('dragAndDropImage')"
                        />
                        <img
                            v-if="data?.logo"
                            :src="imageHelper(data.logo as string)"
                            class="m-2 max-h-40 rounded-xl opacity-60"
                        />
                    </MyFileSelect>
                </div>
                <MyInput v-model="data.name" name="name" autofocus :label="t('companyName')" />
                <MyVatNumberInput
                    v-model="data.vat"
                    name="vat"
                    :label="t('vat')"
                    autofocus
                    :default-country="data.country"
                />
                <MyAddressInput
                    v-model="address"
                    name="address"
                    :label="t('address')"
                    @change="addressChanged"
                />
                <MyInput
                    v-model="data.contactPerson"
                    name="contactPerson"
                    :label="t('contactPerson')"
                />
                <MyInput v-model="data.email" name="email" :label="t('email')" />
                <MyPhoneInput
                    v-model="data.phoneNumber"
                    name="phoneNumber"
                    :label="t('phoneNumber')"
                />
                <MySelect
                    v-model="data.options.currency"
                    :options="currencyOptions"
                    name="options.currency"
                    :label="t('currency')"
                />
                <MySelect
                    v-model="data.options.timezone"
                    :options="timezones"
                    name="options.timezone"
                    :label="t('timezone')"
                    searchable
                />
            </div>
            <div class="mt-4 flex justify-end gap-6">
                <MyButton :disabled="loading" scheme="primary" v-text="t('update')" />
            </div>
        </MyForm>
    </MyModal>
</template>
