<script lang="ts">
interface Props {
    image: MasterTemplateImage
    companyLogo: string | null
}
</script>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useResizeObserver } from '@vueuse/core'

import { CompanyLogoAngle, MasterTemplateImage } from '@/types/damage-report'
import { image as imageHelper } from '@/utils/assets'

const canvasSize = { width: 1600, height: 900 }

const props = defineProps<Props>()

const canvasScale = ref(1)
const wrapper = ref<HTMLDivElement>()

const logoMatrix = computed(() => {
    if (!props.image.companyLogoAngle) return null

    const json: CompanyLogoAngle = JSON.parse(props.image.companyLogoAngle)
    return json.matrix
})

useResizeObserver(wrapper, ([e]) => {
    canvasScale.value = e.contentRect.width / canvasSize.width
})
</script>

<template>
    <div ref="wrapper" class="relative">
        <img class="h-full w-full select-none" :src="imageHelper(image.path, 'public')" />

        <div v-if="logoMatrix && props.companyLogo" class="logo-wrapper">
            <div class="logo-container" :style="{ transform: `matrix3d(${logoMatrix})` }">
                <div
                    :style="{
                        backgroundImage: `url(${imageHelper(props.companyLogo, 'public')})`,
                    }"
                />
            </div>
        </div>
    </div>
</template>

<style>
.logo-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(v-bind(canvasScale));
    transform-origin: top left;
    mix-blend-mode: multiply;
}

.logo-container {
    width: 200px;
    height: 100px;
    transform-origin: top left;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-container > div {
    width: 50%;
    height: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}
</style>
