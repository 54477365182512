import { camelCase } from 'change-case'
import { ref, watch, WatchSource } from 'vue'
import { useI18n } from 'vue-i18n'

import { datetime } from '@/utils/dates'

/**
 * Hook used to translate attribute fields in the given validation message.
 * @param watchSource
 */
export function useValidationMessage(watchSource: WatchSource<string | undefined>, label?: string) {
    const errorMessage = ref<string>()
    const { t } = useI18n()

    watch(watchSource, (value) => {
        if (!value) {
            errorMessage.value = undefined
            return
        }

        errorMessage.value = value
            // Replace attribute name
            .replace(/{([\w\s.]+)}/g, (_, attribute: string) => {
                // In case the attribute name is options.dude we split to only get dude
                attribute = attribute.split('.').at(-1) || ''
                const value = label ?? t(camelCase(attribute))

                return startsWithCapital(attribute) ? value : value.toLowerCase()
            })
            // Convert dates to local timezone
            .replace(/(\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2})/g, (_, date: string) => {
                return datetime(date + '+0000')
            })
    })

    return errorMessage
}

function startsWithCapital(word: string) {
    return word.charAt(0) === word.charAt(0).toUpperCase()
}
