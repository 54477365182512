import { ref } from 'vue'
import { useRoute } from 'vue-router'

import { InternalTableColumn } from '@/types/table'

/**
 * Internal MyTable hook used for sorting columns
 * @param onChange
 */
export function useColumnSorting(onChange: () => void) {
    const route = useRoute()
    const sortedColumn = ref<string>()
    const sortedAscending = ref(true)

    const sort = route.query.sort as string | undefined
    if (sort) {
        sortedColumn.value = sort.replace('-', '')
        sortedAscending.value = !sort.startsWith('-')
    }

    return {
        sortedColumn,
        sortedAscending,
        onSort(column: InternalTableColumn) {
            if (!column.sortable) return

            if (sortedColumn.value === column.property && !sortedAscending.value) {
                sortedColumn.value = undefined
            } else if (sortedColumn.value === column.property) {
                sortedAscending.value = !sortedAscending.value
            } else {
                sortedColumn.value = column.property
                sortedAscending.value = true
            }
            onChange()
        },
    }
}
