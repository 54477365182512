<script lang="ts" setup>
import { MenuItem } from '@headlessui/vue'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { locales, setI18nLanguage } from '@/i18n/i18n'

import MyMenu from '@/components/my-components/MyMenu.vue'

const { locale } = useI18n({ useScope: 'global' })
const currentLocaleFlag = computed(() => {
    return locales[locale.value as string]?.flag ?? 'us'
})

async function setLocale(newLocale: string) {
    await setI18nLanguage(newLocale)
}
</script>

<template>
    <MyMenu is-in-nav>
        <template #trigger>
            <mdi:web class="mr-2 h-4 w-4" />
            <span class="text-xs font-semibold" v-text="currentLocaleFlag" />
        </template>

        <MenuItem
            v-for="(data, localeName) in locales"
            :key="localeName"
            v-slot="{ active }"
            class="hover:bg-gray-200 hover:text-black dark:text-primary-100 dark:hover:bg-gray-800 dark:hover:text-primary-100"
        >
            <button
                class="flex w-full items-center py-2 px-3 text-sm"
                :class="{ 'bg-green-500 text-white': active }"
                @click="setLocale(localeName)"
            >
                <img
                    :src="`https://flagcdn.com/${data.flag}.svg`"
                    :alt="data.name"
                    class="object-fit mr-3 w-6"
                />

                {{ data.name }}
            </button>
        </MenuItem>
    </MyMenu>
</template>
