<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
    <div class="flex items-center bg-yellow-500 text-white p-2 rounded-lg">
        <mdi:egg-outline class="h-10 w-10" />
        <div class="ml-2 flex flex-col text-sm">
            <span class="font-bold" v-text="t('nilTrade')" />
            <p v-text="t('nilTradeWarning')" />
        </div>
    </div>
</template>
