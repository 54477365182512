import axios, { AxiosRequestConfig } from 'axios'
import axiosRetry from 'axios-retry'

interface UploadResponse {
    result: {
        id: string
    }
}

interface UploadUrlResponse {
    id: string
    uploadURL: string
}

axiosRetry(axios, { retries: 1 })

async function getUploadUrl(): Promise<string> {
    const response = await axios.get<UploadUrlResponse>(
        window.route('upload-url.cloudflare-images'),
    )

    return response.data.uploadURL
}

export async function cloudflareUpload(
    file: File,
    config: AxiosRequestConfig = {},
): Promise<string> {
    try {
        const uploadUrl = await getUploadUrl()
        const formData = new FormData()
        formData.append('file', file!)

        const response = await axios.post<UploadResponse>(uploadUrl, formData, {
            ...config,
            withCredentials: false,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })

        return response.data.result.id
    } catch (e) {
        console.error(e)
        throw e
    }
}
