<script lang="ts" setup>
import { computed, provide, ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import { insertFormWizardStepKey, updateFormWizardStepKey, WizardStep } from '@/types/form'

import MyButton from '@/components/my-components/MyButton.vue'

export interface Props {
    submit?: (step: string) => boolean
    modelValue: string
    onSubmit?: (step: string) => boolean
    completed?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void
}>()
const { t } = useI18n()
const form = ref<HTMLFormElement>()
const steps = ref<WizardStep[]>([])

const completedSteps = ref<number[]>([])
const currentStepIndex = computed<number>(() =>
    steps.value.map((s) => s.id).indexOf(props.modelValue),
)
const currentStep = computed(() => steps.value.find((s) => s.id === props.modelValue))
const loading = computed(() => (currentStep.value ? currentStep.value.loading : false))
const next = ref<number | null>(null)
const hasError = ref(false)

function goToStep(index: number) {
    if (completedSteps.value.length < index) return

    if (
        completedSteps.value.includes(index) ||
        index < currentStepIndex.value ||
        index === completedSteps.value.length
    ) {
        emit('update:modelValue', steps.value[index].id)
        return
    }

    next.value = index
    form.value?.submit()
}

function handleSubmit(e: Event) {
    e.preventDefault()

    const current = currentStepIndex.value

    let onwards = false

    if (props.onSubmit) {
        onwards = props.onSubmit(steps.value[current]?.id)
    }

    if (steps.value[current].submit) {
        onwards = steps.value[current].submit!()
    }

    if (!onwards) {
        hasError.value = true
        return
    }

    if (!completedSteps.value.includes(current) && current !== steps.value.length - 1) {
        completedSteps.value.push(current)
    }

    hasError.value = false
    emit('update:modelValue', steps.value[next.value ?? current + 1].id)
    next.value = null
}

const insertStep = (step: WizardStep) => {
    steps.value.push(step)
}

const updateStep = (step: WizardStep) => {
    steps.value = steps.value.map((s) => (s.id === step.id ? step : s))
}

provide(insertFormWizardStepKey, insertStep)
provide(updateFormWizardStepKey, updateStep)

onMounted(() => {
    if (props.completed) {
        steps.value.forEach((_, index) => {
            completedSteps.value.push(index)
        })
    }
})
</script>

<template>
    <header class="relative z-0">
        <ul class="mb-2 flex justify-between pb-6 uppercase">
            <li
                v-for="(step, index) in steps"
                :key="step.id"
                :class="index === currentStepIndex ? 'z-30' : 'z-10'"
                class="group relative grow"
            >
                <div class="flex flex-col items-center justify-center" @click="goToStep(index)">
                    <div
                        :class="{
                            'border-green-500 bg-green-500 text-primary-50 hover:border-green-400 hover:bg-green-400':
                                completedSteps.includes(index) && index !== currentStepIndex,
                            'cursor-not-allowed':
                                index > completedSteps.length && !completedSteps.includes(index),
                            'border-primary-200 bg-primary-50 dark:border-dark-700 dark:bg-dark-500':
                                index !== currentStepIndex && !completedSteps.includes(index),
                            'hover:bg-primary-200 dark:hover:bg-dark-700':
                                index === completedSteps.length,

                            'pointer-events-none shadow-xl': index === currentStepIndex,
                            'border-primary-300 bg-primary-300  dark:border-dark-400 dark:bg-dark-400':
                                index === currentStepIndex && !hasError,
                            'border-red-500 bg-red-500 text-primary-50':
                                hasError && index === currentStepIndex,
                        }"
                        class="text-md relative z-20 flex h-[50px] w-[50px] cursor-pointer flex-col items-center justify-center rounded-full border-8 transition"
                    >
                        <mdi:building v-if="step.id === 'company'" />
                        <mdi:view-parallel v-if="step.id === 'products'" />
                        <mdi:receipt v-if="step.id === 'details'" />
                        <mdi:check-bold v-if="step.id === 'confirm'" />
                    </div>
                    <span
                        class="absolute top-full z-20 w-full pt-2 text-center text-xs font-semibold"
                        v-text="step.title"
                    />
                </div>

                <span
                    :class="
                        completedSteps.includes(index)
                            ? 'bg-green-500'
                            : {
                                  'bg-green-500': index === currentStepIndex && !hasError,
                                  'bg-red-400': hasError && index === currentStepIndex,
                                  'bg-primary-200 dark:bg-dark-700':
                                      !completedSteps.includes(index) && index !== currentStepIndex,
                              }
                    "
                    class="absolute left-0 top-0 bottom-0 z-10 my-auto h-3 w-full transition group-first:rounded-l-full group-last:rounded-r-full"
                />
            </li>
        </ul>
    </header>
    <form ref="form" @submit.prevent="handleSubmit" @keydown.enter.prevent="handleSubmit">
        <div class="my-10">
            <slot />
        </div>
        <footer class="flex items-center justify-between">
            <div>
                <MyButton
                    :disabled="currentStepIndex === 0"
                    icon
                    plain
                    scheme="light"
                    size="small"
                    @click.prevent="goToStep(currentStepIndex - 1)"
                >
                    <mdi:arrow-left />
                </MyButton>
                <MyButton
                    :disabled="currentStepIndex > steps.length - 2"
                    icon
                    plain
                    scheme="light"
                    size="small"
                    type="submit"
                >
                    <mdi:arrow-right />
                </MyButton>
            </div>
            <slot name="footer" />
            <slot name="submit">
                <MyButton :loading="loading" type="submit" scheme="primary">
                    {{ t('continue') }}
                </MyButton>
            </slot>
        </footer>
    </form>
</template>
