<script lang="ts" setup>
import { onBeforeMount, ref, watch } from 'vue'

import Loader from '@/components/loaders/Loader.vue'

export interface Props {
    visible: boolean
    text?: string
    background?: string
    // Whether to show the loader immediately or wait 250ms
    immediate?: boolean
}

const props = defineProps<Props>()

let timeoutRef: number
const isVisible = ref(false)

onBeforeMount(() => {
    if (props.visible) {
        timeoutRef = window.setTimeout(
            () => {
                isVisible.value = true
            },
            props.immediate ? 0 : 250,
        )
    }
})

watch(
    () => props.visible,
    (value) => {
        if (!value) {
            if (timeoutRef) clearTimeout(timeoutRef)
            isVisible.value = value
        } else {
            timeoutRef = window.setTimeout(
                () => {
                    isVisible.value = true
                },
                props.immediate ? 0 : 250,
            )
        }
    },
)
</script>

<template>
    <div
        class="absolute top-0 left-0 z-20 flex h-full w-full flex-col items-center justify-center bg-opacity-70 transition-all duration-500"
        :class="[
            props.background ?? 'bg-black',
            {
                'invisible opacity-0': !isVisible,
                'opacity-1 visible': isVisible,
            },
        ]"
    >
        <Loader :size="70" />

        <div v-if="props.text" class="text-white" v-text="props.text" />
    </div>
</template>
