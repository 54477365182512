<script lang="ts" setup>
import type { Except } from 'type-fest'

import { useI18n } from 'vue-i18n'
import { watch } from 'vue'
import { notify } from '@kyvg/vue3-notification'
import axios from 'axios'

import useForm from '@/hooks/use-form'
import { PermissionType } from '@/types/general'
import { useAuthStore } from '@/stores/auth-store'
import { useConfirm } from '@/hooks/use-confirm'
import { MyButtonScheme } from '@/types/layout/my-button'
import { LicenseType, WebRole } from '@/types/company'
import { useEmployeeRoleStore } from '@/stores/employee-role-store'

import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'

export interface Props {
    modelValue: boolean
}

interface WebRoleForm extends Except<WebRole, 'id'> {
    id: number | null
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'close', value: void): void
    (e: 'update:modelValue', value: boolean): void
}>()

const { t } = useI18n()
const authStore = useAuthStore()
const roleStore = useEmployeeRoleStore()
const confirm = useConfirm()

const form = useForm<WebRoleForm>({
    id: null,
    name: '',
    permissions: [],
})

function editRole(role: WebRole) {
    form.reset({
        id: role.id,
        name: role.name,
        permissions: role.permissions,
    })
}

function togglePermission(permission: PermissionType) {
    if (form.data.permissions.includes(permission)) {
        form.data.permissions = form.data.permissions.filter((p) => p !== permission)
    } else {
        form.data.permissions.push(permission)
    }
}

function hasPermission(permission: PermissionType): boolean {
    return form.data.permissions.includes(permission)
}

async function removeRole(role: WebRole) {
    try {
        await confirm(
            t('deleteEntityTitle', { entity: t('userRole') }),
            t('deleteEntityDescription', { entity: t('userRole') }),
            {
                confirmText: t('yes'),
                cancelText: t('no'),
                confirmButtonScheme: MyButtonScheme.Warning,
            },
        )

        await axios.delete(
            window.route('company.web-roles.destroy', [authStore.companyId, role.id]),
        )
        roleStore.fetchWebRoles(true)
    } catch (_) {
        //
    }
}

async function onSubmit() {
    const method = form.data.id ? 'PUT' : 'POST'
    let url = window.route('company.web-roles.store', [authStore.companyId])

    if (form.data.id) {
        url = window.route('company.web-roles.update', [authStore.companyId, form.data.id])
    }

    const response = await form.submit(method, url)

    if (response) {
        roleStore.fetchWebRoles(true)
        notify(t(form.data.id ? 'roleHasBeenUpdated' : 'roleHasBeenCreated'))
        form.reset()
    }
}

watch(
    () => props.modelValue,
    () => form.reset(),
)
</script>

<template>
    <MyModal :max-width="550" :value="props.modelValue" @close="emit('update:modelValue', false)">
        <template #title>
            {{ t('userRoles') }}
        </template>

        <LoaderWrapper :visible="form.loading.value || roleStore.fetchingWebRoles" />

        <div class="mt-2 text-sm">
            <div v-for="role in roleStore.webRoles" :key="role.id">
                <div class="flex items-center justify-between">
                    <h3 class="text-base font-semibold text-primary-400" v-text="role.name" />

                    <div>
                        <MyButton
                            v-tooltip="t('updateUserRole')"
                            type="button"
                            icon
                            @click="editRole(role)"
                        >
                            <mdi:pencil />
                        </MyButton>

                        <MyButton
                            v-tooltip="t('remove')"
                            type="button"
                            icon
                            @click="removeRole(role)"
                        >
                            <mdi:trash-can-outline class="text-red-500" />
                        </MyButton>
                    </div>
                </div>

                <div class="flex flex-wrap">
                    <div
                        v-for="permission in role.permissions"
                        :key="permission"
                        class="mr-2 mb-2 flex"
                    >
                        {{ t(permission + 'Permission') }}
                        <mdi:information
                            v-tooltip="t(permission + 'PermissionExplanation')"
                            class="ml-1"
                        />
                    </div>

                    <div v-if="role.permissions.length === 0" v-text="t('noPermissions')" />
                </div>

                <hr class="my-2" />
            </div>

            <h1
                class="mt-4 mb-2 text-lg font-semibold text-primary-400"
                v-text="t(form.data.id ? 'updateUserRole' : 'createUserRole')"
            />

            <MyForm :errors="form.errors.value" class="space-y-4" @submit.prevent="onSubmit">
                <MyInput v-model="form.data.name" name="name" :label="t('name')" />

                <div class="grid grid-cols-2 gap-2">
                    <div class="flex items-center space-x-1">
                        <MyCheckbox
                            :checked="hasPermission(PermissionType.ManageCompany)"
                            :label="t('manageCompanyPermission')"
                            @change="togglePermission(PermissionType.ManageCompany)"
                        />
                        <mdi:information v-tooltip="t('manageCompanyPermissionExplanation')" />
                    </div>

                    <div class="flex items-center space-x-1">
                        <MyCheckbox
                            :checked="hasPermission(PermissionType.ManageCustomers)"
                            :label="t('manageCustomersPermission')"
                            @change="togglePermission(PermissionType.ManageCustomers)"
                        />
                        <mdi:information v-tooltip="t('manageCustomersPermissionExplanation')" />
                    </div>
                </div>

                <div v-if="authStore.hasLicense(LicenseType.PackagingModule)">
                    <h4
                        class="text-md mt-4 mb-2 font-semibold text-primary-400"
                        v-text="t('packaging')"
                    />

                    <div class="grid grid-cols-2 gap-2">
                        <div class="flex items-center space-x-1">
                            <MyCheckbox
                                :checked="hasPermission(PermissionType.ManageTransactions)"
                                :label="t('manageTransactionsPermission')"
                                @change="togglePermission(PermissionType.ManageTransactions)"
                            />
                            <mdi:information
                                v-tooltip="t('manageTransactionsPermissionExplanation')"
                            />
                        </div>

                        <div class="flex items-center space-x-1">
                            <MyCheckbox
                                :checked="hasPermission(PermissionType.CreateBankStatements)"
                                :label="t('createBankStatementsPermission')"
                                @change="togglePermission(PermissionType.CreateBankStatements)"
                            />
                            <mdi:information
                                v-tooltip="t('createBankStatementsPermissionExplanation')"
                            />
                        </div>

                        <div class="flex items-center space-x-1">
                            <MyCheckbox
                                :checked="hasPermission(PermissionType.ResetCustomerBalance)"
                                :label="t('resetCustomerBalancePermission')"
                                @change="togglePermission(PermissionType.ResetCustomerBalance)"
                            />
                            <mdi:information
                                v-tooltip="t('resetCustomerBalancePermissionExplanation')"
                            />
                        </div>
                    </div>
                </div>

                <div class="flex flex-row-reverse justify-start space-x-3">
                    <MyButton
                        :disabled="form.loading.value"
                        scheme="primary"
                        v-text="t(form.data.id ? 'update' : 'create')"
                    />

                    <MyButton type="button" @click="emit('update:modelValue', false)">
                        {{ t('cancel') }}
                    </MyButton>
                </div>
            </MyForm>
        </div>
    </MyModal>
</template>
