<script lang="ts">
interface Props {
    inputName?: string
    error?: string
    label?: string
}
</script>

<script lang="ts" setup>
import { computed, inject } from 'vue'

import { useValidationMessage } from '@/hooks/use-validation-message'
import { formErrorsKey } from '@/types/global-injection-keys'

const props = defineProps<Props>()

const snakeName = computed(() => props.inputName ?? '')

const errors = inject(formErrorsKey, undefined)
const error = computed<string | undefined>(() => {
    if (props.error) return props.error
    if (!props.inputName || !errors?.value) return undefined

    return errors.value[snakeName.value]?.[0]
})
const errorMessage = useValidationMessage(error, props.label)
</script>

<template>
    <span v-if="errorMessage" class="mt-1 block pl-2 text-xs text-red-500" v-text="errorMessage" />
</template>
