<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { ref } from 'vue'

import { usePaginatedTable } from '@/hooks/table/use-paginated-table'
import { PaginatedResponse, PermissionType } from '@/types/general'
import { useAuthStore } from '@/stores/auth-store'
import { AlarmNotification } from '@/types/alarm'
import { LicenseType } from '@/types/company'
import { alarmTranslations } from '@/utils/alarm'
import { MyButtonScheme } from '@/types/layout/my-button'
import { useConfirm } from '@/hooks/use-confirm'

import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import MyTable from '@/components/table/MyTable.vue'
import MyTableColumn from '@/components/table/MyTableColumn.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import ManageAlarmNotificationModal from '@/components/alarms/ManageAlarmNotificationModal.vue'
import ActionMenuItem from '@/components/table/ActionMenuItem.vue'

const authStore = useAuthStore()
const { t } = useI18n()
const confirm = useConfirm()

const notificationModalOpen = ref(false)
const selectedNotification = ref<AlarmNotification>()
const table = usePaginatedTable<AlarmNotification>(async (params) => {
    const response = await axios.get<PaginatedResponse<AlarmNotification>>(
        window.route('company.alarm-notifications.index', {
            company: authStore.companyId,
        }),
        { params: params },
    )

    return response.data
})

function getAlarmTranslations(notification: AlarmNotification, module: LicenseType): string {
    const types = notification.alarms[module]

    if (types === undefined || types === null) return t('off')
    if (types.length === 0) return t('all')

    return types.map((type) => t(alarmTranslations[type])).join(', ')
}

function editAlarmNotification(alarmNotification: AlarmNotification) {
    selectedNotification.value = alarmNotification
    notificationModalOpen.value = true
}

async function deleteAlarmNotification(alarmNotification: AlarmNotification) {
    try {
        await confirm(
            t('deleteEntityTitle', { entity: t('alarmNotification') }),
            t('deleteEntityDescription', { entity: t('alarmNotification') }),
            {
                confirmText: t('yes'),
                cancelText: t('no'),
                confirmButtonScheme: MyButtonScheme.Warning,
            },
        )
    } catch {
        return
    }

    await axios.delete(
        window.route('company.alarm-notifications.update', [
            authStore.companyId,
            alarmNotification.id,
        ]),
    )
    await table.refetch()
}
</script>

<template>
    <CrumbsAndActions>
        <Breadcrumb :to="{ name: 'settings' }" v-text="t('settings')" />
        <Breadcrumb current v-text="t('alarmNotifications')" />

        <template v-if="authStore.hasPermission(PermissionType.ManageCompany)" #actions>
            <MyButton plain scheme="primary" size="small" @click="notificationModalOpen = true">
                <mdi:plus class="mr-1" />
                {{ t('addConfiguration') }}
            </MyButton>
        </template>
    </CrumbsAndActions>

    <MyPanel shadow>
        <MyTable
            :error="table.error.value"
            :get-data="table.refetch"
            :loading="table.loading.value"
            :pagination-data="table.paginationData.value"
            :rows="table.data.value"
            table-id="settings-alarms-notifications"
            :entity-name="t('alarmNotifications')"
        >
            <template
                v-if="authStore.hasPermission(PermissionType.ManageCompany)"
                #actionMenu="{ row }"
            >
                <ActionMenuItem
                    class="flex cursor-pointer items-center rounded-lg py-2 px-3 text-sm hover:bg-primary-500 hover:text-primary-50 dark:hover:bg-dark-300"
                    @click="editAlarmNotification(row)"
                >
                    <mdi:file-edit class="mr-2" />
                    {{ t('edit') }}
                </ActionMenuItem>

                <ActionMenuItem
                    class="flex cursor-pointer items-center rounded-lg py-2 px-3 text-sm hover:bg-primary-500 hover:text-primary-50 dark:hover:bg-dark-300"
                    @click="deleteAlarmNotification(row)"
                >
                    <mdi:trash-can class="mr-2" />
                    {{ t('delete') }}
                </ActionMenuItem>
            </template>

            <MyTableColumn :name="t('name')" property="name" />
            <MyTableColumn :name="t('emails')" property="recipients" />
            <template #recipients="{ row }">{{ row.recipients.join(', ') }}</template>
            <MyTableColumn
                v-if="authStore.hasLicense(LicenseType.PackagingModule)"
                :name="`${t('packaging')} ${t('alarms'.toLowerCase())}`"
                property="packagingAlarms"
                :sortable="false"
            />
            <template #packagingAlarms="{ row }">
                {{ getAlarmTranslations(row, LicenseType.PackagingModule) }}
            </template>
            <MyTableColumn
                v-if="authStore.hasLicense(LicenseType.DamageReportModule)"
                :name="`${t('damageReport')} ${t('alarms'.toLowerCase())}`"
                property="damageReportAlarms"
                :sortable="false"
            />
            <template #damageReportAlarms="{ row }">
                {{ getAlarmTranslations(row, LicenseType.DamageReportModule) }}
            </template>

            <MyTableColumn
                v-if="authStore.hasLicense(LicenseType.DeliveryManagementModule)"
                :name="`${t('deliveryManagement')} ${t('alarms'.toLowerCase())}`"
                property="deliveryManagementAlarms"
                :sortable="false"
            />
            <template #deliveryManagementAlarms="{ row }">
                {{ getAlarmTranslations(row, LicenseType.DeliveryManagementModule) }}
            </template>
        </MyTable>
    </MyPanel>

    <ManageAlarmNotificationModal
        v-model="notificationModalOpen"
        :alarm-notification="selectedNotification"
        @saved="table.refetch()"
    />
</template>
