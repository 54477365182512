<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { nextTick, onBeforeMount, ref, watch } from 'vue'
import axios from 'axios'

import { FieldPreference } from '@/types/general'
import { useAuthStore } from '@/stores/auth-store'
import { LicenseType } from '@/types/company'
import { DefaultProductSort, PackagingPreferences, ProductPageStartTab } from '@/types/packaging'

import Loader from '@/components/loaders/Loader.vue'
import MyRadioButtonGroup from '@/components/my-components/form/MyRadioButtonGroup.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import MyRadioButton from '@/components/my-components/form/MyRadioButton.vue'
import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'

let mounted = false

const authStore = useAuthStore()
const { t } = useI18n()

const preferences = ref<PackagingPreferences>({
    signature: FieldPreference.Required,
    signatureScanner: FieldPreference.Hidden,
    consignmentNote: FieldPreference.Optional,
    shipmentNumber: FieldPreference.Optional,
    note: FieldPreference.Optional,
    truckNumber: FieldPreference.Optional,
    trailerNumber: FieldPreference.Optional,
    defaultProductSort: DefaultProductSort.Custom,
    productPageStartTab: ProductPageStartTab.Delivered,
    transitLocationTrades: false,
    nilTradesEnabled: false,
    palletReceiptNumber: FieldPreference.Hidden,
})
const saving = ref(false)

async function savePreferences() {
    if (!mounted) return

    saving.value = true
    await axios.put(
        window.route('packaging.company.preferences.update', { company: authStore.companyId }),
        preferences.value,
    )
    authStore.licenses[LicenseType.PackagingModule] = {
        ...authStore.licenses[LicenseType.PackagingModule],
        ...preferences.value,
    }
    saving.value = false
}

watch(preferences, savePreferences, { deep: true })

onBeforeMount(async () => {
    const prefs: Partial<PackagingPreferences> =
        authStore.licenses[LicenseType.PackagingModule] ?? {}

    preferences.value = { ...preferences.value, ...prefs }
    await nextTick()
    mounted = true
})
</script>

<template>
    <MyPanel panel-class="w-96" padded shadow>
        <Loader
            class="absolute right-6 top-6 opacity-0 transition-opacity duration-300"
            :class="{ 'opacity-100': saving }"
        />

        <div class="flex flex-col space-y-3">
            <MyRadioButtonGroup
                v-model="preferences.productPageStartTab"
                :label="t('productPageStartTab')"
            >
                <MyRadioButton :label="t('delivered')" :value="ProductPageStartTab.Delivered" />
                <MyRadioButton :label="t('received')" :value="ProductPageStartTab.Received" />
            </MyRadioButtonGroup>

            <MyRadioButtonGroup v-model="preferences.signature" :label="t('signature')">
                <MyRadioButton :label="t('required')" :value="FieldPreference.Required" />
                <MyRadioButton :label="t('optional')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />
            </MyRadioButtonGroup>

            <MyRadioButtonGroup v-model="preferences.consignmentNote" :label="t('consignmentNote')">
                <MyRadioButton :label="t('required')" :value="FieldPreference.Required" />
                <MyRadioButton :label="t('optional')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />
            </MyRadioButtonGroup>

            <MyRadioButtonGroup v-model="preferences.shipmentNumber" :label="t('shipmentNumber')">
                <MyRadioButton :label="t('required')" :value="FieldPreference.Required" />
                <MyRadioButton :label="t('optional')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />
            </MyRadioButtonGroup>

            <MyRadioButtonGroup v-model="preferences.truckNumber" :label="t('truckNumber')">
                <MyRadioButton :label="t('required')" :value="FieldPreference.Required" />
                <MyRadioButton :label="t('optional')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />
            </MyRadioButtonGroup>

            <MyRadioButtonGroup v-model="preferences.trailerNumber" :label="t('trailerNumber')">
                <MyRadioButton :label="t('required')" :value="FieldPreference.Required" />
                <MyRadioButton :label="t('optional')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />
            </MyRadioButtonGroup>

            <MyRadioButtonGroup v-model="preferences.note" :label="t('note')">
                <MyRadioButton :label="t('required')" :value="FieldPreference.Required" />
                <MyRadioButton :label="t('optional')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />
            </MyRadioButtonGroup>

            <MyRadioButtonGroup
                v-model="preferences.palletReceiptNumber"
                :label="t('palletReceiptNumber')"
            >
                <MyRadioButton :label="t('required')" :value="FieldPreference.Required" />
                <MyRadioButton :label="t('optional')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />
            </MyRadioButtonGroup>

            <MyRadioButtonGroup
                v-model="preferences.signatureScanner"
                class="relative"
                :label="t('signeeScanner')"
            >
                <MyRadioButton :label="t('visible')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />

                <template #label>
                    {{ t('signeeScanner') }}
                    <mdi:information
                        v-tooltip="t('signeeScannerExplanation')"
                        class="absolute top-1 right-0"
                    />
                </template>
            </MyRadioButtonGroup>

            <MyRadioButtonGroup
                v-model="preferences.defaultProductSort"
                class="relative"
                :label="t('defaultProductSort')"
            >
                <MyRadioButton :label="t('custom')" :value="DefaultProductSort.Custom" />
                <MyRadioButton :label="t('byUsage')" :value="DefaultProductSort.ByUsage" />

                <template #label>
                    {{ t('defaultProductSort') }}
                    <mdi:information
                        v-tooltip="t('defaultProductSortExplanation')"
                        class="absolute top-1 right-0"
                    />
                </template>
            </MyRadioButtonGroup>

            <div class="flex items-center space-x-2">
                <MyCheckbox
                    v-model="preferences.transitLocationTrades"
                    :label="t('transitTrades')"
                />
                <mdi:information v-tooltip="t('transitTradesExplanation')" />
            </div>

            <div class="flex items-center space-x-2">
                <MyCheckbox v-model="preferences.nilTradesEnabled" :label="t('allowNilTrades')" />
                <mdi:information v-tooltip="t('allowNilTradesExplanation')" />
            </div>
        </div>
    </MyPanel>
</template>
