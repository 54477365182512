<script lang="ts" setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import axios from 'axios'
import { computed, inject, onBeforeMount, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router'

import { useAuthStore } from '@/stores/auth-store'
import { ResourceResponse, uuid } from '@/types/general'
import { Receipt, ReceiptType } from '@/types/pod'
import { asset, image as imageHelper, localAsset } from '@/utils/assets'
import { printElement } from '@/utils/print-element'
import { imageViewerOpenKey } from '@/types/global-injection-keys'

import MapComponent from '@/components/MapComponent.vue'
import MyDispatchLog from '@/components/my-components/MyDispatchLog.vue'
import ModalAttachment from '@/components/ModalAttachment.vue'
import BasicShareModal from '@/components/BasicShareModal.vue'
import ContentHeading from '@/components/layout/ContentHeading.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyCompanyCard from '@/components/my-components/MyCompanyCard.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyImageViewer from '@/components/my-components/MyImageViewer.vue'

export interface Props {
    id?: string
    open?: boolean
}

const emit = defineEmits<{ (e: 'update:open', value: boolean): void }>()
const props = withDefaults(defineProps<Props>(), { open: true })

const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const authStore = useAuthStore()

const imageViewerOpen = inject(imageViewerOpenKey)!

const modalContent = ref<HTMLDivElement>()
const receipt = ref<Receipt | null>(null)
const loading = ref(false)
const shareReceiptOpen = ref(false)
const updateEconomyModalOpen = ref(false)

const shareEndpoint = computed(() => {
    if (!authStore.companyId) return ''

    return window.route('pod.company.receipts.share', {
        company: authStore.companyId,
        receipt: receipt.value?.id ?? '',
    })
})

const largeLayout = computed(() => {
    if (!receipt.value) return false

    return (
        receipt.value.note ||
        receipt.value.shipmentNumber ||
        receipt.value.trailerNumber ||
        receipt.value.truckNumber ||
        receipt.value.images.length > 0 ||
        receipt.value.consignmentNotes.length > 0 ||
        receipt.value.transactionId ||
        receipt.value.shipmentId
    )
})

function transactionLink(id: string): RouteLocationRaw {
    if (route.name?.toString().includes('customer')) {
        return {
            name: 'packaging.customers.transactions.show',
            params: { ...route.params, transactionId: id },
            query: route.query,
        }
    }

    return {
        name: 'packaging.transactions.show',
        params: { ...route.params, transactionId: id },
    }
}

function shipmentLink(id: string): RouteLocationRaw {
    return {
        name: 'dm.shipments.show',
        params: { ...route.params, shipmentId: id },
    }
}

async function fetchReceipt() {
    const receiptId = (route.params.receiptId as uuid | undefined) || props.id
    if (!receiptId) return

    loading.value = true

    try {
        let url = window.route('pod.receipts.show', { receipt: receiptId })
        if (authStore.hasCompany) {
            url = window.route('pod.company.receipts.show', {
                company: authStore.companyId,
                receipt: receiptId,
            })
        }

        const response = await axios.get<ResourceResponse<Receipt>>(url)
        receipt.value = response.data.data
    } finally {
        loading.value = false
    }
}

function onClose() {
    if (imageViewerOpen.value) return

    if (authStore.user == null) {
        router.push({ name: 'login' })
    } else if (props.id) {
        emit('update:open', false)
    } else if (route.name?.toString().includes('customer')) {
        router.replace({
            name: 'pod.customers.show',
            params: { ...route.params },
            query: route.query,
        })
    } else {
        router.replace({ name: 'pod.receipts', query: route.query })
    }
}

function print() {
    printElement(modalContent.value!)
}

onBeforeMount(fetchReceipt)
watch(() => props.id, fetchReceipt)
watch(() => route.params.receiptId, fetchReceipt)
</script>

<template>
    <div>
        <!-- TODO: This needs large layout -->
        <MyModal :value="props.open" :max-width="largeLayout ? 1200 : 600" @close="onClose">
            <LoaderWrapper :visible="loading" />

            <div v-if="receipt" ref="modalContent">
                <div class="mb-6 flex justify-between">
                    <ContentHeading class="text-xl font-bold">
                        <template v-if="receipt.type === ReceiptType.Delivery">
                            {{ t('deliveryFull') }}
                        </template>
                        <template v-else>{{ t('dispatchFull') }}</template>

                        <span
                            v-if="receipt.deletedAt"
                            v-tooltip="t('deletedReceiptExplanation')"
                            class="ml-2 font-bold text-red-500"
                        >
                            ({{ t('deleted') }})
                        </span>

                        <span v-if="receipt.hasDeviation" class="ml-2 font-bold text-red-500">
                            ({{ t('withDeviation') }})
                        </span>
                    </ContentHeading>

                    <img
                        class="qrcode ml-auto hidden print:block"
                        style="width: 120px; height: 120px"
                    />

                    <div class="space-x-2">
                        <MyButton icon plain scheme="light" size="small" @click="print()">
                            <mdi:printer />
                        </MyButton>
                        <MyButton
                            v-if="!!authStore.user"
                            v-tooltip="t('shareEntity', { entity: t('receipt') })"
                            icon
                            plain
                            scheme="light"
                            size="small"
                            @click="shareReceiptOpen = true"
                        >
                            <mdi:share />
                        </MyButton>
                    </div>
                </div>

                <section
                    :class="{
                        'grid-cols-2 gap-6 lg:grid': largeLayout,
                    }"
                    class="mt-6"
                >
                    <div class="space-y-6">
                        <MapComponent
                            :markers="[{ location: receipt.location }]"
                            class="h-[250px] w-full rounded-xl bg-green-500 shadow-xl print:shadow-none overflow-hidden"
                        />

                        <div class="space-y-6 print:flex print:space-y-0">
                            <MyCompanyCard
                                v-if="receipt.receivingLocation || receipt.receiverSignature"
                                :title="t('receiver')"
                                :company="receipt.receivingCompany"
                                :location="receipt.receivingLocation"
                                :show-user="!!receipt.receiverSignature || !!receipt.receivingUser"
                                :user-title="t('approvedBy')"
                                :user="{
                                    image:
                                        receipt.receivingUser?.profileImage ??
                                        receipt.receiverSignature,
                                    name: receipt.receivingUser?.name ?? receipt.receiverName ?? '',
                                    date: receipt.transpiredAt,
                                }"
                            />

                            <MyCompanyCard
                                :title="t('sender')"
                                :company="receipt.sendingCompany"
                                :location="receipt.sendingLocation"
                                :show-user="!receipt.haulierCompany"
                                :user-title="t('createdBy')"
                                :user="{
                                    image: receipt.sendingUser.profileImage,
                                    name: receipt.sendingUser.name,
                                    date: receipt.transpiredAt,
                                }"
                            />
                        </div>

                        <MyCompanyCard
                            v-if="receipt.shipperCompany"
                            :title="t('shipper')"
                            :company="receipt.shipperCompany"
                            :location="receipt.shipperLocation"
                        />

                        <MyCompanyCard
                            v-if="receipt.haulierCompany && !receipt.haulierHidden"
                            :title="t('haulier')"
                            :company="receipt.haulierCompany"
                            :location="receipt.haulierLocation"
                            show-user
                            :user-title="t('createdBy')"
                            :user="{
                                image: receipt.sendingUser.profileImage,
                                name: receipt.sendingUser.name,
                                date: receipt.transpiredAt,
                            }"
                        />
                    </div>

                    <div class="space-y-3">
                        <div
                            v-if="receipt.transactionId"
                            class="flex justify-between text-sm print:hidden"
                        >
                            <h3 class="font-semibold uppercase text-primary-400">
                                {{ t('packagingTransaction') }}
                            </h3>

                            <RouterLink
                                :to="transactionLink(receipt.transactionId)"
                                class="font-semibold uppercase"
                                v-text="t('view')"
                            ></RouterLink>
                        </div>
                        <ModalAttachment v-if="receipt.shipmentNumber" :title="t('shipmentNumber')">
                            <template #content>
                                <RouterLink
                                    v-if="receipt.shipmentId"
                                    :to="shipmentLink(receipt.shipmentId!)"
                                    class="font-semibold uppercase"
                                    v-text="t('view') + ' ' + receipt.shipmentNumber"
                                ></RouterLink>
                                <span
                                    v-else
                                    class="font-semibold uppercase"
                                    v-text="receipt.shipmentNumber"
                                />
                            </template>
                        </ModalAttachment>

                        <ModalAttachment
                            v-if="receipt.note"
                            :title="t('note')"
                            :content="receipt.note"
                            break
                        >
                            <template #title>
                                <h3 class="text-sm font-semibold uppercase text-primary-400">
                                    {{ t('note') }}
                                    <span
                                        v-if="receipt.hasDeviation"
                                        v-tooltip="t('withDeviation')"
                                        class="font-bold text-red-500"
                                    >
                                        !
                                    </span>
                                </h3>
                            </template>
                        </ModalAttachment>

                        <ModalAttachment
                            v-if="receipt.trailerNumber"
                            :title="t('trailerNumber')"
                            :content="receipt.trailerNumber"
                        />
                        <ModalAttachment
                            v-if="receipt.truckNumber"
                            :title="t('truckNumber')"
                            :content="receipt.truckNumber"
                        />

                        <div
                            v-if="authStore.isLoggedIn"
                            class="flex justify-between text-sm print:hidden"
                        >
                            <h3
                                class="flex flex-shrink-0 items-center font-semibold uppercase text-primary-400"
                                :class="{
                                    'w-full justify-between': !receipt.companyDetails.economy,
                                }"
                            >
                                {{ t('economyNote') }}

                                <MyButton
                                    v-tooltip="t('edit')"
                                    icon
                                    class="text-gray-600 dark:text-primary-50"
                                    @click="updateEconomyModalOpen = true"
                                >
                                    <mdi:pencil />
                                </MyButton>
                            </h3>

                            <div
                                class="flex w-full items-center justify-end text-gray-600 dark:text-primary-50"
                            >
                                {{ receipt.companyDetails.economy }}
                            </div>
                        </div>

                        <Disclosure v-if="receipt.images.length > 0" as="div" default-open>
                            <DisclosureButton
                                class="mb-2 flex w-full justify-between rounded-lg bg-primary-200 px-4 py-2 text-left text-sm font-semibold uppercase text-primary-400 transition hover:bg-primary-300 dark:bg-dark-400 dark:text-primary-300 dark:hover:bg-dark-300"
                            >
                                <h3
                                    class="hidden font-semibold uppercase text-primary-400 print:mb-2 print:block"
                                    v-text="t('images')"
                                />
                                {{ t('images') }}
                            </DisclosureButton>
                            <DisclosurePanel>
                                <MyImageViewer class="grid grid-cols-4 gap-3">
                                    <div
                                        v-for="image in receipt.images"
                                        :key="image.path"
                                        class="relative flex cursor-pointer items-center justify-center overflow-hidden rounded-xl bg-primary-300"
                                    >
                                        <img
                                            class="min-h-full w-auto object-cover"
                                            :src="imageHelper(image.path!, 'thumbnail')"
                                            alt=" "
                                            :data-src="imageHelper(image.path!, 'public')"
                                        />
                                    </div>
                                </MyImageViewer>
                            </DisclosurePanel>
                        </Disclosure>

                        <Disclosure
                            v-if="receipt.consignmentNotes.length > 0"
                            as="div"
                            default-open
                        >
                            <DisclosureButton
                                class="mb-2 flex w-full justify-between rounded-lg bg-primary-200 px-4 py-2 text-left text-sm font-semibold uppercase text-primary-400 transition hover:bg-primary-300 dark:bg-dark-400 dark:text-primary-300 dark:hover:bg-dark-300"
                            >
                                {{ t('consignmentNotes') }}
                            </DisclosureButton>
                            <DisclosurePanel>
                                <MyImageViewer class="grid grid-cols-4 gap-3">
                                    <template
                                        v-for="consignmentNote in receipt.consignmentNotes"
                                        :key="consignmentNote.id"
                                    >
                                        <a
                                            v-if="
                                                consignmentNote.name?.includes('.pdf') ||
                                                consignmentNote.path?.includes('.pdf')
                                            "
                                            class="relative flex flex-col items-center justify-center overflow-hidden rounded-xl bg-primary-300"
                                            :href="asset(consignmentNote.path!)"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <span
                                                class="flex min-h-[5rem] grow items-center justify-center text-center text-3xl text-red-400"
                                            >
                                                <mdi:file-pdf-box />
                                            </span>

                                            <div
                                                v-truncate-tooltip
                                                class="mb-1 w-full flex-shrink-0 truncate px-3 text-center"
                                                v-text="consignmentNote.name"
                                            />
                                        </a>

                                        <img
                                            v-else
                                            class="relative flex cursor-pointer flex-col items-center justify-center overflow-hidden rounded-xl bg-primary-300 object-cover"
                                            :src="imageHelper(consignmentNote.path!, 'thumbnail')"
                                            :alt="consignmentNote.name"
                                            :data-src="imageHelper(consignmentNote.path!, 'public')"
                                        />
                                    </template>
                                </MyImageViewer>
                            </DisclosurePanel>
                        </Disclosure>
                    </div>
                </section>
                <img
                    :src="localAsset('assets/begreenprintlabel.png')"
                    alt="begreen"
                    class="absolute bottom-0 right-0 w-32 h-32 hidden opacity-30 print:block"
                />
                <MyDispatchLog
                    v-if="receipt.dispatches.length > 0"
                    class="mt-3"
                    :dispatches="receipt.dispatches"
                />
            </div>
            <h1
                v-if="!receipt && !loading"
                class="my-auto text-center text-4xl font-bold"
                v-text="t('receiptNotFound')"
            />
        </MyModal>

        <BasicShareModal
            v-if="receipt"
            v-model="shareReceiptOpen"
            :entity="t('receipt')"
            :endpoint="shareEndpoint"
            :customer-company-id="receipt?.receivingCompany?.id"
        />
    </div>
</template>
