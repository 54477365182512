<script lang="ts" setup>
import { computed, ref, watch, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'

import { TemplateField } from '@/types/damage-report'
import { LanguageCode, languagesCodes } from '@/utils/language-codes'
import { useConfirm } from '@/hooks/use-confirm'
import { MyButtonScheme } from '@/types/layout/my-button'

import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'

export interface Props {
    modelValue: boolean
    field: TemplateField
}

const emit = defineEmits<{
    (e: 'save', value: TemplateField): void
    (e: 'update:modelValue', value: boolean): void
}>()
const props = defineProps<Props>()

const confirm = useConfirm()
const translations = ref(props.field.translations)
const selectValue = ref<LanguageCode | null>(null)

const { t } = useI18n()

const languages = computed(() => {
    const existingLanguages = Object.keys(translations.value)
    return Object.keys(languagesCodes)
        .filter((key) => !existingLanguages.includes(key as LanguageCode))
        .map((key) => {
            return {
                label: languagesCodes[key as unknown as LanguageCode],
                value: key,
            }
        })
})

async function addLanguage() {
    if (!selectValue.value) {
        return
    }

    translations.value[selectValue.value] = ''
    await nextTick()
    selectValue.value = null
}

async function removeLanguage(countryCode: string) {
    try {
        await confirm(
            t('deleteEntityTitle', { entity: t('translation') }),
            t('deleteTranslationDescription'),
            {
                confirmText: t('yes'),
                cancelText: t('no'),
                confirmButtonScheme: MyButtonScheme.Warning,
            },
        )
    } catch {
        return
    }

    const limitedCountryCode = countryCode as LanguageCode
    if (limitedCountryCode in translations.value) {
        delete translations.value[limitedCountryCode]
    }
}

function onSubmit() {
    emit('save', { ...props.field, translations: translations.value })
    emit('update:modelValue', false)
}

watch(
    () => props.modelValue,
    () => {
        if (!props.modelValue) {
            return
        }
    },
)
</script>

<template>
    <MyModal
        :value="props.modelValue"
        back-button
        @back-button-clicked="emit('update:modelValue', false)"
        @close="emit('update:modelValue', false)"
    >
        <template #title>{{ t('translateField') }}</template>

        <MyForm class="space-y-4" @submit.prevent="onSubmit()">
            <div
                v-for="(translation, countryCode) in translations"
                :key="countryCode"
                class="flex flex-row-reverse items-center"
            >
                <MyButton
                    v-if="countryCode.toString() !== 'en'"
                    class="self-end"
                    plain
                    icon
                    type="button"
                    @click="removeLanguage(countryCode as unknown as LanguageCode)"
                >
                    <mdi:close />
                </MyButton>

                <MyInput
                    v-model="translations[countryCode]"
                    name="name"
                    autofocus
                    :label="
                        languagesCodes[countryCode as unknown as LanguageCode] +
                        ' (' +
                        countryCode +
                        ')'
                    "
                    :placeholder="t('translation')"
                    required
                />
            </div>

            <MySelect
                v-model="selectValue"
                name="type"
                searchable
                :label="t('translationLanguage')"
                :options="languages"
                :placeholder="t('addLanguage')"
                clear-button
                @change="addLanguage()"
            />
            <div class="flex justify-end">
                <MyButton scheme="primary">{{ t('save') }}</MyButton>
            </div>
        </MyForm>
    </MyModal>
</template>
