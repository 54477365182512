<script lang="ts">
interface Props {
    customerCompanyId?: uuid
}
</script>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { onBeforeMount, computed, ref } from 'vue'
import { RouteLocationRaw, useRoute } from 'vue-router'

import { useAuthStore } from '@/stores/auth-store'
import { MinimalResource, ResourceResponse, uuid, PaginatedResponse } from '@/types/general'
import { usePaginatedTable } from '@/hooks/table/use-paginated-table'
import { DeviationState, MinimalCompanyTag, MinimalTicket } from '@/types/delivery-management'
import { GetDataParameters } from '@/types/table'
import { DropdownOption, DropdownStringOption } from '@/types/inputs'
import { datetime } from '@/utils/dates'
import { useLazyLoadedList } from '@/hooks/use-lazy-loaded-list'
import { image } from '@/utils/assets'
import { useCompanyStore } from '@/stores/company-store'
import { useMittListener } from '@/hooks/use-mitt-listener'

import MyTable from '@/components/table/MyTable.vue'
import MyTableColumn from '@/components/table/MyTableColumn.vue'
import MyFilterButton from '@/components/table/MyFilterButton.vue'
import MyFilterDivider from '@/components/table/MyFilterDivider.vue'
import MyFilterSelect from '@/components/table/MyFilterSelect.vue'
import ActionRowItem from '@/components/table/ActionRowItem.vue'
import TicketExportModal from '@/components/delivery-management/TicketExportModal.vue'

interface DeliveryManagementPaginatedResponse<Model> extends PaginatedResponse<Model> {
    newTicketsCount: number
    ongoingTicketsCount: number
}

interface FetchTicketsParams extends Record<string, unknown>, GetDataParameters {
    filter: {
        state: string | undefined
        responsibility: string | undefined
        'tag-name': uuid | undefined
        'user-id': uuid | undefined
        'hub-id': uuid | undefined
        'customer-company-id': uuid | undefined
    }
}
const props = defineProps<Props>()
const { t } = useI18n()
const authStore = useAuthStore()
const companyStore = useCompanyStore()
const route = useRoute()

const newTicketsCount = ref<number>()
const ongoingTicketsCount = ref<number>()

const { data, paginationData, params, loading, error, refetch } = usePaginatedTable<
    MinimalTicket,
    FetchTicketsParams
>(
    async (params, abortController) => {
        const response = await axios.get<DeliveryManagementPaginatedResponse<MinimalTicket>>(
            window.route('dm.company.tickets.index', {
                company: authStore.companyId,
            }),
            { params: params, signal: abortController.signal },
        )
        newTicketsCount.value = response.data.newTicketsCount
        ongoingTicketsCount.value = response.data.ongoingTicketsCount

        return response.data
    },
    {
        filter: {
            state: 'new',
            responsibility: 'responsible',
            'tag-name': undefined,
            'user-id': undefined,
            'hub-id': undefined,
            'customer-company-id': props.customerCompanyId,
        },
    },
)

const agents = useLazyLoadedList<DropdownStringOption>(fetchAgents)
const tags = useLazyLoadedList<DropdownStringOption>(fetchTags)
const hubs = useLazyLoadedList<DropdownStringOption>(fetchHubs)

const responsibilityStates = computed<DropdownOption[]>(() => {
    return [
        { label: t('all'), value: 'all' },
        { label: t('responsible'), value: 'responsible' },
        { label: t('notResponsible'), value: 'not-responsible' },
    ]
})

async function fetchTags(): Promise<DropdownStringOption[]> {
    const response = await axios.get<ResourceResponse<MinimalCompanyTag[]>>(
        window.route('dm.company.tags.index', { company: authStore.companyId }),
    )

    return response.data.data.map((tag) => ({ value: tag.name, label: t(tag.name) }))
}

async function fetchAgents(): Promise<DropdownStringOption[]> {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.employees', { company: authStore.companyId, web: true }),
    )

    return response.data.map((user) => ({ value: user.id, label: user.name }))
}

async function fetchHubs(): Promise<DropdownStringOption[]> {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.hubs', { company: authStore.companyId }),
    )

    return response.data.map((hub) => ({
        value: hub.id,
        label: hub.name,
    }))
}

function setFilter(type: string | number | null, filterName: string) {
    params.value.filter[filterName] = type
    params.value.page = 1
}

function showTicket(id: string): RouteLocationRaw {
    if (route.name?.toString()?.includes('customers.show')) {
        return {
            name: 'dm.customers.tickets.show',
            params: { ...route.params, ticketId: id },
            query: route.query,
        }
    }

    return {
        name: 'dm.tickets.show',
        params: { ticketId: id },
        query: route.query,
    }
}

useMittListener('fetchTickets', () => refetch())

onBeforeMount(() => {
    companyStore.fetchCompany()

    if (params.value.filter['user-id']) agents.fetch()
    if (params.value.filter['tag-name']) tags.fetch()
    if (params.value.filter['hub-id']) hubs.fetch()
})
</script>

<template>
    <div v-if="props.customerCompanyId" class="flex items-center justify-end py-4 px-4">
        <TicketExportModal :customer-company-id="props.customerCompanyId" />
    </div>

    <MyTable
        table-id="dm-tickets"
        :rows="data"
        :pagination-data="paginationData"
        :loading="loading"
        :error="error"
        :get-data="refetch"
        :entity-name="t('tickets')"
    >
        <template #filters>
            <MyFilterButton filter-name="state" value="new" default @selected="setFilter">
                <div class="relative flex justify-between">
                    <span class="font-semibold" v-text="t('new')" />
                    <span
                        v-if="newTicketsCount"
                        class="absolute bottom-[12px] right-0 -mr-5 flex h-5 min-w-[20px] items-center justify-center text-white rounded-full bg-red-500 px-1 text-center text-xs"
                        v-text="newTicketsCount"
                    />
                </div>
            </MyFilterButton>

            <MyFilterButton filter-name="state" value="ongoing" @selected="setFilter">
                <div class="relative flex justify-between z-30">
                    <span class="font-semibold" v-text="t('ongoing')" />
                    <span
                        v-if="ongoingTicketsCount"
                        class="absolute bottom-[12px] right-0 -mr-5 flex h-5 min-w-[20px] items-center justify-center text-white rounded-full bg-red-500 px-1 text-center text-xs"
                        v-text="ongoingTicketsCount"
                    />
                </div>
            </MyFilterButton>

            <MyFilterButton
                filter-name="state"
                value="closed"
                @selected="setFilter"
                v-text="t('closed')"
            />

            <MyFilterDivider />

            <MyFilterSelect
                :label="t('responsibility')"
                :options="responsibilityStates"
                filter-name="responsibility"
                @change="setFilter"
            />

            <MyFilterSelect
                :label="t('tag')"
                :options="tags.items.value"
                filter-name="tag-name"
                :loading="tags.fetching.value"
                @focus="tags.fetch()"
                @change="setFilter"
            />

            <MyFilterSelect
                :label="t('agents')"
                :options="agents.items.value"
                :loading="agents.fetching.value"
                filter-name="user-id"
                @focus="agents.fetch()"
                @change="setFilter"
            />

            <MyFilterSelect
                :label="t('hubs')"
                :options="hubs.items.value ?? params.filter['hub-id']"
                :loading="hubs.fetching.value"
                filter-name="hub-id"
                @focus="hubs.fetch()"
                @change="setFilter"
            />
        </template>

        <template #actionRow="{ row }">
            <RouterLink :to="showTicket(row.id)">
                <ActionRowItem>
                    <mdi:eye />
                </ActionRowItem>
            </RouterLink>
        </template>

        <MyTableColumn :name="t('number')" property="number" />
        <MyTableColumn :name="t('createdAt')" property="createdAt" />
        <template #createdAt="{ row }">{{ datetime(row.createdAt) }}</template>
        <MyTableColumn :name="t('tags')" property="tags" :sortable="false" />
        <template #tags="{ row }">{{ row.tags.map((tag) => t(tag.name)).join(', ') }}</template>
        <MyTableColumn :name="t('state')" property="state" />
        <template #state="{ row }">
            <span v-if="row.state === DeviationState.Open" v-text="t('open')" />
            <span v-else-if="row.state === DeviationState.Solved" v-text="t('solved')" />
            <span v-else-if="row.state === DeviationState.Closed" v-text="t('closed')" />
        </template>
        <MyTableColumn :name="t('tour')" property="tour.name" />
        <template #[`tour.name`]="{ row }">
            <div class="flex">
                <span class="w-full truncate" v-text="row.tour?.name" />

                <img
                    v-if="row.tour?.company.logo"
                    :src="image(row.tour.company.logo)"
                    class="ml-2 object-contain"
                    style="width: 20px"
                />
            </div>
        </template>
        <MyTableColumn :name="t('shipment')" property="shipment.shipmentNumber" />
        <MyTableColumn :name="t('hub')" property="tour.hub.name" />
        <MyTableColumn :name="t('receiver')" property="shipment.receiverCompany.name" />
        <MyTableColumn :name="t('shipper')" property="client.name" />
        <template #[`client.name`]="{ row }">
            <div class="flex">
                <span class="w-full truncate" v-text="row.client.name" />

                <mdi:account-clock
                    v-if="row.responsibleCompanyId === row.client.id"
                    v-tooltip="t('responsibleCompanyExplanation', { company: row.client.name })"
                    class="ml-2 object-contain"
                    style="width: 20px"
                />
            </div>
        </template>
        <MyTableColumn :name="t('shipperAgent')" property="clientUser.name" />
        <MyTableColumn :name="t('company')" property="company.name" />
        <template #[`company.name`]="{ row }">
            <div class="flex">
                <span class="w-full truncate" v-text="row.company.name" />

                <mdi:account-clock
                    v-if="row.responsibleCompanyId === row.company.id"
                    v-tooltip="t('responsibleCompanyExplanation', { company: row.company.name })"
                    class="ml-2 object-contain"
                    style="width: 20px"
                />
            </div>
        </template>
        <MyTableColumn :name="t('companyAgent')" property="companyUser.name" />
        <MyTableColumn :name="t('updatedAt')" property="updatedAt" />
        <template #updatedAt="{ row }">{{ datetime(row.updatedAt) }}</template>
    </MyTable>
</template>
