<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

import { MinimalReport } from '@/types/damage-report'
import { transportObjectTypes } from '@/utils/type-translations'

const { t } = useI18n()
const props = defineProps<{ report: MinimalReport }>()
</script>
<template>
    <div class="flex items-center text-xs text-gray-600 dark:text-primary-50">
        <div
            v-if="props.report.hasNewIncidents"
            class="-mr-1.5 h-3.5 w-3.5 rounded-full bg-red-400"
        />
        <div
            v-if="props.report.hasTireThresholdAlarm"
            class="-mr-1.5 h-4 w-4 rounded-full border border-white bg-dark-500 dark:border-dark-500"
        />
        <div
            v-if="props.report.hasFieldAlarm"
            class="-mr-1.5 h-4 w-4 rounded-full border border-white bg-yellow-400 dark:border-dark-500"
        />
        <mdi:refresh-auto
            v-if="props.report.isAutomaticDropOff"
            v-tooltip="
                t('automaticDropOffExplanation', {
                    type: t(transportObjectTypes[report.transportObject.type]).toLowerCase(),
                })
            "
            class="h-4 w-4 text-red-500 font-bold ml-2 first:ml-0"
        />
    </div>
</template>
