<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { onBeforeMount, ref } from 'vue'

import { useAuthStore } from '@/stores/auth-store'
import { MinimalResource, PaginatedResponse, ResourceResponse, uuid } from '@/types/general'
import { usePaginatedTable } from '@/hooks/table/use-paginated-table'
import { MinimalDeviation, DeviationState, MinimalCompanyTag } from '@/types/delivery-management'
import { GetDataParameters } from '@/types/table'
import { DropdownStringOption } from '@/types/inputs'
import { datetime } from '@/utils/dates'
import { useLazyLoadedList } from '@/hooks/use-lazy-loaded-list'
import { image } from '@/utils/assets'
import { useMittListener } from '@/hooks/use-mitt-listener'

import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import MyTable from '@/components/table/MyTable.vue'
import MyTableColumn from '@/components/table/MyTableColumn.vue'
import MyFilterButton from '@/components/table/MyFilterButton.vue'
import MyFilterDivider from '@/components/table/MyFilterDivider.vue'
import MyFilterSelect from '@/components/table/MyFilterSelect.vue'
import ActionRowItem from '@/components/table/ActionRowItem.vue'

interface DeliveryManagementPaginatedResponse<Model> extends PaginatedResponse<Model> {
    newDeviationsCount: number
    ongoingDeviationsCount: number
}

interface FetchDeviationsParams extends Record<string, unknown>, GetDataParameters {
    filter: {
        state: string | undefined
        'tag-id': uuid | undefined
        'user-id': uuid | undefined
        'hub-id': uuid | undefined
    }
}

const { t } = useI18n()
const authStore = useAuthStore()

const newDeviationsCount = ref<number>()
const ongoingDeviationsCount = ref<number>()

const { data, paginationData, params, loading, error, refetch } = usePaginatedTable<
    MinimalDeviation,
    FetchDeviationsParams
>(
    async (params, abortController) => {
        const response = await axios.get<DeliveryManagementPaginatedResponse<MinimalDeviation>>(
            window.route('dm.company.deviations.index', {
                company: authStore.companyId,
            }),
            { params: params, signal: abortController.signal },
        )

        newDeviationsCount.value = response.data.newDeviationsCount
        ongoingDeviationsCount.value = response.data.ongoingDeviationsCount

        return response.data
    },
    { filter: { state: 'new', 'tag-id': undefined, 'user-id': undefined, 'hub-id': undefined } },
)

const users = useLazyLoadedList<DropdownStringOption>(fetchUsers)
const tags = useLazyLoadedList<DropdownStringOption>(fetchTags)
const hubs = useLazyLoadedList<DropdownStringOption>(fetchHubs)

async function fetchUsers(): Promise<DropdownStringOption[]> {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.employees', { company: authStore.companyId, web: true }),
    )

    return response.data.map((user) => ({ value: user.id, label: user.name }))
}

async function fetchTags(): Promise<DropdownStringOption[]> {
    const response = await axios.get<ResourceResponse<MinimalCompanyTag[]>>(
        window.route('dm.company.tags.index', { company: authStore.companyId }),
    )

    return response.data.data.map((tag) => ({ value: tag.id, label: t(tag.name) }))
}

async function fetchHubs(): Promise<DropdownStringOption[]> {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.hubs', { company: authStore.companyId }),
    )

    return response.data.map((hub) => ({
        value: hub.id,
        label: hub.name,
    }))
}

function setFilter(type: string | number | null, filterName: string) {
    params.value.filter[filterName] = type
    params.value.page = 1
}

useMittListener('fetchDeviations', () => refetch())

onBeforeMount(() => {
    if (params.value.filter['user-id']) users.fetch()
    if (params.value.filter['tag-id']) tags.fetch()
    if (params.value.filter['hub-id']) hubs.fetch()
})
</script>

<template>
    <CrumbsAndActions>
        <Breadcrumb :to="{ name: 'dm' }" v-text="t('deliveryManagement')" />
        <Breadcrumb current v-text="t('deviations')" />
    </CrumbsAndActions>

    <RouterView />

    <MyPanel v-if="authStore.companyId" shadow>
        <MyTable
            table-id="dm-deviations"
            :rows="data"
            :pagination-data="paginationData"
            :loading="loading"
            :error="error"
            :get-data="refetch"
            :entity-name="t('deviations')"
        >
            <template #filters>
                <MyFilterButton filter-name="state" value="new" default @selected="setFilter">
                    <div class="relative flex justify-between">
                        <span class="font-semibold" v-text="t('new')" />
                        <span
                            v-if="newDeviationsCount"
                            class="absolute bottom-[12px] right-0 -mr-5 flex h-5 min-w-[20px] items-center justify-center text-white rounded-full bg-red-500 px-1 text-center text-xs"
                            v-text="newDeviationsCount"
                        />
                    </div>
                </MyFilterButton>

                <MyFilterButton filter-name="state" value="ongoing" @selected="setFilter">
                    <div class="relative flex justify-between z-30">
                        <span class="font-semibold" v-text="t('ongoing')" />
                        <span
                            v-if="ongoingDeviationsCount"
                            class="absolute bottom-[12px] right-0 -mr-5 flex h-5 min-w-[20px] items-center justify-center text-white rounded-full bg-red-500 px-1 text-center text-xs"
                            v-text="ongoingDeviationsCount"
                        />
                    </div>
                </MyFilterButton>

                <MyFilterButton
                    filter-name="state"
                    value="closed"
                    @selected="setFilter"
                    v-text="t('closed')"
                />

                <MyFilterDivider />

                <MyFilterSelect
                    :label="t('tag')"
                    :options="tags.items.value"
                    filter-name="tag-id"
                    :loading="tags.fetching.value"
                    @focus="tags.fetch()"
                    @change="setFilter"
                />

                <MyFilterSelect
                    :label="t('agent')"
                    :options="users.items.value"
                    filter-name="user-id"
                    :loading="users.fetching.value"
                    @focus="users.fetch()"
                    @change="setFilter"
                />

                <MyFilterSelect
                    :label="t('hubs')"
                    :options="hubs.items.value ?? params.filter['hub-id']"
                    :loading="hubs.fetching.value"
                    filter-name="hub-id"
                    @focus="hubs.fetch()"
                    @change="setFilter"
                />
            </template>

            <template #actionRow="{ row }">
                <RouterLink :to="{ name: 'dm.deviations.show', params: { deviationId: row.id } }">
                    <ActionRowItem>
                        <mdi:eye />
                    </ActionRowItem>
                </RouterLink>
            </template>

            <MyTableColumn :name="t('number')" property="number" />
            <MyTableColumn :name="t('createdAt')" property="createdAt" />
            <template #createdAt="{ row }">{{ datetime(row.createdAt) }}</template>
            <MyTableColumn :name="t('tags')" property="tags" :sortable="false" />
            <template #tags="{ row }">{{ row.tags.map((tag) => t(tag.name)).join(', ') }}</template>
            <MyTableColumn :name="t('state')" property="state" />
            <template #state="{ row }">
                <span v-if="row.state === DeviationState.Open" v-text="t('open')" />
                <span v-else-if="row.state === DeviationState.Solved" v-text="t('solved')" />
                <span v-else-if="row.state === DeviationState.Closed" v-text="t('closed')" />
            </template>
            <MyTableColumn :name="t('tour')" property="tour.name" />
            <template #[`tour.name`]="{ row }">
                <div class="flex">
                    <span class="w-full truncate" v-text="row.tour.name" />

                    <img
                        v-if="row.tour.company.logo"
                        :src="image(row.tour.company.logo)"
                        class="ml-2 object-contain"
                        style="width: 20px"
                    />
                </div>
            </template>
            <MyTableColumn :name="t('hub')" property="tour.hub.name" />
            <MyTableColumn :name="t('shipment')" property="shipment.shipmentNumber" />
            <MyTableColumn :name="t('receiver')" property="shipment.receiverCompany.name" />
            <MyTableColumn :name="t('haulier')" property="tour.haulierCompany.name" />
            <MyTableColumn :name="t('driver')" property="driver.name" />
            <MyTableColumn :name="t('agent')" property="user.name" />
        </MyTable>
    </MyPanel>
</template>
