<script lang="ts">
interface Props {
    modelValue: boolean
    reportEvent?: ReportEvent
    reportEnded?: boolean
}
</script>

<script setup lang="ts">
import type { MinimalResource, ResourceResponse, uuid } from '@/types/general'

import Datepicker from '@vuepic/vue-datepicker'
import axios, { Method } from 'axios'
import { ref, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { LocationPoint } from '@/types/company'
import { EventActionType, EventType, ReportEvent } from '@/types/driver-report'
import { DropdownStringOption } from '@/types/inputs'
import { emitter } from '@/utils/mitt'

import MyErrorMessage from '@/components/my-components/form/MyErrorMessage.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MyInputLabel from '@/components/my-components/form/MyInputLabel.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import MyTextarea from '@/components/my-components/form/MyTextarea.vue'

interface Form extends Record<string, unknown> {
    type: EventType
    customerLocationId: uuid | null
    location: LocationPoint | null
    units: string[]
    startedAt: Date
    endedAt: Date
    kilometerCount: number
    waitTime: number
    shipmentLoaded: boolean
    shipmentUnloaded: boolean
    arrivalNote: string
    departureNote: string
}

const props = defineProps<Props>()
const route = useRoute()
const authStore = useAuthStore()
const { t } = useI18n()
const emit = defineEmits<{
    (e: 'close'): void
}>()

const customers = ref<DropdownStringOption[]>([])
const customerLocations = ref<DropdownStringOption[]>([])
const customerCompanyId = ref()

const { data, submit, errors, reset, loading } = useForm<Form>({
    type: 2,
    startedAt: new Date(),
    endedAt: new Date(),
    waitTime: 0,
    units: [],
    location: null,
    customerLocationId: null,
    kilometerCount: 0,
    shipmentLoaded: false,
    shipmentUnloaded: false,
    arrivalNote: '',
    departureNote: '',
})

const eventTypes = computed(() => {
    let types = [
        { value: EventType.Customer, label: t('customer') },
        { value: EventType.Traffic, label: t('traffic') },
        { value: EventType.Break, label: t('break') },
        { value: EventType.Other, label: t('other') },
        { value: EventType.Resting, label: t('resting') },
    ]
    if (!props.reportEnded) {
        types = [...types, { value: EventType.ReportEnd, label: t('finish') }]
    }

    if (
        props.reportEvent?.type === EventType.ReportStart ||
        props.reportEvent?.type === EventType.ReportEnd
    ) {
        types = [
            { value: EventType.ReportStart, label: t('start') },
            { value: EventType.ReportEnd, label: t('finish') },
            ...types,
        ]
    }

    return types
})

const createEventRoute = computed(() => {
    if (!authStore.companyId) return ''

    return window.route('dr.company.events.store', {
        company: authStore.companyId,
        report: route.params.reportId.toString(),
    })
})

const updateEventRoute = computed(() => {
    if (!authStore.companyId) return ''

    return window.route('dr.company.events.update', {
        company: authStore.companyId,
        report: route.params.reportId.toString(),
        event: props.reportEvent || '',
    })
})

async function fetchCustomers() {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.customers', { company: authStore.companyId }),
    )

    customers.value = response.data.map((customer) => ({
        value: customer.id,
        label: customer.name,
    }))
}

async function fetchLocations(companyId: string): Promise<DropdownStringOption[]> {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.locations', { company: companyId }),
    )

    return response.data.map((location) => ({ value: location.id, label: location.name }))
}

async function onSubmit() {
    const method: Method = props.reportEvent ? 'PUT' : 'POST'
    const route = props.reportEvent ? updateEventRoute : createEventRoute
    const response = await submit<ResourceResponse<ReportEvent>>(method, route.value)

    if (response !== undefined) {
        emit('close')
        emitter.emit('fetchDriverReports')
    }
}

watch(customerCompanyId, async () => {
    if (data.type === EventType.Customer) {
        customerLocations.value = await fetchLocations(customerCompanyId.value)
        data.customerLocationId = customerLocations.value[0].value
    }
})

watch(
    () => data.type,
    () => {
        if (data.type !== EventType.Customer) {
            customerCompanyId.value = null
            data.customerLocationId = null
        }
    },
)

watch(
    () => data.endedAt,
    () => {
        if (data.type === EventType.ReportStart) {
            data.startedAt = data.endedAt
        }
    },
)

watch(
    () => data.startedAt,
    () => {
        if (data.type === EventType.ReportEnd) {
            data.endedAt = data.startedAt
        }
    },
)

watch(
    () => props.modelValue,
    () => {
        if (!props.modelValue) {
            reset()
            return
        }

        fetchCustomers()

        if (props.reportEvent) {
            data.type = props.reportEvent.type
            data.units = props.reportEvent?.actions
                .filter((action) => action.type === EventActionType.AttachUnit)
                .map((action) => action.actionableId)
            data.startedAt = new Date(props.reportEvent.startedAt)
            data.endedAt = props.reportEvent.endedAt
                ? new Date(props.reportEvent.endedAt)
                : new Date()
            data.waitTime = props.reportEvent.waitTime
            customerCompanyId.value = props.reportEvent.customerCompany?.id ?? ''
            data.customerLocationId = props.reportEvent.customerLocation?.id ?? null
            data.arrivalNote = props.reportEvent.arrivalNote ?? ''
            data.departureNote = props.reportEvent.departureNote ?? ''
            data.shipmentLoaded = props.reportEvent.shipmentLoaded ?? ''
            data.shipmentUnloaded = props.reportEvent.shipmentUnloaded ?? ''
            data.kilometerCount = props.reportEvent.kilometerCount ?? ''
        }
    },
)
</script>

<template>
    <MyModal :value="props.modelValue" :max-width="650" @close="emit('close')">
        <LoaderWrapper :visible="loading" class="rounded-xl" />
        <template #title>
            {{
                reportEvent
                    ? t('updateEntity', { entity: 'event' })
                    : t('createEntity', { entity: 'event' })
            }}
        </template>
        <MyForm :errors="errors" @submit.prevent="onSubmit">
            <div class="space-y-2">
                <div class="flex space-x-3">
                    <MySelect
                        v-model="data.type"
                        :options="eventTypes"
                        name="type"
                        group-class="w-4/6"
                        :label="t('type')"
                        :disabled="
                            reportEvent?.type === EventType.ReportStart ||
                            reportEvent?.type === EventType.ReportEnd
                        "
                    />
                    <MyInput
                        v-model="data.kilometerCount"
                        name="kilometerCount"
                        type="number"
                        :label="t('km')"
                    />
                    <MyInput
                        v-if="data.type === EventType.Customer"
                        v-model="data.waitTime"
                        name="waitTime"
                        type="number"
                        :label="t('waitTime')"
                    />
                </div>
                <div class="flex justify-between space-x-3">
                    <div v-if="data.type !== EventType.ReportStart" class="w-full">
                        <MyInputLabel v-text="t('arrival')" />
                        <Datepicker
                            v-model="data.startedAt"
                            class="input-field w-full rounded-xl border border-gray-300"
                            input-class-name="w-64"
                            name="startedAt"
                            enable-time-picker
                            enable-seconds
                            close-on-scroll
                            auto-position
                            position="left"
                            format="yyyy-MM-dd HH:mm:ss"
                            :transitions="false"
                            :placeholder="t('startedAt')"
                        />
                        <MyErrorMessage input-name="startedAt" :label="t('arrival')" />
                    </div>

                    <div v-if="data.type !== EventType.ReportEnd" class="w-full">
                        <MyInputLabel v-text="t('departure')" />
                        <Datepicker
                            v-model="data.endedAt"
                            class="input-field w-full rounded-xl border border-gray-300"
                            input-class-name="w-64"
                            name="endedAt"
                            enable-time-picker
                            enable-seconds
                            close-on-scroll
                            auto-position
                            position="left"
                            format="yyyy-MM-dd HH:mm:ss"
                            :transitions="false"
                            :placeholder="t('endedAt')"
                        />
                        <MyErrorMessage input-name="endedAt" :label="t('departure')" />
                    </div>
                </div>

                <div v-if="data.type === EventType.Customer" class="flex">
                    <div class="flex w-full justify-between space-x-3">
                        <div class="w-full">
                            <MyInputLabel v-text="t('customer')" />
                            <MySelect
                                v-model="customerCompanyId"
                                :options="customers"
                                searchable
                                class="dark:text-blue-400"
                            />

                            <MyErrorMessage
                                input-name="customerLocationId"
                                :label="t('customer')"
                            />
                        </div>

                        <div class="w-full">
                            <MyInputLabel v-text="t('location')" />
                            <MySelect
                                v-model="data.customerLocationId"
                                :options="customerLocations"
                                searchable
                            />
                        </div>
                    </div>
                </div>

                <div v-if="data.type === EventType.Customer" class="flex items-end space-x-3">
                    <div class="flex w-full justify-evenly space-y-1">
                        <MyCheckbox
                            v-model="data.shipmentLoaded"
                            name="shipmentLoaded"
                            :label="t('shipmentLoaded')"
                        />
                        <MyCheckbox
                            v-model="data.shipmentUnloaded"
                            name="shipmentUnloaded"
                            :label="t('shipmentUnloaded')"
                        />
                    </div>
                </div>

                <MyTextarea
                    v-if="data.type !== EventType.ReportStart"
                    v-model="data.arrivalNote"
                    :label="t('arrivalNote')"
                    rows="3"
                />

                <MyTextarea
                    v-if="data.type !== EventType.ReportEnd"
                    v-model="data.departureNote"
                    :label="t('departureNote')"
                    rows="3"
                />
            </div>
            <div class="mt-4 flex justify-end gap-6">
                <MyButton
                    :disabled="loading"
                    scheme="primary"
                    v-text="props.reportEvent ? t('update') : t('create')"
                />
            </div>
        </MyForm>
    </MyModal>
</template>
