<script setup lang="ts">
import type { DropdownOption } from '@/types/inputs'
import type { EmployeeOrderBy } from '@/types/stock'
import type { DashboardEmployee } from '@/types/user'

import axios from 'axios'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { PermissionType, ApiPaginationData, PaginatedResponse } from '@/types/general'
import { useAuthStore } from '@/stores/auth-store'
import { useDashboardStore } from '@/stores/dashboard-store'
import { UserType } from '@/types/user'
import { image } from '@/utils/assets'
import { moduleLicenses } from '@/utils/licenses'

import MyButton from '@/components/my-components/MyButton.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'

const { t } = useI18n()
const authStore = useAuthStore()
const { startDate, endDate, selectedLocation, date } = storeToRefs(useDashboardStore())
const orderBy = ref<EmployeeOrderBy>('-transactionCount')
const orderOptions = ref<DropdownOption[]>([
    { label: t('transactionCount'), value: '-transactionCount' },
    { label: t('name'), value: 'name' },
    { label: t('userType'), value: 'userType' },
])
const employees = ref<DashboardEmployee[]>([])
const pagination = ref<ApiPaginationData>()
const hasPagination = computed(() => pagination.value && pagination.value.last_page > 1)
const loading = ref(false)

function prev() {
    if (pagination.value && pagination.value.current_page > 1)
        fetchEmployees(pagination.value.current_page - 1)
}

function next() {
    if (pagination.value && pagination.value.current_page < pagination.value.last_page)
        fetchEmployees(pagination.value.current_page + 1)
}

function getUserType($type: UserType) {
    if ($type === UserType.Driver) return t('driver')
    if ($type === UserType.Warehouse) return t('warehouse')

    return '---'
}

async function fetchEmployees(page = 1) {
    loading.value = true
    const { data: response } = await axios.post<PaginatedResponse<DashboardEmployee>>(
        window.route('company.dashboard.employees', {
            company: authStore.companyId,
            page,
            'per-page': 8,
            sort: orderBy.value,
        }),
        {
            startDate: startDate.value.toISOString(),
            endDate: endDate.value.toISOString(),
            location: selectedLocation.value,
        },
    )

    employees.value = response.data
    pagination.value = response.meta

    loading.value = false
}

onMounted(fetchEmployees)

watch([orderBy, date, selectedLocation], () => fetchEmployees(1))
</script>

<script lang="ts">
export default { inheritAttrs: false }
</script>

<template>
    <MyPanel
        shadow
        :loading="loading"
        :title="t('employees')"
        class="flex flex-col gap-4 px-6 pb-4"
        :panel-class="$attrs.class as string"
    >
        <template #header>
            <div class="flex justify-end justify-self-end pr-6">
                <MySelect v-model="orderBy" :options="orderOptions" hide-chevron>
                    <template #default="{ isOpen }">
                        <MyButton :active="!!orderBy" class="pr-0 text-primary-500" size="small">
                            <span class="font-bold">{{ t('sortBy') }}</span>
                            <mdi:chevron-down
                                class="ml-1 transition duration-300"
                                :class="{ 'rotate-180': isOpen }"
                            />
                        </MyButton>
                    </template>
                </MySelect>
            </div>
        </template>

        <section class="grow space-y-4 pt-4">
            <article
                v-for="employee in employees"
                :key="'employee-' + employee.id"
                class="flex items-center gap-4"
            >
                <div>
                    <div
                        class="flex h-[3rem] w-[3rem] items-center justify-center rounded-full bg-primary-300"
                    >
                        <img
                            v-if="employee.profileImage"
                            :src="image(employee.profileImage)"
                            :alt="employee.name"
                        />
                        <span v-else class="text-primary-100"><mdi:account /></span>
                    </div>
                </div>
                <div class="grow">
                    <p class="font-semibold text-primary-400" v-text="employee.name" />
                    <p
                        class="text-xs font-semibold text-gray-500"
                        v-text="getUserType(employee.userType)"
                    />
                </div>
                <div>
                    <p class="text-[.5rem] uppercase" v-text="t('transactions')" />
                    <p
                        class="text-center text-2xl font-bold text-blue-500"
                        v-text="employee.transactionCount"
                    />
                </div>
            </article>
        </section>

        <div class="grid grid-cols-[1fr_3fr_1fr] items-center">
            <div class="col-start-2 text-center">
                <RouterLink
                    v-if="
                        authStore.hasLicense(moduleLicenses) &&
                        authStore.hasPermission(PermissionType.ManageCompany)
                    "
                    class="text-sm font-semibold text-blue-500 underline decoration-2 underline-offset-2"
                    :to="{ name: 'settings.employees' }"
                    v-text="t('goTo', { to: t('employees') })"
                />
            </div>
            <div v-if="pagination && pagination.last_page > 1" class="flex justify-end gap-1">
                <a
                    v-if="hasPagination"
                    class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-lg transition-all hover:bg-primary-400 hover:text-primary-50"
                    :class="{
                        'pointer-events-none opacity-50': pagination.current_page === 1,
                    }"
                    @click.prevent="prev"
                >
                    <mdi:chevron-left />
                </a>
                <a
                    v-if="hasPagination"
                    class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-lg transition-all hover:bg-primary-400 hover:text-primary-50"
                    :class="{
                        'pointer-events-none opacity-50':
                            pagination.current_page === pagination.last_page,
                    }"
                    @click.prevent="next"
                >
                    <mdi:chevron-right />
                </a>
            </div>
        </div>
    </MyPanel>
</template>
