import { ReportType } from '@/types/damage-report'
import { IncidentType } from '@/types/transport-object'

export const incidentTypes: Record<IncidentType, string> = {
    [IncidentType.Scratch]: 'scratch',
    [IncidentType.Dent]: 'dent',
    [IncidentType.Hole]: 'hole',
    [IncidentType.Missing]: 'missing',
}

export const incidentTypeColors: Record<IncidentType, string> = {
    [IncidentType.Scratch]: 'bg-red-400',
    [IncidentType.Dent]: 'bg-yellow-400',
    [IncidentType.Hole]: 'bg-dark-500',
    [IncidentType.Missing]: 'bg-blue-300',
}

export function getIncidentTypeColor(type: IncidentType) {
    return incidentTypeColors[type] ?? 'bg-black'
}

export const reportTypes: Record<ReportType, string> = {
    [ReportType.DropOff]: 'dropOff',
    [ReportType.Pickup]: 'pickup',
}
