<script lang="ts">
interface Props {
    modelValue: boolean
    colli?: Colli
}
</script>

<script setup lang="ts">
import type { ResourceResponse } from '@/types/general'

import { Method } from 'axios'
import { watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { Colli } from '@/types/delivery-management'

import MyTextarea from '@/components/my-components/form/MyTextarea.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'

interface Form {
    name: string
    colliNumber: string
    description: string | null
    volume: number
    height: number
    width: number
    length: number
    weight: number
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'close'): void
    (e: 'saved'): void
}>()

const authStore = useAuthStore()
const { t } = useI18n()
const { data, submit, errors, reset, loading } = useForm<Form>({
    name: '',
    colliNumber: '',
    description: null,
    volume: 0,
    height: 0,
    width: 0,
    length: 0,
    weight: 0,
})

const title = computed(() => {
    if (props.colli) {
        return t('updateEntity', { entity: t('colli') })
    } else {
        return t('createEntity', { entity: t('colli') })
    }
})

const calculatedVolume = computed(() => {
    return Math.round(data.height / 100) * (data.width / 100) * (data.length / 100)
})

async function onSubmit() {
    data.volume = Number(calculatedVolume.value)
    const method: Method = props.colli ? 'PUT' : 'POST'
    const createRoute = window.route('dm.company.collis.store', {
        company: authStore.companyId,
    })
    const updateRoute = window.route('dm.company.collis.update', {
        company: authStore.companyId,
        colli: props.colli?.id ?? '',
    })
    const response = await submit<ResourceResponse<Colli>>(
        method,
        props.colli ? updateRoute : createRoute,
    )

    if (response !== undefined) {
        emit('close')
        emit('saved')
    }
}

watch(
    () => props.modelValue,
    () => {
        if (!props.modelValue) return
        reset()

        if (props.colli) {
            data.name = props.colli.name
            data.colliNumber = props.colli.colliNumber
            data.description = props.colli.description
            data.volume = props.colli.volume
            data.height = props.colli.height
            data.width = props.colli.width
            data.length = props.colli.length
            data.weight = props.colli.weight
        }
    },
)
</script>

<template>
    <MyModal :value="props.modelValue" :max-width="500" @close="emit('close')">
        <LoaderWrapper :visible="loading" class="rounded-xl" />
        <template #title>
            {{ title }}
        </template>
        <MyForm :errors="errors" @submit.prevent="onSubmit">
            <div class="space-y-2">
                <div class="flex flex-col">
                    <MyInput
                        v-model="data.name"
                        name="name"
                        type="text"
                        placeholder="name"
                        :label="t('name')"
                        required
                        autofocus
                    />

                    <MyInput
                        v-model="data.colliNumber"
                        name="colliNumber"
                        type="text"
                        placeholder="colli Number"
                        :label="t('colliNumber')"
                        required
                    />
                </div>

                <div class="flex space-x-3">
                    <MyInput
                        v-model="data.length"
                        name="length"
                        type="number"
                        placeholder="length"
                        :label="t('length')"
                    >
                        <template #button>
                            <span class="absolute top-0 bottom-1 right-2 m-auto h-5">cm</span>
                        </template>
                    </MyInput>

                    <MyInput
                        v-model="data.width"
                        name="width"
                        type="number"
                        placeholder="width"
                        :label="t('width')"
                    >
                        <template #button>
                            <span class="absolute top-0 bottom-1 right-2 m-auto h-5">cm</span>
                        </template>
                    </MyInput>

                    <MyInput
                        v-model="data.height"
                        name="height"
                        type="number"
                        class="w-full"
                        placeholder="height"
                        :label="t('height')"
                    >
                        <template #button>
                            <span class="absolute top-0 bottom-1 right-2 m-auto h-5">cm</span>
                        </template>
                    </MyInput>
                </div>

                <div class="flex space-x-3">
                    <MyInput
                        :value="calculatedVolume"
                        name="volume"
                        type="number"
                        placeholder="volume"
                        :label="t('volume')"
                        disabled
                    >
                        <template #button>
                            <span class="absolute top-0 bottom-1 right-2 m-auto h-5">cbm</span>
                        </template>
                    </MyInput>

                    <MyInput
                        v-model="data.weight"
                        name="weight"
                        type="number"
                        placeholder="weight"
                        :label="t('weight')"
                    >
                        <template #button>
                            <span class="absolute top-0 bottom-1 right-2 m-auto h-5">kg</span>
                        </template>
                    </MyInput>
                </div>

                <MyTextarea
                    v-model="data.description"
                    name="description"
                    type="text"
                    placeholder="description"
                    :label="t('description')"
                />
            </div>
            <div class="mt-4 flex justify-end gap-6">
                <MyButton
                    :disabled="loading"
                    scheme="primary"
                    v-text="props.colli ? t('update') : t('create')"
                />
            </div>
        </MyForm>
    </MyModal>
</template>
