<script lang="ts" setup>
import type { MyInputRef } from '@/types/inputs'

import axios from 'axios'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@/hooks/use-form'
import { localAsset } from '@/utils/assets'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'

interface Response {
    intended: string
}

const { t } = useI18n()
const username = ref<MyInputRef>()
const { data, loading, errors, submit } = useForm({ username: '', password: '' })

async function login() {
    const response = await submit<Response>('post', window.route('login.attempt'))

    if (response) {
        window.location.href = response.intended
    }
}

onMounted(() => {
    username.value?.input.focus()
    // Retrieve CSRF token
    axios.get('/sanctum/csrf-cookie')
})
</script>

<template>
    <MyPanel
        class="flex w-full flex-col overflow-hidden"
        padded
        panel-class="max-w-sm shadow-xl shadow-primary-500 dark:shadow-secondary-900"
    >
        <img
            :src="localAsset('assets/mypallet.png')"
            alt="myPallet"
            class="mx-auto mb-5 dark:hidden"
            style="width: 300px; height: 90px"
        />
        <img
            :src="localAsset('assets/mypallet-white.png')"
            alt="myPallet"
            class="mx-auto mb-5 hidden dark:block"
            style="width: 300px; height: 90px"
        />

        <MyForm :errors="errors" @submit.prevent="login">
            <MyInput
                ref="username"
                v-model="data.username"
                :label="t('username')"
                :placeholder="t('username')"
                autocomplete="username"
                autofocus
                name="username"
                required
            >
                <template #icon>
                    <mdi:account />
                </template>
            </MyInput>

            <MyInput
                v-model="data.password"
                :label="t('password')"
                :placeholder="t('password')"
                autocomplete="current-password"
                group-class="mt-5"
                name="password"
                required
                type="password"
            >
                <template #icon>
                    <mdi:lock />
                </template>
            </MyInput>

            <div class="mt-10 block text-right">
                <MyButton class="block w-full" scheme="primary" type="submit" v-text="t('login')" />

                <RouterLink
                    class="mt-1 inline-block text-xs font-medium underline hover:text-blue-500"
                    to="/forgot-password"
                    v-text="t('forgotPassword')"
                />
            </div>

            <LoaderWrapper :visible="loading" />
        </MyForm>
    </MyPanel>
</template>
