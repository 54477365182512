<script lang="ts">
interface Props {
    customerCompanyId?: uuid
}
</script>

<script lang="ts" setup>
import axios from 'axios'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouteLocationRaw, useRoute } from 'vue-router'

import { usePaginatedTable } from '@/hooks/table/use-paginated-table'
import { useConfirm } from '@/hooks/use-confirm'
import { useAuthStore } from '@/stores/auth-store'
import { PaginatedResponse, uuid } from '@/types/general'
import { MyButtonScheme } from '@/types/layout/my-button'
import { Receipt, ReceiptType } from '@/types/pod'
import { GetDataParameters } from '@/types/table'
import { datetime } from '@/utils/dates'
import { useMittListener } from '@/hooks/use-mitt-listener'

import BasicShareModal from '@/components/BasicShareModal.vue'
import ActionMenuItem from '@/components/table/ActionMenuItem.vue'
import ActionRowItem from '@/components/table/ActionRowItem.vue'
import MyFilterButton from '@/components/table/MyFilterButton.vue'
import MyTable from '@/components/table/MyTable.vue'
import MyTableColumn from '@/components/table/MyTableColumn.vue'
import UpdateEconomyModal from '@/components/packaging/transactions/UpdateEconomyModal.vue'

interface FetchReceiptsParams extends Record<string, unknown>, GetDataParameters {
    filter: {
        type: ReceiptType | null
        'customer-company-id': uuid | undefined
    }
}

const props = defineProps<Props>()

const { t } = useI18n()
const authStore = useAuthStore()
const confirm = useConfirm()
const route = useRoute()

const selectedReceiptId = ref<string>()
const economyModalOpen = ref(false)
const shareReceiptOpen = ref(false)
const { data, paginationData, params, loading, error, refetch } = usePaginatedTable<
    Receipt,
    FetchReceiptsParams
>(
    async (params, abortController) => {
        const response = await axios.get<PaginatedResponse<Receipt>>(
            window.route('pod.company.receipts.index', {
                company: authStore.companyId,
            }),
            { params: params, signal: abortController.signal },
        )

        return response.data
    },
    { filter: { type: null, 'customer-company-id': props.customerCompanyId } },
)

const selectedReceipt = computed(() =>
    data.value.find((receipt) => receipt.id === selectedReceiptId.value),
)

const shareEndpoint = computed(() =>
    window.route('pod.company.receipts.share', {
        company: authStore.companyId,
        receipt: selectedReceiptId.value ?? '',
    }),
)

function canDeleteReceipt(row: Receipt) {
    const associatedCompanyId = row.companyId || row.sendingCompany.id || row.haulierCompany?.id
    return associatedCompanyId === authStore.companyId
}

async function deleteReceipt(id: uuid) {
    try {
        await confirm(
            t('deleteEntityTitle', { entity: t('receipt') }),
            t('deleteEntityDescription', { entity: t('receipt') }),
            {
                confirmText: t('yes'),
                cancelText: t('no'),
                confirmButtonScheme: MyButtonScheme.Warning,
            },
        )
    } catch {
        return
    }

    await axios.delete(
        window.route('pod.company.receipts.destroy', { company: authStore.companyId, receipt: id }),
    )
    await refetch()
}

function receiptLink(id: string): RouteLocationRaw {
    if (route.name?.toString()?.includes('customers.show')) {
        return {
            name: 'pod.customers.receipts.show',
            params: { ...route.params, receiptId: id },
            query: route.query,
        }
    }

    return {
        name: 'pod.receipts.show',
        params: { receiptId: id },
        query: route.query,
    }
}

function setFilter(type: string | number | null, filterName: string) {
    params.value.filter[filterName] = type
    params.value.page = 1
}

function shareReceipt(id: uuid) {
    selectedReceiptId.value = id
    shareReceiptOpen.value = true
}

function updateEconomyNote(id: uuid) {
    selectedReceiptId.value = id
    economyModalOpen.value = true
}

useMittListener('fetchReceipts', () => refetch())
</script>

<template>
    <BasicShareModal
        v-if="selectedReceipt"
        v-model="shareReceiptOpen"
        :entity="t('receipt')"
        :endpoint="shareEndpoint"
        :customer-company-id="selectedReceipt.receivingCompany?.id"
        @close="selectedReceiptId = undefined"
    />

    <UpdateEconomyModal
        v-if="economyModalOpen && selectedReceipt"
        v-model="economyModalOpen"
        :receipt-id="selectedReceipt.id"
        :economy="selectedReceipt.companyDetails.economy ?? ''"
    />

    <MyTable
        table-id="pod-receipts"
        :rows="data"
        :pagination-data="paginationData"
        :loading="loading"
        :error="error"
        :get-data="refetch"
        :entity-name="t('receipts')"
    >
        <template #filters>
            <MyFilterButton
                filter-name="type"
                :value="null"
                default
                @selected="setFilter"
                v-text="t('all')"
            />

            <MyFilterButton
                filter-name="type"
                :value="ReceiptType.Dispatch.toString()"
                @selected="setFilter"
                v-text="t('dispatched')"
            />

            <MyFilterButton
                filter-name="type"
                :value="ReceiptType.Delivery.toString()"
                @selected="setFilter"
                v-text="t('delivered')"
            />
        </template>

        <template #actionRow="{ row }">
            <RouterLink :to="receiptLink(row.id as uuid)">
                <ActionRowItem>
                    <mdi:eye />
                </ActionRowItem>
            </RouterLink>
        </template>

        <template #actionMenu="{ row }">
            <ActionMenuItem
                class="flex cursor-pointer items-center rounded-lg py-2 px-3 text-sm hover:bg-primary-500 hover:text-primary-50 dark:hover:bg-dark-300"
                @click="shareReceipt(row.id as uuid)"
            >
                <mdi:receipt class="mr-2" />
                {{ t('share') }}
            </ActionMenuItem>

            <ActionMenuItem
                v-if="canDeleteReceipt(row)"
                class="flex cursor-pointer items-center rounded-lg py-2 px-3 text-sm hover:bg-primary-500 hover:text-primary-50 dark:hover:bg-dark-300"
                @click="deleteReceipt(row.id as uuid)"
            >
                <mdi:trash-can class="mr-2" />
                {{ t('delete') }}
            </ActionMenuItem>
        </template>

        <MyTableColumn
            :draggable="false"
            :sortable="false"
            name=""
            property="statuses"
            class="justify-start gap-1"
        />

        <template #statuses="{ row }">
            <div class="flex space-x-1 text-xs text-gray-600 dark:text-primary-50">
                <mdi:exclamation-thick
                    v-if="row.hasDeviation"
                    v-tooltip="t('hasDeviation')"
                    class="cursor-help"
                />

                <mdi:file-document
                    v-if="row.consignmentNotes.length > 0"
                    v-tooltip="t('hasConsignmentNotes')"
                    class="cursor-help"
                />

                <mdi:comment v-if="!!row.note" v-tooltip="t('hasNote')" class="cursor-help" />

                <mdi:signature-freehand
                    v-if="!!row.receiverSignature"
                    v-tooltip="t('hasSignature')"
                    class="cursor-help"
                />

                <mdi:image-multiple
                    v-if="row.images.length > 0"
                    v-tooltip="t('hasImages')"
                    class="cursor-help"
                />
            </div>
        </template>

        <MyTableColumn :name="t('type')" property="type" />
        <template #type="{ row }">{{
            t(row.type === ReceiptType.Dispatch ? 'dispatch' : 'delivery')
        }}</template>
        <MyTableColumn :name="t('contractor')" property="company.name" />
        <MyTableColumn
            :name="t('sending', { property: t('location') })"
            property="sendingLocation.name"
        />
        <MyTableColumn :name="t('receiver')" property="receivingCompany.name" :sortable="false" />
        <MyTableColumn
            :name="t('receiving', { property: t('location') })"
            property="receivingLocation.name"
        />
        <MyTableColumn
            :name="t('receiving', { property: t('user') })"
            property="receivingUser.name"
        />
        <template #receivingUser="{ row }">
            {{ row.receivingUser?.name ?? row.receiverName }}
        </template>
        <MyTableColumn :name="t('shipper')" property="shipperCompany.name" :sortable="false" />
        <MyTableColumn :name="t('shipperLocation')" property="shipperLocation.name" />
        <MyTableColumn :name="t('haulier')" property="haulierCompany.name" :sortable="false" />
        <MyTableColumn
            :name="t('haulier', { property: t('location') })"
            property="haulierLocation.name"
        />
        <MyTableColumn :name="t('user')" property="sendingUser.name" />
        <MyTableColumn :name="t('shipmentNumber')" property="shipmentNumber" />
        <MyTableColumn :name="t('trailerNumber')" property="trailerNumber" />
        <MyTableColumn :name="t('truckNumber')" property="truckNumber" />
        <MyTableColumn :name="t('note')" property="note" />
        <MyTableColumn :name="t('transpiredAt')" property="transpiredAt" />
        <template #transpiredAt="{ row }">{{ datetime(row.transpiredAt) }}</template>

        <MyTableColumn :name="t('economy')" property="companyDetails.economy" hidden />

        <template #[`companyDetails.economy`]="{ row }">
            <div class="group/item flex items-center">
                <span class="w-full truncate" v-text="row.companyDetails.economy" />

                <span
                    class="cursor-pointer pl-3 text-gray-600 opacity-0 transition-opacity group-hover/item:opacity-100 dark:text-primary-50"
                    @click="updateEconomyNote(row.id)"
                >
                    <mdi:pencil />
                </span>
            </div>
        </template>
    </MyTable>
</template>
