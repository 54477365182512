<script lang="ts" setup>
import type { StockLocation } from '@/types/stock'

import axios from 'axios'
import { ref, computed, onBeforeMount, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useProductStore } from '@/stores/product-store'
import { useAuthStore } from '@/stores/auth-store'
import { image } from '@/utils/assets'
import { ResourceResponse, PermissionType, uuid } from '@/types/general'
import { numberFormatter } from '@/utils/numbers'
import { useCompanyStore } from '@/stores/company-store'
import { CompanyProduct } from '@/types/packaging'

import MyPanel from '@/components/my-components/MyPanel.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import ContentHeading from '@/components/layout/ContentHeading.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import AdjustStockModal from '@/components/packaging/balance/AdjustStockModal.vue'

const companyStore = useCompanyStore()
const productStore = useProductStore()
const authStore = useAuthStore()
const fetchingBalance = ref(false)
const stockAdjustmentItem = ref<{
    id: string
    balance: number
    locationId: string
}>()
const stockAdjustmentOpen = ref(false)
const sortedBy = ref<uuid>()
const sortedDescending = ref(false)
const searchQuery = ref('')

const locations = ref<StockLocation[]>([])

const { t } = useI18n()

const products = computed<CompanyProduct[]>(() => {
    if (searchQuery.value === '') {
        return productStore.companyProducts
    }

    return productStore.companyProducts.filter((product) =>
        product.name.toLowerCase().includes(searchQuery.value.toLowerCase()),
    )
})

async function fetchBalancePerLocation() {
    fetchingBalance.value = true
    const params: Record<string, string> = { company: authStore.companyId }

    const response = await axios.get<ResourceResponse<StockLocation[]>>(
        window.route('packaging.company.balance.per-location', params),
    )

    locations.value = response.data.data

    companyStore.locations.map(({ id, name }) => {
        if (!locations.value.find((location) => location.locationId === id)) {
            locations.value.push({
                locationId: id,
                locationName: name,
                locationDeleted: false,
                balance: 0,
                products: [],
            })
        }
    })

    locations.value.sort((a, b) => {
        if (sortedBy.value === undefined) {
            return sortedDescending.value
                ? b.locationName.localeCompare(a.locationName)
                : a.locationName.localeCompare(b.locationName)
        }

        if (sortedBy.value === 'total') {
            return sortedDescending.value ? b.balance - a.balance : a.balance - b.balance
        }

        const balanceA = getProductBalance(a, sortedBy.value)
        const balanceB = getProductBalance(b, sortedBy.value)

        return sortedDescending.value ? balanceB - balanceA : balanceA - balanceB
    })

    fetchingBalance.value = false
}

async function sortBy(id?: string) {
    if (sortedBy.value === id) {
        sortedDescending.value = !sortedDescending.value
    } else {
        sortedBy.value = id
        sortedDescending.value = true
    }
    fetchBalancePerLocation()
}

function getProductBalance(location: StockLocation, productId: string): number {
    return location.products.find((product) => product.productId === productId)?.balance ?? 0
}

function adjustStock(location: StockLocation, product: CompanyProduct) {
    stockAdjustmentItem.value = {
        id: product.id,
        balance: getProductBalance(location, product.id),
        locationId: location.locationId,
    }
    stockAdjustmentOpen.value = true
}

onBeforeMount(async () => {
    if (!stockAdjustmentOpen.value) await fetchBalancePerLocation()
})

watch(stockAdjustmentOpen, async () => {
    if (!stockAdjustmentOpen.value) await fetchBalancePerLocation()
})
</script>

<template>
    <AdjustStockModal
        v-model="stockAdjustmentOpen"
        :product-id="stockAdjustmentItem?.id"
        :current-balance="stockAdjustmentItem?.balance"
        :location-id="stockAdjustmentItem?.locationId"
    />

    <MyInput
        v-model="searchQuery"
        name="search"
        autofocus
        group-class="max-w-xs mb-2"
        :label="t('search')"
        :placeholder="t('productName')"
    >
        <template #icon>
            <mdi:magnify />
        </template>
    </MyInput>

    <MyPanel class="relative max-w-full overflow-x-auto" shadow>
        <LoaderWrapper
            :visible="productStore.fetching || companyStore.loading || fetchingBalance"
        />
        <section class="flex">
            <aside
                class="sticky left-0 z-10 inline-block min-w-[300px] rounded-tl-xl bg-primary-50 uppercase shadow-lg dark:bg-dark-500"
            >
                <div class="h-[160px] flex items-end pb-3 pl-6">
                    <button class="relative" @click="sortBy(undefined)">
                        <ContentHeading
                            v-truncate-tooltip
                            class="truncate"
                            v-text="t('location')"
                        />
                        <mdi:chevron-down
                            v-if="sortedBy === undefined"
                            class="transition-transform absolute left-[100%] top-[2px]"
                            :class="{ 'rotate-180': !sortedDescending }"
                        />
                    </button>
                </div>
                <div class="text-xs">
                    <article
                        v-for="location in locations"
                        :key="location.locationId"
                        class="flex h-12 items-center px-6 font-semibold odd:bg-primary-100 odd:dark:bg-dark-600"
                    >
                        <span
                            v-truncate-tooltip="location.locationName"
                            class="line-clamp-2"
                            v-text="location.locationName"
                        />
                        <mdi:trash-can-outline
                            v-if="location.locationDeleted"
                            v-tooltip="t('locationDeleted')"
                            class="ml-2 text-red-600"
                        />
                    </article>
                </div>
            </aside>
            <div>
                <header class="flex">
                    <section
                        class="flex justify-center items-end h-[160px] w-[250px] border-r pb-3 border-primary-200 dark:border-dark-400"
                    >
                        <button class="relative" @click="sortBy('total')">
                            <ContentHeading
                                v-truncate-tooltip
                                class="truncate"
                                v-text="t('total')"
                            />
                            <mdi:chevron-down
                                v-if="sortedBy === 'total'"
                                class="transition-transform absolute left-[100%] top-[2px]"
                                :class="{ 'rotate-180': !sortedDescending }"
                            />
                        </button>
                    </section>
                    <section
                        v-for="product in products"
                        :key="'header-' + product.id"
                        class="flex h-[160px] w-[250px] flex-col items-center justify-end pb-3 gap-3 border-r border-primary-200 px-4 last:border-r-0 dark:border-dark-400"
                    >
                        <img
                            :alt="product.name"
                            :src="image(product.image, 'thumbnail')"
                            class="max-h-[120px] max-w-[200px]"
                        />
                        <button class="relative" @click="sortBy(product.id)">
                            <ContentHeading
                                v-truncate-tooltip
                                class="w-full truncate text-center"
                                v-text="product.name"
                            />
                            <mdi:chevron-down
                                v-if="sortedBy === product.id"
                                class="transition-transform absolute left-[100%] top-[2px]"
                                :class="{ 'rotate-180': !sortedDescending }"
                            />
                        </button>
                    </section>
                </header>
                <section
                    v-for="location in locations"
                    :key="location.locationId"
                    class="group flex even:bg-primary-100 even:dark:bg-dark-600"
                >
                    <article
                        class="flex h-12 min-w-[250px] items-center justify-center border-r border-primary-200 px-6 dark:border-dark-400"
                    >
                        <span v-text="numberFormatter.format(location.balance)" />
                    </article>
                    <article
                        v-for="product in products"
                        :key="location.locationId + '-' + product.id"
                        class="relative flex h-12 min-w-[250px] items-center justify-center border-r border-primary-200 px-6 last:border-r-0 dark:border-dark-400 edit-hover"
                    >
                        <span
                            v-text="numberFormatter.format(getProductBalance(location, product.id))"
                        />

                        <MyButton
                            v-if="authStore.hasPermission(PermissionType.ManageCompany)"
                            v-tooltip="t('adjustStock')"
                            class="absolute right-0 ml-2 edit-btn"
                            small
                            icon
                            @click="adjustStock(location, product)"
                        >
                            <mdi:pencil />
                        </MyButton>
                    </article>
                </section>
            </div>
        </section>
    </MyPanel>
</template>

<style scoped>
@media (hover: hover) {
    .edit-btn {
        display: none;
    }
    .edit-hover:hover .edit-btn {
        display: block;
    }
}
</style>
