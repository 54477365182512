import { HTMLAttributes, VueElement } from 'vue'

import { Model, uuid } from '@/types/general'
import { MinimalUser } from '@/types/user'
import { MinimalCompany } from '@/types/company'

export enum ActivityLogType {
    CompanyUpdated = 0,
    LocationCreated = 1,
    LocationUpdated = 2,
    LocationDeleted = 3,
    CustomerCreated = 4,
    CustomerUpdated = 5,
    CustomerCompanyUpdated = 6,
    EmployeeCreated = 7,
    EmployeeUpdated = 8,
    EmployeeRemoved = 9,
    ProductAdded = 10,
    ProductUpdated = 11,
    ProductRemoved = 12,
    ProductOrderUpdated = 13,
    CustomerLocationCreated = 14,
    CustomerLocationUpdated = 15,
    CustomerLocationDeleted = 16,
    CustomerMerged = 17,
    PODPreferencesUpdated = 18,
    EmailTemplateUpdated = 19,
    ScheduledStatementEnabled = 20,
    ScheduledStatementUpdated = 21,
    ScheduledStatementDisabled = 22,
    CustomerBalanceLimitUpdated = 23,
    DriverReportPreferencesUpdated = 24,
    TemplateCreated = 25,
    TemplateUpdated = 26,
    TemplateDeleted = 27,
    TransportObjectCreated = 28,
    TransportObjectUpdated = 29,
    TransportObjectDeleted = 30,
    EmployeeRoleCreated = 31,
    EmployeeRoleUpdated = 32,
    EmployeeRoleDeleted = 33,
    AlarmNotificationCreated = 34,
    AlarmNotificationUpdated = 35,
    AlarmNotificationDeleted = 36,
    DamageReportPreferencesUpdated = 37,
    SettlementTemplateCreated = 38,
    SettlementTemplateUpdated = 39,
    SettlementTemplateDeleted = 40,
    WebRoleCreated = 41,
    WebRoleUpdated = 42,
    WebRoleDeleted = 43,
    PackagingPreferencesUpdated = 44,
    DeliveryManagementHubCreated = 45,
    DeliveryManagementHubUpdated = 46,
    DeliveryManagementShipperHubUpdated = 47,
    DeliveryManagementTourCreated = 48,
    DeliveryManagementTourUpdated = 49,
    DeliveryManagementTourDeleted = 50,
    DeliveryManagementShipmentCreated = 51,
    DeliveryManagementShipmentUpdated = 52,
    DeliveryManagementColliCreated = 53,
    DeliveryManagementColliUpdated = 54,
    DeliveryManagementColliDeleted = 55,
    DeliveryManagementPreferencesUpdated = 56,
    DeliveryManagementDeliveryDescriptionCreated = 57,
    DeliveryManagementDeliveryDescriptionUpdated = 58,
    DeliveryManagementDeliveryDescriptionDeleted = 59,
    TourOptimized = 60,
    DeliveryManagementShipmentsScannedOnOtherTour = 62,
    DeliveryManagementShipmentsScannedOnTour = 63,
    DeliveryManagementShipmentsRemovedFromTour = 64,
}

export enum ActivityLogIcon {
    Updated,
    Created,
    Removed,
    Reorder,
    Warning,
}

export interface LogConfig {
    name: string
    entity: string
    color: HTMLAttributes['class']
    icon: ActivityLogIcon
    component?: VueElement
    showRoute?: string
}

export interface ActivityLog extends Model {
    id: string
    company: MinimalCompany
    user: MinimalUser
    type: ActivityLogType
    loggableId: uuid
    loggableType: string
    createdAt: string
    data: {
        fields: Model
        previousTourIds?: uuid[]
        newTourId?: uuid
        shipmentIds?: uuid[]
    } | null
    previousData: { fields: Model } | null
}

export const logConfig: Partial<Record<ActivityLogType, LogConfig>> = {
    [ActivityLogType.CompanyUpdated]: {
        name: 'entityUpdated',
        entity: 'company',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.LocationCreated]: {
        name: 'entityCreated',
        entity: 'location',
        color: 'green-500',
        icon: ActivityLogIcon.Created,
    },
    [ActivityLogType.LocationUpdated]: {
        name: 'entityUpdated',
        entity: 'location',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.LocationDeleted]: {
        name: 'entityDeleted',
        entity: 'location',
        color: 'yellow-500',
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.CustomerCreated]: {
        name: 'entityCreated',
        entity: 'customer',
        color: 'green-500',
        icon: ActivityLogIcon.Created,
        showRoute: 'packaging.customers.show',
    },
    [ActivityLogType.CustomerUpdated]: {
        name: 'entityUpdated',
        entity: 'customer',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
        showRoute: 'packaging.customers.show',
    },
    [ActivityLogType.CustomerCompanyUpdated]: {
        name: 'entityUpdated',
        entity: 'customer',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.EmployeeCreated]: {
        name: 'entityCreated',
        entity: 'employee',
        color: 'green-500',
        icon: ActivityLogIcon.Created,
    },
    [ActivityLogType.EmployeeUpdated]: {
        name: 'entityUpdated',
        entity: 'employee',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.EmployeeRemoved]: {
        name: 'entityDeleted',
        entity: 'employee',
        color: 'yellow-500',
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.ProductAdded]: {
        name: 'entityAdded',
        entity: 'product',
        color: 'green-500',
        icon: ActivityLogIcon.Created,
    },
    [ActivityLogType.ProductUpdated]: {
        name: 'entityUpdated',
        entity: 'product',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.ProductRemoved]: {
        name: 'entityDeleted',
        entity: 'product',
        color: 'yellow-500',
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.ProductOrderUpdated]: {
        name: 'entityUpdated',
        entity: 'productOrder',
        color: 'blue-500',
        icon: ActivityLogIcon.Reorder,
    },
    [ActivityLogType.CustomerLocationCreated]: {
        name: 'entityCreated',
        entity: 'customerLocation',
        color: 'green-500',
        icon: ActivityLogIcon.Created,
    },
    [ActivityLogType.CustomerLocationUpdated]: {
        name: 'entityUpdated',
        entity: 'customerLocation',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.CustomerLocationDeleted]: {
        name: 'entityDeleted',
        entity: 'customerLocation',
        color: 'yellow-500',
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.CustomerMerged]: {
        name: 'entityMerged',
        entity: 'customer',
        color: 'blue-400',
        icon: ActivityLogIcon.Reorder,
    },
    [ActivityLogType.PODPreferencesUpdated]: {
        name: 'entityUpdated',
        entity: 'podPreferences',
        color: 'blue-400',
        icon: ActivityLogIcon.Reorder,
    },
    [ActivityLogType.EmailTemplateUpdated]: {
        name: 'entityUpdated',
        entity: 'emailTemplate',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.ScheduledStatementEnabled]: {
        name: 'entityEnabled',
        entity: 'scheduledStatement',
        color: 'green-500',
        icon: ActivityLogIcon.Created,
    },
    [ActivityLogType.ScheduledStatementUpdated]: {
        name: 'entityUpdated',
        entity: 'scheduledStatement',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.ScheduledStatementDisabled]: {
        name: 'entityDisabled',
        entity: 'scheduledStatement',
        color: 'yellow-500',
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.CustomerBalanceLimitUpdated]: {
        name: 'entityUpdated',
        entity: 'customerBalanceLimit',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.DriverReportPreferencesUpdated]: {
        name: 'entityUpdated',
        entity: 'driverReportPreferences',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.TemplateCreated]: {
        name: 'entityCreated',
        entity: 'template',
        color: 'green-500',
        icon: ActivityLogIcon.Created,
    },
    [ActivityLogType.TemplateUpdated]: {
        name: 'entityUpdated',
        entity: 'template',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.TemplateDeleted]: {
        name: 'entityDeleted',
        entity: 'template',
        color: 'yellow-500',
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.TransportObjectCreated]: {
        name: 'entityCreated',
        entity: 'transportObject',
        color: 'green-500',
        icon: ActivityLogIcon.Created,
        showRoute: 'transport-objects.show',
    },
    [ActivityLogType.TransportObjectUpdated]: {
        name: 'entityUpdated',
        entity: 'transportObject',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
        showRoute: 'transport-objects.show',
    },
    [ActivityLogType.TransportObjectDeleted]: {
        name: 'entityDeleted',
        entity: 'transportObject',
        color: 'yellow-500',
        icon: ActivityLogIcon.Removed,
        showRoute: 'transport-objects.show',
    },
    [ActivityLogType.EmployeeRoleCreated]: {
        name: 'entityCreated',
        entity: 'employeeRole',
        color: 'green-500',
        icon: ActivityLogIcon.Created,
    },
    [ActivityLogType.EmployeeRoleUpdated]: {
        name: 'entityUpdated',
        entity: 'employeeRole',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.EmployeeRoleDeleted]: {
        name: 'entityDeleted',
        entity: 'employeeRole',
        color: 'yellow-500',
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.AlarmNotificationCreated]: {
        name: 'entityCreated',
        entity: 'alarmNotification',
        color: 'green-500',
        icon: ActivityLogIcon.Created,
    },
    [ActivityLogType.AlarmNotificationUpdated]: {
        name: 'entityUpdated',
        entity: 'alarmNotification',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.AlarmNotificationDeleted]: {
        name: 'entityDeleted',
        entity: 'alarmNotification',
        color: 'yellow-500',
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.DamageReportPreferencesUpdated]: {
        name: 'entityUpdated',
        entity: 'damageReportPreferences',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.SettlementTemplateCreated]: {
        name: 'entityCreated',
        entity: 'settlementTemplate',
        color: 'green-500',
        icon: ActivityLogIcon.Created,
    },
    [ActivityLogType.SettlementTemplateUpdated]: {
        name: 'entityUpdated',
        entity: 'settlementTemplate',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.SettlementTemplateDeleted]: {
        name: 'entityDeleted',
        entity: 'settlementTemplate',
        color: 'yellow-500',
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.WebRoleCreated]: {
        name: 'entityCreated',
        entity: 'webRole',
        color: 'green-500',
        icon: ActivityLogIcon.Created,
    },
    [ActivityLogType.WebRoleUpdated]: {
        name: 'entityUpdated',
        entity: 'webRole',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.WebRoleDeleted]: {
        name: 'entityDeleted',
        entity: 'webRole',
        color: 'yellow-500',
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.PackagingPreferencesUpdated]: {
        name: 'entityUpdated',
        entity: 'packagingPreferences',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.DeliveryManagementHubCreated]: {
        name: 'entityCreated',
        entity: 'hub',
        color: 'green-500',
        icon: ActivityLogIcon.Created,
    },
    [ActivityLogType.DeliveryManagementHubUpdated]: {
        name: 'entityUpdated',
        entity: 'hub',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.DeliveryManagementShipperHubUpdated]: {
        name: 'entityUpdated',
        entity: 'hub',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.DeliveryManagementTourCreated]: {
        name: 'entityCreated',
        entity: 'tour',
        color: 'green-500',
        icon: ActivityLogIcon.Created,
        showRoute: 'dm.tours.show',
    },
    [ActivityLogType.DeliveryManagementTourUpdated]: {
        name: 'entityUpdated',
        entity: 'tour',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
        showRoute: 'dm.tours.show',
    },
    [ActivityLogType.DeliveryManagementTourDeleted]: {
        name: 'entityDeleted',
        entity: 'tour',
        color: 'yellow-500',
        icon: ActivityLogIcon.Removed,
        showRoute: 'dm.tours.show',
    },
    [ActivityLogType.DeliveryManagementShipmentCreated]: {
        name: 'entityCreated',
        entity: 'shipment',
        color: 'green-500',
        icon: ActivityLogIcon.Created,
        showRoute: 'dm.shipments.show',
    },
    [ActivityLogType.DeliveryManagementShipmentUpdated]: {
        name: 'entityUpdated',
        entity: 'shipment',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
        showRoute: 'dm.shipments.show',
    },
    [ActivityLogType.DeliveryManagementColliCreated]: {
        name: 'entityCreated',
        entity: 'colli',
        color: 'green-500',
        icon: ActivityLogIcon.Created,
    },
    [ActivityLogType.DeliveryManagementColliUpdated]: {
        name: 'entityUpdated',
        entity: 'colli',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.DeliveryManagementColliDeleted]: {
        name: 'entityDeleted',
        entity: 'colli',
        color: 'yellow-500',
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.DeliveryManagementPreferencesUpdated]: {
        name: 'entityUpdated',
        entity: 'preferences',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.DeliveryManagementDeliveryDescriptionCreated]: {
        name: 'entityCreated',
        entity: 'deliveryDescription',
        color: 'green-500',
        icon: ActivityLogIcon.Created,
    },
    [ActivityLogType.DeliveryManagementDeliveryDescriptionUpdated]: {
        name: 'entityUpdated',
        entity: 'deliveryDescription',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.DeliveryManagementDeliveryDescriptionDeleted]: {
        name: 'entityDeleted',
        entity: 'deliveryDescription',
        color: 'yellow-500',
        icon: ActivityLogIcon.Removed,
    },
    [ActivityLogType.TourOptimized]: {
        name: 'entityUpdated',
        entity: 'tour',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.DeliveryManagementShipmentsScannedOnOtherTour]: {
        name: 'shipmentsScannedOnOtherTour',
        entity: 'tour',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.DeliveryManagementShipmentsScannedOnTour]: {
        name: 'shipmentsScannedOnTour',
        entity: 'tour',
        color: 'blue-400',
        icon: ActivityLogIcon.Updated,
    },
    [ActivityLogType.DeliveryManagementShipmentsRemovedFromTour]: {
        name: 'shipmentsRemovedFromTour',
        entity: 'tour',
        color: 'yellow-500',
        icon: ActivityLogIcon.Removed,
    },
}

// This manages the translations for the fields in the activity log. We will have to add more fields as we go.
export const translationsForFields: Record<string, string> = {
    driver_user_id: 'driver',
    company_id: 'company',
    hub_id: 'hub',
    app_role_id: 'appRole',
    web_role_id: 'webRole',
    settlement_template_id: 'settlementTemplate',
    template_id: 'template',
    template_field_id: 'templateField',
    report_id: 'report',
    category_id: 'category',
    sending_location_id: 'sendingLocation',
    receiving_location_id: 'receivingLocation',
    shipper_location_id: 'shipperLocation',
    haulier_company_id: 'haulier',
    shipper_company_id: 'shipper',
    transport_object_ids: 'vehiclesAndUnits',
    shipment_ids: 'shipments',
}
