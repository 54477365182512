import { toDataURL as QRToDataUrl } from 'qrcode'

export async function printElement(element: HTMLElement, landscape = false) {
    await generateQRCode(element)

    setPageSize(landscape)
    const wasDarkMode = document.documentElement.classList.contains('dark')
    if (wasDarkMode) {
        document.documentElement.classList.remove('dark')
    }
    const printElement = document.createElement('div')
    printElement.classList.add('print-element')
    printElement.innerHTML = element.innerHTML
    document.body.appendChild(printElement)
    setTimeout(() => {
        window.print()
        printElement.remove()
        if (wasDarkMode) {
            document.documentElement.classList.add('dark')
        }
    }, 50)
}
async function generateQRCode(element: HTMLElement) {
    const qrElement = element.querySelector('.qrcode') as HTMLImageElement | null
    if (!qrElement) return

    let url = `${window.location.origin}${window.location.pathname}`
    if (qrElement.getAttribute('data-url')) {
        url = qrElement.getAttribute('data-url') as string
    }

    qrElement.src = await QRToDataUrl(url)
}

export async function generateQRFromUrl(url: string): Promise<string> {
    return await QRToDataUrl(window.location.origin + url)
}

function setPageSize(landscape: boolean) {
    document.head.querySelectorAll('style[print="1"]').forEach((el) => el.remove())

    const pageStyle = document.createElement('style')
    pageStyle.setAttribute('type', 'text/css')
    pageStyle.setAttribute('print', '1')
    document.head.appendChild(pageStyle)
    pageStyle.innerHTML = `
        @page {
        size: A4 ${landscape ? 'landscape' : 'portrait'};
        }
    `
}
