<script lang="ts" setup>
import { computed, ref, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { useAuthStore } from '@/stores/auth-store'
import { LicenseType } from '@/types/company'
import { CompaniesTab, CompaniesTableRef, PermissionType } from '@/types/general'
import { currentRouteModuleKey } from '@/types/global-injection-keys'

import BasicExportModal from '@/components/import-export/BasicExportModal.vue'
import CompaniesTable from '@/components/customers/CompaniesTable.vue'
import CreateCustomerModal from '@/components/customers/CreateCustomerModal.vue'
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import MyTabs from '@/components/my-components/MyTabs.vue'
import LocationBalanceTable from '@/components/packaging/balance/LocationBalanceTable.vue'
import CustomerImportModal from '@/components/customers/CustomerImportModal.vue'

const currentRouteModule = inject(currentRouteModuleKey)!
const authStore = useAuthStore()
const { t } = useI18n()
const route = useRoute()

const tabs = computed(() => {
    const tabs: Partial<Record<CompaniesTab, string>> = {
        [CompaniesTab.Customers]: t('customers'),
        [CompaniesTab.Hauliers]: t('hauliers'),
    }

    const isPackaging = route.name?.toString()?.includes('packaging')
    if (authStore.hasLicense(LicenseType.LocationTrade) && isPackaging) {
        tabs[CompaniesTab.Locations] = t('locations')
    }

    return tabs
})

const companiesTableRef = ref<CompaniesTableRef>()

const exportEndpoint = computed(() =>
    window.route('company.customers.export', { company: authStore.companyId }),
)
const isHauliersTab = computed(() => route.query?.tab === CompaniesTab.Hauliers)
</script>

<template>
    <CrumbsAndActions>
        <Breadcrumb
            current
            v-text="currentRouteModule?.name === 'packaging' ? t('balance') : t('companies')"
        />

        <template #actions>
            <CustomerImportModal
                v-if="
                    authStore.hasPermission(PermissionType.ManageCustomers) &&
                    !authStore.isReadonly(LicenseType.PackagingModuleReadonly)
                "
                @import-finished="companiesTableRef?.refetch()"
            />

            <BasicExportModal
                :title="t('exportModel', { model: t('customers') })"
                :endpoint="exportEndpoint"
            />

            <CreateCustomerModal
                v-if="
                    authStore.hasPermission(PermissionType.ManageCustomers) &&
                    !authStore.isReadonly(LicenseType.PackagingModuleReadonly)
                "
                :haulier-as-default="isHauliersTab"
            />
        </template>
    </CrumbsAndActions>

    <MyTabs v-slot="{ tabKey }" :tabs="tabs" shadow>
        <LocationBalanceTable v-if="tabKey == CompaniesTab.Locations" />

        <CompaniesTable v-else ref="companiesTableRef" :tab="tabKey as CompaniesTab" />
    </MyTabs>
</template>
