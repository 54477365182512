import messages from '@intlify/unplugin-vue-i18n/messages'
import axios from 'axios'
import dayjs from 'dayjs'
import 'dayjs/locale/da'
import 'dayjs/locale/de'
import 'dayjs/locale/en'
import 'dayjs/locale/sv'
import { createI18n } from 'vue-i18n'

export interface Locale {
    flag: string
    name: string
}

export const locales: Record<string, Locale> = {
    en: { flag: 'us', name: 'English' },
    da: { flag: 'dk', name: 'Dansk' },
    de: { flag: 'de', name: 'Deutsch' },
    no: { flag: 'no', name: 'Norsk' },
    sv: { flag: 'se', name: 'Svenska' },
    it: { flag: 'it', name: 'Italiano' },
    fr: { flag: 'fr', name: 'Français' },
    hu: { flag: 'hu', name: 'Magyar' },
    pl: { flag: 'pl', name: 'Polski' },
}

export const i18n = createI18n({
    locale: getLanguage(),
    fallbackLocale: 'en',
    legacy: false,
    silentFallbackWarn: true,
    messages,
})

// TODO: Re-add lazy loading of translations
export async function setI18nLanguage(locale: string) {
    i18n.global.locale.value = locale
    localStorage.setItem('language', locale)
    document.querySelector('html')!.setAttribute('lang', locale)
    dayjs().locale(locale)

    if (window.user) {
        await axios.put(window.route('locale.update', { locale }))
    }
}

function getLanguage(): string {
    // We'll force english for storybook
    if (window.location.hostname === 'localhost') return 'en'
    if (window.user?.locale) return window.user.locale
    if (localStorage.getItem('language')) return localStorage.getItem('language')!

    const browserLanguage = (navigator.language || '').split('-')[0]
    if (browserLanguage) return browserLanguage

    return 'en'
}
