<script lang="ts" setup>
import { notify } from '@kyvg/vue3-notification'
import { watch } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { uuid } from '@/types/general'
import { emitter } from '@/utils/mitt'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'

export interface Props {
    modelValue: boolean
    transactionId?: uuid
    receiptId?: uuid
    economy: string
}

const props = defineProps<Props>()

const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void
}>()

const authStore = useAuthStore()
const { t } = useI18n()

const form = useForm({ economy: props.economy })

function close() {
    emit('update:modelValue', false)
}

async function save() {
    const transactionRoute = window.route('packaging.company.transactions.company-details.update', {
        company: authStore.companyId,
        transaction: props.transactionId ?? '',
    })

    const receiptRoute = window.route('pod.company.receipts.company-details.update', {
        company: authStore.companyId,
        receipt: props.receiptId ?? '',
    })

    const response = await form.submit('put', props.transactionId ? transactionRoute : receiptRoute)

    if (response) {
        close()
        props.transactionId ? emitter.emit('fetchTransactions') : emitter.emit('fetchReceipts')
        notify({ type: 'success', text: t('economyNoteUpdated') })
    }
}

watch(
    () => props.modelValue,
    () => form.reset({ economy: props.economy }),
)
</script>

<template>
    <MyModal :value="props.modelValue" @close="close">
        <template #title>
            {{ t('updateEntity', { entity: t('economyNote') }) }}
        </template>

        <LoaderWrapper :visible="form.loading.value" immediate />

        <p class="mt-2 mb-4" v-text="t('economyNoteIsPrivate')" />

        <MyForm :errors="form.errors.value" @submit.prevent="save">
            <MyInput
                v-model="form.data.economy"
                name="economy"
                :label="t('economyNote')"
                autofocus
            />

            <div class="mt-4 flex justify-end">
                <MyButton :disabled="form.loading.value" scheme="primary" v-text="t('save')" />
            </div>
        </MyForm>
    </MyModal>
</template>
