<script lang="ts">
interface Props {
    modelValue: boolean
    incidentId: uuid
    reportId?: uuid
}
</script>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { watch, ref, computed, onBeforeMount } from 'vue'
import { useRoute } from 'vue-router'
import axios from 'axios'

import { ResourceResponse, uuid } from '@/types/general'
import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { emitter } from '@/utils/mitt'
import { Report } from '@/types/damage-report'
import { useMittListener } from '@/hooks/use-mitt-listener'
import { incidentTypes } from '@/utils/damage-report'

import IncidentView from './IncidentView.vue'

import MyTextarea from '@/components/my-components/form/MyTextarea.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void
    (e: 'saved'): void
}>()

const { t } = useI18n()
const authStore = useAuthStore()
const route = useRoute()
const report = ref<Report>()
const loading = ref(false)
const form = useForm({ note: '' })

const incident = computed(() =>
    report.value?.transportObject.incidents?.find((incident) => incident.id === props.incidentId),
)

const reportNotesForIncidentInSection = computed(() => {
    if (!report.value?.notes) return

    return report.value.notes.filter(
        (note) => note.masterTemplateSectionId === incident.value?.masterTemplateSectionId,
    )
})

async function onSubmit() {
    const response = await form.submit(
        'PUT',
        window.route('dmr.company.incidents.fix', [authStore.companyId, props.incidentId]),
    )

    if (response) {
        emit('update:modelValue', false)
        emit('saved')
        emitter.emit('fetchAlarms')
        emitter.emit('incidentFixed')
    }
}

async function fetchReport() {
    report.value = undefined
    const reportId = (route.params.reportId as uuid | undefined) || props.reportId
    if (!reportId) return

    loading.value = true

    try {
        const response = await axios.get<ResourceResponse<Report>>(
            window.route('dmr.reports.show', { report: reportId }),
        )
        report.value = response.data.data
    } finally {
        loading.value = false
    }
}

onBeforeMount(() => {
    if (props.reportId && props.incidentId) {
        fetchReport()
    }
})

watch(
    () => props.modelValue,
    () => {
        if (!props.modelValue) return

        form.reset()

        if (props.reportId && props.incidentId) {
            fetchReport()
        }
    },
)

useMittListener('fetchAlarms', () => {
    if (props.modelValue) fetchReport()
})
</script>

<template>
    <MyModal
        :value="props.modelValue"
        :back-button="!props.incidentId"
        @close="emit('update:modelValue', false)"
    >
        <template #title>
            {{ t('fixIncidentType', { incidentType: t(incidentTypes[incident?.type ?? 0]) }) }}
        </template>

        <LoaderWrapper :visible="form.loading.value || loading" immediate />

        <IncidentView
            v-if="report?.transportObject && report.transportObject.template && props.incidentId"
            :transport-object="report.transportObject"
            :template="report.template"
            :report-id="report.id"
            :section-notes="reportNotesForIncidentInSection"
            show-fixed-by-default
            :specific-incident-id="props.incidentId"
        />

        <MyForm v-if="!incident?.fixedAt" class="space-y-4" @submit.prevent="onSubmit">
            <MyTextarea v-model="form.data.note" :label="t('fixNote')" autofocus />

            <div class="flex justify-end">
                <MyButton :disabled="loading" scheme="primary">{{ t('fix') }}</MyButton>
            </div>
        </MyForm>
    </MyModal>
</template>
