import axios, { AxiosRequestConfig, Method } from 'axios'
import { computed, reactive, ref } from 'vue'

import { ErrorResponse } from '@/types/general'

export interface SubmitOptions {
    data?: Record<string, unknown>
    appendErrors?: boolean
}

export default function useForm<Data extends object>(data: Data) {
    const initialData = JSON.parse(JSON.stringify({ ...data }))
    const formData = reactive<Data>(data)
    const errors = ref<Record<string, string[]>>({})
    const loading = ref(false)
    const hasErrors = computed(() => Object.keys(errors).length > 0)

    return {
        data: formData,
        errors,
        hasErrors,
        loading,
        reset(newData: Partial<Data> = {}) {
            newData = { ...JSON.parse(JSON.stringify({ ...initialData })), ...newData }

            for (const [key, value] of Object.entries(newData)) {
                // Typescript is dumb here?
                // @ts-ignore
                formData[key] = value
            }
        },
        async submit<T>(method: Method, url: string, options?: SubmitOptions): Promise<T | void> {
            options = { appendErrors: false, ...options }
            const data = options.data ? options.data : formData
            loading.value = true

            if (!options.appendErrors) errors.value = {}

            try {
                const request: AxiosRequestConfig = { method, url }
                if (method === 'get' || method === 'GET') {
                    request.params = data
                } else {
                    request.data = data
                }

                const response = await axios(request)

                return response.data as T
            } catch (e) {
                if (!axios.isAxiosError(e)) throw e

                if (e.response && e.response.status === 422) {
                    const newErrors = (e.response.data as ErrorResponse).errors
                    errors.value = { ...errors.value, ...newErrors }
                } else {
                    throw e
                }
            } finally {
                loading.value = false
            }
        },
    }
}
