<script setup lang="ts">
import { notify } from '@kyvg/vue3-notification'
import axios from 'axios'
import { onBeforeMount, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { EmailTemplate, EmailTemplateType } from '@/types/emails'
import { ResourceResponse, uuid } from '@/types/general'
import { useEmailTemplateOptions } from '@/utils/email-templates'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyVariableInput from '@/components/my-components/form/MyVariableInput.vue'
import MyEmailsInput from '@/components/my-components/form/MyEmailsInput.vue'

export interface Props {
    modelValue: boolean
    customerCompanyId?: string
    statementId: uuid
}

interface Form {
    emails: string[]
    emailBody: string
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'shared', value: void): void
    (e: 'update:modelValue', value: boolean): void
}>()

const authStore = useAuthStore()
const { t } = useI18n()

const templateOptions = useEmailTemplateOptions()
const { data, submit, errors, loading } = useForm<Form>({
    emails: [],
    emailBody: '',
})

async function share() {
    const response = await submit(
        'POST',
        window.route('packaging.company.statements.share', {
            company: authStore.companyId,
            statement: props.statementId,
        }),
    )

    if (response) {
        emit('shared')
        emit('update:modelValue', false)
        notify({ type: 'success', text: t('entityShared', { entity: 'statement' }) })
    } else {
        for (const [field, messageData] of Object.entries(errors.value)) {
            const messages = messageData.map((message) =>
                message.replace(/{emails.\d+}/g, '{email}'),
            )
            if (field.includes('emails')) {
                errors.value['emails'] = [...(errors.value['emails'] || []), ...messages]
            }
        }
    }
}

async function onLoad() {
    await Promise.all([fetchEmails(), fetchEmailTemplate()])
}

async function fetchEmails() {
    if (!props.customerCompanyId) return

    loading.value = true
    data.emails = []
    const response = await axios.get<string[]>(
        window.route('company.customers.email', {
            company: authStore.companyId,
            receivingCompanyId: props.customerCompanyId,
        }),
    )

    if (response.data) {
        data.emails = response.data
    }

    loading.value = false
}

async function fetchEmailTemplate() {
    try {
        const response = await axios.get<ResourceResponse<EmailTemplate>>(
            window.route('company.email-templates.show', {
                company: authStore.companyId,
                type: EmailTemplateType.PackagingStatement,
            }),
        )

        data.emailBody = response.data.data?.body
    } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === 404) {
            data.emailBody =
                window.defaultEmailTemplates[EmailTemplateType.PackagingStatement]?.body
        }
    }
}

watch(
    () => props.modelValue,
    async (open) => {
        if (open) await onLoad()
    },
)
onBeforeMount(async () => {
    if (props.modelValue) await onLoad()
})
</script>

<template>
    <MyModal :value="props.modelValue" @close="emit('update:modelValue', false)">
        <template #title>
            {{ t('shareEntity', { entity: 'statement' }) }}
        </template>

        <LoaderWrapper :visible="loading" class="rounded-xl" />

        <MyForm class="space-y-5" :errors="errors" @submit.prevent="share">
            <MyEmailsInput v-model="data.emails" :input-label="'receiverEmail'" required />

            <MyVariableInput
                v-model="data.emailBody"
                name="body"
                :label="t('emailBody')"
                :min-height="200"
                :variables="templateOptions[EmailTemplateType.PackagingStatement].variables"
            />

            <div class="mt-4 flex justify-end">
                <MyButton :disabled="loading" scheme="primary" v-text="t('share')" />
            </div>
        </MyForm>
    </MyModal>
</template>
