<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { useAuthStore } from '@/stores/auth-store'

import BasicExportModal from '@/components/import-export/BasicExportModal.vue'
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import ReceiptsTable from '@/components/pod/ReceiptsTable.vue'

const { t } = useI18n()
const authStore = useAuthStore()
const route = useRoute()

const exportEndpoint = computed(() => {
    if (!authStore.companyId) return ''

    return window.route('pod.company.receipts.export', {
        company: authStore.companyId,
        ...route.query,
    })
})
</script>

<template>
    <CrumbsAndActions v-if="authStore.companyId">
        <Breadcrumb :to="{ name: 'pod' }" v-text="t('pod')" />
        <Breadcrumb current v-text="t('receipts')" />
        <template #actions>
            <BasicExportModal
                :title="t('exportModel', { model: t('receipts') })"
                :endpoint="exportEndpoint"
            />
        </template>
    </CrumbsAndActions>

    <RouterView />

    <MyPanel v-if="authStore.companyId" shadow>
        <ReceiptsTable />
    </MyPanel>
</template>
