<script lang="ts" setup>
import axios from 'axios'
import { computed, inject, onBeforeMount, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { ResourceResponse, uuid } from '@/types/general'
import { useAuthStore } from '@/stores/auth-store'
import { Booking, EventType, TourEvent } from '@/types/delivery-management'
import { imageViewerOpenKey } from '@/types/global-injection-keys'
import { image as imageHelper } from '@/utils/assets'
import { datetime } from '@/utils/dates'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import TourEventEntries from '@/components/delivery-management/tour-modal/TourEventEntries.vue'
import MyTable from '@/components/table/MyTable.vue'
import MyTableColumn from '@/components/table/MyTableColumn.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import ActionRowItem from '@/components/table/ActionRowItem.vue'
import TransactionModal from '@/components/packaging/transactions/TransactionModal.vue'

export interface Props {
    id?: string
    open?: boolean
}

const props = withDefaults(defineProps<Props>(), { open: true })
const emit = defineEmits<{ (e: 'update:open', value: boolean): void }>()

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()
const { t } = useI18n()

const imageViewerOpen = inject(imageViewerOpenKey)!

const booking = ref<Booking | null>(null)
const loading = ref(false)
const transactionModalOpen = ref(false)
const selectedTransactionId = ref<uuid | null>(null)

const tourEvents = computed<TourEvent[]>(() => {
    const shipments = booking.value?.shipments ?? []

    return shipments.map<TourEvent>((shipment, index) => {
        return {
            id: shipment.id,
            shipment: shipment,
            tourId: shipment.tour?.id ?? '',
            deliveryLocation: null,
            order: index,
            plannedDuration: 0,
            estimatedAt: shipment.plannedAt,
            liveEstimatedAt: null,
            createdAt: shipment.createdAt,
            updatedAt: shipment.updatedAt,
            type: EventType.Shipment,
            handledAt: shipment.handledAt,
            transactions: shipment.transactions,
            waypoint: null,
        }
    })
})

const transactions = computed(() => {
    const shipments = booking.value?.shipments ?? []

    return shipments.flatMap((shipment) => shipment.transactions)
})

function showTransaction(id: uuid) {
    selectedTransactionId.value = id
    transactionModalOpen.value = true
}

function transactionModalClosed() {
    transactionModalOpen.value = false
    selectedTransactionId.value = null
}

async function fetchBooking(id?: uuid) {
    loading.value = true
    try {
        const response = await axios.get<ResourceResponse<Booking>>(
            window.route('dm.company.bookings.show', {
                company: authStore.companyId,
                booking: id ?? props.id!,
            }),
        )
        booking.value = response.data.data
    } finally {
        loading.value = false
    }
}

function onClose() {
    if (imageViewerOpen.value) return

    if (props.id) {
        emit('update:open', false)
    } else {
        router.replace({ name: 'dm.bookings', query: route.query })
    }
}

onBeforeMount(async () => {
    if (route.params.bookingId) fetchBooking(route.params.bookingId as uuid)
    if (props.id) await fetchBooking()
})

watch(
    () => props.id,
    () => {
        if (props.id) {
            fetchBooking()
        }
    },
)
watch(
    () => route.params.bookingId,
    () => {
        if (route.params.bookingId) fetchBooking(route.params.bookingId as uuid)
    },
)
</script>

<template>
    <MyModal :value="props.open" :max-width="1200" @close="onClose">
        <LoaderWrapper :visible="loading" />

        <div v-if="booking" class="space-y-3 mt-3">
            <div class="flex flex-col">
                <span
                    class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                    v-text="t('bookingNumber')"
                />
                <span
                    class="text-md font-semibold text-primary-500 dark:text-primary-100"
                    v-text="booking.bookingNumber"
                />
            </div>

            <div class="flex flex-col">
                <span
                    class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                    v-text="t('customer')"
                />
                <span
                    class="text-md font-semibold text-primary-500 dark:text-primary-100"
                    v-text="booking.customerCompany?.name ?? t('notSpecified')"
                />

                <img
                    v-if="booking.customerCompany?.logo"
                    class="mt-2 w-24 rounded-xl bg-primary-200"
                    :src="imageHelper(booking.customerCompany.logo, 'thumbnail')"
                />
            </div>

            <div>
                <div
                    class="font-semibold text-primary-400 dark:text-primary-300 text-center"
                    v-text="t('shipments')"
                />

                <TourEventEntries :tour-events="tourEvents" />
            </div>

            <div>
                <div
                    class="font-semibold text-primary-400 dark:text-primary-300 mb-2"
                    v-text="t('transactions')"
                />

                <MyPanel>
                    <MyTable
                        table-id="booking-transactions"
                        :rows="transactions"
                        disable-search
                        disable-column-preferences
                        disable-actions
                    >
                        <template #actionRow="{ row }">
                            <ActionRowItem @click="showTransaction(row.id)">
                                <mdi:eye />
                            </ActionRowItem>
                        </template>

                        <MyTableColumn property="confirmedAt" :name="t('confirmedAt')" />
                        <template #confirmedAt="{ row }">{{ datetime(row.confirmedAt) }}</template>
                        <MyTableColumn property="products" :name="t('products')" />
                        <template #products="{ row }">{{ row.products?.length ?? 0 }}</template>
                    </MyTable>
                </MyPanel>
            </div>
        </div>

        <h1
            v-if="!booking && !loading"
            class="my-auto text-center text-4xl font-bold"
            v-text="t('bookingNotFound')"
        />

        <TransactionModal
            v-if="selectedTransactionId"
            :id="selectedTransactionId"
            v-model:open="transactionModalOpen"
            @close="transactionModalClosed"
        />
    </MyModal>
</template>
