<script lang="ts" setup>
import { computed, ref, watch } from 'vue'

import { useFilterQuery } from '@/hooks/use-filter-query'
import { DropdownOption } from '@/types/inputs'

import MyButton from '@/components/my-components/MyButton.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'

export interface Props {
    label: string
    filterName: string
    default?: string
    loading?: boolean
    options: DropdownOption[]
    dropdownWidth?: number
}

const emit = defineEmits<{
    (e: 'change', value: string | null, filterName: string): void
}>()
const props = defineProps<Props>()

const { filters } = useFilterQuery()
const item = ref(filters.value[props.filterName] || (props.default ?? null))

const label = computed(() => {
    return item.value ? props.options.find((o) => o.value == item.value)?.label : props.label
})

watch(item, () => emit('change', item.value, props.filterName))
watch(
    () => filters.value[props.filterName],
    () => {
        item.value = filters.value[props.filterName] ?? null
    },
)
</script>
<script lang="ts">
export default { inheritAttrs: false }
</script>

<template>
    <MySelect
        v-model="item"
        :options="props.options"
        custom-class="uppercase font-semibold text-gray-500 text-xs"
        :loading="loading"
        hide-chevron
        inline-search
        v-bind="$attrs"
        :dropdown-width="props.dropdownWidth"
    >
        <MyButton :active="!!item" plain scheme="light" size="small" v-text="label" />

        <template #option="slotProps">
            <slot name="option" v-bind="slotProps" />
        </template>
    </MySelect>
</template>
