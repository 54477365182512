<script lang="ts">
export default defineComponent({ inheritAttrs: true })
</script>
<script lang="ts" setup>
import type { MinimalTransaction, Transaction } from '@/types/transaction'

import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

import { image } from '@/utils/assets'

export interface Props {
    row: Transaction | MinimalTransaction
    value: number
}

const props = defineProps<Props>()
const { t } = useI18n()
</script>

<template>
    <span class="-ml-3 -mr-3 block h-full w-[calc(100%_+_1.5rem)] cursor-default">
        <VMenu class="h-full" placement="top" :delay="150">
            <!-- This will be the popover reference (for the events and position) -->
            <button
                class="h-full w-full cursor-default text-blue-500 underline decoration-blue-700 decoration-dashed underline-offset-2 transition hover:text-blue-700 active:text-blue-600 dark:text-blue-300 dark:hover:text-blue-400"
                v-bind="$attrs"
                v-text="props.value"
            />

            <template #popper>
                <div class="w-[30rem] max-w-full p-5">
                    <div
                        class="grid grid-cols-[50px_1fr_70px_70px] pb-3 text-xs uppercase text-primary-400"
                    >
                        <span />
                        <span class="font-semibold" v-text="t('product')" />
                        <span class="text-center font-semibold" v-text="t('received')" />
                        <span class="text-center font-semibold" v-text="t('delivered')" />
                    </div>
                    <div class="space-y-2 text-sm">
                        <div
                            v-for="product in props.row.products"
                            :key="props.row.id + '-' + product.id"
                            class="grid grid-cols-[50px_1fr_70px_70px] items-center"
                        >
                            <img class="h-9" :src="image(product.image, 'thumbnail')" />
                            <span v-text="product.name" />
                            <span class="text-center" v-text="product.received" />
                            <span class="text-center" v-text="product.delivered" />
                        </div>
                    </div>

                    <div
                        class="grid grid-cols-[50px_1fr_70px_70px] pt-2 text-sm uppercase text-blue-400"
                    >
                        <span />
                        <span class="font-semibold" v-text="t('total')" />
                        <span
                            class="text-center font-semibold"
                            v-text="props.row.productsReceived"
                        />
                        <span
                            class="text-center font-semibold"
                            v-text="props.row.productsDelivered"
                        />
                    </div>
                </div>
            </template>
        </VMenu>
    </span>
</template>
