import { Loader } from '@googlemaps/js-api-loader'

export function googleMapsLink(latitude: number, longitude: number) {
    return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
}

export const mapsLoader = new Loader({
    apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    version: 'weekly',
    libraries: ['places'],
})
