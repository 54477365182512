<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

import { Company, LicenseType, Location } from '@/types/company'
import { image } from '@/utils/assets'
import { localeDate, localeTime } from '@/utils/dates'
import { useAuthStore } from '@/stores/auth-store'

import MyPanel from '@/components/my-components/MyPanel.vue'

export interface Props {
    title?: string
    company?: Omit<Company, 'locations'>
    location?: Location
    showUser?: boolean
    userTitle?: string
    user?: { image?: string; name: string; date?: string }
}

const props = withDefaults(defineProps<Props>(), { showUser: false })

const { t } = useI18n()
const authStore = useAuthStore()
</script>

<template>
    <MyPanel
        :title="props.title"
        background="bg-primary-50 dark:bg-dark-500"
        class="grid grid-cols-[100px_1fr]"
        padded
    >
        <template v-if="props.company">
            <span
                class="translate-y-0.5 text-sm font-semibold uppercase text-gray-500"
                v-text="t('company')"
            />
            <span v-text="props.company.name" />

            <template v-if="props.location">
                <span
                    class="translate-y-0.5 text-sm font-semibold uppercase text-gray-500"
                    v-text="t('location')"
                />

                <span>
                    {{ location?.name }}
                    <template
                        v-if="
                            authStore.hasLicense(LicenseType.AgentLocationFiltering) &&
                            location?.agentNumber
                        "
                    >
                        ({{ location?.agentNumber }})
                    </template>
                </span>

                <span
                    class="translate-y-0.5 text-sm font-semibold uppercase text-gray-500"
                    v-text="t('address')"
                />
                <span>
                    <span class="block" v-text="props.location.address" />
                    {{ props.location.zipcode }},
                    {{ props.location.city }}
                </span>
            </template>

            <div class="mb-3" />
        </template>

        <template v-if="showUser && props.user">
            <div class="col-span-2 mb-3" />

            <img
                v-if="props.user.image"
                :alt="props.user.name"
                class="mr-2.5 w-16 rounded-full"
                :src="image(props.user.image)"
            />
            <div
                v-else
                class="mr-2.5 flex h-16 w-16 items-center justify-center rounded-full bg-gray-200 text-gray-400"
            >
                <mdi:account class="h-10 w-10" />
            </div>

            <div>
                <div>
                    <h4
                        class="text-sm font-semibold uppercase text-primary-400"
                        v-text="props.userTitle"
                    />

                    <span v-text="props.user.name" />

                    <div class="flex items-center">
                        <span v-if="props.user.date" class="mr-2 text-primary-400">
                            <mdi:clock />
                        </span>
                        <span v-else class="mr-2 text-red-400">
                            <mdi:alert-circle-outline />
                        </span>
                        <template v-if="props.user.date">
                            <span class="flex items-center text-sm text-gray-500">
                                {{ localeDate(props.user.date) }}
                                <span class="mx-2 text-xs font-bold text-primary-400"> | </span>
                                {{ localeTime(props.user.date) }}
                            </span>
                        </template>

                        <span v-else v-text="t('unconfirmed')" />
                    </div>
                </div>
            </div>
        </template>
    </MyPanel>
</template>
