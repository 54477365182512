<script lang="ts" setup>
import { computed } from 'vue'

import { ElementInputEvent } from '@/types/general'

export interface Props {
    modelValue?: boolean
    checked?: boolean
    indeterminate?: boolean
    disabled?: boolean
    label?: string
}

const props = withDefaults(defineProps<Props>(), {
    modelValue: undefined,
    checked: undefined,
    indeterminate: false,
})
const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void
    (e: 'change', value: boolean): void
}>()

const checked = computed(() => (props.modelValue !== undefined ? props.modelValue : props.checked))

function onChange(value: boolean) {
    emit('update:modelValue', value)
    emit('change', value)
}
</script>

<template>
    <label class="inline-flex select-none items-center gap-2">
        <input
            :disabled="props.disabled"
            :checked="checked"
            class="rounded border-2 border-primary-400 bg-transparent outline-none ring-green-500 checked:bg-primary-500 indeterminate:bg-primary-500 checked:hover:bg-primary-500 indeterminate:hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-0 checked:focus:bg-primary-500 indeterminate:focus:bg-primary-500 disabled:cursor-not-allowed dark:border-dark-300 dark:checked:bg-primary-400 dark:indeterminate:bg-primary-400 dark:checked:hover:bg-primary-400 dark:indeterminate:hover:bg-primary-400 dark:checked:focus:bg-primary-400 dark:indeterminate:focus:bg-primary-400"
            type="checkbox"
            :indeterminate.prop="props.indeterminate"
            @input="onChange(($event as ElementInputEvent).target.checked)"
        />
        <span v-if="props.label" class="text-sm" v-text="props.label" />
    </label>
</template>
