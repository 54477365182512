<script lang="ts" setup>
import type { User } from '@/types/user'

import { Switch } from '@headlessui/vue'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@/hooks/use-form'
import { useUsernameCheck } from '@/hooks/use-username-check'
import { useAuthStore } from '@/stores/auth-store'

import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import Loader from '@/components/loaders/Loader.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'

const authStore = useAuthStore()
const user = authStore.user

const { t } = useI18n()
const changePassword = ref(false)
const { data, loading, errors, submit } = useForm({
    name: user!.name,
    email: user!.email,
    username: user!.username,
    password: '' as string | undefined,
    password_confirmation: '' as string | undefined,
})
const {
    isChecked,
    isLoading: loadingUsernameCheck,
    isAvailable,
    message,
    resetCheck,
} = useUsernameCheck(() => data.username)

async function onSubmit() {
    if (!changePassword.value) {
        data.password = undefined
        data.password_confirmation = undefined
    }

    resetCheck()
    const response = await submit<User>('put', window.route('users.update', { id: user!.id }))
    if (!response) return

    changePassword.value = false

    authStore.updateUser({
        name: response!.name,
        email: response!.email,
        username: response!.username,
    })
}
</script>

<template>
    <CrumbsAndActions>
        <Breadcrumb current v-text="t('profile')" />
    </CrumbsAndActions>

    <div class="flex justify-center">
        <div class="w-full max-w-3xl">
            <MyPanel :title="t('profile')" padded panel-class="w-full" shadow>
                <form class="flex flex-col items-start gap-3" @submit.prevent="onSubmit">
                    <label class="w-full">
                        <span v-text="t('name')" />

                        <MyInput
                            v-model="data.name"
                            :error="errors.name ? errors.name[0] : undefined"
                            class="mt-1"
                        />
                    </label>

                    <label class="w-full">
                        <span v-text="t('email')" />

                        <MyInput
                            v-model="data.email"
                            :error="errors.email ? errors.email[0] : undefined"
                            class="mt-1"
                            type="email"
                        />
                    </label>

                    <label class="w-full">
                        <span v-text="t('username')" />

                        <MyInput
                            v-model="data.username"
                            :error="errors.username ? errors.username[0] : undefined"
                            class="mt-1"
                        >
                            <Loader
                                v-if="loadingUsernameCheck"
                                :size="25"
                                class="absolute top-0 bottom-0 right-2 m-auto text-gray-800"
                            />
                        </MyInput>

                        <span
                            v-if="isChecked"
                            class="block"
                            :class="{
                                'text-green-500': isAvailable,
                                'text-red-500': !isAvailable,
                            }"
                            v-text="message"
                        />
                    </label>

                    <div class="mt-2 flex items-center gap-3">
                        <Switch
                            v-model="changePassword"
                            :class="changePassword ? 'bg-gray-400' : 'bg-gray-300'"
                            class="relative inline-flex h-[28px] w-[64px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-opacity-75"
                        >
                            <span class="sr-only" v-text="t('changePassword')" />

                            <span
                                :class="changePassword ? 'translate-x-9' : 'translate-x-0'"
                                aria-hidden="true"
                                class="pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
                            />
                        </Switch>

                        <span v-text="t('changePassword')" />
                    </div>

                    <template v-if="changePassword">
                        <label class="w-full">
                            <span v-text="t('password')" />

                            <MyInput
                                v-model="data.password"
                                :error="errors.password ? errors.password[0] : undefined"
                                class="mt-1"
                                type="password"
                            />
                        </label>

                        <label class="w-full">
                            <span v-text="t('confirmPassword')" />

                            <MyInput
                                v-model="data.password_confirmation"
                                class="mt-1"
                                type="password"
                            />
                        </label>
                    </template>

                    <MyButton
                        :disabled="loading"
                        native-type="submit"
                        scheme="primary"
                        v-text="t('save')"
                    />
                </form>

                <LoaderWrapper :visible="loading" />
            </MyPanel>
        </div>
    </div>
</template>
