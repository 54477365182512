<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@/stores/auth-store'
import { useTransactionStore } from '@/stores/transaction-store'
import { LicenseType } from '@/types/company'
import { TransactionState, TransactionStep, TransactionType } from '@/types/transaction'
import { PermissionType } from '@/types/general'

import ManageTransactionCompany from './ManageTransactionCompany.vue'
import ManageTransactionConfirm from './ManageTransactionConfirm.vue'
import ManageTransactionDetails from './ManageTransactionDetails.vue'
import ManageTransactionProducts from './ManageTransactionProducts.vue'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyFormWizard from '@/components/my-components/form/MyFormWizard.vue'

export interface Props {
    noButton?: boolean
}
const props = withDefaults(defineProps<Props>(), { noButton: false })

const { t } = useI18n()

const authStore = useAuthStore()
const transactionStore = useTransactionStore()
const { currentStep, transaction, managerOpen } = storeToRefs(transactionStore)
const imageUploadOpen = ref(false)
const additionalTransactions = ref(0)
const showDraftsSelector = computed(() => {
    if (authStore.hasLicense(LicenseType.TransactionDrafts)) return false
    if (!transactionStore.transaction?.id) return false

    return transactionStore.transaction?.type !== TransactionType.Location
})

function resetTransactionManager() {
    additionalTransactions.value = 0
    imageUploadOpen.value = false
}

function closeTransactionManager() {
    if (managerOpen.value) return

    transactionStore.close()

    resetTransactionManager()
}

onMounted(() => resetTransactionManager())

onUnmounted(() => closeTransactionManager())

watch(managerOpen, () => closeTransactionManager)
</script>

<template>
    <LoaderWrapper :visible="managerOpen && transactionStore.loading" />
    <MyModal v-model="managerOpen" :max-width="800" @close="closeTransactionManager">
        <MyFormWizard
            v-if="!transactionStore.loading"
            v-model="currentStep"
            :completed="!transactionStore.isCreatingNew"
        >
            <ManageTransactionCompany
                v-if="transaction?.state !== TransactionState.IncomingDraft"
                :active="currentStep === TransactionStep.Company"
            />
            <ManageTransactionProducts
                :active="currentStep === TransactionStep.Products"
                :image-upload-open="imageUploadOpen"
                @close-image-upload="imageUploadOpen = false"
            />
            <ManageTransactionDetails :active="currentStep === TransactionStep.Details" />
            <ManageTransactionConfirm :active="currentStep === TransactionStep.Confirm" />

            <template #footer>
                <template v-if="currentStep === TransactionStep.Products">
                    <div class="flex grow items-center justify-end px-5">
                        <button
                            type="button"
                            class="flex h-10 cursor-pointer items-center gap-1.5 px-2.5 text-sm font-semibold text-blue-500 transition-all hover:text-blue-700"
                            @click="imageUploadOpen = !imageUploadOpen"
                            v-text="t(!imageUploadOpen ? 'addImages' : 'closeImages')"
                        />
                    </div>
                </template>
            </template>

            <template v-if="currentStep === TransactionStep.Confirm" #submit>
                <div class="flex items-center gap-6">
                    <label
                        v-if="showDraftsSelector"
                        class="block cursor-pointer rounded-xl px-2.5 py-1.5 pr-3 focus-within:bg-white dark:focus-within:bg-dark-400"
                    >
                        <input
                            v-model="additionalTransactions"
                            autocomplete="off"
                            class="m-0 mx-auto w-6 appearance-none border-none bg-transparent p-1 text-left text-sm font-semibold outline-none ring-0 transition focus:ring-0"
                            max="10"
                            min="0"
                            type="number"
                            @focus=";($event.target as HTMLInputElement).select()"
                        />
                        <span
                            class="select-none text-sm font-semibold text-blue-500"
                            v-text="t('additionalTransactions')"
                        />
                    </label>

                    <MyButton
                        scheme="primary"
                        @click.prevent="transactionStore.saveTransaction(additionalTransactions)"
                        v-text="t('submit')"
                    />
                </div>
            </template>
        </MyFormWizard>
    </MyModal>

    <MyButton
        v-if="!props.noButton && authStore.hasPermission(PermissionType.ManageTransactions)"
        plain
        scheme="primary"
        size="small"
        @click="transactionStore.create()"
    >
        <mdi:plus-thick class="mr-1" />
        {{ t('createNewTransaction') }}
    </MyButton>
</template>
