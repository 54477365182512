<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@/stores/auth-store'
import { LicenseType } from '@/types/company'

import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import DriverReportPreferences from '@/components/settings/DriverReportPreferences.vue'
import DeliveryManagementPreferences from '@/components/settings/DeliveryManagementPreferences.vue'
import DamageReportPreferences from '@/components/settings/DamageReportPreferences.vue'
import PodPreferences from '@/components/settings/PodPreferences.vue'
import PackagingPreferences from '@/components/settings/PackagingPreferences.vue'

const { t } = useI18n()
const authStore = useAuthStore()
</script>

<template>
    <CrumbsAndActions>
        <Breadcrumb :to="{ name: 'settings' }" v-text="t('settings')" />
        <Breadcrumb current v-text="t('licenses')" />
    </CrumbsAndActions>

    <div class="flex flex-wrap justify-center md:space-x-8 lg:justify-start">
        <div v-if="authStore.hasLicense(LicenseType.PODModule)" class="mb-6 max-w-min">
            <h1 class="mb-2 text-3xl font-bold uppercase text-primary-400" v-text="t('pod')" />
            <PodPreferences />
        </div>
        <div v-if="authStore.hasLicense(LicenseType.PackagingModule)" class="mb-6">
            <h1
                class="mb-2 text-3xl font-bold uppercase text-primary-400"
                v-text="t('packaging')"
            />

            <PackagingPreferences />
        </div>

        <div v-if="authStore.hasLicense(LicenseType.DriverReportModule)" class="mb-6 max-w-min">
            <h1
                class="mb-2 text-3xl font-bold uppercase text-primary-400"
                v-text="t('driverReports')"
            />

            <DriverReportPreferences />
        </div>

        <div v-if="authStore.hasLicense(LicenseType.DamageReportModule)" class="mb-6 max-w-min">
            <h1
                class="mb-2 text-3xl font-bold uppercase text-primary-400"
                v-text="t('damageReports')"
            />

            <DamageReportPreferences />
        </div>

        <div
            v-if="authStore.hasLicense(LicenseType.DeliveryManagementModule)"
            class="mb-6 max-w-min"
        >
            <h1
                class="mb-2 text-3xl font-bold uppercase text-primary-400"
                v-text="t('deliveryManagement')"
            />

            <DeliveryManagementPreferences />
        </div>
    </div>
</template>
