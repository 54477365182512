<script lang="ts">
interface Props {
    modelValue: boolean
    shipmentId?: uuid
}
</script>

<script setup lang="ts">
import type { MinimalResource, ResourceResponse, uuid } from '@/types/general'

import axios, { Method } from 'axios'
import { watch, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Datepicker from '@vuepic/vue-datepicker'
import dayjs from 'dayjs'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { DropdownStringOption } from '@/types/inputs'
import { Shipment, ShipmentColli, ShipmentType, Colli } from '@/types/delivery-management'
import { useCompanyStore } from '@/stores/company-store'
import { emitter } from '@/utils/mitt'

import ColliPickerModal from '@/components/delivery-management/ColliPickerModal.vue'
import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'
import MyRadioButton from '@/components/my-components/form/MyRadioButton.vue'
import MyRadioButtonGroup from '@/components/my-components/form/MyRadioButtonGroup.vue'
import MyErrorMessage from '@/components/my-components/form/MyErrorMessage.vue'
import MyInputLabel from '@/components/my-components/form/MyInputLabel.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'

interface FormShipmentColli {
    colli: Colli
    amount: number
}

interface Form {
    type: ShipmentType
    sendingCompanyId: uuid
    sendingLocationId: uuid | null
    receivingCompanyId: uuid | null
    receivingLocationId: uuid | null
    shipperCompanyId: uuid | null
    shipperLocationId: uuid | null
    haulierCompanyId: uuid | null
    haulierLocationId: uuid | null
    deliveredByUserId: uuid | null
    categoryId: uuid | null
    deliveryNote: string | null
    palletExchange: boolean
    plannedAt: string
    handledAt: string | null
    collis: FormShipmentColli[]
    shipmentNumber: string | null
    name: string | null
    references: string | null
    soldTo: string | null
    shipTo: string | null
    handleableAfter: string | null
    handleableBefore: string | null
}

const props = defineProps<Props>()
const companyStore = useCompanyStore()

const emit = defineEmits<{
    (e: 'close'): void
    (e: 'saved'): void
}>()

const authStore = useAuthStore()
const { t } = useI18n()
const { data, submit, errors, reset, loading } = useForm<Form>({
    type: ShipmentType.Delivery,
    sendingCompanyId: authStore.companyId,
    sendingLocationId: null,
    receivingCompanyId: null,
    receivingLocationId: null,
    shipperCompanyId: null,
    shipperLocationId: null,
    haulierCompanyId: null,
    haulierLocationId: null,
    deliveredByUserId: null,
    deliveryNote: null,
    categoryId: null,
    palletExchange: false,
    plannedAt: '',
    handledAt: null,
    collis: [],
    shipmentNumber: null,
    name: null,
    references: null,
    soldTo: null,
    shipTo: null,
    handleableAfter: null,
    handleableBefore: null,
})

const shipment = ref<Shipment>()
const colliPickerModalOpen = ref(false)
const informationToggle = ref(false)
const initializing = ref(false)
const receivers = ref<DropdownStringOption[]>([])
const shippers = ref<DropdownStringOption[]>([])
const receiverLocations = ref<DropdownStringOption[]>([])
const shipperLocations = ref<DropdownStringOption[]>([])
const hauliers = ref<DropdownStringOption[]>([])
const haulierLocations = ref<DropdownStringOption[]>([])
const categories = ref<DropdownStringOption[]>([])

const title = computed(() => {
    if (props.shipmentId) {
        return t('updateEntity', { entity: t('shipment') })
    } else {
        return t('createEntity', { entity: t('shipment') })
    }
})

function convertCollis(collis: ShipmentColli[]): FormShipmentColli[] {
    data.collis = collis.map((colli) => ({
        colli: colli,
        amount: colli.amount,
    }))
    return data.collis
}

function removeColli(colli: Colli) {
    data.collis = data.collis?.filter((i) => i.colli.id !== colli.id)
}

function handleableBeforeOpened() {
    if (!data.plannedAt || data.handleableBefore !== null) return

    data.handleableBefore = dayjs(data.plannedAt).endOf('day').toISOString()
}

function handleableAfterOpened() {
    if (!data.plannedAt || data.handleableAfter !== null) return

    data.handleableAfter = dayjs(data.plannedAt).startOf('day').toISOString()
}

const companyLocations = computed<DropdownStringOption[]>(() => {
    const locations = companyStore.locations
    return locations.map((location) => ({ value: location.id, label: location.name }))
})

const companyIsShipper = computed(() => {
    return data.shipperCompanyId === authStore.companyId
})

async function fetchShipment() {
    initializing.value = true
    const response = await axios.get<ResourceResponse<Shipment>>(
        window.route('dm.company.shipments.show', {
            company: authStore.companyId,
            shipment: props.shipmentId ?? '',
        }),
    )
    shipment.value = response.data.data

    data.type = shipment.value.type
    data.sendingCompanyId = shipment.value.sendingCompany.id
    data.sendingLocationId = shipment.value.sendingLocation.id
    data.receivingCompanyId = shipment.value.receivingCompany?.id
    data.receivingLocationId = shipment.value.receivingLocation?.id
    data.shipperCompanyId = shipment.value.shipperCompany?.id ?? null
    data.shipperLocationId = shipment.value.shipperLocation?.id ?? null
    data.haulierCompanyId = shipment.value.haulierCompany?.id ?? null
    data.haulierLocationId = shipment.value.haulierLocation?.id ?? null
    data.deliveredByUserId = shipment.value.deliveredByUser?.id ?? null
    data.deliveryNote = shipment.value.deliveryNote
    data.categoryId = shipment.value.category?.id ?? null
    data.palletExchange = shipment.value.palletExchange
    data.plannedAt = shipment.value.plannedAt
    data.handledAt = shipment.value.handledAt
    data.shipmentNumber = shipment.value.shipmentNumber ?? null
    data.name = shipment.value.name ?? null
    data.references = shipment.value.references ?? null
    data.soldTo = shipment.value.soldTo ?? null
    data.shipTo = shipment.value.shipTo ?? null
    data.handleableAfter = shipment.value.handleableAfter ?? null
    data.handleableBefore = shipment.value.handleableBefore ?? null

    if (shipment.value.collis) convertCollis(shipment.value.collis)
    if (data.receivingCompanyId) {
        receiverLocations.value = await fetchLocations(data.receivingCompanyId)
    }
    if (data.shipperCompanyId) {
        shipperLocations.value = await fetchLocations(data.shipperCompanyId)
    }
    if (data.haulierCompanyId) {
        haulierLocations.value = await fetchLocations(data.haulierCompanyId)
    }

    initializing.value = false
}

async function fetchShippers() {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.customers', {
            company: authStore.companyId,
        }),
    )

    shippers.value = response.data.map((shipper) => ({
        value: shipper.id,
        label: shipper.name,
    }))
}

async function fetchReceivers() {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.customers', {
            company: authStore.companyId,
        }),
    )

    receivers.value = response.data.map((receiver) => ({
        value: receiver.id,
        label: receiver.name,
    }))

    receivers.value.unshift({ value: authStore.companyId, label: authStore.company.name })
}

async function fetchLocations(id: uuid | null): Promise<DropdownStringOption[]> {
    if (!id) return []
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.locations', {
            company: id,
        }),
    )

    return response.data.map((location) => ({ value: location.id, label: location.name }))
}

async function fetchHauliers() {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.customers', {
            company: authStore.companyId,
            haulier: true,
        }),
    )

    hauliers.value = response.data.map((haulier) => ({
        value: haulier.id,
        label: haulier.name,
    }))
}

async function fetchCategories() {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.categories', { company: authStore.companyId }),
    )

    categories.value = response.data.map((category) => ({
        value: category.id,
        label: category.name,
    }))
}

async function onSubmit() {
    const method: Method = props.shipmentId ? 'PUT' : 'POST'
    const createRoute = window.route('dm.company.shipments.store', {
        company: authStore.companyId,
    })
    const updateRoute = window.route('dm.company.shipments.update', {
        company: authStore.companyId,
        shipment: props.shipmentId ?? '',
    })
    const response = await submit<ResourceResponse<Shipment>>(
        method,
        props.shipmentId ? updateRoute : createRoute,
    )

    if (response !== undefined) {
        emitter.emit('fetchShipments')
        emit('close')
        emit('saved')
    }
}

async function receiverCompanyChanged() {
    if (initializing.value) return
    data.receivingLocationId = null
    if (data.receivingCompanyId === data.sendingCompanyId) {
        receiverLocations.value = companyLocations.value.filter(
            (location) => location.value != data.sendingLocationId,
        )
    } else if (data.receivingCompanyId) {
        receiverLocations.value = await fetchLocations(data.receivingCompanyId)
        data.receivingLocationId = receiverLocations.value[0]?.value || null
    } else {
        receiverLocations.value = []
    }
}

function senderLocationChanged() {
    if (data.receivingLocationId === data.sendingLocationId && data.receivingCompanyId) {
        data.receivingLocationId = null
    }
    if (data.receivingCompanyId === data.sendingCompanyId) {
        receiverLocations.value = companyLocations.value.filter(
            (location) => location.value != data.sendingLocationId,
        )
    }
}

async function shipperCompanyChanged() {
    if (initializing.value) return
    data.shipperLocationId = null
    if (data.shipperCompanyId) {
        shipperLocations.value = await fetchLocations(data.shipperCompanyId)
        data.shipperLocationId = shipperLocations.value[0]?.value || null
    } else {
        shipperLocations.value = []
    }
}

async function haulierCompanyChanged() {
    if (initializing.value) return
    data.haulierLocationId = null
    if (data.haulierCompanyId) {
        haulierLocations.value = await fetchLocations(data.haulierCompanyId)
        data.haulierLocationId = haulierLocations.value[0]?.value || null
    } else {
        haulierLocations.value = []
    }
}

watch(
    () => props.modelValue,
    () => {
        if (!props.modelValue) return

        if (props.shipmentId) {
            fetchShipment()
        }

        if (receivers.value.length === 0) {
            fetchReceivers()
        }

        if (shippers.value.length === 0) {
            fetchShippers()
        }

        if (hauliers.value.length === 0) {
            fetchHauliers()
        }

        if (categories.value.length === 0) {
            fetchCategories()
        }

        reset()
    },
)
</script>

<template>
    <MyModal :value="props.modelValue" :max-width="850" @close="emit('close')">
        <LoaderWrapper :visible="loading" class="rounded-xl" />
        <template #title>
            {{ title }}
        </template>
        <MyForm :errors="errors" @submit.prevent="onSubmit">
            <div class="flex">
                <div class="flex w-full flex-col space-x-3 pr-3">
                    <div class="flex items-end space-x-3">
                        <div class="flex w-1/2 flex-col space-y-3">
                            <div>
                                <MyInputLabel v-text="t('sender')" />
                                <h4
                                    class="mt-0 flex h-10 cursor-default items-center rounded-xl border border-primary-200 pl-2 text-sm font-semibold text-gray-600 dark:border-dark-400 dark:text-primary-200"
                                    v-text="
                                        !companyIsShipper
                                            ? authStore.company.name
                                            : shipment?.sendingCompany.name
                                    "
                                />
                            </div>
                            <MySelect
                                v-if="!companyIsShipper"
                                v-model="data.receivingCompanyId"
                                :options="receivers"
                                :label="t('receiver')"
                                searchable
                                @change="receiverCompanyChanged"
                            />
                            <div v-else>
                                <MyInputLabel v-text="t('receiver')" />
                                <h4
                                    class="mt-0 flex h-10 cursor-default items-center rounded-xl border border-primary-200 pl-2 text-sm font-semibold text-gray-600 dark:border-dark-400 dark:text-primary-200"
                                    v-text="shipment?.receivingCompany.name"
                                />
                            </div>
                        </div>
                        <div class="flex w-1/2 flex-col space-y-3">
                            <MySelect
                                v-if="!companyIsShipper"
                                v-model="data.sendingLocationId"
                                :options="companyLocations"
                                :label="t('sendingLocation')"
                                name="sendingLocationId"
                                searchable
                                @change="senderLocationChanged"
                            />
                            <div v-else>
                                <MyInputLabel v-text="t('sendingLocation')" />
                                <h4
                                    class="mt-0 flex h-10 cursor-default items-center rounded-xl border border-primary-200 pl-2 text-sm font-semibold text-gray-600 dark:border-dark-400 dark:text-primary-200"
                                    v-text="shipment?.sendingLocation.name"
                                />
                            </div>

                            <MySelect
                                v-if="!companyIsShipper"
                                v-model="data.receivingLocationId"
                                :options="receiverLocations"
                                :label="t('receivingLocation')"
                                name="receivingLocationId"
                                searchable
                            />

                            <div v-else>
                                <MyInputLabel v-text="t('receivingLocation')" />
                                <h4
                                    class="mt-0 flex h-10 cursor-default items-center rounded-xl border border-primary-200 pl-2 text-sm font-semibold text-gray-600 dark:border-dark-400 dark:text-primary-200"
                                    v-text="shipment?.receivingLocation.name"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="!m-0 flex w-full space-x-3">
                        <div class="mt-3 flex w-1/2 flex-col">
                            <MySelect
                                v-if="!companyIsShipper"
                                v-model="data.shipperCompanyId"
                                name="shipper"
                                group-class="w-full"
                                :label="t('shipper')"
                                searchable
                                clear-button
                                :options="shippers"
                                @change="shipperCompanyChanged"
                            />
                            <div v-else>
                                <MyInputLabel v-text="t('shipper')" />
                                <h4
                                    class="mt-0 flex h-10 cursor-default items-center rounded-xl border border-primary-200 pl-2 text-sm font-semibold text-gray-600 dark:border-dark-400 dark:text-primary-200"
                                    v-text="shipment?.shipperCompany?.name"
                                />
                            </div>
                        </div>
                        <div class="mt-3 flex w-1/2 flex-col">
                            <MySelect
                                v-if="!companyIsShipper"
                                v-model="data.shipperLocationId"
                                :options="shipperLocations"
                                :label="t('shipperLocation')"
                                searchable
                            />
                            <div v-else>
                                <MyInputLabel v-text="t('shipperLocation')" />
                                <h4
                                    class="mt-0 flex h-10 cursor-default items-center rounded-xl border border-primary-200 pl-2 text-sm font-semibold text-gray-600 dark:border-dark-400 dark:text-primary-200"
                                    v-text="shipment?.shipperLocation?.name"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="!m-0 flex w-full space-x-3">
                        <div class="mt-3 flex w-1/2 flex-col">
                            <MySelect
                                v-if="!companyIsShipper"
                                v-model="data.haulierCompanyId"
                                name="shipper"
                                group-class="w-full"
                                :label="t('haulier')"
                                searchable
                                clear-button
                                :options="hauliers"
                                @change="haulierCompanyChanged"
                            />
                            <div v-else>
                                <MyInputLabel v-text="t('haulier')" />
                                <h4
                                    class="mt-0 flex h-10 cursor-default items-center rounded-xl border border-primary-200 pl-2 text-sm font-semibold text-gray-600 dark:border-dark-400 dark:text-primary-200"
                                    v-text="shipment?.haulierCompany?.name"
                                />
                            </div>
                        </div>
                        <div class="mt-3 flex w-1/2 flex-col">
                            <MySelect
                                v-if="!companyIsShipper"
                                v-model="data.haulierLocationId"
                                :options="haulierLocations"
                                :label="t('haulierLocation')"
                                searchable
                            />
                            <div v-else>
                                <MyInputLabel v-text="t('haulierLocation')" />
                                <h4
                                    class="mt-0 flex h-10 cursor-default items-center rounded-xl border border-primary-200 pl-2 text-sm font-semibold text-gray-600 dark:border-dark-400 dark:text-primary-200"
                                    v-text="shipment?.haulierLocation?.name"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="!m-0 flex w-full space-x-3 space-y-3 mb">
                        <div class="flex w-1/2 pt-3">
                            <MyRadioButtonGroup
                                v-model="data.type"
                                class="w-full"
                                name="type"
                                :label="t('type')"
                            >
                                <MyRadioButton
                                    :label="t('delivery')"
                                    :value="ShipmentType.Delivery"
                                />
                                <MyRadioButton :label="t('return')" :value="ShipmentType.Return" />
                                <MyRadioButton :label="t('pickup')" :value="ShipmentType.Pickup" />
                            </MyRadioButtonGroup>
                        </div>

                        <div class="mt-3 w-1/2">
                            <MyInputLabel v-text="t('expectedDeliveryDate')" />
                            <Datepicker
                                v-model="data.plannedAt"
                                class="input-field w-full rounded-xl border border-gray-300"
                                name="plannedAt"
                                close-on-scroll
                                :enable-time-picker="false"
                                auto-apply
                                clearable
                                auto-position
                                position="left"
                                format="yyyy-MM-dd"
                                :transitions="false"
                                :placeholder="t('selectDate')"
                            />
                            <MyErrorMessage input-name="plannedAt" :label="t('date')" />
                        </div>

                        <div class="w-1/2">
                            <MySelect
                                v-if="!companyIsShipper"
                                v-model="data.categoryId"
                                name="categoryId"
                                group-class="w-full"
                                :label="t('category')"
                                searchable
                                clear-button
                                :options="categories"
                            />

                            <div v-else>
                                <MyInputLabel v-text="t('category')" />
                                <h4
                                    class="mt-0 flex h-10 cursor-default items-center rounded-xl border border-primary-200 pl-2 text-sm font-semibold text-gray-600 dark:border-dark-400 dark:text-primary-200"
                                    v-text="shipment?.category?.name"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="!mx-0 mt-2 flex flex-col mb-2">
                        <div
                            class="mt-2 ml-0.5 flex cursor-pointer justify-between"
                            @click="informationToggle = !informationToggle"
                        >
                            <h5
                                class="mb-2 select-none text-base"
                                v-text="t('additionalInformation')"
                            />

                            <mdi:chevron-down
                                class="ml-1 transition duration-300"
                                :class="{ 'rotate-180': informationToggle }"
                            />
                        </div>

                        <hr class="mb-2" />

                        <div v-if="informationToggle" class="space-y-2">
                            <div class="flex space-x-3">
                                <MyInput
                                    v-model="data.shipmentNumber"
                                    name="shipmentNumber"
                                    type="text"
                                    :label="t('shipmentNumber')"
                                />
                                <MyInput
                                    v-model="data.references"
                                    name="references"
                                    type="text"
                                    :label="t('references')"
                                />
                            </div>

                            <div class="flex space-x-3">
                                <MyInput
                                    v-model="data.name"
                                    name="name"
                                    type="text"
                                    :label="t('name')"
                                />
                                <MyInput
                                    v-model="data.soldTo"
                                    name="soldTo"
                                    type="text"
                                    :label="t('soldTo')"
                                />
                                <MyInput
                                    v-model="data.shipTo"
                                    name="shipTo"
                                    type="text"
                                    :label="t('shipTo')"
                                />
                            </div>

                            <div class="flex space-x-3 justify-evenly">
                                <div class="flex w-1/2 flex-col">
                                    <MyInputLabel
                                        v-text="
                                            data.type === ShipmentType.Delivery
                                                ? t('deliverAfter')
                                                : t('pickupAfter')
                                        "
                                    />

                                    <Datepicker
                                        v-model="data.handleableAfter"
                                        class="input-field w-full rounded-xl border border-gray-300"
                                        name="handleableAfter"
                                        close-on-scroll
                                        enable-time-picker
                                        auto-apply
                                        clearable
                                        auto-position
                                        position="left"
                                        format="yyyy-MM-dd HH:mm"
                                        :transitions="false"
                                        :placeholder="t('selectDate')"
                                        @open="handleableAfterOpened"
                                    />
                                </div>

                                <div class="flex w-1/2 flex-col">
                                    <MyInputLabel
                                        v-text="
                                            data.type === ShipmentType.Delivery
                                                ? t('deliverBefore')
                                                : t('pickupBefore')
                                        "
                                    />

                                    <Datepicker
                                        v-model="data.handleableBefore"
                                        class="input-field w-full rounded-xl border border-gray-300"
                                        name="handleableBefore"
                                        close-on-scroll
                                        enable-time-picker
                                        auto-apply
                                        clearable
                                        auto-position
                                        position="left"
                                        format="yyyy-MM-dd HH:mm"
                                        :transitions="false"
                                        :placeholder="t('selectDate')"
                                        @open="handleableBeforeOpened"
                                    />
                                </div>
                            </div>
                            <MyErrorMessage
                                input-name="handleableBefore"
                                :label="
                                    data.type === ShipmentType.Delivery
                                        ? t('deliverBefore')
                                        : t('pickupBefore')
                                "
                                class="!mt-3 !p-0"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <MyCheckbox
                v-model="data.palletExchange"
                name="palletExchange"
                :label="t('palletExchange')"
            />

            <div class="mt-2 flex justify-end">
                <MyButton
                    class="h-10 w-3/12"
                    plain
                    type="button"
                    scheme="primary"
                    @click="colliPickerModalOpen = true"
                >
                    <mdi:plus-thick class="mr-1" />
                    {{ t('addCollis') }}
                </MyButton>
            </div>

            <ColliPickerModal
                v-model="colliPickerModalOpen"
                :existing-collis="data.collis"
                @close="colliPickerModalOpen = false"
                @save-collis="data.collis = $event"
            />

            <div v-if="data.collis && data.collis.length > 0" class="mt-4 space-y-3">
                <div
                    v-for="colli in data.collis"
                    :key="colli.colli.id"
                    class="flex w-full items-center rounded-xl bg-white shadow-sm dark:bg-dark-300"
                >
                    <div class="flex w-4/12 flex-col p-4">
                        <span class="text-md">{{ colli.colli.name }}</span>
                        <span class="text-xs text-gray-500 dark:text-primary-100">{{
                            colli.colli.colliNumber
                        }}</span>
                    </div>

                    <div class="flex w-1/6 flex-col text-center">
                        <span class="text-xs text-primary-300">
                            {{ t('l') }}
                        </span>
                        <span> {{ colli.colli.length }} {{ t('cm') }}</span>
                    </div>

                    <div class="flex w-1/6 flex-col text-center">
                        <span class="text-xs text-primary-300">
                            {{ t('w') }}
                        </span>
                        <span> {{ colli.colli.width }} {{ t('cm') }}</span>
                    </div>

                    <div class="flex w-1/6 flex-col text-center">
                        <span class="text-xs text-primary-300">
                            {{ t('h') }}
                        </span>
                        <span> {{ colli.colli.height }} {{ t('cm') }}</span>
                    </div>

                    <div class="flex w-3/12 flex-row justify-evenly space-x-4">
                        <div class="flex flex-col text-center">
                            <span class="text-xs text-primary-300">
                                {{ t('volume') }}
                            </span>
                            <span> {{ colli.colli.volume }} {{ t('cbm') }}</span>
                        </div>
                        <div class="flex flex-col text-center">
                            <span class="text-xs text-primary-300">
                                {{ t('kg') }}
                            </span>
                            <span> {{ colli.colli.weight }} {{ t('kg') }}</span>
                        </div>
                    </div>

                    <div class="ml-4 flex w-1/12 flex-col justify-center">
                        <MyInput
                            v-model="colli.amount"
                            class="h-9"
                            name="amount"
                            type="number"
                            min="1"
                            required
                        />
                        <MyInputLabel class="pl-0.5" v-text="t('amount')" />
                    </div>
                    <div class="flex w-1/12 justify-center">
                        <mdi:trash-can
                            class="h-5 w-5 cursor-pointer text-red-400"
                            @click="removeColli(colli.colli)"
                        />
                    </div>
                </div>
            </div>

            <div
                v-else
                class="mt-2 flex w-full items-center justify-center rounded-xl bg-primary-50 p-5 dark:bg-dark-400"
            >
                <span class="dark:text-primary-200">{{ t('noCollisForShipment') }}</span>
            </div>

            <div class="mt-4 flex justify-end gap-6">
                <MyButton
                    :disabled="loading"
                    scheme="primary"
                    v-text="props.shipmentId ? t('update') : t('create')"
                />
            </div>
        </MyForm>
    </MyModal>
</template>
