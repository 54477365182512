<script lang="ts" setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { StockViewMode } from '@/types/stock'

import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import StockDetails from '@/components/packaging/stock/StockDetails.vue'
import StockOverview from '@/components/packaging/stock/StockOverview.vue'
import TransactionsTable from '@/components/packaging/transactions/TransactionsTable.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'

const { t } = useI18n()

const viewMode = ref<StockViewMode>(StockViewMode.Details)
const showTransactionsTable = ref(false)
const selectedLocationId = ref<string | null>(null)

function toggleTransactionsTable() {
    showTransactionsTable.value = true
}

function changeView(mode: StockViewMode) {
    viewMode.value = mode
    selectedLocationId.value = null
}
</script>

<template>
    <CrumbsAndActions>
        <Breadcrumb :to="{ name: 'packaging' }" v-text="t('packaging')" />
        <Breadcrumb current v-text="t('stock')" />

        <template #actions>
            <MyButton
                scheme="action"
                :active="viewMode === StockViewMode.Details"
                size="small"
                @click="changeView(StockViewMode.Details)"
            >
                <mdi:warehouse class="mr-1" />
                {{ t('details') }}
            </MyButton>
            <MyButton
                scheme="action"
                size="small"
                :active="viewMode === StockViewMode.Overview"
                @click="changeView(StockViewMode.Overview)"
            >
                <mdi:view-list class="mr-1" />
                {{ t('overview') }}
            </MyButton>
        </template>
    </CrumbsAndActions>

    <StockDetails
        v-if="viewMode === StockViewMode.Details"
        @location-changed="selectedLocationId = $event"
    />
    <StockOverview v-if="viewMode === StockViewMode.Overview" />

    <div class="mt-6">
        <MyButton v-if="!showTransactionsTable" @click="toggleTransactionsTable">
            <mdi:magnify class="mr-2" />
            {{ t('showStockAdjustments') }}
        </MyButton>
        <h1 v-else class="text-xl font-bold mb-2" v-text="t('stockAdjustments')" />
        <MyPanel shadow>
            <TransactionsTable
                v-if="showTransactionsTable"
                only-stock-adjustments
                inline-view-modal
                :location-id="selectedLocationId || undefined"
                :table-id="'stockTransaction'"
            />
        </MyPanel>
    </div>
</template>
