<script lang="ts" setup>
import type { MyInputRef } from '@/types/inputs'

import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@/hooks/use-form'
import { localAsset } from '@/utils/assets'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'

const { t } = useI18n()
const username = ref<MyInputRef>()
const success = ref(false)
const { data, errors, loading, submit } = useForm({ key: 'username', value: '' })

async function onSubmit() {
    success.value = false
    const response = await submit('post', window.route('password.reset-link'))

    if (response) {
        success.value = true
    }
}

onMounted(() => username.value?.input.focus())
</script>

<template>
    <MyPanel
        class="flex w-full flex-col overflow-hidden"
        padded
        panel-class="max-w-sm shadow-xl shadow-primary-500 dark:shadow-secondary-900"
    >
        <img
            :src="localAsset('assets/mypallet.png')"
            alt="myPallet"
            class="mx-auto mb-5 dark:hidden"
            style="width: 300px; height: 90px"
        />
        <img
            :src="localAsset('assets/mypallet-white.png')"
            alt="myPallet"
            class="mx-auto mb-5 hidden dark:block"
            style="width: 300px; height: 90px"
        />

        <MyForm :errors="errors" @submit.prevent="onSubmit">
            <h3 class="font-lg text-center" v-text="t('forgotPassword')" />

            <MyInput
                ref="username"
                v-model="data.value"
                :label="t('username')"
                :placeholder="t('username')"
                autocomplete="username"
                name="username"
                required
            />

            <div class="mt-5 block text-right">
                <MyButton
                    class="w-full"
                    scheme="primary"
                    type="submit"
                    v-text="t('emailResetLink')"
                />

                <RouterLink
                    class="mt-1 inline-block text-xs font-medium underline hover:text-blue-500"
                    to="/login"
                    v-text="t('goBackToLogin')"
                />
            </div>

            <div
                v-if="success"
                class="mt-2 text-center text-sm text-green-400"
                v-text="t('resetLinkSent')"
            />

            <LoaderWrapper :visible="loading" class="rounded-xl" />
        </MyForm>
    </MyPanel>
</template>
