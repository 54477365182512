<script lang="ts">
export interface Props {
    id?: uuid
    open?: boolean
}
</script>

<script setup lang="ts">
import { computed, inject, onBeforeMount, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'

import { ExtendedTransportObject, TransportObjectType } from '@/types/transport-object'
import { ResourceResponse, uuid } from '@/types/general'
import { useAuthStore } from '@/stores/auth-store'
import { transportObjectTypes } from '@/utils/type-translations'
import { localeDate } from '@/utils/dates'
import { useMittListener } from '@/hooks/use-mitt-listener'
import { LicenseType } from '@/types/company'
import { imageViewerOpenKey } from '@/types/global-injection-keys'

import IncidentView from '@/components/damage-reports/IncidentView.vue'
import ContentHeading from '@/components/layout/ContentHeading.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyModal from '@/components/my-components/MyModal.vue'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()

const imageViewerOpen = inject(imageViewerOpenKey)!

const loading = ref(false)
const transportObject = ref<ExtendedTransportObject>()
const informationToggle = ref(false)

const props = withDefaults(defineProps<Props>(), { open: true })
const emit = defineEmits<{ (e: 'update:open', value: boolean): void }>()

const largeLayout = computed(() => {
    return transportObject.value?.template !== null
})

async function fetchTransportObject() {
    const transportObjectId = (route.params.transportObjectId as uuid | undefined) || props.id
    if (!transportObjectId) return

    loading.value = true

    try {
        const response = await axios.get<ResourceResponse<ExtendedTransportObject>>(
            window.route('company.transport-objects.show', {
                company: authStore.companyId,
                transport_object: transportObjectId,
            }),
        )
        transportObject.value = response.data.data
    } finally {
        loading.value = false
    }
}

function formatDate(date: string | undefined) {
    if (!date) return t('notSpecified')
    return localeDate(date)
}

async function onClose() {
    if (imageViewerOpen.value) return

    if (route.fullPath.includes('damage-reports')) {
        router.replace({ name: 'dmr.transport-objects', query: route.query })
    } else if (props.id) {
        emit('update:open', false)
    } else {
        router.replace({ name: 'transport-objects', query: route.query })
    }
}

// We listen for this in case an alarm is fixed
useMittListener('fetchAlarms', fetchTransportObject)
useMittListener('incidentFixed', fetchTransportObject)
onBeforeMount(fetchTransportObject)
watch(() => props.id, fetchTransportObject)
watch(() => route.params.transportObjectId, fetchTransportObject)
</script>

<template>
    <MyModal :value="props.open" :max-width="largeLayout ? 1200 : 650" @close="onClose()">
        <LoaderWrapper :visible="loading" />

        <div class="flex space-x-6">
            <div class="flex flex-col" :class="largeLayout ? 'w-1/2' : 'w-full'">
                <div class="flex">
                    <h3 class="text-lg font-semibold uppercase text-primary-400">
                        {{
                            transportObject?.type === TransportObjectType.Vehicle
                                ? t('vehicle')
                                : t('unit')
                        }}
                    </h3>

                    <div
                        v-if="!transportObject?.active"
                        v-tooltip="t('disabledTransportObjectExplanation')"
                        class="ml-2 self-center rounded-lg bg-red-600 py-0.5 px-2 text-xs font-semibold uppercase text-white"
                        v-text="t('inactive')"
                    />
                </div>

                <div class="mb-6 flex justify-between">
                    <ContentHeading>
                        <div class="mt-1 flex space-x-2 text-xs">
                            <h4 class="font-semibold text-primary-500 dark:text-primary-300">
                                {{ t('templateName') }}:
                            </h4>
                            <h4 class="font-medium dark:text-primary-400">
                                {{ transportObject?.template?.name ?? t('notSpecified') }}
                            </h4>
                        </div>
                    </ContentHeading>
                </div>

                <div class="h-fit w-full space-y-6 rounded-lg bg-white px-8 py-6 dark:bg-dark-500">
                    <div class="flex justify-between">
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('type')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="t(transportObjectTypes[transportObject?.type ?? 0])"
                            />
                        </div>
                        <div class="flex w-3/5 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('nickname')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="transportObject?.nickname ?? t('notSpecified')"
                            />
                        </div>
                        <div class="flex w-1/3 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('registrationNumberShort')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="transportObject?.registrationNumber ?? t('notSpecified')"
                            />
                        </div>
                    </div>

                    <div class="flex justify-between">
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('serviceDate')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="formatDate(transportObject?.service)"
                            />
                        </div>
                        <div
                            v-if="authStore.hasLicense(LicenseType.TransportObjectOwnerCompany)"
                            class="flex w-3/5 flex-col"
                        >
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('owner')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="transportObject?.ownerCompany?.name ?? t('notSpecified')"
                            />
                        </div>
                        <div v-else class="flex w-3/5 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('ownerName')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="transportObject?.ownerName ?? t('notSpecified')"
                            />
                        </div>
                        <div class="flex w-1/3 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-400 dark:text-primary-300"
                                v-text="t('axles')"
                            />
                            <span
                                class="text-md font-semibold text-primary-500 dark:text-primary-100"
                                v-text="
                                    transportObject?.template?.masterTemplate.axles ??
                                    t('notSpecified')
                                "
                            />
                        </div>
                    </div>

                    <div class="flex justify-between">
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                v-text="t('registrationDate')"
                            />
                            <span
                                class="text-xs font-normal text-primary-400 dark:text-primary-100"
                                v-text="formatDate(transportObject?.registrationDate)"
                            />
                        </div>
                        <div class="flex w-3/5 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                v-text="t('vin')"
                            />
                            <span
                                class="text-xs font-normal text-primary-400 dark:text-primary-100"
                                v-text="transportObject?.vin ?? t('notSpecified')"
                            />
                        </div>
                        <div class="flex w-1/3 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                v-text="t('department')"
                            />
                            <span
                                class="text-xs font-normal text-primary-400 dark:text-primary-100"
                                v-text="transportObject?.department ?? t('notSpecified')"
                            />
                        </div>
                    </div>

                    <div class="flex justify-between">
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                v-text="t('manufacturer')"
                            />
                            <span
                                class="text-xs font-normal text-primary-400 dark:text-primary-100"
                                v-text="transportObject?.manufacturer ?? t('notSpecified')"
                            />
                        </div>
                        <div class="flex w-3/5 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                v-text="t('model')"
                            />
                            <span
                                class="text-xs font-normal text-primary-400 dark:text-primary-100"
                                v-text="transportObject?.model ?? t('notSpecified')"
                            />
                        </div>
                        <div class="flex w-1/3 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                v-text="t('weight')"
                            />
                            <span
                                class="text-xs font-normal text-primary-400 dark:text-primary-100"
                                v-text="
                                    transportObject?.weight
                                        ? transportObject?.weight + ' kg'
                                        : t('notSpecified')
                                "
                            />
                        </div>
                    </div>

                    <div class="flex justify-between">
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                v-text="t('freightWeight')"
                            />
                            <span
                                class="text-xs font-normal text-primary-400 dark:text-primary-100"
                                v-text="transportObject?.freightWeight + ' kg' ?? t('notSpecified')"
                            />
                        </div>
                        <div class="flex w-3/5 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                v-text="t('email')"
                            />
                            <span
                                class="text-xs font-normal text-primary-400 dark:text-primary-100"
                                v-text="transportObject?.email ?? t('notSpecified')"
                            />
                        </div>
                        <div class="flex w-1/3 flex-col">
                            <span
                                class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                v-text="t('phone')"
                            />
                            <span
                                class="text-xs font-normal text-primary-400 dark:text-primary-100"
                                v-text="transportObject?.phoneNumber ?? t('notSpecified')"
                            />
                        </div>
                    </div>

                    <div v-if="transportObject?.note" class="flex flex-col">
                        <span
                            class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                            v-text="t('note')"
                        />
                        <span
                            class="text-xs text-primary-400 dark:text-primary-100"
                            v-text="transportObject?.note"
                        />
                    </div>

                    <div class="flex flex-col">
                        <div
                            class="mt-2 flex cursor-pointer justify-between"
                            @click="informationToggle = !informationToggle"
                        >
                            <h5
                                class="mb-2 select-none text-sm text-primary-500 dark:text-primary-100"
                                v-text="t('additionalInformation')"
                            />

                            <mdi:chevron-down
                                class="ml-1 transition duration-300"
                                :class="{ 'rotate-180': informationToggle }"
                            />
                        </div>

                        <hr />
                    </div>

                    <div v-if="informationToggle" class="space-y-6">
                        <div class="flex justify-between">
                            <div class="flex w-1/2 flex-col">
                                <span
                                    class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                    v-text="t('height')"
                                />
                                <span
                                    class="text-xs font-normal text-primary-400 dark:text-primary-100"
                                    v-text="transportObject?.height ?? t('notSpecified')"
                                />
                            </div>
                            <div class="flex w-1/2 flex-col">
                                <span
                                    class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                    v-text="t('length')"
                                />
                                <span
                                    class="text-xs font-normal text-primary-400 dark:text-primary-100"
                                    v-text="transportObject?.length ?? t('notSpecified')"
                                />
                            </div>
                            <div class="flex w-1/3 flex-col">
                                <span
                                    class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                    v-text="t('width')"
                                />
                                <span
                                    class="text-xs font-normal text-primary-400 dark:text-primary-100"
                                    v-text="transportObject?.width ?? t('notSpecified')"
                                />
                            </div>
                        </div>

                        <div class="flex justify-between">
                            <div class="flex w-1/2 flex-col">
                                <span
                                    class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                    v-text="t('maxEur')"
                                />
                                <span
                                    class="text-xs font-normal text-primary-400 dark:text-primary-100"
                                    v-text="transportObject?.maxEur ?? t('notSpecified')"
                                />
                            </div>
                            <div class="flex w-1/2 flex-col">
                                <span
                                    class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                    v-text="t('maxLdr')"
                                />
                                <span
                                    class="text-xs font-normal text-primary-400 dark:text-primary-100"
                                    v-text="transportObject?.maxLdr ?? t('notSpecified')"
                                />
                            </div>
                            <div class="flex w-1/3 flex-col">
                                <span
                                    class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                    v-text="t('maxM3')"
                                />
                                <span
                                    class="text-xs font-normal text-primary-400 dark:text-primary-100"
                                    v-text="transportObject?.maxM3 ?? t('notSpecified')"
                                />
                            </div>
                        </div>

                        <div class="flex justify-evenly">
                            <div class="flex flex-col">
                                <span
                                    class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                    v-text="t('mot')"
                                />
                                <span
                                    class="text-xs font-normal text-primary-400 dark:text-primary-100"
                                    v-text="formatDate(transportObject?.mot)"
                                />
                            </div>
                            <div class="flex flex-col">
                                <span
                                    class="text-xs font-semibold text-primary-500 dark:text-primary-300"
                                    v-text="t('tir')"
                                />
                                <span
                                    class="text-xs font-normal text-primary-400 dark:text-primary-100"
                                    v-text="formatDate(transportObject?.tir)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="transportObject && transportObject.template" class="w-1/2">
                <IncidentView
                    :transport-object="transportObject"
                    :template="transportObject.template"
                    :alarms="transportObject.incidentAlarms"
                />
            </div>
        </div>
    </MyModal>
</template>
