import { InjectionKey, Ref } from 'vue'

export type HTMLClass = string | string[] | Record<string, boolean>

export type DropdownOptionValue = string | number | null | (string | number)[]

export interface DropdownOption {
    value: string | number | null
    label: string

    [other: string]: unknown
}

export interface DropdownStringOption {
    value: string | null
    label: string

    [other: string]: unknown
}

/**
 * Defines exposed properties for MyInput.vue
 * Useful when using a ref on MyInput components
 */
export interface MyInputRef {
    input: HTMLInputElement
}

export interface MyPhoneInputRef {
    focus: () => void
}

export interface MyFormRef {
    form: HTMLFormElement
    focus: () => void
}

export interface MyVatNumberInputRef {
    validate: () => boolean
    focus: () => void
}

export interface MyRadioButtonGroupRef {
    focus: () => void
}

export interface RadioGroupProps {
    id: string
    name?: string
    modelValue: string | number | boolean | null
    disabled: boolean
}

export const radioGroupPropsKey: InjectionKey<Ref<RadioGroupProps>> = Symbol('radio-group-props')
export const radioGroupValueChangedKey: InjectionKey<(value: string | number | boolean) => void> =
    Symbol('radio-group-value-changed')

export interface InputVariable {
    value: string
    description: string
}
