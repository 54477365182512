<script lang="ts" setup>
import { computed, useSlots } from 'vue'

import { HTMLClass } from '@/types/inputs'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyMenu from '@/components/my-components/MyMenu.vue'

export interface Props {
    invisible?: boolean
    padded?: boolean
    divideHeader?: boolean
    shadow?: boolean
    bordered?: boolean
    title?: string
    background?: string
    panelClass?: HTMLClass
    loading?: boolean
    hideTabs?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    loading: false,
})
const slots = useSlots()

const hasHeader = computed(() => slots.header || props.title || slots.actions)
const onlyActions = computed(() => !slots.header && !props.title && slots.actions)
</script>

<script lang="ts">
export default { inheritAttrs: false }
</script>

<template>
    <article
        :class="[
            props.panelClass,
            props.invisible
                ? 'bg-transparent'
                : props.background ?? 'bg-primary-50 dark:bg-dark-500',
            {
                'shadow-xl shadow-primary-200/50 dark:shadow-black': props.shadow,
                border: props.bordered,
            },
        ]"
        class="relative flex max-w-full flex-col overflow-hidden rounded-xl border-primary-200 dark:border-dark-700"
    >
        <div v-if="$slots.tabs" class="overflow-hidden rounded-t-xl">
            <nav
                v-if="!hideTabs"
                class="flex justify-between border-b border-primary-200 bg-gradient-radial-to-br from-primary-500 via-primary-500 to-green-500 uppercase dark:border-dark-400 dark:from-secondary-900 dark:via-secondary-900 dark:to-primary-500"
            >
                <slot name="tabs" />
            </nav>
        </div>
        <header
            v-if="hasHeader"
            :class="[
                onlyActions ? 'justify-end' : 'justify-between',
                { 'px-6 pt-4': props.padded, 'border-b': props.divideHeader },
            ]"
            class="flex items-center border-primary-200 text-sm text-primary-400 dark:border-dark-400 dark:text-primary-300"
        >
            <h3
                v-if="props.title"
                :class="{ 'py-4 px-6': !props.padded }"
                class="relative font-bold uppercase tracking-wide"
                v-text="props.title"
            />

            <div v-if="slots.header" class="grow">
                <slot name="header" />
            </div>

            <MyMenu
                v-if="$slots.actions"
                :class="{ 'px-6 py-4': !props.padded }"
                hide-arrow
                class="relative z-20"
                width="w-60"
            >
                <template #trigger>
                    <mdi:dots-horizontal class="h-4 w-4" />
                </template>

                <slot name="actions" />
            </MyMenu>
        </header>

        <section
            :class="{
                'p-6': props.padded,
                'rounded-b-xl': !$slots.footer,
            }"
            class="grow"
            v-bind="$attrs"
        >
            <LoaderWrapper
                class="bg-opacity-100"
                background="bg-primary-50 dark:bg-dark-500"
                :visible="props.loading"
            />
            <slot />
        </section>

        <slot name="footer" />
    </article>
</template>
