<script lang="ts" setup>
import { HTMLAttributes } from 'vue'
import { useI18n } from 'vue-i18n'

import { image as imageHelper } from '@/utils/assets'
import { Template } from '@/types/damage-report'
import { transportObjectTypes } from '@/utils/type-translations'

export interface Props extends /** @vue-ignore */ HTMLAttributes {
    template: Template
}

const props = withDefaults(defineProps<Props>(), {})

const { t } = useI18n()
</script>

<template>
    <div
        class="h-auto w-64 rounded-lg bg-primary-50 shadow hover:cursor-pointer hover:opacity-70 hover:shadow-md dark:bg-dark-500"
    >
        <div class="relative p-4 pb-0">
            <img
                v-if="props.template.masterTemplate.primaryImage"
                :src="imageHelper(props.template.masterTemplate.primaryImage.path)"
                class="h-36 w-full max-w-sm rounded-t-lg object-contain"
            />
            <slot name="actions" />
        </div>

        <div class="p-3">
            <div class="flex h-1/5 justify-between text-sm">
                <span
                    class="text-md w-1/2 justify-start break-words font-semibold uppercase text-primary-400"
                    v-text="props.template.name"
                />
                <div class="ml-2" />
                <span
                    class="w-1/2 break-words text-right font-semibold text-primary-400"
                    v-text="props.template.masterTemplate.name"
                >
                </span>
            </div>
            <span
                class="text-xs font-semibold text-primary-400"
                v-text="t(transportObjectTypes[props.template.masterTemplate.type])"
            ></span>
        </div>
    </div>
</template>
