<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { useAuthStore } from '@/stores/auth-store'
import { LicenseType } from '@/types/company'

import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'

const { t } = useI18n()
const authStore = useAuthStore()
const route = useRoute()
const licenseType = computed(() => route.params.license as unknown as LicenseType)
const moduleLinks: Partial<Record<LicenseType, string>> = {
    [LicenseType.PackagingModule]: 'https://mypallet.io/features/packaging-management/',
    [LicenseType.DamageReportModule]: 'https://mypallet.io/features/fleet-damage-management/',
    [LicenseType.DeliveryManagementModule]: 'https://mypallet.io/da/features/track-trace/',
    [LicenseType.ECMRModule]: 'https://mypallet.io/da/features/ecmr/',
    [LicenseType.PODModule]: 'https://mypallet.io/features/proof-of-delivery/',
}
const moduleNames: Partial<Record<LicenseType, string>> = {
    [LicenseType.PackagingModule]: 'products.packaging',
    [LicenseType.DamageReportModule]: 'products.damageReports',
    [LicenseType.DeliveryManagementModule]: 'products.DeliveryManagementModule',
    [LicenseType.ECMRModule]: 'products.ecmr',
    [LicenseType.PODModule]: 'pod',
}
</script>

<template>
    <CrumbsAndActions v-if="authStore.isLoggedIn">
        <Breadcrumb current v-text="t('noModuleLicense')" />
    </CrumbsAndActions>
    <div class="flex min-h-[50vh] flex-col items-center justify-center space-y-3">
        <h1 class="text-3xl text-primary-400" v-text="t('noModuleLicense')" />
        <p
            class="text-sm text-primary-400"
            v-text="
                t('companyHasNoModuleLicense', {
                    company: authStore.company.name,
                    module: t(moduleNames[licenseType] ?? ''),
                })
            "
        />

        <p class="space-x-1 text-sm text-primary-400">
            <span v-text="t('visit')" />
            <a
                class="text-blue-500 hover:text-blue-800"
                :href="moduleLinks[licenseType] || 'https://mypallet.io'"
            >
                mypallet.io
            </a>
            <span v-text="t('forMoreInformation').toLowerCase()" />
        </p>
    </div>
</template>
