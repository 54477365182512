<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

import { datetime } from '@/utils/dates'
import { Dispatch } from '@/types/general'

export interface Props {
    dispatches: Dispatch[]
}

const props = defineProps<Props>()

const { t } = useI18n()
</script>

<template>
    <div v-if="props.dispatches.length > 0" class="print:hidden">
        <h3 class="text-md font-semibold" v-text="t('sharedWith')" />
        <div v-for="dispatch in props.dispatches" :key="dispatch.id">
            <div
                v-html="
                    t('statementDispatch', {
                        emails: `<b class='font-semibold'>${dispatch.emails.join(', ')}</b>`,
                        user: `<b class='font-semibold'>${dispatch.user?.name ?? t('system')}</b>`,
                        date: datetime(dispatch.createdAt),
                    })
                "
            />
        </div>
    </div>
</template>
