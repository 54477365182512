import { Composer } from 'vue-i18n'

import { DropdownOption } from '@/types/inputs'
import { UserType } from '@/types/user'

export function userTypeOptions(t: Composer['t']): DropdownOption[] {
    return [
        { value: UserType.Driver, label: t('driver') },
        { value: UserType.Warehouse, label: t('warehouse') },
    ]
}
