<script lang="ts" setup>
import axios from 'axios'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@/stores/auth-store'
import { useTransactionStore } from '@/stores/transaction-store'
import { MinimalResource, uuid } from '@/types/general'
import { DropdownStringOption } from '@/types/inputs'
import { ManageTransaction, TransactionType } from '@/types/transaction'
import { useCompanyStore } from '@/stores/company-store'
import { LicenseType } from '@/types/company'

import MyFormWizardStep from '@/components/my-components/form/MyFormWizardStep.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'
import MyInputLabel from '@/components/my-components/form/MyInputLabel.vue'

export interface Props {
    active: boolean
}

const props = defineProps<Props>()

const { t } = useI18n()
const authStore = useAuthStore()
const companyStore = useCompanyStore()
const transaction = useTransactionStore().$state.transaction! as ManageTransaction

const receiverLocations = ref<DropdownStringOption[]>([])
const haulierLocations = ref<DropdownStringOption[]>([])
const customers = ref<DropdownStringOption[]>([])
const haulierCompanies = ref<DropdownStringOption[]>([])
const errors = ref<Record<string, string>>({})

const haulierOptions = computed(() =>
    haulierCompanies.value.filter((haulier) => haulier.value !== transaction.receivingCompanyId),
)

const transitTradesEnabled = computed(() => {
    const packagingLicense = authStore.licenses[LicenseType.PackagingModule]

    return packagingLicense?.transitLocationTrades === true
})

const companyLocations = computed<DropdownStringOption[]>(() => {
    let locations = companyStore.locations
    const userLocations = authStore.company.locations
    if (authStore.hasLicense(LicenseType.AgentLocationFiltering) && userLocations.length > 0) {
        locations = locations.filter((location) => {
            if (location.id === transaction.sendingLocationId) return true

            return userLocations.includes(location.id)
        })
    }

    return locations.map((location) => ({ value: location.id, label: location.name }))
})

function toggleTransitTrade() {
    if (transaction.type === TransactionType.Transit) {
        transaction.type = TransactionType.Default
    } else {
        transaction.type = TransactionType.Transit
        transaction.shipperLocationId = null
    }
}

async function fetch() {
    fetchCustomers()
    if (transaction.receivingCompanyId) {
        receiverLocations.value = await fetchLocations(transaction.receivingCompanyId)
    }

    if (transaction.haulierCompanyId) {
        haulierLocations.value = await fetchLocations(transaction.haulierCompanyId)
    }

    if (!transaction.sendingLocationId) {
        transaction.sendingLocationId = companyLocations.value[0].value
    }
}

async function fetchLocations(id: uuid): Promise<DropdownStringOption[]> {
    const params = { forCompanyId: authStore.companyId }

    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.locations', {
            company: id,
            ...params,
        }),
    )

    return response.data.map((location) => ({ value: location.id, label: location.name }))
}

async function fetchCustomers() {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.customers', { company: authStore.companyId }),
    )

    customers.value = response.data.map((customer) => ({
        value: customer.id,
        label: customer.name,
    }))
    customers.value.unshift({ value: authStore.companyId, label: authStore.company.name })
}

async function fetchHauliers() {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.customers', {
            company: authStore.companyId,
            haulier: true,
        }),
    )

    haulierCompanies.value = response.data.map((haulier) => ({
        value: haulier.id,
        label: haulier.name,
    }))
}

function submit() {
    errors.value = {}
    if (!transaction.sendingLocationId) {
        errors.value['sendingLocationId'] = t('sendingLocationError')
    }

    if (!transaction.receivingLocationId) {
        errors.value['receivingLocationId'] = t('receivingLocationError')
    }

    if (transaction.type === TransactionType.Transit && !transaction.haulierLocationId) {
        errors.value['haulierLocationId'] = t('haulierLocationError')
    }

    return Object.keys(errors.value).length === 0
}

watch(
    () => transaction.receivingCompanyId,
    async () => {
        transaction.receivingLocationId = null
        if (transaction.receivingCompanyId === authStore.companyId) {
            receiverLocations.value = companyLocations.value.filter(
                (location) => location.value != transaction.sendingLocationId,
            )
        } else if (transaction.receivingCompanyId) {
            delete errors.value['receivingLocationId']
            receiverLocations.value = await fetchLocations(transaction.receivingCompanyId)
            transaction.receivingLocationId = receiverLocations.value[0]?.value || null
        } else {
            receiverLocations.value = []
        }
    },
)

watch(
    () => transaction.sendingLocationId,
    async () => {
        if (
            transaction.receivingLocationId === transaction.sendingLocationId &&
            transaction.receivingCompanyId
        ) {
            transaction.receivingLocationId = null
        }
        if (transaction.receivingCompanyId === authStore.companyId) {
            receiverLocations.value = companyLocations.value.filter(
                (location) => location.value != transaction.sendingLocationId,
            )
        }
    },
)

watch(
    () => transaction.haulierCompanyId,
    async () => {
        transaction.haulierLocationId = null

        if (transaction.haulierCompanyId) {
            delete errors.value['haulierLocationId']
            haulierLocations.value = await fetchLocations(transaction.haulierCompanyId)
            transaction.haulierLocationId = haulierLocations.value[0]?.value || null
        } else {
            haulierLocations.value = []
        }
    },
)

onMounted(fetch)

watch(
    () => transaction.type,
    async () => {
        if (transaction.type === TransactionType.Transit) {
            if (haulierCompanies.value.length === 0) {
                await fetchHauliers()
            }
            transaction.haulierCompanyId = haulierOptions.value[0]?.value || null
        } else {
            transaction.haulierCompanyId = null
            transaction.haulierLocationId = null
        }
    },
)
</script>

<template>
    <MyFormWizardStep id="company" :active="props.active" :submit="submit" :title="t('company')">
        <div v-if="transaction" class="grid grid-cols-2 justify-between gap-6">
            <div>
                <MyInputLabel v-text="t('sender')" />
                <h4
                    class="mt-0 flex h-10 cursor-default items-center rounded-xl border border-primary-200 pl-2 text-sm font-semibold text-gray-600 dark:border-dark-400 dark:text-primary-200"
                    v-text="authStore.company.name"
                />
            </div>

            <MySelect
                v-model="transaction.sendingLocationId"
                :options="companyLocations"
                :label="t('sendingLocation')"
                :error="errors['sendingLocationId'] ?? undefined"
                searchable
            />

            <MySelect
                v-model="transaction.receivingCompanyId"
                :options="customers"
                :label="t('receiver')"
                searchable
            />

            <MySelect
                v-model="transaction.receivingLocationId"
                :options="receiverLocations"
                :label="t('receivingLocation')"
                :error="errors['receivingLocationId'] ?? undefined"
                :disabled="transaction.receivingCompanyId === null"
                searchable
            />

            <div v-if="transitTradesEnabled" class="flex space-x-2 col-span-2">
                <MyCheckbox
                    :checked="transaction.type === TransactionType.Transit"
                    :label="t('transitTransaction')"
                    @change="toggleTransitTrade"
                />

                <mdi:information v-tooltip="t('transitTradesExplanation')" />
            </div>

            <MySelect
                v-if="transaction.type === TransactionType.Transit"
                v-model="transaction.haulierCompanyId"
                :options="haulierOptions"
                :label="t('haulier')"
                searchable
                clear-button
            />

            <MySelect
                v-if="transaction.type === TransactionType.Transit"
                v-model="transaction.haulierLocationId"
                :options="haulierLocations"
                :label="t('haulierLocation')"
                :error="errors['haulierLocationId'] ?? undefined"
                :disabled="transaction.haulierCompanyId === null"
                searchable
            />
        </div>
    </MyFormWizardStep>
</template>
