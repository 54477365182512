<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@/stores/auth-store'

import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'

const { t } = useI18n()
const authStore = useAuthStore()
</script>

<template>
    <CrumbsAndActions v-if="authStore.isLoggedIn">
        <Breadcrumb current v-text="t('pageNotFound')" />
    </CrumbsAndActions>
    <div class="flex min-h-[50vh] flex-col items-center justify-center">
        <h1 class="text-[10rem] font-bold leading-[1] text-primary-400" v-text="404" />
        <h1 class="text-3xl text-primary-400" v-text="t('pageNotFound')" />
    </div>
</template>
