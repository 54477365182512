<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@/stores/auth-store'

import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import ReportsTable from '@/components/damage-reports/ReportsTable.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'

const { t } = useI18n()
const authStore = useAuthStore()
</script>

<template>
    <CrumbsAndActions>
        <Breadcrumb current v-text="t('reports')" />
    </CrumbsAndActions>

    <RouterView />

    <div v-if="authStore.companyId">
        <div class="flex space-x-3 p-3 text-xs uppercase">
            <div class="flex items-center space-x-1">
                <div class="h-3 w-3 rounded-full bg-yellow-400" />
                <span class="font-semibold" v-text="t('fieldDeviation')" />
            </div>
            <div class="flex items-center space-x-1">
                <div class="h-3 w-3 rounded-full bg-red-400" />
                <span class="font-semibold" v-text="t('newIncident')" />
            </div>
            <div class="flex items-center space-x-1">
                <div class="h-3 w-3 rounded-full bg-dark-500" />
                <span class="font-semibold" v-text="t('wornTireThread')" />
            </div>
        </div>

        <MyPanel shadow>
            <ReportsTable />
        </MyPanel>
    </div>
</template>
