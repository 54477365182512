<script setup lang="ts">
import { ref, onMounted, nextTick, watch, computed } from 'vue'
import { useResizeObserver, useDebounceFn, useLocalStorage } from '@vueuse/core'
import { useI18n } from 'vue-i18n'

import '@here/maps-api-for-javascript'

import { markerColors } from '@/utils/maps'
import { Marker } from '@/types/general'
import { LocationPoint } from '@/types/company'

import MyButton from '@/components/my-components/MyButton.vue'
import MyRadioButtonGroup from '@/components/my-components/form/MyRadioButtonGroup.vue'
import MyRadioButton from '@/components/my-components/form/MyRadioButton.vue'

interface Props {
    markers: Marker[]
    connectMarkers?: boolean
    panTo?: LocationPoint
}

interface Layers {
    vector: {
        normal: {
            map: H.map.layer.Layer
        }
    }
    raster: {
        satellite: {
            map: H.map.layer.Layer
        }
    }
}

const props = withDefaults(defineProps<Props>(), {
    connectMarkers: false,
})

const emit = defineEmits<{
    (e: 'markerClicked', event: string): void
}>()

const { t } = useI18n()

// Initialize map-related objects.
let map: H.Map | null
let ui: H.ui.UI | null
const apikey = import.meta.env.VITE_HERE_API_KEY
const mapMarkers = new H.map.Group()
const platform = new H.service.Platform({ apikey })
const layers = platform.createDefaultLayers() as Layers
const zoom = 15
const mapType = useLocalStorage('hereMapType', 'normal')
const mapContainer = ref<HTMLDivElement>()

const computedPanTo = computed(() => {
    if (!props.panTo) return
    return { lat: props.panTo.latitude, lng: props.panTo.longitude }
})

/**
 * It's not possible to zoom with proper animation, so it has to be done like this
 */
function setZoom(zoomIncrease: number) {
    let currentZoom = map?.getZoom() ?? 0
    const endZoom = currentZoom + zoomIncrease
    function animationStep() {
        currentZoom += zoomIncrease * 0.1
        map?.setZoom(currentZoom)
        if (zoomIncrease > 0 && currentZoom < endZoom) {
            requestAnimationFrame(animationStep)
        } else if (zoomIncrease < 0 && currentZoom > endZoom) {
            requestAnimationFrame(animationStep)
        }
    }
    animationStep()
}

function markerSvgStyle(fill: string): string {
    return `<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 1024 1024" width="24" height="24">
            <path d="M512 85.333333C347.050667 85.333333 213.333333 219.072 213.333333 384 213.333333 548.949333 512 938.666667 512 938.666667S810.666667 548.949334 810.666667 384c0-164.928-133.717333-298.666667-298.666667-298.666667zm0 448a149.333333 149.333333 0 1 1 0-298.666666 149.333333 149.333333 0 0 1 0 298.666666z" fill="${fill}"/>
        </svg>`
}

function markerSvgStyleLabel(fill: string, label: string | number): string {
    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width="24" height="24">
            <path d="M512,85.333333 C347.050667,85.333333,213.333333,219.072,213.333333,384 C213.333333,548.949333,512,938.666667,512,938.666667 S810.666667,548.949334,810.666667,384 c0,-164.928,-133.717333,-298.666667,-298.666667,-298.666667 m0,448 Z" fill="${fill}" />
            <text x="49%" y="41%" text-anchor="middle" fill="white" font-family="Arial, Helvetica, sans-serif" font-size="360" font-weight="bold" dy=".3em" stroke="black" stroke-width="10">${label}</text>
        </svg>`
}

function removeInfoBubbles() {
    ui?.getBubbles().forEach((bubble) => ui?.removeBubble(bubble))
}

function onMarkerLeave() {
    removeInfoBubbles()
    map!.getViewPort().element.style.cursor = 'auto'
}

function onMarkerHover(location: H.geo.IPoint, content: string) {
    if (!ui) return
    map!.getViewPort().element.style.cursor = 'pointer'
    const bubbles = ui.getBubbles()
    if (bubbles[0]?.getContentElement()?.innerHTML === content) {
        bubbles[0].setPosition(location)
        if (bubbles[0].getState() === H.ui.InfoBubble.State.CLOSED) bubbles[0].open()
        return
    }

    removeInfoBubbles()
    const bubble = new H.ui.InfoBubble(location, { content })
    ui.addBubble(bubble)
}

function initializeMap() {
    map = new H.Map(
        mapContainer.value!,
        mapType.value == 'normal' ? layers.vector.normal.map : layers.raster.satellite.map,
        {
            padding: { top: 50, right: 50, bottom: 50, left: 50 },
            pixelRatio: window.devicePixelRatio || 1,
            center: computedPanTo.value ?? undefined,
            zoom: computedPanTo.value ? zoom : undefined,
        },
    )
    map?.addObject(mapMarkers)
    new H.mapevents.Behavior(new H.mapevents.MapEvents(map))
    ui = H.ui.UI.createDefault(map, layers)
    ui.removeControl('zoom')
    ui.removeControl('mapsettings')

    nextTick(() => drawMarkers())
}

function drawMarkers() {
    if (props.markers.length === 0) return

    let lineString: H.geo.LineString | undefined
    if (props.markers.length > 1 && props.connectMarkers) {
        lineString = new H.geo.LineString()
    }

    for (const mark of props.markers) {
        const location: H.geo.IPoint = {
            lat: mark.location.latitude,
            lng: mark.location.longitude,
        }
        const marker = new H.map.Marker(location)
        let markerIcon: string
        if (!mark.label) {
            markerIcon = markerSvgStyle(mark.fillColor ?? markerColors.default)
        } else {
            markerIcon = markerSvgStyleLabel(mark.fillColor ?? markerColors.default, mark.label)
        }

        const iconSize = 48
        // The anchor point is based on the size property. If that changes, this should too
        // For some reason it's equal to the markers height but just a little above it
        // When the icon size is 48 the shift was measured to 5
        const iconShift = (5 / 48) * iconSize
        marker.setIcon(
            new H.map.Icon(markerIcon, {
                size: { w: iconSize, h: iconSize },
                anchor: { x: iconSize / 2, y: iconSize - iconShift },
            }),
        )

        if (mark.content) {
            marker.addEventListener('pointerenter', () => onMarkerHover(location, mark.content!))
            marker.addEventListener('pointerleave', onMarkerLeave)
        }
        marker.addEventListener('tap', () => {
            if (mark.href) {
                window.open(mark.href, '_blank')
            }

            emit('markerClicked', mark.markerId ?? '')
        })

        mapMarkers.addObject(marker)

        if (lineString) {
            lineString.pushPoint(location)
        }
    }

    if (lineString) {
        mapMarkers?.addObject(
            new H.map.Polyline(lineString, {
                style: { lineWidth: 4, strokeColor: markerColors.lightRed },
                data: '',
            }),
        )
    }

    const boundingBox = mapMarkers.getBoundingBox()
    if (boundingBox && !props.panTo) {
        if (props.markers.length === 1) {
            map?.setCenter({
                lat: props.markers[0].location.latitude,
                lng: props.markers[0].location.longitude,
            })
            map?.setZoom(zoom)
        } else {
            map?.getViewModel().setLookAtData({ bounds: boundingBox })
        }
    }
}

function setMapType(type: string) {
    if (type !== 'normal') {
        map?.setBaseLayer(layers.raster.satellite.map)
        return
    }
    map?.setBaseLayer(layers.vector.normal.map)
}

const debouncedResize = useDebounceFn(() => {
    if (map) map.getViewPort().resize()
}, 100)

watch(
    () => mapType.value,
    (value) => setMapType(value),
)

watch(
    () => computedPanTo.value,
    (panTo) => {
        // If we want the smooth animation we can toggle this to true, but the animation stacks and can be kinda funky
        if (panTo) map?.setCenter(panTo, false)
    },
)

watch(
    () => props.markers,
    (markers) => {
        if (markers) {
            mapMarkers.removeAll()
            drawMarkers()
        }
    },
    { deep: true },
)

useResizeObserver(mapContainer, debouncedResize)

onMounted(() => initializeMap())
</script>

<template>
    <div class="relative">
        <div ref="mapContainer" class="w-full h-full" />
        <div
            class="absolute top-0 right-0 w-full flex justify-between flex-col h-full p-2 select-none pointer-events-none z-10"
        >
            <MyRadioButtonGroup
                v-model="mapType"
                class="w-fit pointer-events-auto bg-white scale-75 rounded-lg -translate-x-5 shadow-md"
            >
                <MyRadioButton :label="t('satellite')" value="sattelite" />
                <MyRadioButton :label="t('normal')" value="normal" />
            </MyRadioButtonGroup>
            <div class="flex flex-col self-end mb-8 space-y-1 pointer-events-auto">
                <MyButton
                    icon
                    size="small"
                    shadow
                    class="bg-white text-black"
                    type="button"
                    @click="setZoom(1)"
                >
                    <mdi:plus-thick />
                </MyButton>
                <MyButton
                    icon
                    size="small"
                    class="bg-white text-black"
                    shadow
                    type="button"
                    @click="setZoom(-1)"
                >
                    <mdi:minus-thick />
                </MyButton>
            </div>
        </div>
    </div>
</template>

<style>
.H_ib_top {
    margin-top: -35px;
}

.H_ib_body {
    width: 150px;
    z-index: 2;
    transform: translateX(45px);
    @apply rounded-lg;
}

.H_ib_tail {
    z-index: 3;
}
</style>
