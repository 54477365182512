<script lang="ts" setup>
import type { DropdownOption } from '@/types/inputs'

import Datepicker from '@vuepic/vue-datepicker'
import axios from 'axios'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@/stores/auth-store'
import { useTransactionStore } from '@/stores/transaction-store'
import { MinimalResource } from '@/types/general'
import { ManageTransaction, TransactionState } from '@/types/transaction'

import ManageConsignmentNoteItem from './ManageConsignmentNoteItem.vue'

import MyFileSelect from '@/components/my-components/MyFileSelect.vue'
import MyFormWizardStep from '@/components/my-components/form/MyFormWizardStep.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import MyTextarea from '@/components/my-components/form/MyTextarea.vue'
import MyInputLabel from '@/components/my-components/form/MyInputLabel.vue'

export interface Props {
    active: boolean
}

const { t } = useI18n()
const authStore = useAuthStore()
const props = defineProps<Props>()
const transactionStore = useTransactionStore()
const transaction = transactionStore.$state.transaction! as ManageTransaction
const uploadingConsignmentNotes = computed(
    () =>
        transaction.consignmentNotes.filter((note) => !note.path).length !== 0 &&
        transaction.consignmentNotes.length > 0,
)

const rawShipperOptions = ref<DropdownOption[]>([])
const shipperOptions = computed(() =>
    rawShipperOptions.value.filter((shipper) => shipper.value !== transaction.receivingCompanyId),
)

function handleConsignmentNotes(files: File[]) {
    const consignmentNotes = files.map((file) => {
        const regex = new RegExp('image/*')
        const isImage = regex.test(file.type)
        return { file, isImage }
    })
    transactionStore.appendConsignmentNote(consignmentNotes)
}

async function fetchShippers() {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.customer-headquarters', { company: authStore.companyId }),
    )

    rawShipperOptions.value = response.data.map((hq) => ({
        value: hq.id,
        label: hq.name,
    }))
}

watch(
    () => props.active,
    () => {
        if (!props.active || rawShipperOptions.value.length !== 0) return

        fetchShippers()
    },
)
</script>

<template>
    <MyFormWizardStep
        id="details"
        :active="props.active"
        :submit="() => !uploadingConsignmentNotes || true"
        :title="t('details')"
        :loading="uploadingConsignmentNotes"
    >
        <div class="grid grid-cols-2 gap-6">
            <div class="col-span-2 flex gap-6">
                <ManageConsignmentNoteItem
                    v-for="(consignmentNote, index) in transaction.consignmentNotes"
                    :key="'consignmentNote-' + index"
                    :consignment-note="consignmentNote"
                />
                <div class="grow">
                    <MyFileSelect
                        multiple
                        class="h-32 rounded-xl"
                        type="image/*,application/pdf"
                        @selected:multiple="handleConsignmentNotes"
                    >
                        <p
                            class="text-xs font-semibold dark:text-gray-300"
                            v-text="t('dragAndDrop')"
                        />
                        <p
                            class="text-sm font-semibold dark:text-gray-300"
                            v-text="t('consignmentNote')"
                        />
                    </MyFileSelect>
                </div>
            </div>

            <div v-if="transaction?.state !== TransactionState.IncomingDraft">
                <MyInputLabel class="w-full" for="transpiredAt">
                    {{ t('createdAt') }}
                </MyInputLabel>

                <Datepicker
                    id="transpiredAt"
                    v-model="transaction.transpiredAt"
                    class="input-field w-full rounded-xl border border-gray-300"
                    input-class-name="w-64"
                    name="transpiredAt"
                    enable-time-picker
                    enable-seconds
                    close-on-scroll
                    auto-position
                    position="left"
                    format="yyyy-MM-dd HH:mm:ss"
                    :transitions="false"
                />
            </div>

            <div v-else>
                <MyInputLabel class="w-full" for="confirmedAt">
                    {{ t('confirmedAt') }}
                </MyInputLabel>

                <Datepicker
                    id="confirmedAt"
                    v-model="transaction.confirmedAt"
                    class="input-field w-full rounded-xl border border-gray-300"
                    input-class-name="w-64"
                    name="confirmedAt"
                    enable-time-picker
                    enable-seconds
                    close-on-scroll
                    auto-position
                    position="left"
                    format="yyyy-MM-dd HH:mm:ss"
                    :transitions="false"
                />
            </div>

            <MySelect
                v-if="!transaction.haulierCompanyId"
                v-model="transaction.shipperLocationId"
                :label="t('shipperLocationCompany')"
                :options="shipperOptions"
                :placeholder="t('selectShipperLocationCompany')"
                searchable
                clear-button
            />

            <div v-else />

            <MyInput v-model="transaction.details!.trailerNumber" :label="t('trailerNumber')" />

            <MyInput v-model="transaction.details!.truckNumber" :label="t('truckNumber')" />

            <MyInput
                v-model="transaction.details!.palletReceiptNumber"
                :label="t('palletReceiptNumber')"
            />

            <MyInput v-model="transaction.details!.shippingCode" :label="t('shipmentNumber')" />

            <div class="col-span-2">
                <MyTextarea
                    v-model="transaction.details!.note"
                    :label="t('remarks')"
                    @keydown.enter.stop
                />
            </div>
        </div>
    </MyFormWizardStep>
</template>
