<script lang="ts" setup>
const props = defineProps<{ text: string }>()
</script>

<template>
    <div class="flex w-full flex-col items-center justify-center rounded-xl bg-primary-50 py-6">
        <p
            class="font-semibold uppercase tracking-wider"
            :class="{ 'mb-3': $slots.default }"
            v-text="props.text"
        />
        <slot />
    </div>
</template>
