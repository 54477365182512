<script lang="ts" setup>
import { notify } from '@kyvg/vue3-notification'
import axios from 'axios'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@/stores/auth-store'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'

export interface Props {
    modelValue: boolean
    productId?: string
    locationId?: string
    currentBalance?: number
}

const emit = defineEmits<{ (e: 'update:modelValue', value: boolean): void }>()
const props = defineProps<Props>()

const { t } = useI18n()
const authStore = useAuthStore()
const loading = ref(false)
const balance = ref(props.currentBalance ?? 0)
const error = ref<string>()

async function adjustStock() {
    error.value = undefined

    if (balance.value === props.currentBalance) {
        error.value = t('balanceIsUnchanged')
        return
    }

    loading.value = true
    const data = {
        locationId: props.locationId,
        products: [{ id: props.productId, amount: balance.value - props.currentBalance! }],
    }

    try {
        await axios.put(
            window.route('packaging.company.stock.adjust', { company: authStore.companyId }),
            data,
        )
    } catch (_) {
        notify({ title: t('unknownError'), text: t('tryAgainLater'), type: 'error' })
    }

    loading.value = false
    emit('update:modelValue', false)
}

watch(
    () => props.modelValue,
    () => {
        balance.value = props.currentBalance || 0
    },
)
</script>

<template>
    <MyModal :value="props.modelValue" @close="emit('update:modelValue', false)">
        <LoaderWrapper :visible="loading" />

        <MyForm v-if="productId" @submit.prevent="adjustStock">
            <MyInput
                v-model.number="balance"
                type="number"
                :label="t('newBalance')"
                :error="error"
                autofocus
            />

            <div class="mt-3 flex justify-end">
                <MyButton :disabled="loading" scheme="primary">{{ t('save') }}</MyButton>
            </div>
        </MyForm>
    </MyModal>
</template>
