<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { RouterLinkProps } from 'vue-router'

export interface Props extends RouterLinkProps {
    translation: string
    activated?: boolean
    requiresModuleLicense?: boolean
}

const props = withDefaults(defineProps<Props>(), { activated: true })
const { t } = useI18n()
</script>

<template>
    <RouterLink
        v-slot="routerLinkProps"
        v-tooltip="{
            content: t('noAccessForReadonly'),
            disabled: props.activated || (!props.activated && !props.requiresModuleLicense),
            placement: 'bottom',
        }"
        :to="props.to"
        :active-class="
            !props.activated
                ? ''
                : 'bg-green-500 dark:active:bg-green-500 active:bg-green-500 shadow-xl'
        "
        class="group group relative flex h-9 w-10 cursor-pointer items-center rounded-xl px-3 py-2 text-primary-100 transition-all hover:bg-green-500 hover:shadow-xl active:bg-green-700 active:shadow dark:active:bg-green-300 lg:flex-row lg:justify-center"
    >
        <span class="relative mr-3 text-xl lg:mr-0" :class="{ 'opacity-60': !props.activated }">
            <slot v-bind="routerLinkProps" />
        </span>
        <div
            class="lg:absolute lg:-top-[30px] lg:hidden lg:whitespace-nowrap lg:text-black lg:group-hover:block dark:lg:text-primary-100"
            v-text="t(props.translation)"
        />
    </RouterLink>
</template>
