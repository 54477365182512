<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { AxiosProgressEvent } from 'axios'

import { useTransactionStore } from '@/stores/transaction-store'
import { TransactionImage } from '@/types/transaction'
import { image as imageHelper } from '@/utils/assets'
import { cloudflareUpload } from '@/utils/upload'

const props = defineProps<{ image: TransactionImage }>()
const { t } = useI18n()
const transactionStore = useTransactionStore()
const uploadProgress = ref(0)
const note = ref(props.image.note)

function getImage(variant?: string) {
    if (props.image.path) return imageHelper(props.image.path, variant) as string
    return URL.createObjectURL(props.image.file as File)
}

const thumbnail = computed(() => getImage())

function onUploadProgress(event: AxiosProgressEvent) {
    uploadProgress.value = Math.round((event.loaded / (event.total || 1)) * 100)
}

onMounted(() => {
    if (props.image.file) {
        cloudflareUpload(props.image.file, { onUploadProgress }).then((data) => {
            transactionStore.updateImage(props.image, data)
        })
    }
})

watch(note, () => {
    transactionStore.updateNote(props.image.path!, note.value)
})
</script>

<template>
    <div
        class="relative flex h-32 justify-center overflow-hidden rounded-lg bg-primary-300 text-center"
    >
        <div
            class="absolute top-0 right-0 z-10 space-y-1 rounded-bl-lg bg-primary-300 text-xs text-primary-50 shadow-lg"
        >
            <span
                v-if="props.image"
                class="flex h-6 w-6 cursor-pointer items-center justify-center transition-all hover:bg-red-400 hover:text-primary-50"
                @click="transactionStore.removeImage(props.image.path!)"
            >
                <mdi:trash-can />
            </span>

            <VDropdown v-if="props.image.path" :distance="6">
                <!-- This will be the popover reference (for the events and position) -->
                <button
                    type="button"
                    class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-bl-lg transition-all hover:bg-primary-400 hover:text-primary-50"
                >
                    <mdi:comment-plus />
                </button>

                <!-- This will be the content of the popover -->
                <template #popper>
                    <textarea
                        v-model="note"
                        class="resize-none border-0 text-sm"
                        :placeholder="t('addNote')"
                    />
                </template>
            </VDropdown>
        </div>
        <img
            :src="thumbnail"
            class="z-0 w-auto min-w-full object-cover"
            :class="{ 'animate-pulse ': !props.image.path }"
        />
        <div
            v-if="!props.image.path"
            class="absolute top-0 h-full w-full bg-primary-300 transition-all"
            :style="{ transform: `translateY(${uploadProgress}%)` }"
        />
    </div>
</template>
