<script lang="ts" setup>
import type { MinimalResource, PaginatedResponse, uuid } from '@/types/general'
import type { DatePickerInstance } from '@vuepic/vue-datepicker'

import axios from 'axios'
import { ref, computed, nextTick, onBeforeMount, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import dayjs from 'dayjs'
import Datepicker from '@vuepic/vue-datepicker'

import { usePaginatedTable } from '@/hooks/table/use-paginated-table'
import { useAuthStore } from '@/stores/auth-store'
import { MinimalReport, ReportFilter, ReportType } from '@/types/damage-report'
import { useConfirm } from '@/hooks/use-confirm'
import { MyButtonScheme } from '@/types/layout/my-button'
import { DropdownStringOption } from '@/types/inputs'
import { GetDataParameters } from '@/types/table'
import { printElement } from '@/utils/print-element'
import { datetime } from '@/utils/dates'
import { useMittListener } from '@/hooks/use-mitt-listener'
import { useLazyLoadedList } from '@/hooks/use-lazy-loaded-list'
import { googleMapsLink } from '@/utils/google-maps'
import { usePresetRangeList } from '@/hooks/use-preset-range-list'
import { image } from '@/utils/assets'

import MyButton from '@/components/my-components/MyButton.vue'
import UpdateDepartureModal from '@/components/damage-reports/UpdateDepartureModal.vue'
import BasicShareModal from '@/components/BasicShareModal.vue'
import ActionMenuItem from '@/components/table/ActionMenuItem.vue'
import MyTable from '@/components/table/MyTable.vue'
import MyTableColumn from '@/components/table/MyTableColumn.vue'
import MyFilterButton from '@/components/table/MyFilterButton.vue'
import ActionRowItem from '@/components/table/ActionRowItem.vue'
import MyFilterDivider from '@/components/table/MyFilterDivider.vue'
import MyFilterSelect from '@/components/table/MyFilterSelect.vue'
import ReportStatuses from '@/components/damage-reports/ReportStatuses.vue'

interface FetchReportsParams extends Record<string, unknown>, GetDataParameters {
    filter: {
        state: ReportFilter
        'user-id': uuid | undefined
        'template-id': uuid | undefined
        'start-date': string | undefined
        'end-date': string | undefined
    }
}

const { t } = useI18n()
const authStore = useAuthStore()
const route = useRoute()
const router = useRouter()
const confirm = useConfirm()
const presetRanges = usePresetRangeList()

const drivers = useLazyLoadedList<DropdownStringOption>(fetchDrivers)
const templates = useLazyLoadedList<DropdownStringOption>(fetchTemplates)
const selectedReportId = ref<uuid>()
const shareReportOpen = ref(false)
const departureModalOpen = ref(false)
const reportSheetContent = ref<HTMLDivElement>()
const printMode = ref(false)
const datepicker = ref<DatePickerInstance>(null)

const { data, paginationData, params, loading, error, refetch } = usePaginatedTable<
    MinimalReport,
    FetchReportsParams
>(
    async (params, abortController) => {
        const response = await axios.get<PaginatedResponse<MinimalReport>>(
            window.route('dmr.company.reports.index', {
                company: authStore.companyId,
            }),
            { params: params, signal: abortController.signal },
        )

        return response.data
    },
    {
        filter: {
            state: 'all',
            'user-id': undefined,
            'template-id': undefined,
            'start-date': dayjs().subtract(1, 'month').startOf('day').toDate().toISOString(),
            'end-date': dayjs().endOf('day').toDate().toISOString(),
        },
    },
)

const date = ref<Date[]>(
    params.value.filter['start-date'] && params.value.filter['end-date']
        ? [
              dayjs(params.value.filter['start-date']).toDate(),
              dayjs(params.value.filter['end-date']).toDate(),
          ]
        : [],
)

const selectedReport = computed(() => {
    if (!selectedReportId.value) return undefined
    return data.value!.find(({ id }) => id === selectedReportId.value)
})

const shareEndpoint = computed(() => {
    if (!authStore.companyId) return ''

    return window.route('dmr.company.reports.share', {
        company: authStore.companyId,
        report: selectedReportId.value ?? '',
    })
})

function setFilter(value: string | number | null, filterName: string) {
    params.value.filter[filterName] = value
    params.value.page = 1
}

function shareReport(id: uuid) {
    selectedReportId.value = id
    shareReportOpen.value = true
}

async function fetchDrivers(): Promise<DropdownStringOption[]> {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.employees', { company: authStore.companyId }),
    )

    return response.data.map((user) => ({ value: user.id, label: user.name }))
}

async function fetchTemplates(): Promise<DropdownStringOption[]> {
    const response = await axios.get<{ name: string; id: uuid }[]>(
        window.route('minimal.companies.templates', { company: authStore.companyId }),
    )

    return response.data.map(({ id, name }) => ({ value: id, label: name }))
}

async function deleteReport(report: MinimalReport) {
    try {
        await confirm(
            t('deleteEntityTitle', { entity: t('report') }),
            t('deleteEntityDescription', { entity: t('report') }),
            {
                confirmText: t('yes'),
                cancelText: t('no'),
                confirmButtonScheme: MyButtonScheme.Warning,
            },
        )
    } catch {
        return
    }

    await axios.delete(
        window.route('dmr.company.reports.destroy', {
            company: authStore.companyId,
            report: report.id,
        }),
    )
    await refetch()
}

function getReportUrl(report: MinimalReport) {
    const reportRoute = router.resolve({
        name: 'dmr.reports.show',
        params: { reportId: report.id },
    })

    return `${window.location.origin}${reportRoute.fullPath}`
}

function updateDeparture(id: uuid) {
    selectedReportId.value = id
    departureModalOpen.value = true
}

async function print(report: MinimalReport) {
    printMode.value = true
    selectedReportId.value = report.id
    await nextTick()
    await printElement(reportSheetContent.value!)
    selectedReportId.value = undefined
    printMode.value = false
}

function clearDates() {
    datepicker.value?.closeMenu()
    delete params.value.filter['start-date']
    delete params.value.filter['end-date']
    date.value = []
}

onBeforeMount(() => {
    if (params.value.filter['user-id']) drivers.fetch()
    if (params.value.filter['template-id']) templates.fetch()
})

watch(date, () => {
    if (!date.value[0] || !date.value[1]) return
    setFilter(dayjs(date.value[0]).startOf('day').toISOString(), 'start-date')
    setFilter(dayjs(date.value[1]).endOf('day').toISOString(), 'end-date')
})

useMittListener('fetchDamageReports', () => refetch())
</script>

<template>
    <MyTable
        :error="error"
        :get-data="refetch"
        :loading="loading"
        :pagination-data="paginationData"
        table-id="dmr_reports"
        :rows="data"
        :entity-name="t('damageReports')"
        :has-time-range="!!params.filter['start-date']"
    >
        <template #filters>
            <Datepicker
                ref="datepicker"
                v-model="date"
                input-class-name="w-64"
                range
                :enable-time-picker="false"
                auto-apply
                :teleport="true"
                :clearable="false"
                :preset-dates="presetRanges"
                close-on-scroll
                position="left"
                format="yyyy-MM-dd"
                :transitions="false"
                :placeholder="t('allTime')"
                :config="{ closeOnAutoApply: true }"
            >
                <template #allTime>
                    <button
                        class="dp__btn dp--preset-range"
                        @click="clearDates()"
                        v-text="t('allTime')"
                    />
                </template>
            </Datepicker>

            <MyFilterDivider />
            <MyFilterButton
                filter-name="state"
                value="all"
                default
                @selected="setFilter"
                v-text="t('all')"
            />
            <MyFilterButton
                filter-name="state"
                value="withAlarms"
                @selected="setFilter"
                v-text="t('withAlarms')"
            />
            <MyFilterButton
                filter-name="state"
                value="withoutAlarms"
                @selected="setFilter"
                v-text="t('withoutAlarms')"
            />
            <MyFilterDivider />
            <MyFilterSelect
                :label="t('drivers')"
                :options="drivers.items.value"
                filter-name="user-id"
                :loading="drivers.fetching.value"
                @focus="drivers.fetch()"
                @change="setFilter"
            />
            <MyFilterSelect
                :label="t('template')"
                :options="templates.items.value"
                :loading="templates.fetching.value"
                filter-name="template-id"
                @focus="templates.fetch"
                @change="setFilter"
            />
        </template>

        <template #actionRow="{ row }">
            <RouterLink
                :to="{
                    name: 'dmr.reports.show',
                    params: { reportId: row.id },
                    query: route.query,
                }"
            >
                <ActionRowItem>
                    <mdi:eye />
                </ActionRowItem>
            </RouterLink>
        </template>
        <template #actionMenu="{ row }">
            <ActionMenuItem @click="print(row)">
                <span class="mr-2"><mdi:qrcode /></span>
                {{ t('reportSheet') }}
            </ActionMenuItem>
            <ActionMenuItem
                class="flex cursor-pointer items-center rounded-lg py-2 px-3 text-sm hover:bg-primary-500 hover:text-primary-50 dark:hover:bg-dark-300"
                @click="shareReport(row.id as string)"
            >
                <mdi:share class="mr-2" />
                {{ t('shareEntity', { entity: t('report') }) }}
            </ActionMenuItem>
            <ActionMenuItem
                v-if="row.company.id === authStore.companyId"
                @click="deleteReport(row)"
            >
                <span class="mr-2"><mdi:trash-can /></span>
                {{ t('remove') }}
            </ActionMenuItem>
        </template>

        <MyTableColumn
            :draggable="false"
            :sortable="false"
            name=""
            property="statuses"
            class="justify-start gap-1"
            :width="100"
        />
        <template #statuses="{ row }">
            <ReportStatuses :report="row" />
        </template>

        <MyTableColumn :name="t('vehicleOrUnit')" property="transportObject.registrationNumber" />
        <template #[`transportObject.registrationNumber`]="{ row }">
            <div class="flex items-center justify-between">
                <RouterLink
                    :to="{
                        name: 'transport-objects.show',
                        params: { transportObjectId: row.transportObject.id },
                    }"
                >
                    <span
                        class="w-full truncate font-semibold text-primary-400"
                        v-text="row.transportObject.registrationNumber"
                    />
                </RouterLink>
                <img
                    v-if="row.transportObject.companyLogo"
                    v-tooltip="row.transportObject?.companyName"
                    :src="image(row.transportObject.companyLogo)"
                    class="ml-2 object-contain h-full !max-w-[20px] rounded-sm"
                />
            </div>
        </template>
        <MyTableColumn :name="t('nickname')" property="transportObject.nickname" />
        <MyTableColumn :name="t('user')" property="user.name" />
        <MyTableColumn :name="t('templateName')" property="template.name" />
        <MyTableColumn :name="t('customer')" property="customerCompany.name" />
        <MyTableColumn :name="t('company')" property="company.name" />
        <MyTableColumn :name="t('location')" property="companyLocation.name" />
        <MyTableColumn :name="t('address')" property="address" />
        <template #address="{ row }">
            <a
                :href="googleMapsLink(row.location.latitude, row.location.longitude)"
                class="flex w-full truncate font-semibold text-primary-400"
                target="_blank"
                rel="noopener noreferrer"
                v-text="row.address || `${row.location.latitude}, ${row.location.longitude}`"
            />
        </template>
        <MyTableColumn :name="t('vehicle')" property="vehicle.registrationNumber" />
        <template #[`vehicle.registrationNumber`]="{ row }">
            <div v-if="row.vehicle" class="flex items-center justify-between">
                <RouterLink
                    :to="{
                        name: 'transport-objects.show',
                        params: { transportObjectId: row.vehicle.id },
                    }"
                >
                    <span
                        class="w-full truncate font-semibold text-primary-400"
                        v-text="row.vehicle.registrationNumber"
                    />
                </RouterLink>
                <img
                    v-if="row.vehicle.companyLogo"
                    v-tooltip="row.vehicle?.companyName"
                    :src="image(row.vehicle.companyLogo)"
                    class="ml-2 object-contain h-full !max-w-[20px] rounded-sm"
                />
            </div>
        </template>
        <MyTableColumn :name="t('haulier')" property="haulierCompany.name" />
        <MyTableColumn :name="t('referenceNumber')" property="note" />
        <MyTableColumn :name="t('type')" property="type" />
        <template #type="{ row }">
            {{ t(row.type === ReportType.Pickup ? 'pickup' : 'dropOff') }}
        </template>
        <MyTableColumn :name="t('departure')" property="departure" />
        <template #departure="{ row }">
            <div class="group/item flex items-center">
                <span class="w-full truncate" v-text="row.departure" />

                <span
                    class="cursor-pointer pl-3 text-gray-600 opacity-0 transition-opacity group-hover/item:opacity-100 dark:text-primary-50"
                    @click="updateDeparture(row.id)"
                >
                    <mdi:pencil />
                </span>
            </div>
        </template>
        <MyTableColumn :name="t('reason')" property="reason" />
        <template #reason="{ row }">{{ row.reason ? t(row.reason) : '' }}</template>
        <MyTableColumn :min-width="170" :name="t('transpiredAt')" property="transpiredAt" />
        <template #transpiredAt="{ row }">
            <span v-text="datetime(row.transpiredAt)" />
        </template>
        <template #noDataButton>
            <MyButton
                v-if="params.filter['start-date']"
                plain
                scheme="light"
                size="small"
                @click="clearDates()"
            >
                <mdi:magnify class="mr-2 h-4 w-4" />
                {{ t('searchAllTime') }}
            </MyButton>
        </template>
    </MyTable>

    <BasicShareModal
        v-if="selectedReportId && shareEndpoint"
        v-model="shareReportOpen"
        :entity="t('report')"
        :endpoint="shareEndpoint"
    />

    <UpdateDepartureModal
        v-if="departureModalOpen && selectedReport"
        v-model="departureModalOpen"
        :report-id="selectedReport.id"
        :departure="selectedReport.departure"
    />

    <!-- For QR Print -->
    <div
        v-if="selectedReport && printMode"
        ref="reportSheetContent"
        class="hidden w-full break-words p-6 print:block"
    >
        <h3
            class="mb-8 text-2xl font-semibold uppercase text-primary-400"
            v-text="t('damageReport')"
        />
        <div class="space-y-4">
            <div class="flex w-full">
                <span class="mr-2 w-1/3 text-xl font-semibold" v-text="t('company') + ':'" />
                <span class="w-2/3 text-xl" v-text="selectedReport?.company.name" />
            </div>

            <div class="flex w-full">
                <span
                    class="mr-2 w-1/3 text-xl font-semibold"
                    v-text="t('registrationNumber') + ':'"
                />
                <span
                    class="w-2/3 text-xl"
                    v-text="selectedReport?.transportObject.registrationNumber"
                />
            </div>

            <div class="flex w-full">
                <span class="mr-2 w-1/3 text-xl font-semibold" v-text="t('type') + ':'" />
                <span class="w-2/3 text-xl" v-text="selectedReport?.transportObject.templateName" />
            </div>

            <div class="flex w-full">
                <span class="mr-2 w-1/3 text-xl font-semibold" v-text="t('transpiredAt') + ':'" />
                <span class="w-2/3 text-xl" v-text="datetime(selectedReport.transpiredAt)" />
            </div>

            <div v-if="selectedReport?.customerCompany" class="flex w-full">
                <span class="mr-2 w-1/3 text-xl font-semibold" v-text="t('customer') + ':'" />
                <span class="w-2/3 text-xl" v-text="selectedReport?.customerCompany.name" />
            </div>

            <div v-if="selectedReport?.departure" class="flex w-full">
                <span class="mr-2 w-1/3 text-xl font-semibold" v-text="t('departure') + ':'" />
                <span class="w-2/3 text-xl" v-text="selectedReport.departure" />
            </div>
        </div>

        <img
            class="qrcode"
            :data-url="getReportUrl(selectedReport)"
            style="width: 300px; height: 300px"
        />
    </div>
</template>
