import { useI18n } from 'vue-i18n'

import { emitter } from '@/utils/mitt'
import { ConfirmOptions } from '@/types/general'
import { MyButtonScheme } from '@/types/layout/my-button'

export function useConfirm() {
    const { t } = useI18n()
    const defaultOptions: ConfirmOptions = {
        confirmText: t('confirm'),
        cancelText: t('cancel'),
        confirmButtonScheme: MyButtonScheme.Primary,
    }
    function confirm(
        title: string,
        description?: string,
        options: ConfirmOptions = defaultOptions,
    ): Promise<boolean> {
        return new Promise((resolve, reject) => {
            emitter.emit('showConfirm', {
                title,
                description,
                options,
                resolve,
                reject,
            })
        })
    }

    return confirm
}
