<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import Datepicker, { DatePickerInstance } from '@vuepic/vue-datepicker'

import { DropdownOption, MyInputRef } from '@/types/inputs'
import { ColumnFilterType, ColumnFilter, InternalTableColumn, DataType } from '@/types/table'

import MyButton from '@/components/my-components/MyButton.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'

export interface Props {
    column: InternalTableColumn
    filters: ColumnFilter[]
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'addFilter'): void
    (e: 'removeFilter', filterIndex: number): void
    (e: 'clearFilters'): void
}>()

const { t } = useI18n()

const inputs = ref<MyInputRef[]>()
const datepicker = ref<DatePickerInstance>(null)

const filterTypeOptions: Record<DataType, DropdownOption[]> = {
    [DataType.String]: [
        { value: ColumnFilterType.Contains, label: t('contains') },
        { value: ColumnFilterType.NotContains, label: t('doesNotContain') },
    ],
    [DataType.Date]: [
        { value: ColumnFilterType.Between, label: t('betweenRange') },
        { value: ColumnFilterType.NotBetween, label: t('notBetweenRange') },
    ],
}

const hasValidFilters = computed(() => {
    if (!props.filters) return

    return props.filters.some((filter) => {
        /* We determine if a filter is a string or array to check if the user is filtering on an input or
           if the user is filtering on a date, using the typeof filter.value instead of checking the type
           directly on the column here because typescript doesn't recognise it's not a string array
           otherwise and checking both filter.value and the dataType is redundant */
        if (typeof filter.value === 'string') {
            return filter.value.trim().length > 0
        } else if (Array.isArray(filter.value)) {
            return filter.value.length === 2
        }
        return false
    })
})

const canAddAdditionalFilter = computed(() => {
    if (props.filters.length === 0) return false

    // Check that the newest filter actually has a value
    const lastFilterValue = props.filters[props.filters.length - 1].value

    if (typeof lastFilterValue === 'string') {
        return lastFilterValue.trim().length >= 1
    }

    return lastFilterValue.length === 2
})

const columnFilterOptions = computed(() => props.column.filterable!)

const columnPopperClass = computed(() => {
    return `${props.column.property.replace(/\./g, '-')}-popper`
})

const popperContainerPath = computed(() => {
    return `.${columnPopperClass.value}>.v-popper__wrapper`
})

function filterButtonClicked() {
    if (props.filters.length > 0) return

    emit('addFilter')
}

function focusLatestInput() {
    setTimeout(() => {
        if (!inputs.value) return
        const filtersLength = inputs.value!.length
        inputs.value![filtersLength - 1].input.focus()
    }, 50)
}

watch(
    () => props.filters.length,
    () => focusLatestInput(),
)
</script>

<template>
    <VDropdown
        v-if="column.filterable"
        class="items-center"
        theme="filter-panel"
        :popper-class="columnPopperClass"
        @apply-show="focusLatestInput()"
    >
        <template #default="{ shown }">
            <span
                :class="{
                    'group-hover/item:block hidden': !hasValidFilters && !shown,
                }"
                @click.stop="filterButtonClicked"
            >
                <mdi:filter-outline v-if="!hasValidFilters" />
                <mdi:filter v-else />
            </span>
        </template>

        <template #popper>
            <div class="flex flex-col py-3 px-3 space-y-3 max-w-lg min-w-[500px]">
                <div v-for="(row, rowIndex) in props.filters" :key="rowIndex" class="flex group">
                    <MyInput
                        v-if="columnFilterOptions.dataType === DataType.String"
                        ref="inputs"
                        v-model="row.value as string"
                        :placeholder="t('filter')"
                        class="dark:text-white rounded-l-lg rounded-r-none shadow-none focus:shadow-none group-focus-within:shadow-md border-r-0"
                        group-class="border-r-2 dark:border-dark-500 border-gray-300"
                    />
                    <Datepicker
                        v-else
                        ref="datepicker"
                        :key="`${props.column.property}-datepicker`"
                        v-model="row.value as string[]"
                        class="input-field !border-r-2 dark:border-dark-500 border-gray-300 border"
                        input-class-name="!rounded-r-none"
                        :name="`${props.column.property}-datepicker`"
                        range
                        close-on-scroll
                        enable-time-picker
                        auto-apply
                        :clearable="false"
                        format="yyyy-MM-dd"
                        :transitions="false"
                        :placeholder="t(props.column.property)"
                        :teleport="popperContainerPath"
                        position="left"
                        :alt-position="() => ({ top: 65, left: 0 })"
                        :config="{ closeOnAutoApply: true }"
                        utc
                        :start-time="[
                            { hours: 0, minutes: 0, seconds: 0 },
                            { hours: 23, minutes: 59, seconds: 59 },
                        ]"
                    />
                    <MySelect
                        v-model="row.type"
                        name="type"
                        wrapper-class="bg-white! rounded-l-none rounded-r-lg shadow-none group-focus-within:shadow-md border-l-0 min-w-44"
                        :options="filterTypeOptions[columnFilterOptions.dataType]"
                        :popper-container="popperContainerPath"
                    />
                    <MyButton v-if="rowIndex > 0" icon @click="emit('removeFilter', rowIndex)">
                        <mdi:close />
                    </MyButton>
                </div>
                <div class="flex justify-between">
                    <MyButton
                        v-if="hasValidFilters"
                        class="max-w-fit"
                        scheme="warning"
                        pill
                        @click="emit('clearFilters')"
                    >
                        {{ t('resetFilters') }}
                    </MyButton>
                    <MyButton
                        v-if="canAddAdditionalFilter"
                        class="max-w-fit self-end"
                        scheme="primary"
                        plain
                        pill
                        @click="emit('addFilter')"
                    >
                        {{ t('addFilter') }}
                        <mdi:plus />
                    </MyButton>
                </div>
            </div>
        </template>
    </VDropdown>
</template>
