<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'

import { useAuthStore } from '@/stores/auth-store'

import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import TicketsTable from '@/components/delivery-management/TicketsTable.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import CreateTicketModal from '@/components/delivery-management/CreateTicketModal.vue'
import TicketExportModal from '@/components/delivery-management/TicketExportModal.vue'

const { t } = useI18n()
const authStore = useAuthStore()
const ticketModalOpen = ref(false)
</script>

<template>
    <CrumbsAndActions>
        <Breadcrumb :to="{ name: 'dm' }" v-text="t('deliveryManagement')" />
        <Breadcrumb current v-text="t('tickets')" />

        <template #actions>
            <MyButton plain scheme="primary" size="small" @click="ticketModalOpen = true">
                <mdi:plus-thick class="mr-1" />
                {{ t('createEntity', { entity: t('ticket') }) }}
            </MyButton>

            <TicketExportModal />
        </template>
    </CrumbsAndActions>

    <RouterView />

    <MyPanel v-if="authStore.companyId" shadow>
        <TicketsTable />
    </MyPanel>

    <CreateTicketModal
        v-if="ticketModalOpen"
        v-model="ticketModalOpen"
        @close="ticketModalOpen = false"
    />
</template>
