<script lang="ts" setup>
import type { ConsignmentNote } from '@/types/transaction'

import { onMounted, ref } from 'vue'
import { AxiosProgressEvent } from 'axios'

import { useTransactionStore } from '@/stores/transaction-store'
import { image } from '@/utils/assets'
import { cloudflareUpload } from '@/utils/upload'

const props = defineProps<{ consignmentNote: ConsignmentNote }>()
const transactionStore = useTransactionStore()
const uploadProgress = ref(0)

function getImage() {
    if (props.consignmentNote.path) return image(props.consignmentNote.path, 'thumbnail')
    return URL.createObjectURL(props.consignmentNote.file as File)
}

function onCloudflareUploadProgress(event: AxiosProgressEvent) {
    uploadProgress.value = Math.round((event.loaded / (event.total || 1)) * 100)
}

function onS3UploadProgress(progress: number) {
    uploadProgress.value = Math.round(progress * 100)
}

function handleConsignmentNote() {
    if (!props.consignmentNote.file) return
    const file = props.consignmentNote.file

    if (props.consignmentNote.isImage) {
        cloudflareUpload(file, { onUploadProgress: onCloudflareUploadProgress }).then((data) => {
            transactionStore.updateConsignmentNote(props.consignmentNote, data)
        })
    } else {
        window.Vapor.store(file, { progress: onS3UploadProgress, visibility: 'public-read' }).then(
            ({ key }) => {
                transactionStore.updateConsignmentNote(props.consignmentNote, key, false)
            },
        )
    }
}

onMounted(() => handleConsignmentNote())
</script>

<template>
    <div class="relative max-h-32 w-24 overflow-hidden rounded-xl bg-primary-50">
        <span
            v-if="props.consignmentNote"
            class="absolute top-2 right-2 z-10 cursor-pointer text-red-500 drop-shadow-lg transition-all hover:text-red-400"
            @click="transactionStore.removeConsignmentNote(props.consignmentNote.path!)"
        >
            <mdi:trash-can />
        </span>
        <div
            v-if="!props.consignmentNote.name?.includes('.pdf')"
            class="flex h-32 justify-center"
            :class="{ 'animate-pulse ': !props.consignmentNote.path }"
        >
            <img :src="getImage()" class="z-0 w-auto min-w-full object-cover" />
        </div>
        <div v-else class="flex h-full flex-col p-3">
            <span class="flex items-center justify-center text-center text-3xl text-red-400">
                <mdi:file-pdf-box />
            </span>
            <div class="flex grow flex-wrap items-center">
                <span
                    class="block min-w-[1%] break-all text-xs font-semibold"
                    v-text="props.consignmentNote.name || props.consignmentNote.file?.name"
                />
            </div>
        </div>

        <div
            v-if="!props.consignmentNote.path"
            class="absolute top-0 h-full w-full bg-primary-300 transition-all"
            :style="{ transform: `translateY(${uploadProgress}%)` }"
        />
    </div>
</template>
