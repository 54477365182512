<script lang="ts" setup>
import { computed, inject, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { columnsKey, organizeColumnsKey, updateColumnKey } from '@/types/table'

import MyButton from '@/components/my-components/MyButton.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'

const columns = inject(columnsKey)!
const updateColumn = inject(updateColumnKey)!
const organizeColumns = inject(organizeColumnsKey)!

const { t } = useI18n()
const dropdownValue = ref<string[]>([])
const options = computed(() => {
    return Array.from(columns.value.values())
        .filter(({ name }) => name !== '')
        .map((column) => ({
            label: column.name,
            value: column.property,
        }))
})

let oldValue: string[] = []
watch(
    dropdownValue,
    (value) => {
        // Newly hidden columns
        oldValue.forEach((column) => {
            if (!value.includes(column)) {
                updateColumn(column, { hidden: true })
            }
        })
        // Newly shown columns
        value.forEach((column) => {
            if (!oldValue.includes(column)) {
                updateColumn(column, { hidden: false })
            }
        })

        oldValue = [...value]
    },
    { deep: true },
)

watch(
    columns,
    () => {
        dropdownValue.value = Array.from(columns.value.values())
            .filter((column) => !column.hidden && column.name !== '')
            .sort((a, b) => (a.order > b.order ? 1 : -1))
            .map((column) => column.property)
        // Avoid causing an update
        oldValue = [...dropdownValue.value]
    },
    { deep: true },
)
</script>

<template>
    <div
        class="absolute right-0 top-0 flex h-12 items-center space-x-1 px-3 transition group-focus-within:bg-white dark:group-focus-within:bg-dark-400"
    >
        <MyButton
            v-tooltip="t('organizeColumns')"
            scheme="light"
            plain
            icon
            size="small"
            @click="organizeColumns(true)"
        >
            <mdi:view-column-outline />
        </MyButton>

        <MySelect v-model="dropdownValue" :options="options" multiple inline-search>
            <MyButton
                v-tooltip="t('customizeVisibleColumns')"
                scheme="light"
                plain
                icon
                size="small"
            >
                <mdi:table-cog />
            </MyButton>
        </MySelect>
    </div>
</template>
