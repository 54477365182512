<script lang="ts" setup>
const props = withDefaults(defineProps<{ size?: number }>(), { size: 40 })
</script>

<template>
    <svg
        class="text-white"
        :style="{ width: props.size + 'px', height: props.size + 'px' }"
        viewBox="0 0 480 320"
        xmlns="http://www.w3.org/2000/svg"
        stroke="currentColor"
    >
        <g stroke="#ca6500" stroke-width="2">
            <path
                d="M221.998 227.661V92.34h34.762V227.66ZM91.641 227.661V92.34h34.762V227.66ZM353.596 227.661V92.34h34.762V227.66Z"
                fill="#ff9a65"
                stroke-width="1.2415"
            />
            <path
                d="M388.358 96.063H91.641V67.51L97.85 61.3H382.15l6.208 6.208ZM91.641 223.316h296.717v29.175l-6.208 6.208H97.85l-6.208-6.208ZM91.641 187.933h296.717v23.589H91.641ZM91.641 107.236h296.717v23.589H91.641ZM91.641 141.998h296.717v34.762H91.641Z"
                fill="#ffca65"
                stroke-width="1.2415"
            />

            <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                from="0 240 160"
                to="360 240 160"
                dur="2s"
                repeatCount="indefinite"
            />
        </g>
    </svg>
</template>
