<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { localeTime } from '@/utils/dates'
import { LicenseType } from '@/types/company'
import { useAuthStore } from '@/stores/auth-store'
import {
    ShipmentType,
    DeviationState,
    IncludedTicket,
    IncludedDeviation,
    TourEvent,
} from '@/types/delivery-management'
import { image } from '@/utils/assets'
import { addressString } from '@/utils/string'

import MyFileAttachment from '@/components/my-components/MyFileAttachment.vue'
import MyImageViewer from '@/components/my-components/MyImageViewer.vue'
import ShipmentModal from '@/components/delivery-management/ShipmentModal.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'

export interface Props {
    event: TourEvent
    tickets?: IncludedTicket[]
    deviations?: IncludedDeviation[]
    isShipperCompany?: boolean
    isHubHaulier?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    isShipperCompany: false,
    isHubHauler: false,
    deviations: () => [],
    tickets: () => [],
})

const router = useRouter()
const { t } = useI18n()
const authStore = useAuthStore()

const shipmentModalOpen = ref(false)

const shipmentCompany = computed(() => {
    if (!props.event.shipment) return null
    if (props.event.shipment.type === ShipmentType.Pickup)
        return props.event.shipment.sendingCompany
    return props.event.shipment.receivingCompany
})

const shipmentLocation = computed(() => {
    if (!props.event.shipment) return null
    if (props.event.shipment.type === ShipmentType.Pickup)
        return props.event.shipment.sendingLocation
    return props.event.shipment.receivingLocation
})

const hasShipmentInformation = computed(() => {
    if (!props.event.shipment) return false
    if (props.event.shipment.shipmentNumber) return true
    if (props.event.shipment.references) return true
    if (props.event.shipment.name) return true

    return false
})
</script>

<template>
    <MyPanel bordered panel-class="mt-4" class="grid grid-cols-11 gap-2 pb-2">
        <div class="col-span-4 ml-6">
            <h3
                class="relative text-left pt-4 text-sm font-bold uppercase tracking-wide text-primary-400"
                v-text="t('company')"
            />
            <div class="flex flex-col justify-center py-2">
                <div class="grid grid-cols-[100px_1fr]">
                    <span
                        class="translate-y-0.5 text-sm font-semibold uppercase text-gray-500"
                        v-text="t('company')"
                    />
                    <div>
                        <RouterLink
                            v-if="event.shipment!.receivingCustomerId"
                            :to="{
                                name: 'dm.customers.show',
                                params: {
                                    id: event.shipment!.receivingCustomerId as string,
                                },
                            }"
                        >
                            <span class="font-semibold" v-text="shipmentCompany?.name" />
                        </RouterLink>
                        <span v-else v-text="shipmentCompany?.name" />
                        <span
                            v-if="event.shipment!.soldTo"
                            v-text="' - ' + event.shipment!.soldTo"
                        />
                    </div>
                </div>

                <div class="grid grid-cols-[100px_1fr]">
                    <span
                        class="translate-y-0.5 text-sm font-semibold uppercase text-gray-500"
                        v-text="t('location')"
                    />

                    <div>
                        <span>
                            {{ shipmentLocation?.name }}
                            <template
                                v-if="
                                    authStore.hasLicense(LicenseType.AgentLocationFiltering) &&
                                    shipmentLocation?.agentNumber
                                "
                            >
                                ({{ shipmentLocation?.agentNumber }})
                            </template>
                        </span>
                        <span
                            v-if="event.shipment!.shipTo"
                            v-text="'- ' + event.shipment!.shipTo"
                        />
                    </div>

                    <span
                        class="translate-y-0.5 text-sm font-semibold uppercase text-gray-500"
                        v-text="t('address')"
                    />
                    <span v-text="event.shipment!.address ?? addressString(shipmentLocation!)" />
                </div>
            </div>
        </div>

        <div v-if="event.shipment && hasShipmentInformation" class="col-span-3 col-start-5">
            <h3
                class="relative pt-4 text-sm font-bold uppercase tracking-wide text-primary-400"
                v-text="t('shipment')"
            />
            <div class="flex flex-col justify-center py-2">
                <div
                    v-if="event.shipment.handleableAfter && event.shipment.handleableBefore"
                    class="flex space-x-3"
                >
                    <span
                        class="translate-y-[3px] text-sm font-semibold uppercase tracking-wide text-gray-500"
                        v-text="t('timeslot')"
                    />
                    <div>
                        <span
                            class="text-md font-semibold dark:text-primary-100"
                            v-text="localeTime(event.shipment.handleableAfter) ?? t('notSpecified')"
                        />
                        <span class="font-semibold mx-1">-</span>
                        <span
                            class="text-md font-semibold dark:text-primary-100"
                            v-text="
                                localeTime(event.shipment.handleableBefore) ?? t('notSpecified')
                            "
                        />
                    </div>
                </div>
                <div v-if="event.shipment.category" class="flex space-x-3">
                    <span
                        class="translate-y-[3px] text-sm font-semibold uppercase tracking-wide text-gray-500"
                        v-text="t('category')"
                    />
                    <span v-text="event.shipment?.category.name ?? t('notSpecified')" />
                </div>
                <div v-if="event.shipment.shipmentNumber" class="flex flex-col space-y-1">
                    <span
                        class="translate-y-[3px] text-sm font-semibold uppercase tracking-wide text-gray-500"
                        v-text="t('shipmentNumber') + ':'"
                    />
                    <span v-text="event.shipment?.shipmentNumber ?? t('notSpecified')" />
                </div>

                <div v-if="event.shipment.name" class="flex space-x-3">
                    <span
                        class="translate-y-[3px] text-sm font-semibold uppercase tracking-wide text-gray-500"
                        v-text="t('name')"
                    />
                    <span v-text="event.shipment.name ?? t('notSpecified')" />
                </div>

                <div v-if="event.shipment.references" class="flex flex-col space-y-1">
                    <span
                        class="translate-y-[3px] text-sm font-semibold uppercase tracking-wide text-gray-500"
                        v-text="t('references') + ':'"
                    />
                    <span v-text="event.shipment.references ?? t('notSpecified')" />
                </div>
            </div>
        </div>

        <div v-if="event.shipment?.handledAt" class="col-span-3 col-start-8">
            <h3
                class="relative pt-4 text-sm font-bold uppercase tracking-wide text-primary-400"
                v-text="t('deliveryInformation')"
            />
            <div class="flex flex-col justify-center py-2">
                <div class="flex space-x-4">
                    <span
                        class="translate-y-[3px] text-sm font-semibold uppercase tracking-wide text-gray-500"
                        v-text="t('receiverName')"
                    />
                    <span v-text="event.shipment?.receiverName ?? t('notSpecified')" />
                </div>

                <div v-if="event.shipment?.receiverSignature" class="flex space-x-3">
                    <span
                        class="translate-y-[3px] text-sm font-semibold uppercase tracking-wide text-gray-500"
                        v-text="t('receiverSignature')"
                    />
                    <img
                        class="mt-2 w-16 rounded-full"
                        :src="image(event?.shipment.receiverSignature)"
                    />
                </div>

                <div class="flex flex-col space-y-1">
                    <span
                        class="translate-y-[3px] text-sm font-semibold uppercase tracking-wide text-gray-500"
                        v-text="t('deliveryNote') + ':'"
                    />
                    <span v-text="event.shipment?.deliveryNote ?? t('notSpecified')" />
                </div>
                <div v-if="event.shipment.images.length > 0">
                    <span
                        class="translate-y-[3px] text-sm font-semibold uppercase tracking-wide text-gray-500"
                        v-text="t('images')"
                    />
                    <MyImageViewer class="grid grid-cols-3 gap-3 mt-2">
                        <MyFileAttachment
                            v-for="file in event.shipment.images"
                            :key="file.id"
                            class="cursor-pointer !min-h-[5rem] !max-h-[5rem]"
                            :file="file"
                        />
                    </MyImageViewer>
                </div>
            </div>
        </div>

        <div
            v-if="event.shipment && !props.isHubHaulier"
            class="flex flex-col justify-center col-span-1 col-start-11 space-y-2 p-4"
        >
            <MyButton
                v-if="event.shipment"
                v-tooltip="t('showShipment')"
                class="self-center"
                icon
                plain
                scheme="primary"
                size="small"
                @click="shipmentModalOpen = true"
            >
                <mdi:eye />
            </MyButton>

            <div v-if="props.isShipperCompany" class="flex flex-col self-center space-y-2">
                <MyButton
                    v-for="deviation in props.deviations"
                    :key="deviation.id"
                    v-tooltip="t('showDeviation')"
                    class="relative"
                    icon
                    plain
                    scheme="primary"
                    size="small"
                    @click="
                        router.push({
                            name: 'dm.deviations.show',
                            params: { deviationId: deviation.id },
                        })
                    "
                >
                    <mdi:alert-outline />
                    <span
                        v-if="deviation.state === DeviationState.Closed"
                        class="absolute -top-1 -right-1 rounded-full h-3 w-3 bg-gray-400"
                    />
                    <span
                        v-if="deviation.state === DeviationState.Open"
                        class="absolute -top-1 -right-1 rounded-full h-3 w-3 bg-yellow-500"
                    />

                    <span
                        v-if="deviation.state === DeviationState.Solved"
                        class="absolute -top-1 -right-1 rounded-full h-3 w-3 bg-green-500"
                    />
                </MyButton>
            </div>

            <div v-else class="flex flex-col self-center space-y-2">
                <MyButton
                    v-for="ticket in props.tickets"
                    :key="ticket.id"
                    v-tooltip="t('showTicket')"
                    class="relative"
                    icon
                    plain
                    scheme="primary"
                    size="small"
                    @click="
                        router.push({
                            name: 'dm.tickets.show',
                            params: { ticketId: ticket.id },
                        })
                    "
                >
                    <mdi:alert-outline />
                    <span
                        v-if="ticket.state === DeviationState.Closed"
                        class="absolute -top-1 -right-1 rounded-full h-3 w-3 bg-gray-400"
                    />
                    <span
                        v-if="ticket.state === DeviationState.Open"
                        class="absolute -top-1 -right-1 rounded-full h-3 w-3 bg-yellow-500"
                    />

                    <span
                        v-if="ticket.state === DeviationState.Solved"
                        class="absolute -top-1 -right-1 rounded-full h-3 w-3 bg-green-500"
                    />
                </MyButton>
            </div>

            <hr v-if="event.shipment?.palletExchange" class="my-1" />

            <div v-if="event.shipment?.palletExchange" class="self-center">
                <mdi:swap-horizontal
                    v-tooltip="t('palletExchange')"
                    class="h-6 w-6 text-green-400"
                />
            </div>
        </div>
    </MyPanel>

    <ShipmentModal
        v-if="event.shipment && shipmentModalOpen"
        :id="event.shipment.id"
        @update:open="shipmentModalOpen = false"
    />
</template>
