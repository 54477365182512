<script lang="ts" setup>
import { HTMLAttributes } from 'vue'

export interface Props extends /** @vue-ignore */ HTMLAttributes {
    title: string
    content?: string | null
    break?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    title: '',
    break: false,
})
</script>
<template>
    <div
        class="print:break-inside-avoid"
        :class="props.break ? 'flex flex-col whitespace-pre-wrap' : 'flex justify-between text-sm'"
    >
        <slot name="title">
            <h3 class="mb-2 text-sm font-semibold uppercase text-primary-400">
                {{ props.title }}
            </h3>
        </slot>
        <slot name="content">
            <div class="text-sm text-gray-600 dark:text-primary-50">{{ props.content }}</div>
        </slot>
    </div>
</template>
