<script lang="ts" setup>
import type { Company } from '@/types/company'
import type { PaginatedResponse } from '@/types/general'
import type { DropdownOption } from '@/types/inputs'

import axios from 'axios'
import { reactive, ref } from 'vue'

import { usePaginatedTable } from '@/hooks/table/use-paginated-table'
import { useAuthStore } from '@/stores/auth-store'

import MyButton from '@/components/my-components/MyButton.vue'
import MyMenuItem from '@/components/my-components/MyMenuItem.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MyPhoneInput from '@/components/my-components/form/MyPhoneInput.vue'
import MyRadio from '@/components/my-components/form/MyRadio.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import MyTextarea from '@/components/my-components/form/MyTextarea.vue'
import MyFilterSelect from '@/components/table/MyFilterSelect.vue'
import MyTable from '@/components/table/MyTable.vue'
import MyTableColumn from '@/components/table/MyTableColumn.vue'

const data = reactive({
    name: '',
    text: '',
    region: null as string | null,
    regions: [] as string[],
    date1: '',
    date2: '',
    delivery: false,
    type: [],
    amount: 0,
    resource: '',
    desc: '',
    checkit: false,
    radio: 'jeremyClarkson',
    phoneNumber: '',
    number: 332232,
})
const regions = ref<DropdownOption[]>([
    { value: 'yo', label: 'Yo' },
    { value: 'ya', label: 'Ya' },
    { value: 'yu', label: 'Yu' },
    { value: 'do', label: 'Do' },
    { value: 'da', label: 'Da' },
    { value: 'du', label: 'Du' },
    { value: 'lo', label: 'Lo' },
    { value: 'la', label: 'La' },
    { value: 'lu', label: 'Lu' },
])
const oneRegion = ref([regions.value[0]])
const modalOpen = ref(false)

//=== Tables
const authStore = useAuthStore()
const table = usePaginatedTable<Company>(async (params) => {
    const response = await axios.get<PaginatedResponse<Company>>(
        window.route('company.customers.index', { company: authStore.companyId }),
        {
            params: params,
        },
    )

    return response.data
})
</script>

<template>
    <div class="grid gap-6 pb-14 lg:grid-cols-2">
        <MyPanel class="overflow-auto" padded panel-class="lg:col-span-2" shadow title="Buttons">
            <div class="space-y-4">
                <div class="grid grid-cols-[100px_1fr_350px] items-center justify-between gap-2">
                    <span class="text-xs font-semibold uppercase"> Default: </span>
                    <div class="flex items-center gap-2.5">
                        <MyButton>Default</MyButton>
                        <MyButton scheme="light">Light</MyButton>
                        <MyButton scheme="dark">Dark</MyButton>
                        <MyButton scheme="primary">Primary</MyButton>
                        <MyButton scheme="secondary">Secondary</MyButton>
                        <MyButton scheme="success">Success</MyButton>
                        <MyButton scheme="info">Info</MyButton>
                        <MyButton scheme="warning">Warning</MyButton>
                        <MyButton scheme="danger">Danger</MyButton>
                        <MyButton scheme="primary" small>Small</MyButton>
                        <MyButton icon scheme="primary" small>
                            <mdi:account />
                        </MyButton>
                        <MyButton icon scheme="primary">
                            <mdi:account />
                        </MyButton>
                    </div>
                </div>

                <div class="grid grid-cols-[100px_1fr_350px] items-center justify-between gap-2">
                    <span class="text-xs font-semibold uppercase"> Pill: </span>
                    <div class="flex items-center gap-2.5">
                        <MyButton
                            class="rounded-xl bg-blue-600 text-red-200 hover:bg-red-200 hover:text-blue-600"
                            shape="pill"
                        >
                            Custom
                        </MyButton>
                        <MyButton scheme="light" shape="pill">Light</MyButton>
                        <MyButton scheme="dark" shape="pill">Dark</MyButton>
                        <MyButton scheme="primary" shape="pill">Primary</MyButton>
                        <MyButton scheme="secondary" shape="pill"> Secondary</MyButton>
                        <MyButton scheme="success" shape="pill">Success</MyButton>
                        <MyButton scheme="info" shape="pill">Info</MyButton>
                        <MyButton scheme="warning" shape="pill">Warning</MyButton>
                        <MyButton scheme="danger" shape="pill">Danger</MyButton>
                        <MyButton scheme="primary" shape="pill" small> Small</MyButton>
                        <MyButton icon scheme="primary" shape="pill" small>
                            <mdi:account />
                        </MyButton>
                        <MyButton icon scheme="primary" shape="pill">
                            <mdi:account />
                        </MyButton>
                    </div>
                </div>

                <div class="grid grid-cols-[210px_1fr_350px] items-center justify-between gap-2">
                    <span class="text-xs font-semibold uppercase"> Square: </span>
                    <div class="flex items-center gap-2.5">
                        <MyButton scheme="light" shape="square">Light</MyButton>
                        <MyButton scheme="dark" shape="square">Dark</MyButton>
                        <MyButton scheme="primary" shape="square"> Primary</MyButton>
                        <MyButton scheme="secondary" shape="square"> Secondary</MyButton>
                        <MyButton scheme="success" shape="square"> Success</MyButton>
                        <MyButton scheme="info" shape="square">Info</MyButton>
                        <MyButton scheme="warning" shape="square"> Warning</MyButton>
                        <MyButton scheme="danger" shape="square">Danger</MyButton>
                        <MyButton scheme="primary" shape="square" small> Small</MyButton>
                        <MyButton icon scheme="primary" shape="square" small>
                            <mdi:account />
                        </MyButton>
                        <MyButton icon scheme="primary" shape="square">
                            <mdi:account />
                        </MyButton>
                    </div>
                </div>

                <div class="grid grid-cols-[210px_1fr_350px] items-center justify-between gap-2">
                    <span class="text-xs font-semibold uppercase"> Plain: </span>
                    <div class="flex items-center gap-2.5">
                        <MyButton plain scheme="light">Light</MyButton>
                        <MyButton plain scheme="dark">Dark</MyButton>
                        <MyButton plain scheme="primary">Primary</MyButton>
                        <MyButton plain scheme="secondary">Secondary</MyButton>
                        <MyButton plain scheme="success">Success</MyButton>
                        <MyButton plain scheme="info">Info</MyButton>
                        <MyButton plain scheme="warning">Warning</MyButton>
                        <MyButton plain scheme="danger">Danger</MyButton>
                    </div>
                </div>

                <div class="grid grid-cols-[210px_1fr_350px] items-center justify-between gap-2">
                    <span class="mr-2 w-[200px] text-xs font-semibold uppercase"> Shadowed: </span>
                    <div class="flex items-center gap-2.5">
                        <MyButton scheme="light" shadow>Light</MyButton>
                        <MyButton scheme="dark" shadow>Dark</MyButton>
                        <MyButton scheme="primary" shadow>Primary</MyButton>
                        <MyButton scheme="secondary" shadow>Secondary</MyButton>
                        <MyButton scheme="success" shadow>Success</MyButton>
                        <MyButton scheme="info" shadow>Info</MyButton>
                        <MyButton scheme="warning" shadow>Warning</MyButton>
                        <MyButton scheme="danger" shadow>Danger</MyButton>
                    </div>
                </div>
                <div
                    class="grid grid-cols-[210px_1fr_350px] items-center justify-between gap-2 bg-yellow-500 py-5"
                >
                    <span
                        class="mr-2 w-[200px] pl-3 text-xs font-semibold uppercase"
                        v-text="'shadow on yellow example:'"
                    />
                    <div class="flex items-center gap-2.5">
                        <MyButton class="shadow-yellow-50" scheme="warning" shadow v-text="'50'" />
                        <MyButton
                            class="shadow-yellow-100"
                            scheme="warning"
                            shadow
                            v-text="'100'"
                        />
                        <MyButton
                            class="shadow-yellow-200"
                            scheme="warning"
                            shadow
                            v-text="'200'"
                        />
                        <MyButton
                            class="shadow-yellow-300"
                            scheme="warning"
                            shadow
                            v-text="'300'"
                        />
                        <MyButton
                            class="shadow-yellow-400"
                            scheme="warning"
                            shadow
                            v-text="'400'"
                        />
                        <MyButton
                            class="shadow-yellow-500"
                            scheme="warning"
                            shadow
                            v-text="'500'"
                        />
                        <MyButton
                            class="shadow-yellow-600"
                            scheme="warning"
                            shadow
                            v-text="'600'"
                        />
                        <MyButton
                            class="shadow-yellow-700"
                            scheme="warning"
                            shadow
                            v-text="'700'"
                        />
                        <MyButton
                            class="shadow-yellow-800"
                            scheme="warning"
                            shadow
                            v-text="'800'"
                        />
                        <MyButton
                            class="shadow-yellow-900"
                            scheme="warning"
                            shadow
                            v-text="'900'"
                        />
                    </div>
                </div>
            </div>
        </MyPanel>
        <MyPanel bordered class="overflow-auto" padded panel-class="lg:row-span-3" title="Inputs">
            <div class="space-y-5">
                <div>
                    <MyInput
                        id="name"
                        v-model="data.name"
                        error="string"
                        group-class="bg-white dark:bg-dark-600 border border-gray-300 dark:border-dark-700 p-3"
                        helper="*Help string"
                        label="Default"
                    />
                </div>
                <div>
                    <MyInput
                        id="no-label"
                        v-model="data.name"
                        disabled
                        placeholder="No label and disabled"
                    />
                </div>
                <div>
                    <MyInput
                        id="w-icon"
                        v-model="data.name"
                        label="With Icon"
                        placeholder="Username"
                    >
                        <template #icon>
                            <mdi:account />
                        </template>
                    </MyInput>
                </div>
                <div>
                    <MyInput v-model="data.number" label="Number" type="number" />
                </div>
                <div>
                    <MyTextarea v-model="data.text" label="Textarea" />
                </div>

                <div>
                    <MyPhoneInput v-model="data.phoneNumber" label="Phone number" />
                </div>

                <div>
                    <MyCheckbox v-model="data.checkit" label="Checkbox with label, yass!" />
                    <MyCheckbox :checked="data.checkit" label="Checkbox with label, yass!" />
                </div>

                <div>
                    <div class="flex flex-col gap-3">
                        <MyRadio
                            v-model="data.radio"
                            label="Richard Hammond"
                            name="grandTour"
                            value="richardHammond"
                        />
                        <MyRadio
                            v-model="data.radio"
                            label="Jeremy Clarkson"
                            name="grandTour"
                            value="jeremyClarkson"
                        />
                        <MyRadio
                            v-model="data.radio"
                            label="James May"
                            name="grandTour"
                            value="jamesMay"
                        />
                    </div>
                </div>
            </div>
        </MyPanel>
        <MyPanel bordered padded title="Dropdowns">
            <template #actions>
                <MyMenuItem @click="data.region = 'ya'"> Change to ya</MyMenuItem>
            </template>
            <div class="grid grid-cols-3 gap-3">
                <MySelect v-model="data.region" :options="regions" label="Default" />

                <MySelect
                    v-model="data.region"
                    :options="regions"
                    bordered
                    label="Searchable"
                    searchable
                />

                <MySelect v-model="data.region" :options="regions" label="Slots">
                    <template #option="{ active, selected, option }">
                        <span
                            :class="{
                                'text-yellow-500': active,
                                'font-normal': !active,
                                'text-blue-500': selected && !active,
                                'text-green-500': !selected && !active,
                            }"
                            class="block truncate"
                            v-text="option.label"
                        />
                    </template>
                </MySelect>

                <MySelect v-model="data.regions" :options="regions" label="Multiple" multiple />

                <MySelect
                    v-model="data.regions"
                    :options="regions"
                    label="Multiple - summary"
                    multiple
                    value-as-count
                />

                <MySelect
                    v-model="data.regions"
                    :options="regions"
                    label="Multiple & searchable"
                    multiple
                    searchable
                    value-as-count
                />

                <MySelect v-model="data.region" :options="oneRegion" label="Small list" />

                <MySelect
                    v-model="data.region"
                    :options="regions"
                    clear-button
                    label="Default with clear button"
                />

                <MyFilterSelect
                    :options="regions"
                    label="Filter button"
                    query-param="filterButton"
                    filter-name="filterButton"
                />
            </div>
        </MyPanel>
        <MyPanel
            bordered
            class="overflow-auto"
            padded
            panel-class="lg:row-span-2"
            title="Miscellaneous"
        >
            <div>
                <h2 class="mb-1 text-sm font-semibold uppercase text-gray-500">Modals</h2>
                <MyButton scheme="primary" @click="modalOpen = true"> Open modal</MyButton>

                <MyModal v-model="modalOpen">
                    <template #title> Payment successful</template>

                    <div class="mt-2">
                        <input type="text" />

                        <MySelect
                            v-model="data.region"
                            :options="regions"
                            bordered
                            label="Searchable"
                            searchable
                        />

                        <p class="text-sm text-gray-500">
                            Your payment has been successfully submitted. We’ve sent you an email
                            with all of the details of your order.
                        </p>
                    </div>

                    <div class="mt-2">
                        <p class="text-sm text-gray-500">
                            Your payment has been successfully submitted. We’ve sent you an email
                            with all of the details of your order.
                        </p>
                    </div>

                    <div class="mt-2">
                        <p class="text-sm text-gray-500">
                            Your payment has been successfully submitted. We’ve sent you an email
                            with all of the details of your order.
                        </p>
                    </div>

                    <div class="mt-4">
                        <MyButton scheme="info" @click="modalOpen = false">
                            Got it, thanks!
                        </MyButton>
                    </div>
                </MyModal>
            </div>
            <div class="mt-5">
                <h2 class="mb-1 text-sm font-semibold uppercase text-gray-500">Panel</h2>
            </div>
        </MyPanel>
        <MyPanel panel-class="lg:col-span-2" shadow>
            <MyTable
                :error="table.error.value"
                :get-data="table.refetch"
                :loading="table.loading.value"
                :paginated-data="table.paginationData.value"
                :rows="table.data.value"
                shadow
                table-id="companies"
            >
                <MyTableColumn :sortable="false" name="ID" property="id" />
                <MyTableColumn name="Name" property="customerCompany.name" />
                <MyTableColumn name="Vat" property="customerCompany.vat" />
            </MyTable>
        </MyPanel>
    </div>
</template>
