<script lang="ts" setup>
import { notify } from '@kyvg/vue3-notification'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { Company, Customer, CustomerCompany, LicenseType } from '@/types/company'
import { Address } from '@/types/form'
import { MyVatNumberInputRef } from '@/types/inputs'
import { image as imageHelper } from '@/utils/assets'
import { addressString } from '@/utils/string'
import { cloudflareUpload } from '@/utils/upload'

import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyFileSelect from '@/components/my-components/MyFileSelect.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyAddressInput from '@/components/my-components/form/MyAddressInput.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MyVatNumberInput from '@/components/my-components/form/MyVatNumberInput.vue'

export interface Props {
    modelValue: boolean
    customer: Customer<CustomerCompany>
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void
    (e: 'saved'): void
}>()

const { t } = useI18n()
const authStore = useAuthStore()
const addressInput = ref('')
const vatInputRef = ref<MyVatNumberInputRef>()
const { data, loading, submit, errors } = useForm<Company>({
    ...props.customer.customerCompany,
})
const uploading = ref(false)

function addressChanged(address: Address) {
    data.address = address.address
    data.city = address.city
    data.zipcode = address.zipcode + ''
    data.country = address.country
    data.location = { latitude: address.latitude, longitude: address.longitude }
}

async function onSubmit() {
    if (!data.privateCustomer && !vatInputRef.value?.validate()) return

    const companyResponse = await submit(
        'PUT',
        window.route('company.update', {
            company: props.customer.customerCompany.id,
            userCompanyId: authStore.companyId,
        }),
    )

    if (companyResponse) emit('saved')
}

async function handleImageUpload(file: File) {
    uploading.value = true
    try {
        data.logo = await cloudflareUpload(file)
        uploading.value = false
    } catch (e) {
        notify({ title: t('unknownError'), type: 'error' })
    }
}

watch(
    () => props.modelValue,
    (open) => {
        if (!open) return

        data.privateCustomer = props.customer.customerCompany.privateCustomer
        data.vat = props.customer.customerCompany.vat || ''
        data.name = props.customer.customerCompany.name
        data.address = props.customer.customerCompany.address
        data.city = props.customer.customerCompany.city
        data.zipcode = props.customer.customerCompany.zipcode
        data.country = props.customer.customerCompany.country
        data.logo = props.customer.customerCompany?.logo
        addressInput.value = addressString(props.customer.customerCompany)
    },
)
</script>

<template>
    <MyModal :value="props.modelValue" @close="emit('update:modelValue', false)">
        <template #title>
            {{ t('editCompany') }}
        </template>
        <LoaderWrapper :visible="loading" class="rounded-xl" />

        <MyForm class="mt-5 space-y-4" :errors="errors" @submit.prevent="onSubmit">
            <div class="flex justify-center">
                <LoaderWrapper :visible="uploading" class="rounded-xl" />

                <MyFileSelect class="w-fit rounded-xl" type="image/*" @selected="handleImageUpload">
                    <p
                        class="absolute z-10 text-xs font-semibold dark:text-gray-300"
                        v-text="t('dragAndDropImage')"
                    />
                    <img
                        v-if="data?.logo"
                        :src="imageHelper(data.logo)"
                        class="m-2 max-h-40 rounded-xl opacity-60"
                    />
                </MyFileSelect>
            </div>

            <MyVatNumberInput
                v-if="!data.privateCustomer"
                ref="vatInputRef"
                v-model="data.vat"
                name="vat"
                :label="t('vat')"
                autofocus
                :default-country="data.country"
            />

            <MyCheckbox
                v-if="authStore.hasLicense(LicenseType.PrivateCustomers)"
                v-model="data.privateCustomer"
                :label="t('privateCustomer')"
                @change="data.vat = ''"
            />

            <MyInput
                v-model="data.name"
                name="name"
                :label="t('companyName')"
                :autofocus="!props.customer.customerCompany.vat"
            />

            <MyAddressInput
                v-model="addressInput"
                name="address"
                :label="t('address')"
                @change="addressChanged"
            />

            <div class="flex justify-end">
                <MyButton :disabled="loading" scheme="primary">
                    <span v-text="t('save')" />
                </MyButton>
            </div>
        </MyForm>
    </MyModal>
</template>
