<script lang="ts" setup>
import { useMagicKeys, useMutationObserver } from '@vueuse/core'
import { ref, watch } from 'vue'

const modalCount = ref(0)
const modalContainer = ref<HTMLDivElement>()
const scrollContainer = ref<HTMLDivElement>()
const { escape } = useMagicKeys()

useMutationObserver(modalContainer, childrenChanged, { childList: true })

function childrenChanged() {
    const children = modalContainer.value?.children ?? []
    modalCount.value = children.length || 0

    if (modalCount.value === 0) return

    // Scroll to top when opening and closing a modal
    scrollContainer.value?.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

    // Remove class from the modal in front and add it to all behind it
    children[modalCount.value - 1].classList.remove('my-modal-behind')
    for (const child of Array.from(children).slice(0, -1)) {
        child.classList.add('my-modal-behind')
    }
}

function closeLatestModal() {
    const children = modalContainer.value?.children ?? []
    if (children.length === 0) return

    children[children.length - 1].dispatchEvent(new Event('close'))
}

function closeAllModals() {
    const children = Array.from(modalContainer.value?.children ?? [])

    children.forEach((child) => child.dispatchEvent(new Event('close')))
}

function containerClicked(e: MouseEvent) {
    // Close current modal when clicking a modal below it
    if ((e.target as HTMLElement)?.classList.contains('my-modal-behind')) {
        closeLatestModal()
    }
}

watch(escape, (pressed) => {
    if (pressed) closeLatestModal()
})
</script>

<template>
    <div
        ref="scrollContainer"
        :class="{ 'pointer-events-none': modalCount === 0 }"
        class="fixed inset-0 z-50 overflow-y-auto"
    >
        <div class="min-h-screen px-4 text-center">
            <Transition appear name="modal-fade">
                <div
                    v-if="modalCount > 0"
                    class="fixed inset-0 bg-dark-900/70"
                    aria-hidden
                    @click="closeLatestModal"
                >
                    <div class="absolute right-0 cursor-pointer p-3" @click="closeAllModals">
                        <mdi:close class="text-white" />
                    </div>
                </div>
            </Transition>

            <div id="modals" ref="modalContainer" @click="containerClicked" />
        </div>
    </div>
</template>
