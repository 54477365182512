<script lang="ts" setup>
import type { MinimalTransaction } from '@/types/transaction'

import { useI18n } from 'vue-i18n'

import { TransactionType } from '@/types/transaction'

const { t } = useI18n()
const props = defineProps<{ transaction: MinimalTransaction }>()
</script>

<template>
    <div class="flex space-x-1 text-xs text-gray-600 dark:text-primary-50">
        <mdi:file-edit
            v-if="!!transaction.originalTransactionId"
            v-tooltip="t('transactionIsRevision')"
            class="cursor-help"
        />
        <mdi:signature-freehand
            v-if="!!props.transaction.details?.receiverSignature"
            v-tooltip="t('hasSignature')"
            class="cursor-help"
        />

        <mdi:file-document
            v-if="props.transaction.consignmentNoteCount > 0"
            v-tooltip="t('hasConsignmentNotes')"
            class="cursor-help"
        />
        <mdi:comment
            v-if="!!props.transaction.details?.note"
            v-tooltip="t('hasNote')"
            class="cursor-help"
        />

        <mdi:image-multiple
            v-if="transaction.imageCount > 0"
            v-tooltip="t('hasImages')"
            class="cursor-help"
        />

        <mdi:handshake
            v-if="!!props.transaction.receivingUser"
            v-tooltip="t('activeTransaction')"
            class="cursor-help"
        />

        <mdi:scale-balance
            v-if="transaction.type === TransactionType.Reset"
            v-tooltip="t('balanceAdjustmentTransaction')"
            class="cursor-help"
        />
        <mdi:warehouse
            v-if="transaction.type === TransactionType.Location"
            v-tooltip="t('locationTransaction')"
            class="cursor-help"
        />
        <mdi:view-parallel
            v-if="transaction.type === TransactionType.PalletBank"
            v-tooltip="t('palletBank')"
            class="cursor-help"
        />
        <mdi:desktop-mac
            v-if="transaction.type === TransactionType.Backend"
            v-tooltip="t('webTransaction')"
            class="cursor-help"
        />
        <mdi:cellphone-android
            v-if="transaction.type === TransactionType.Default"
            v-tooltip="t('appTransaction')"
            class="cursor-help"
        />
        <mdi:human-dolly
            v-if="transaction.type === TransactionType.Load"
            v-tooltip="t('loadingTransaction')"
            class="cursor-help rotate-y-180"
        />
        <mdi:human-dolly
            v-if="transaction.type === TransactionType.Unload"
            v-tooltip="t('unloadingTransaction')"
            class="cursor-help"
        />
        <mdi:truck-delivery-outline
            v-if="transaction.type === TransactionType.Transit"
            v-tooltip="t('transitTransaction')"
            class="cursor-help"
        />

        <mdi:clipboard-alert-outline
            v-if="transaction.transitProductDifference"
            v-tooltip="t('transitProductDifferenceExplanation')"
            class="cursor-help text-red-500"
        />

        <mdi:egg-outline
            v-if="transaction.productsDelivered === 0 && transaction.productsReceived === 0"
            v-tooltip="t('nilTradeWarning')"
            class="cursor-help text-yellow-500"
        />
    </div>
</template>
