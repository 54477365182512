<script lang="ts">
interface Props {
    templateImage: MasterTemplateImage
    modelValue: DatabaseSection[]
    incidents?: ReportIncident[]
    filter?: IncidentType
    companyLogo: string | null
}
</script>

<script lang="ts" setup>
import { useResizeObserver } from '@vueuse/core'
import { computed, onMounted, ref, watch } from 'vue'

import {
    Coordinate,
    DatabaseSection,
    PreparedSection,
    Section,
} from '@/types/template-section-view'
import { IncidentType, ReportIncident } from '@/types/transport-object'
import { uuid } from '@/types/general'
import { getIncidentTypeColor } from '@/utils/damage-report'
import { MasterTemplateImage } from '@/types/damage-report'

import MasterTemplateImageView from '@/components/damage-reports/MasterTemplateImageView.vue'

const canvasSize = { width: 1600, height: 900 }

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'update:modelValue', sections: DatabaseSection[]): void
    (e: 'sectionClicked', section: DatabaseSection): void
}>()

const wrapper = ref<HTMLDivElement>()
const sections = ref<Section[]>(toSections(props.modelValue))
const canvasScale = ref(1)

const groupedIncidents = computed(() => {
    const incidents: Record<uuid, Partial<Record<IncidentType, number>>> = {}

    for (const incident of props.incidents ?? []) {
        incidents[incident.masterTemplateSectionId] ??= {}
        incidents[incident.masterTemplateSectionId][incident.type] ??= 0
        incidents[incident.masterTemplateSectionId]![incident.type]! += 1
    }

    return incidents
})

const preparedSections = computed<PreparedSection[]>(() => {
    return sections.value.map((section) => {
        let minX = canvasSize.width
        let maxX = 0
        let minY = canvasSize.height
        let maxY = 0

        for (const coordinate of section.coordinates) {
            minX = Math.min(minX, coordinate.x)
            maxX = Math.max(maxX, coordinate.x)
            minY = Math.min(minY, coordinate.y)
            maxY = Math.max(maxY, coordinate.y)
        }

        const rect = { left: minX, top: minY, width: maxX - minX, height: maxY - minY }

        let centerX = Math.max(section.coordinates[0].x, section.coordinates[3].x)
        let centerWidth = Math.min(section.coordinates[1].x, section.coordinates[2].x) - centerX
        let centerY = Math.max(section.coordinates[0].y, section.coordinates[1].y)
        let centerHeight = Math.min(section.coordinates[2].y, section.coordinates[3].y) - centerY
        centerX = (rect.left - centerX) / 2 + centerX
        centerY = (rect.top - centerY) / 2 + centerY
        centerHeight = (rect.height - centerHeight) / 2 + centerHeight
        centerWidth = (rect.width - centerWidth) / 2 + centerWidth

        return {
            ...section,
            rect,
            centerRect: { left: centerX, top: centerY, width: centerWidth, height: centerHeight },
        }
    })
})

function convertCoordinates(coordinates: Coordinate[]): string {
    return coordinates.map((coordinate) => `${coordinate.x},${coordinate.y}`).join(' ')
}

function toSections(sections: DatabaseSection[]): Section[] {
    return sections.map((section) => ({
        ...section,
        coordinates: section.coordinates.split(' ').map((coordinate) => {
            const split = coordinate.split(',')

            return { x: parseFloat(split[0]), y: parseFloat(split[1]) }
        }),
    }))
}

function toTemplateSections(sections: Section[]): DatabaseSection[] {
    return sections.map((section) => ({
        id: section.id,
        name: section.name,
        coordinates: convertCoordinates(section.coordinates),
    }))
}

useResizeObserver(wrapper, ([e]) => {
    canvasScale.value = e.contentRect.width / canvasSize.width
})

watch(
    () => props.modelValue,
    (value) => {
        const templateSections = toTemplateSections(sections.value)
        const stateCoordinates = templateSections.map((section) => section.coordinates).join('')
        const propCoordinates = props.modelValue.map((section) => section.coordinates).join('')

        if (propCoordinates !== stateCoordinates) sections.value = toSections(value)
    },
)

onMounted(() => {
    canvasScale.value = wrapper.value!.getBoundingClientRect().width / canvasSize.width
})
</script>

<template>
    <div>
        <div ref="wrapper" class="relative aspect-video w-full">
            <MasterTemplateImageView :image="templateImage" :company-logo="props.companyLogo" />

            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xmlns:svgjs="http://svgjs.dev/svgjs"
                :viewBox="`0 0 ${canvasSize.width} ${canvasSize.height}`"
                class="absolute top-0 left-0 h-full w-full"
            >
                <polygon
                    v-for="(section, index) in sections"
                    :key="section.name"
                    class="cursor-pointer fill-transparent"
                    :points="convertCoordinates(section.coordinates)"
                    @click="emit('sectionClicked', props.modelValue[index])"
                />
            </svg>

            <div
                v-for="section in preparedSections"
                :key="section.name"
                class="section-data pointer-events-none absolute flex flex-col items-center justify-center text-white drop-shadow-md"
                :style="{
                    left: (section.centerRect.left / canvasSize.width) * 100 + '%',
                    top: (section.centerRect.top / canvasSize.height) * 100 + '%',
                    height: section.centerRect.height + 'px',
                    width: section.centerRect.width + 'px',
                }"
            >
                {{ section.name }}

                <div class="section-damages">
                    <div
                        v-for="(amount, incidentType) in groupedIncidents[section.id]"
                        :key="incidentType"
                        :class="getIncidentTypeColor(incidentType)"
                        v-text="amount"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.section-data {
    font-size: 35px;
    font-weight: bold;
    transform: scale(v-bind(canvasScale));
    transform-origin: top left;
    text-shadow: 0 0 3px black;
}

.section-damages {
    text-shadow: none;
    @apply flex flex-wrap justify-center space-x-0.5;
}

.section-damages > div {
    @apply mb-1 rounded-lg px-5 py-2.5 text-center text-2xl leading-snug;
}
</style>
