import { defineStore } from 'pinia'
import axios from 'axios'

import { useAuthStore } from '@/stores/auth-store'
import { AlarmCounts } from '@/types/alarm'
import { ResourceResponse } from '@/types/general'

interface AlarmStoreState {
    counts: AlarmCounts
}

export const useAlarmStore = defineStore('AlarmStore', {
    state(): AlarmStoreState {
        return {
            counts: {
                total: 0,
                byLicense: {},
            },
        }
    },

    actions: {
        async fetchCounts() {
            const authStore = useAuthStore()

            if (!authStore.companyId) return

            const response = await axios.get<ResourceResponse<AlarmCounts>>(
                window.route('company.alarms.counts', [authStore.companyId]),
            )

            this.counts = response.data.data
        },
    },
})
