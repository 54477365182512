<script lang="ts" setup>
import type { Except } from 'type-fest'

import { useI18n } from 'vue-i18n'
import { ref, watch } from 'vue'
import { notify } from '@kyvg/vue3-notification'
import axios from 'axios'

import useForm from '@/hooks/use-form'
import { useEmployeeRoleStore } from '@/stores/employee-role-store'
import { EmployeeRole } from '@/types/user'
import { uuid } from '@/types/general'
import { ReportPage, VehicleReportPreference } from '@/types/damage-report'
import { DropdownOption } from '@/types/inputs'
import { useAuthStore } from '@/stores/auth-store'
import { useConfirm } from '@/hooks/use-confirm'
import { MyButtonScheme } from '@/types/layout/my-button'

import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import MyRadioButtonGroup from '@/components/my-components/form/MyRadioButtonGroup.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyRadioButton from '@/components/my-components/form/MyRadioButton.vue'

export interface Props {
    modelValue: boolean
}

interface AppRoleForm extends Except<EmployeeRole, 'companyId' | 'id'> {
    id: uuid | null
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'close', value: void): void
    (e: 'update:modelValue', value: boolean): void
}>()

const { t } = useI18n()
const authStore = useAuthStore()
const roleStore = useEmployeeRoleStore()
const confirm = useConfirm()

const pages = ref<DropdownOption[]>([
    { value: ReportPage.Incidents, label: t('incidents') },
    { value: ReportPage.TireThreads, label: t('tireThreads') },
    { value: ReportPage.Fields, label: t('fields') },
])
const form = useForm<AppRoleForm>({
    id: null,
    name: '',
    dmrInstantUnitPickup: false,
    dmrOnlyPickup: false,
    dmrPages: [],
    dmrVehicleReport: VehicleReportPreference.Optional,
})

function getPageTranslations(role: EmployeeRole): string {
    if (role.dmrPages.length === 0 || role.dmrPages.length === 3) {
        return t('all')
    }

    const pageTranslations = {
        [ReportPage.Incidents]: t('incidents'),
        [ReportPage.TireThreads]: t('tireThreads'),
        [ReportPage.Fields]: t('fields'),
    }

    return role.dmrPages.map((page) => pageTranslations[page]).join(', ')
}

function instantUnitPickupToggled(checked: boolean) {
    if (!checked) return

    form.data.dmrOnlyPickup = true
}

function editRole(role: EmployeeRole) {
    form.reset({
        id: role.id,
        name: role.name,
        dmrOnlyPickup: role.dmrOnlyPickup,
        dmrPages: role.dmrPages,
        dmrVehicleReport: role.dmrVehicleReport,
        dmrInstantUnitPickup: role.dmrInstantUnitPickup,
    })
}

async function removeRole(role: EmployeeRole) {
    try {
        await confirm(
            t('deleteEntityTitle', { entity: t('userRole') }),
            t('deleteEntityDescription', { entity: t('userRole') }),
            {
                confirmText: t('yes'),
                cancelText: t('no'),
                confirmButtonScheme: MyButtonScheme.Warning,
            },
        )

        await axios.delete(
            window.route('company.employee-roles.destroy', [authStore.companyId, role.id]),
        )
        roleStore.fetchAppRoles(true)
    } catch (_) {
        //
    }
}

async function onSubmit() {
    const method = form.data.id ? 'PUT' : 'POST'
    let url = window.route('company.employee-roles.store', [authStore.companyId])

    if (form.data.id) {
        url = window.route('company.employee-roles.update', [authStore.companyId, form.data.id])
    }

    const response = await form.submit(method, url)

    if (response) {
        roleStore.fetchAppRoles(true)
        notify(t(form.data.id ? 'roleHasBeenUpdated' : 'roleHasBeenCreated'))
        form.reset()
    }
}

watch(
    () => form.data.dmrOnlyPickup,
    (onlyPickup) => {
        if (onlyPickup && form.data.dmrVehicleReport === VehicleReportPreference.Required) {
            form.data.dmrVehicleReport = VehicleReportPreference.Optional
        }
    },
)

watch(
    () => props.modelValue,
    () => form.reset(),
)
</script>

<template>
    <MyModal :value="props.modelValue" @close="emit('update:modelValue', false)">
        <template #title>
            {{ t('userRoles') }}
        </template>

        <LoaderWrapper :visible="form.loading.value || roleStore.fetchingAppRoles" />

        <div class="mt-2 text-sm">
            <div v-for="role in roleStore.appRoles" :key="role.id">
                <div class="flex items-center justify-between">
                    <h3 class="text-base font-semibold text-primary-400" v-text="role.name" />

                    <div v-if="role.companyId">
                        <MyButton
                            v-tooltip="t('updateUserRole')"
                            type="button"
                            icon
                            @click="editRole(role)"
                        >
                            <mdi:pencil />
                        </MyButton>

                        <MyButton
                            v-if="role.companyId"
                            v-tooltip="t('remove')"
                            type="button"
                            icon
                            @click="removeRole(role)"
                        >
                            <mdi:trash-can-outline class="text-red-500" />
                        </MyButton>
                    </div>
                </div>

                <div>
                    <b class="mr-2 font-semibold" v-text="t('damageReports')" />

                    <span v-if="role.dmrInstantUnitPickup">
                        <div class="inline-flex items-center space-x-1">
                            <span v-text="t('instantUnitPickup')" />
                            <mdi:information v-tooltip="t('instantUnitPickupExplanation')" />
                        </div>
                    </span>

                    <template v-else>
                        <span v-if="role.dmrOnlyPickup" v-text="t('onlyPickup')" />
                        <span v-else v-text="t('pickupAndDropOff')" />
                    </template>
                </div>

                <div>
                    <b class="mr-2 font-semibold" v-text="t('pages')" />

                    <span v-text="getPageTranslations(role)" />
                </div>

                <div>
                    <b class="mr-2 font-semibold" v-text="t('vehicleReports')" />

                    <span
                        v-if="role.dmrVehicleReport === VehicleReportPreference.Required"
                        v-text="t('required')"
                    />
                    <span
                        v-if="role.dmrVehicleReport === VehicleReportPreference.Optional"
                        v-text="t('optional')"
                    />
                    <span
                        v-if="role.dmrVehicleReport === VehicleReportPreference.Off"
                        v-text="t('off')"
                    />
                </div>

                <hr class="my-2" />
            </div>

            <h1
                class="mt-4 mb-2 text-lg font-semibold text-primary-400"
                v-text="t(form.data.id ? 'updateUserRole' : 'createUserRole')"
            />

            <MyForm :errors="form.errors.value" class="space-y-4" @submit.prevent="onSubmit">
                <MyInput v-model="form.data.name" name="name" :label="t('name')" />

                <div class="flex items-center space-x-2">
                    <MyCheckbox
                        v-model="form.data.dmrInstantUnitPickup"
                        :label="t('instantUnitPickup')"
                        @change="instantUnitPickupToggled"
                    />
                    <mdi:information v-tooltip="t('instantUnitPickupExplanation')" />
                </div>

                <MyRadioButtonGroup
                    v-if="!form.data.dmrInstantUnitPickup"
                    v-model="form.data.dmrOnlyPickup"
                    name="dmrOnlyPickup"
                    :label="t('damageReports')"
                >
                    <MyRadioButton :label="t('pickupAndDropOff')" :value="false" />
                    <MyRadioButton :label="t('onlyPickup')" :value="true" />
                </MyRadioButtonGroup>

                <MyRadioButtonGroup
                    v-if="!form.data.dmrInstantUnitPickup"
                    v-model="form.data.dmrVehicleReport"
                    name="dmrVehicleReport"
                    :label="t('vehicleReports')"
                >
                    <MyRadioButton
                        v-if="!form.data.dmrOnlyPickup"
                        :label="t('required')"
                        :value="VehicleReportPreference.Required"
                    />
                    <MyRadioButton
                        :label="t('optional')"
                        :value="VehicleReportPreference.Optional"
                    />
                    <MyRadioButton :label="t('off')" :value="VehicleReportPreference.Off" />
                </MyRadioButtonGroup>

                <MySelect
                    v-model="form.data.dmrPages"
                    name="dmrPages"
                    :label="t('pages')"
                    :options="pages"
                    :placeholder="t('all')"
                    clear-button
                    multiple
                />

                <div class="flex flex-row-reverse justify-start space-x-3">
                    <MyButton
                        :disabled="form.loading.value"
                        scheme="primary"
                        v-text="t(form.data.id ? 'update' : 'create')"
                    />

                    <MyButton type="button" @click="emit('update:modelValue', false)">
                        {{ t('cancel') }}
                    </MyButton>
                </div>
            </MyForm>
        </div>
    </MyModal>
</template>
