<script lang="ts" setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import MyButton from '@/components/my-components/MyButton.vue'

const logoutForm = ref<HTMLFormElement>()
const csrfToken = window.csrfToken

const { t } = useI18n({ useScope: 'global' })

function logout() {
    logoutForm.value!.submit()
}
</script>

<template>
    <MyButton plain size="small" class="my-auto" @click="logout">
        <mdi:logout class="mr-2 h-4 w-4" />
        <span class="text-xs font-semibold" v-text="t('logout')" />
    </MyButton>
    <form ref="logoutForm" action="/logout" method="POST">
        <input type="hidden" name="_token" :value="csrfToken" />
    </form>
</template>
