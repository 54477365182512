<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import { CustomerLocation } from '@/types/company'
import { addressString } from '@/utils/string'

export interface Props {
    location: CustomerLocation
    error?: string | null
}

const props = withDefaults(defineProps<Props>(), {
    error: null,
})

const { t } = useI18n()
</script>

<template>
    <div
        class="flex items-center justify-between w-full h-20 rounded-xl bg-white p-3 shadow-md dark:bg-dark-400 relative"
    >
        <div class="flex flex-col mr-4">
            <span class="text-sm dark:text-primary-50">
                {{ `${props.location.name} (${props.location.companyName})` }}
            </span>
            <span class="text-xs dark:text-primary-200">{{ addressString(props.location) }}</span>
            <span v-if="props.location.pNumber" class="text-xs dark:text-primary-200">
                <b class="uppercase">{{ t('pNumber') }}</b>
                {{ props.location.pNumber }}
            </span>
        </div>
        <slot name="action" />

        <div
            v-if="error"
            class="absolute bottom-[-0.5rem] right-4 bg-red-500 rounded-xl px-2 py-1 text-xs flex-shrink-0"
        >
            {{ error }}
        </div>
    </div>
</template>
