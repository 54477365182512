<script lang="ts" setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@/stores/auth-store'

import Importer from '@/components/import-export/Importer.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'

const emit = defineEmits<{ (e: 'import-finished'): void }>()

const { t } = useI18n()
const authStore = useAuthStore()

const open = ref(false)
const tabs = { customers: t('customers'), locations: t('locations') }
const tab = ref('customers')

function getEndpoint(): string {
    if (tab.value === 'customers') {
        return window.route('company.customers.import', { company: authStore.companyId })
    }

    return window.route('company.customers.import-locations', { company: authStore.companyId })
}
</script>

<template>
    <MyModal v-model="open" no-padding>
        <nav
            class="flex w-full justify-between overflow-hidden rounded-t-xl border-b border-primary-200 bg-gradient-radial-to-br from-primary-500 via-primary-500 to-green-500 uppercase dark:border-dark-400 dark:from-primary-700 dark:via-primary-600 dark:to-green-700"
        >
            <div
                v-for="(name, key) in tabs"
                :key="key"
                :class="{
                    'pointer-events-none cursor-default bg-transparent text-white': key === tab,
                    'cursor-pointer bg-primary-50 hover:bg-transparent hover:text-white dark:bg-dark-500 dark:hover:bg-transparent':
                        key !== tab,
                }"
                class="block w-full border-r border-primary-200 py-1.5 px-3 text-center text-xs font-semibold transition-colors last:border-r-0 dark:border-dark-400 lg:py-4 lg:text-sm"
                @click="tab = key"
                v-text="name"
            />
        </nav>

        <div class="m-6 mt-4">
            <h3
                class="relative mb-2 text-lg font-semibold uppercase text-primary-400"
                v-text="t('importModel', { model: t(tab) })"
            />

            <Importer
                v-if="open"
                :endpoint="getEndpoint()"
                :template="tab === 'customers' ? 'customers' : 'customer-locations'"
                @import-finished="emit('import-finished')"
            />
        </div>
    </MyModal>

    <slot>
        <MyButton scheme="action" size="small" @click="open = true">
            <mdi:export class="mr-1" />
            {{ t('import') }}
        </MyButton>
    </slot>
</template>
