<script lang="ts" setup>
import type { HTMLClass } from '@/types/inputs'

import { computed, getCurrentInstance, inject, onBeforeUnmount, onMounted, watch } from 'vue'

import {
    insertColumnKey,
    InternalTableColumn,
    removeColumnKey,
    updateColumnKey,
    DataType,
    ColumnFilterOptions,
} from '@/types/table'

export interface Props {
    property: string
    name: string
    date?: string
    hidden?: boolean
    sortable?: boolean
    draggable?: boolean
    group?: string
    minWidth?: number
    width?: number
    booleanColumn?: boolean
    class?: HTMLClass
    filterable?: boolean | ColumnFilterOptions
}

const props = withDefaults(defineProps<Props>(), {
    hidden: false,
    sortable: true,
    draggable: true,
    minWidth: 100,
})

const instance = getCurrentInstance()

const insertColumn = inject(insertColumnKey)!
const updateColumn = inject(updateColumnKey)!
const removeColumn = inject(removeColumnKey)!

const column = computed<InternalTableColumn>(() => ({
    property: props.property,
    name: props.name,
    date: props.date,
    hidden: props.hidden,
    sortable: props.sortable,
    draggable: props.draggable,
    group: props.group,
    minWidth: Math.min(props.minWidth, props.width || Infinity),
    width: props.width || (props.booleanColumn ? 120 : undefined),
    slots: instance?.slots,
    order: 0,
    booleanColumn: props.booleanColumn || false,
    class: props.class,
    filterable: determineFilterType(),
}))

function determineFilterType(): ColumnFilterOptions | undefined {
    if (!props.filterable) return

    if (props.filterable === true) {
        return { dataType: DataType.String }
    }
    return props.filterable
}

watch(column, (value, oldValue) => {
    // Due to a possible bug with Vue the watcher is called even when it has no changes
    // This happens only for components in slots that has a prop with object type
    // MyTableColumn is rendered in MyTable's slot and filterable is an object
    if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
        updateColumn(column.value.property, column.value)
    }
})
onMounted(() => insertColumn!(column.value))
onBeforeUnmount(() => removeColumn(column.value))
</script>

<template>
    <slot />
</template>
