<script lang="ts" setup>
import { useDark, useToggle } from '@vueuse/core'
import axios from 'axios'

import { useAuthStore } from '@/stores/auth-store'

interface Response {
    redirect: string
}

const isDark = useDark()
const toggleDark = useToggle(isDark)
const authStore = useAuthStore()

async function stopImpersonating() {
    const response = await axios.delete<Response>('/nova-api/impersonate')

    window.location.href = response.data.redirect === '/' ? '/nova' : response.data.redirect
}
</script>

<template>
    <nav
        v-if="authStore.isAdmin || authStore.isImpersonating"
        class="flex items-center justify-between text-sm"
        :class="{
            'bg-green-500 text-white': authStore.isImpersonating,
            'bg-primary-200 text-primary-400 dark:bg-dark-700': !authStore.isImpersonating,
        }"
    >
        <div class="py-2 px-6">
            <a
                class="cursor-pointer text-xs hover:text-primary-600 dark:hover:text-primary-200"
                @click.prevent="toggleDark()"
            >
                <mdi:brightness-3 v-if="isDark" />
                <mdi:brightness-7 v-else />
            </a>
        </div>

        <div class="flex grow items-center justify-end space-x-5 py-2 px-6 text-right">
            <RouterLink
                to="/component-page"
                class="text-xs font-semibold uppercase hover:text-primary-600 dark:hover:text-primary-200"
            >
                Components
            </RouterLink>

            <a
                v-if="!authStore.isImpersonating"
                href="/nova"
                class="text-xs font-semibold uppercase hover:text-primary-600 dark:hover:text-primary-200"
            >
                Nova dashboard
            </a>

            <button
                v-else
                class="flex items-center text-xs font-semibold uppercase hover:text-primary-600 dark:hover:text-primary-200"
                @click="stopImpersonating"
            >
                <mdi:fingerprint class="mr-2" />

                Stop Impersonating
            </button>
        </div>
    </nav>
</template>
