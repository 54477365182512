<script lang="ts" setup>
import Datepicker, { DatePickerInstance } from '@vuepic/vue-datepicker'
import axios from 'axios'
import dayjs from 'dayjs'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouteLocationRaw } from 'vue-router'

import { usePaginatedTable } from '@/hooks/table/use-paginated-table'
import { useAuthStore } from '@/stores/auth-store'
import { PaginatedResponse, uuid } from '@/types/general'
import { GetDataParameters } from '@/types/table'
import { ReportEmployee } from '@/types/user'
import { usePresetRangeList } from '@/hooks/use-preset-range-list'

import MyButton from '@/components/my-components/MyButton.vue'
import ActionRowItem from '@/components/table/ActionRowItem.vue'
import MyTable from '@/components/table/MyTable.vue'
import MyTableColumn from '@/components/table/MyTableColumn.vue'

interface FetchEmployeeParams extends Record<string, unknown>, GetDataParameters {
    filter: {
        'start-date': string | undefined
        'end-date': string | undefined
    }
}

const { t } = useI18n()
const authStore = useAuthStore()
const presetRanges = usePresetRangeList()

const { data, paginationData, refetch, params, loading, error } = usePaginatedTable<
    ReportEmployee,
    FetchEmployeeParams
>(
    async (params, abortController) => {
        const response = await axios.get<PaginatedResponse<ReportEmployee>>(
            window.route('dr.company.employees.index', { company: authStore.companyId }),
            { params: params, signal: abortController.signal },
        )

        return response.data
    },
    {
        filter: {
            'start-date': dayjs().subtract(13, 'days').startOf('day').toDate().toISOString(),
            'end-date': dayjs().endOf('day').toDate().toISOString(),
        },
    },
)
const datepicker = ref<DatePickerInstance>(null)
const date = ref<Date[]>(
    params.value.filter['start-date'] && params.value.filter['end-date']
        ? [
              dayjs(params.value.filter['start-date']).toDate(),
              dayjs(params.value.filter['end-date']).toDate(),
          ]
        : [],
)

function employeeReports(id: string): RouteLocationRaw {
    return {
        name: 'dr.reports',
        query: {
            filter: {
                // @ts-ignore
                'user-id': id,
                'start-date': params.value.filter['start-date']
                    ? date.value[0].toISOString()
                    : undefined,
                'end-date': params.value.filter['start-date']
                    ? date.value[1].toISOString()
                    : undefined,
            },
            tab: 'reports',
        },
    }
}

function setFilter(type: string | number | null, filterName: string) {
    params.value.filter[filterName] = type
    params.value.page = 1
}

function clearDates() {
    datepicker.value?.closeMenu()
    date.value = []
    delete params.value.filter['start-date']
    delete params.value.filter['end-date']
}

watch(date, () => {
    if (!date.value[0] || !date.value[1]) return
    setFilter(dayjs(date.value[0]).startOf('day').toISOString(), 'start-date')
    setFilter(dayjs(date.value[1]).endOf('day').toISOString(), 'end-date')
})
</script>

<template>
    <MyTable
        table-id="report-employees"
        :rows="data"
        :pagination-data="paginationData"
        :loading="loading"
        :error="error"
        :get-data="refetch"
        :entity-name="t('employees')"
        :has-time-range="!!params.filter['start-date']"
    >
        <template #filters>
            <Datepicker
                ref="datepicker"
                v-model="date"
                input-class-name="w-64"
                range
                :enable-time-picker="false"
                auto-apply
                :teleport="true"
                :clearable="false"
                :preset-dates="presetRanges"
                close-on-scroll
                position="left"
                format="yyyy-MM-dd"
                :transitions="false"
                :placeholder="t('allTime')"
                :config="{ closeOnAutoApply: true }"
            >
                <template #allTime>
                    <button
                        class="dp__btn dp--preset-range"
                        @click="clearDates()"
                        v-text="t('allTime')"
                    />
                </template>
            </Datepicker>
        </template>

        <template #actionRow="{ row }">
            <RouterLink :to="employeeReports(row.id as uuid)">
                <ActionRowItem v-tooltip="t('showReportsForEmployee')">
                    <mdi:account />
                </ActionRowItem>
            </RouterLink>
        </template>
        <MyTableColumn :name="t('employee')" property="name" />
        <MyTableColumn :name="t('drivingTime')" property="totalDrivingTime" />
        <MyTableColumn :name="t('kmTotal')" property="totalKilometerCount" />
        <MyTableColumn :name="t('status')" property="free" />
        <template #free="{ row }">
            <span v-if="!row.free" v-text="t('enRoute')" />
            <span v-else v-text="t('free')" />
        </template>

        <template #noDataButton>
            <MyButton
                v-if="params.filter['start-date']"
                plain
                scheme="light"
                size="small"
                @click="clearDates()"
            >
                <mdi:magnify class="mr-2 h-4 w-4" />
                {{ t('searchAllTime') }}
            </MyButton>
        </template>
    </MyTable>
</template>
